import gql from "graphql-tag";

export const addressFragment = gql`
    fragment addressFragment on Address {
        city
        street
        country
        streetno
        postalcode
    }
`;