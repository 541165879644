import {PDFDocument, RotationTypes, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getCategories, getQuantities, printPageNo, getCleanedValue} from "./util";
import {formatDateTime} from "../../services";
import {printChart} from "./util";


export async function ambulantTTP24hRRIntervention(masterData, correlationSamples, appointmentDate, domNode) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Ambulant_TTP_24h_RR_Intervention_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Ambulantes TTP - 24h Blutdruckmessung", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const helveticaBoldFont = await pdfDocWithHeading.embedFont(StandardFonts.HelveticaBold);
    const page = pdfDocWithHeading.getPages()[0];

    // adding heading to first page
    page.drawLine({start: { x: 40, y: 270 }, end: { x: 555, y: 270 }, thickness: 0.1});
    page.drawText("Datum", { x: 70, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 107, y: 270 }, end: { x: 107, y: 190 }, thickness: 0.1});
    page.drawText("Systole", { x: 122, y: 227, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 135, y: 270 }, end: { x: 135, y: 190 }, thickness: 0.1});
    page.drawText("Diastole", { x: 150, y: 225, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 163, y: 270 }, end: { x: 163, y: 190 }, thickness: 0.1});
    page.drawText("MD", { x: 178, y: 240, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 192, y: 270 }, end: { x: 192, y: 190 }, thickness: 0.1});
    page.drawText("HF", { x: 207, y: 240, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 220, y: 270 }, end: { x: 220, y: 190 }, thickness: 0.1});
    page.drawText("Dipper %", { x: 247, y: 220, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 265, y: 270 }, end: { x: 265, y: 190 }, thickness: 0.1});
    page.drawText("Abweichung Systole %", { x: 280, y: 203, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees}, maxWidth: 50 });
    page.drawLine({start: { x: 312, y: 270 }, end: { x: 312, y: 190 }, thickness: 0.1});
    page.drawText("Abweichung Diastole %", { x: 327, y: 203, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees}, maxWidth: 55 });
    page.drawLine({start: { x: 360, y: 270 }, end: { x: 360, y: 190 }, thickness: 0.1});
    page.drawText("Fehler", { x: 380, y: 220, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 395, y: 270 }, end: { x: 395, y: 190 }, thickness: 0.1});
    page.drawText("Bermerkungen", { x: 440, y: 225, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 40, y: 190 }, end: { x: 555, y: 190 }, thickness: 0.1});


    const tableData = convertToTable(correlationSamples);

    await printChart(pdfDocWithHeading, domNode);

    printTable(tableData, pdfDocWithHeading, courierFont, helveticaBoldFont);

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Ambulantes_TTP_24h_RR_Summary", "application/pdf");
}

function convertToTable(correlationSamples) {
    return correlationSamples?.map(sample => {
        return [
            formatDateTime(sample.startDate),
            getCleanedValue(getQuantities(sample, "bloodPressureSystolic")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "bloodPressureDiastolic")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "md")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "heartRate")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "dipper")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "deviationSystole")?.quantity) || "",
            getCleanedValue(getQuantities(sample, "deviationDiastole")?.quantity) || "",
            getCategories(sample, "error")?.value || "",
            getCategories(sample, "notes")?.value || ""
        ]
    });
}

function printTable(tableData, pdfDoc, courierFont, helveticaBoldFont) {
    let yValue = 175;
    const interval = 25;
    const maxYValue = 100;
    let page = pdfDoc.getPages()[0];

    for (let i = 0; i < tableData.length; ++i) {
        page.drawText(tableData[i][0].toString(), { x: 40, y: yValue, size: 10, font: courierFont, maxWidth: 20 });
        page.drawLine({start: { x: 107, y: yValue+15 }, end: { x: 107, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][1].toString(), { x: 110, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 135, y: yValue+15 }, end: { x: 135, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][2].toString(), { x: 142, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 163, y: yValue+15 }, end: { x: 163, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][3].toString(), { x: 168, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 192, y: yValue+15 }, end: { x: 192, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][4].toString(), { x: 198, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 220, y: yValue+15 }, end: { x: 220, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][5].toString(), { x: 226, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 265, y: yValue+15 }, end: { x: 265, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][6].toString(), { x: 272, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 312, y: yValue+15 }, end: { x: 312, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][7].toString(), { x: 325, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 360, y: yValue+15 }, end: { x: 360, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][8].toString(), { x: 362, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 395, y: yValue+15 }, end: { x: 395, y: yValue-35 }, thickness: 0.1});
        page.drawText(tableData[i][9].toString(), { x: 400, y: yValue, size: 10, font: courierFont, maxWidth: 150 });
        page.drawLine({start: { x: 40, y: yValue-35 }, end: { x: 555, y: yValue-35 }, thickness: 0.1});

        yValue -= 50;

        if (yValue - interval < maxYValue) {
            page = pdfDoc.addPage();
            printTableHeading(page, helveticaBoldFont);
            yValue = 700;
        }
    }

    printPageNo(pdfDoc, helveticaBoldFont);
}

function printTableHeading(page, helveticaBoldFont) {
    page.drawLine({start: { x: 40, y: 795 }, end: { x: 555, y: 795 }, thickness: 0.1});
    page.drawText("Datum", { x: 70, y: 780, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 107, y: 795 }, end: { x: 107, y: 715 }, thickness: 0.1});
    page.drawText("Systole", { x: 122, y: 752, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 135, y: 795 }, end: { x: 135, y: 715 }, thickness: 0.1});
    page.drawText("Diastole", { x: 150, y: 750, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 163, y: 795 }, end: { x: 163, y: 715 }, thickness: 0.1});
    page.drawText("MD", { x: 178, y: 765, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 192, y: 795 }, end: { x: 192, y: 715 }, thickness: 0.1});
    page.drawText("HF", { x: 207, y: 765, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 220, y: 795 }, end: { x: 220, y: 715 }, thickness: 0.1});
    page.drawText("Dipper %", { x: 247, y: 745, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 265, y: 795 }, end: { x: 265, y: 715 }, thickness: 0.1});
    page.drawText("Abweichung Systole %", { x: 280, y: 728, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees}, maxWidth: 50 });
    page.drawLine({start: { x: 312, y: 795 }, end: { x: 312, y: 715 }, thickness: 0.1});
    page.drawText("Abweichung Diastole %", { x: 327, y: 728, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees}, maxWidth: 55 });
    page.drawLine({start: { x: 360, y: 795 }, end: { x: 360, y: 715 }, thickness: 0.1});
    page.drawText("Fehler", { x: 380, y: 745, size: 10, font: helveticaBoldFont, rotate: {angle: 90, type: RotationTypes.Degrees} });
    page.drawLine({start: { x: 395, y: 795 }, end: { x: 395, y: 715 }, thickness: 0.1});
    page.drawText("Bermerkungen", { x: 440, y: 750, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 40, y: 715 }, end: { x: 555, y: 715 }, thickness: 0.1});
}
