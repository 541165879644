import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.css";
import "style/style.scss";
import {AuthContextProvider, GraphQlProvider} from "services";
import App from "App";
import {initI18n} from "i18n";
import {HashRouter} from "react-router-dom";

const Index = () => {
    initI18n();
    return (
        <AuthContextProvider>
            <GraphQlProvider>
                <HashRouter>
                    <App/>
                </HashRouter>
            </GraphQlProvider>
        </AuthContextProvider>
    );
};

ReactDOM.render(<Index/>, document.getElementById("root"));
