import React, {useState} from "react";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import TermsAndConditions from "./TermsAndConditions";
import NewPatientForm from "./NewPatientForm";
import AgreementDownload from "../agreement/AgreementDownload";
import Finish from "../agreement/Finish";
import {useMutateMasterData} from "../../hooks";
import {get} from "lodash";

const STEPS = {
    AGREEMENT: "AGREEMENT",
    NEW_PATIENT: "NEW_PATIENT",
    AGREEMENT_DOWNLOAD: "AGREEMENT_DOWNLOAD",
    FINISH: "FINISH"
};

const NewPatient = ({onClose, stepProp}) => {
    const {t} = useTranslation();
    let saveCallback;
    const [step, setStep] = useState(stepProp || STEPS.AGREEMENT);
    const [modalOpen, setModalOpen] = useState(true);
    const [openSecond, setOpenSecond] = useState(false);
    const {mutateMasterData, loading} = useMutateMasterData({isEditMode: false});
    const patient = JSON.parse(window.localStorage.getItem("new_patient"));
    const masterData = get(patient, "masterData");
    const studyData = get(patient, "studyData");
    const masterDataSaved = window.masterDataSaved;

    const save = () => {
        if (!masterDataSaved) {
            if (!loading) {
                const model = {input: masterData, doctorInCharge: studyData.doctorInCharge.id};
                mutateMasterData(model).then((id) => {
                    if (id) {
                        window.localStorage.setItem("new_patient_id", id);
                        window.masterDataSaved = true;
                        setStep(STEPS.FINISH);
                    } else {
                        window.masterDataSaved = false;
                        //TODO: if there is an error?
                    }
                });
            }
        } else {
            setStep(STEPS.FINISH);
        }
    };

    const handleFinish = () => {
        window.masterDataSaved = false;
        handleClose(true, window.localStorage.getItem("new_patient_id"));
        window.localStorage.setItem("new_patient", null);
    };

    const handleClose = (refreshData, patientId) => {
        setModalOpen(false);
        window.localStorage.setItem("new_patient", null);
        if (onClose) onClose(refreshData, patientId);
    };

    const handleNext = () => {
        switch (step) {
            case STEPS.AGREEMENT:
                setStep(STEPS.NEW_PATIENT);
                break;
            case STEPS.NEW_PATIENT:
                if (handleSave()) {
                    setStep(STEPS.AGREEMENT_DOWNLOAD);
                }
                break;
            case STEPS.AGREEMENT_DOWNLOAD:
                setOpenSecond(true);
                break;
            default:
                console.warn("Unhandled case in NewPatient handleNext:", step);
        }
    };

    const handleBack = () => {
        switch (step) {
            case STEPS.NEW_PATIENT:
                setStep(STEPS.AGREEMENT);
                break;
            case STEPS.AGREEMENT_DOWNLOAD:
                setStep(STEPS.NEW_PATIENT);
                break;
            case STEPS.FINISH:
                setStep(STEPS.AGREEMENT_DOWNLOAD);
                break;
            default:
                console.warn("Unhandled case in NewPatient handleBack:", step );
        }
    };

    const backVisible = () => {
        return step !== STEPS.AGREEMENT && step !== STEPS.FINISH;
    };

    const nextVisible = () => {
        return step !== STEPS.FINISH;
    };

    const saveVisible = () => {
        return step === STEPS.NEW_PATIENT;
    };

    const finishVisible = () => {
        return step === STEPS.FINISH;
    };

    const handleSave = () => {
        if (saveCallback) {
            return saveCallback();
        } else {
            return true;
        }
    };

    const registerSaveCallback = (saveFn) => {
        saveCallback = saveFn;
    };

    const getLabelForNext = () => {
        if (step === STEPS.AGREEMENT_DOWNLOAD) {
            return t("saveAndContinue");
        } else {
            return t("next");
        }
    };

    return (
        <div>
            <Modal open={modalOpen} closeOnDimmerClick={false} closeOnEscape={false} onClose={() => handleClose(false)}>
                <Header>
                    <Icon name={"user plus"}
                          inverted/> {step === STEPS.FINISH ? t("finish_patient_created") : t("newPatient")}
                </Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {step === STEPS.AGREEMENT && <TermsAndConditions/>}
                        {step === STEPS.NEW_PATIENT &&
                        <NewPatientForm patientData={patient}
                                        registerSaveCallback={registerSaveCallback}
                                        isEditMode={false}
                                        onSaveFinished={() => {}}/>
                        }
                        {step === STEPS.AGREEMENT_DOWNLOAD && <AgreementDownload/>}
                        {step === STEPS.FINISH && <Finish/>}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    {step !== STEPS.FINISH &&
                    <Button basic onClick={() => handleClose(false)}>
                        {t("cancel")}
                    </Button>
                    }

                    {backVisible() &&
                    <Button basic={saveVisible()} onClick={handleBack}>
                        <Icon name='chevron left'/> {t("back")}
                    </Button>
                    }

                    {nextVisible() &&
                    <Button color={"teal"} onClick={handleNext}>
                        {getLabelForNext()}
                        <Icon name='chevron right'/>
                    </Button>
                    }

                    {finishVisible() &&
                    <Button color={"teal"} onClick={handleFinish}>
                        {t("finish")}
                    </Button>
                    }
                </Modal.Actions>
            </Modal>
            <Modal
                onClose={() => setOpenSecond(false)}
                open={openSecond}
            >
                <Modal.Header>{t("inclusion_header")}</Modal.Header>
                <Modal.Content image>
                    <p>
                        {t("inclusion_description")}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setOpenSecond(false)} content={t("cancel")}/>
                    <Button
                        content={t("inclusion_yes")}
                        icon='checkmark'
                        onClick={() => {
                            save();
                            setOpenSecond(false);
                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default NewPatient;
