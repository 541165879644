import React, {forwardRef} from "react";
import {find} from "lodash";
import {formatDateTime} from "../../services";

const RenderTensioTable = ({appointmentModel, leftTime, rightTime},ref) => {

    const sortSamples =(samples) => {
        samples.sort((a,b) => (a.startDate > b.startDate) ? 1 : -1);
        return samples;
    };

    const getQuantities = (salusCorrelationSample, type) => {
        return find(salusCorrelationSample.quantitySamples,
            (it) => it.quantityType === type
        );
    };

    const getCategories = (salusCorrelationSample, type) => {
        return find(salusCorrelationSample.categorySamples,
            (it) => it.categoryType === type
        );
    };

    if (appointmentModel && appointmentModel.correlationSamples && appointmentModel.correlationSamples.length > 0) {
        return (
            <table>
                <thead>
                <tr>
                    <td><label className={"c-header c-header--small"}>Datum</label></td>
                    <td><label className={"c-header c-header--small"}>IOP (OD)</label></td>
                    <td><label className={"c-header c-header--small"}>Messqualität OD</label></td>
                    <td><label className={"c-header c-header--small"}>IOP (OS)</label></td>
                    <td><label className={"c-header c-header--small"}>Messqualität OS</label></td>
                </tr>
                </thead>
                <tbody>
                {sortSamples(appointmentModel.correlationSamples)?.filter(value => {
                    return (leftTime ? (new Date(value.startDate)).getTime() >= leftTime : true) &&
                        (rightTime ? (new Date(value.startDate)).getTime() <= rightTime : true)
                        && ((value.correlationType === "tensioMeasurement")||(value.correlationType === "tensioControlImport"));
                }).sort((a,b) => a.startDate > b.startDate? 1 : -1).map(salusCorrelationSample => {
                    return (
                        <tr key={salusCorrelationSample.id}>
                            <td>{formatDateTime(salusCorrelationSample.startDate)}</td>
                            <td>{getQuantities(salusCorrelationSample, "intraocularPressureRight")?.quantity.toFixed(1)}</td>
                            <td>{getCategories(salusCorrelationSample, "measurementQualityEstimateRightEye")?.value}</td>
                            <td>{getQuantities(salusCorrelationSample, "intraocularPressureLeft")?.quantity.toFixed(1)}</td>
                            <td>{getCategories(salusCorrelationSample, "measurementQualityEstimateLeftEye")?.value}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
};
export default forwardRef(RenderTensioTable);
