import React, {useContext, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Logo from "assets/salus-logo2.png";
import PageHeader from "components/PageHeader";
import {PatientList} from "components/patient";
import NewPatient from "components/patientNew/NewPatient";
import {useOrganizationProfile, usePatients, usePatientSelf, useStats, useUserProfile} from "hooks";
import EditPatient from "components/EditPatient";
import PageFooter from "./components/footer/PageFooter";
import {AuthContext} from "./services";
import DemoModalWarning from "./components/DemoModalWarning";
import {isNil} from "lodash";
import {Button, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import ReaderView from "./components/reader/ReaderView";


const ProfessionalsRoot = (userRoles) => {
    const history = useHistory();
    const [showNewPatientForm, setShowNewPatientForm] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const {loading, data, reload} = usePatients();
    const patients = (data && data.patients && data.patients.data) || [];
    const [searchString, setSearchString] = useState("");
    const {data: stats, refetch: reloadStats} = useStats();
    const {data: userProfile, refetch: reloadUserProfile} = useUserProfile();
    const {data: organizationProfile, refetch: reloadOrganization} = useOrganizationProfile();
    const {t} = useTranslation();

    const handleOnNewPersonClick = () => {

        if(isNil(userProfile.profile) || isNil(organizationProfile.organisation)) {
            setShowWarning(true);
        } else {
            setShowNewPatientForm(true);
        }
    };

    const update = () => {
        reload();
        reloadStats();
        updateProfiles();
    };

    const updateProfiles = () => {
        reloadUserProfile();
        reloadOrganization();
    };

    const handleOnNewPatientClose = (refreshData, patientId) => {
        setShowNewPatientForm(false);
        if (patientId) {
            history.push(`/patient/${patientId}`);
        }
        if (refreshData) {
            update();
        }
    };

    const handleOnSearchClick = (newSearchString) => {
        setSearchString(newSearchString);
    };

    return (
        <>
            <DemoModalWarning/>
            <Switch>
                <Route exact path="/">
                    <div className={"flex-column-full-height "}>
                        <PageHeader onNewPersonClick={handleOnNewPersonClick} onSearchClick={handleOnSearchClick}
                                    loading={loading} refresh={update}/>
                        <Modal
                            onClose={() => setShowWarning(false)}
                            onOpen={() => setShowWarning(true)}
                            open={showWarning}
                        >
                            <Modal.Header>WARNING</Modal.Header>
                            <Modal.Content>
                                <Modal.Description>
                                    <p>{t("warning_fill_profiles")}</p>
                                </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => setShowWarning(false)} primary>
                                    {t("close")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                        {showNewPatientForm && !showWarning && <NewPatient onClose={handleOnNewPatientClose}/>}
                        {/* {showAgreementForm && <Agreement onClose={handleOnAgreementClose}/>}*/}
                        <PatientList patients={patients} loading={loading} searchString={searchString} stats={stats}/>
                        <PageFooter stats={stats} loading={loading} userRoles={userRoles}/>
                    </div>
                </Route>
                <Route path="/patient/:id">
                    <EditPatient stats={stats} handleRefresh={update} userRoles={userRoles}/>
                </Route>
            </Switch>
        </>
    );
};

const PatientsRoot = (userRoles) => {
    const { loading, data, refetch: reloadPatientSelf } = usePatientSelf();
    const { patientSelf: {masterData: {forename = "", surname = ""} = {}} = {} } = !loading && data;
    const nameOfUser = `${forename} ${surname}`;

    const update = () => {
        reloadPatientSelf();
    };

    return (
        <>
            <DemoModalWarning/>
            <Switch>
                <Route path="/">
                    <div className={"flex-column-full-height "}>
                        <PageHeader loading={loading} refresh={update} nameOfUser={nameOfUser}/>
                            <EditPatient handleRefresh={update} userRoles={userRoles} data={data}/>
                        <PageFooter loading={loading} userRoles={userRoles}/>
                    </div>
                </Route>
            </Switch>
        </>
    );
};

const QualityControlRoot = (userRoles) => {
    const {loading, data, reload} = usePatients();
    const patients = (data && data.patients && data.patients.data) || [];
    const [searchString, setSearchString] = useState("");
    const {data: stats, refetch: reloadStats} = useStats();
    // const {refetch: reloadUserProfile} = useUserProfile();
    const {refetch: reloadOrganization} = useOrganizationProfile();

    const update = () => {
        reload();
        reloadStats();
        updateProfiles();
    };

    const updateProfiles = () => {
        // reloadUserProfile();
        reloadOrganization();
    };

    const handleOnSearchClick = (newSearchString) => {
        setSearchString(newSearchString);
    };

    return (
        <>
            <DemoModalWarning/>
            <Switch>
                <Route exact path="/">
                    <div className={"flex-column-full-height "}>
                        <PageHeader onSearchClick={handleOnSearchClick} loading={loading} refresh={update}/>
                        <ReaderView patients={patients} loading={loading} searchString={searchString}/>
                        <PageFooter stats={stats} loading={loading} userRoles={userRoles}/>
                    </div>
                </Route>
                <Route path="/patient/:id">
                    <EditPatient stats={stats} handleRefresh={update} isReader={true} userRoles={userRoles}/>
                </Route>
            </Switch>
        </>
    );
};

function containsRole (roles, role) {
    return roles && Array.isArray(roles) && roles.indexOf(role) >= 0;
}

const App = () => {

    const {user, logout} = useContext(AuthContext);
    const goBackToLogin = () => {
        logout();
    };

    if (containsRole(user.roles, "resident") || containsRole(user.roles, "clinician") || containsRole(user.roles, "study_lead") || containsRole(user.roles, "accounting") || containsRole(user.roles, "unlock_database")) {
        return ProfessionalsRoot(user.roles);
    } else if (containsRole(user.roles, "patient")) {
        return PatientsRoot(user.roles);
    } else if (containsRole(user.roles, "reader_a") || containsRole(user.roles, "reader_b") || containsRole(user.roles, "reader_c") || containsRole(user.roles, "data_entry_manager")) {
        return QualityControlRoot(user.roles);
    } else {
        console.warn("Unexpected user roles:", user.roles);
        console.debug("user:", user);
        return (
            <div>
            <div className="Grid page-header elevated padding-normal">
                <div> <img src={Logo} alt={"SALUS"} className={"cursor-pointer"} style={{height: "42px"}}/></div>
            </div>
                <h1>Sie sind erfolgreich für die Elektronische Fallakte registriert.</h1>
                <h3>Wir prüfen Ihre Angaben und melden uns schnellstmöglich zurück, sobald Ihr Zugang für die Elektronische Fallakte freigeschaltet wurde.</h3>
                <h3>Erst dann können Sie sich anmelden.</h3>
                <div>
                <Button className={"button-plain"} onClick={goBackToLogin}>Zum Anmeldebildschirm...</Button>
                </div>
            </div>
    );
    }
};

export default App;
