import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Menu, Modal, Tab} from "semantic-ui-react";
import MedicalHistory from "../MedicalHistory";
import Exclusion from "../Exclusion";
import {useTranslation} from "react-i18next";
import {AuthContext, formatDateTime, hasPermission} from "../../services";
import createModelFollowUpFirst from "data/models/appointmentSharedFollowUpFirst";
import createModelControl from "data/models/appointmentControl";
import createModelInclusionFinal from "data/models/appointmentSharedInclusionFinal";
import createModelFinal from "data/models/appointmentFinal";
import createModelTensio from "data/models/appointmentTensio";
import Appointment from "../appointment/Appointment";
import _, {get, find} from "lodash";
import {
    APPT_READING,
    APPT_READING_FINISHED,
    APPT_STATUS_CANCELED,
    APPT_STATUS_NOT_SCHEDULED_YET,
    APPT_STATUS_TOOK_PLACE,
    CAT_CONTROL,
    CAT_FINAL,
    CAT_FIRST,
    CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4,
    CAT_INCLUSION,
    CAT_TENSIO
} from "../../data/mutations/appointments";
import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";
import createModelFollowUp from "../../data/models/appointmentFollowUp";
import createModelFollowUpT4 from "../../data/models/appointmentFollowupT4";
import ReactTooltip from "react-tooltip";
import {checkDocLength, checkRequiredFields} from "../reader/models/utils";
import AptTabTensioAndChart from "./AptTabTensioAndChart";

export const TAB_EXAMINATION = "tab_examination";
export const TAB_QUESTIONNAIRE = "tab_questionnaire";

const Tabs = ({patient, refresh, changedTab}) => {
    const {t} = useTranslation();
    const appointmentRef = useRef();
    const [activeTab, setActiveTab] = useState(() => {
        const appointmentTabActiveIndexForPatient = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
        return appointmentTabActiveIndexForPatient || 0;
    });
    const [nextTab, setNextTab] = useState(0);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const modelFirst = createModelFollowUpFirst(patient, t, CAT_FIRST);
    const modelFollowUp1 = createModelFollowUp(patient, t, CAT_FOLLOWUP_T2);
    const modelFollowUp2 = createModelFollowUp(patient, t, CAT_FOLLOWUP_T3);
    const modelFollowUp3 = createModelFollowUpT4(patient, t, CAT_FOLLOWUP_T4);
    const modelControl = createModelControl(patient, t);
    const modelInclusion = createModelInclusionFinal(patient, t, CAT_INCLUSION);
    const modelFinal = createModelFinal(patient, t, CAT_FINAL);
    const modelTensio = createModelTensio(patient);

    const getDateTime = (value) => (value ? formatDateTime(value) : "---");
    const {user} = useContext(AuthContext);
    const isAccountingUser = user.roles.includes("accounting")
    const isPatient = user.roles.includes("patient");

    const allAppointmentsCorrelationSamples = [
        {
            appointmentName: CAT_FIRST,
            correlationSamples: modelFirst.correlationSamples
        },
        {
            appointmentName: CAT_INCLUSION,
            correlationSamples: modelInclusion.correlationSamples
        },
        patient.studyData.cohort === CAT_CONTROL ?
            {
                appointmentName: CAT_CONTROL,
                correlationSamples: modelControl.correlationSamples
            } :
            {
                appointmentName: CAT_TENSIO,
                correlationSamples: modelTensio.correlationSamples
            },
        {
            appointmentName: CAT_FOLLOWUP_T2,
            correlationSamples: modelFollowUp1.correlationSamples
        },
        {
            appointmentName: CAT_FOLLOWUP_T3,
            correlationSamples: modelFollowUp2.correlationSamples
        },
        {
            appointmentName: CAT_FOLLOWUP_T4,
            correlationSamples: modelFollowUp3.correlationSamples
        },
        {
            appointmentName: CAT_FINAL,
            correlationSamples: modelFinal.correlationSamples
        },
    ];

    //TODO: not only for the first appointment!
    const handleOnAppointmentSave = () => {
        if (appointmentRef && appointmentRef.current) appointmentRef.current.cleanLocal();
        refresh();
    };

    const handleExclusionSave = () => {
        refresh();
    };

    const isControlCohort = patient && patient.studyData.cohort === "CONTROL";
    const isExcluded = patient.status === PAT_STATUS_DATA_EXCLUDED;
    let allModels = [modelInclusion, isControlCohort ? modelControl : modelTensio, modelFollowUp1, modelFollowUp2,
        modelFollowUp3, modelFinal]

    /*eslint-disable*/
    useEffect(() => {
        setActiveTab(window.sessionStorage.getItem("appointmentTabActiveIndexForPatient") || 0);
    }, [window.sessionStorage.getItem("appointmentTabActiveIndexForPatient")])
    /*eslint-enable*/

    const getTabMenuRole = (category) => {
        const residentDocTabMenu = ["MEDICAL_HISTORY", CAT_FIRST, CAT_TENSIO, CAT_TENSIO + "_2ND_TAB", CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4];
        const clinicianTabMenu = [CAT_INCLUSION, CAT_CONTROL, CAT_CONTROL + "_2ND_TAB", CAT_FINAL]

        if (residentDocTabMenu.includes(category)) {
            return "resident-doc";
        } else if (clinicianTabMenu.includes(category)) {
            return "clinician"
        } else {
            return ""
        }
    }

    const getActiveTabCSS = (category) => {
        // const contorlOrInterventionTab = isControlCohort ? CAT_CONTROL : CAT_TENSIO;
        // const appointmentTab = ["MEDICAL_HISTORY", CAT_FIRST, CAT_INCLUSION, CAT_CONTROL, CAT_TENSIO, contorlOrInterventionTab + "_2ND_TAB", CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL, "EXCLUSION"];
        const appointmentTab = ["MEDICAL_HISTORY", CAT_FIRST, CAT_INCLUSION, isControlCohort ? CAT_CONTROL : CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL, "EXCLUSION"];

        if (activeTab < 9) {
            if (category === appointmentTab[activeTab]) {
                return "active-tab-color-" + getTabMenuRole(category);
            }
        } else {
            if (category === appointmentTab[activeTab]) {
                return "active-tab-menu";
            }
        }
        return "";
    }

    const didAppointmentTookPlace = (status) => {
        return status === APPT_STATUS_TOOK_PLACE;
    }

    const getPreviousAppointmentDate = (category) => {
        switch (category) {
            case CAT_FOLLOWUP_T2:
                return new Date(modelInclusion?.startDate);
            case CAT_FOLLOWUP_T3:
                return new Date(modelFollowUp1?.startDate);
            case CAT_FOLLOWUP_T4:
                return new Date(modelFollowUp2?.startDate);
            default:
                return null;
        }
    }

    const didPreviousAppointmentTookPlace = (category) => {
        switch (category) {
            case CAT_FOLLOWUP_T2:
                return didAppointmentTookPlace(modelInclusion?.status);
            case CAT_FOLLOWUP_T3:
                return didAppointmentTookPlace(modelFollowUp1?.status);
            case CAT_FOLLOWUP_T4:
                return didAppointmentTookPlace(modelFollowUp2?.status);
            default:
                return null;
        }
    }

    const checkIfNextAppointmentsTookPlace = (currentAppointment) => {
        allModels = allModels.filter((item) => item.category !== currentAppointment.category)
        return allModels.some((item) => item.status === APPT_STATUS_TOOK_PLACE);
    }

    const isAppointmentInComplete = (appointment) => {
        const sample = get(appointment, "correlationSamples", [])

        if ([CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4].includes(appointment?.category)) {
            const categorySamples = patient?.appointments.find(x => _.get(x, "category") === appointment.category)?.categorySamples ?? null;
            const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null
            if (readingStatus && readingStatus.value === APPT_READING_FINISHED) {
                return []
            }
        }

        const didAppointmentTakePlace = didAppointmentTookPlace(appointment?.status);
        const didNextAppointmentsTookPlace = checkIfNextAppointmentsTookPlace(appointment);
        const followUp = [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4];
        let appointmentCompleteText = []

        if (didAppointmentTakePlace && ((sample && !checkRequiredFields(sample)) || checkDocLength(appointment))) {
            appointmentCompleteText.push(t("first_rule_violated"))
        }
        if (!didAppointmentTakePlace && appointment.startDate && new Date(appointment.startDate).getTime() <= new Date().getTime()) {
            appointmentCompleteText.push(t("second_rule_violated"))
        }
        if (!didAppointmentTakePlace && didNextAppointmentsTookPlace) {
            appointmentCompleteText.push(t("third_rule_violated"))
        }

        if (followUp.includes(appointment.category)) {
            let previousAppointmentDate = getPreviousAppointmentDate(appointment.category);
            previousAppointmentDate.setMonth(previousAppointmentDate.getMonth() + 3);
            if (!didAppointmentTakePlace && didPreviousAppointmentTookPlace(appointment.category) &&
                new Date().getTime() >= previousAppointmentDate.getTime()) {

                appointmentCompleteText.push(t("fourth_rule_violated"))
            }
        }
        return appointmentCompleteText;
    }

    const getTabCSS = (tabMenuRole, activeTabCSS, appointmentCompletionStatus) => {
        if (activeTabCSS === "") {
            return !appointmentCompletionStatus ? `tab-menu-${tabMenuRole} tab-color-${tabMenuRole}` :
                `tab-menu-${tabMenuRole} tab-menu-${tabMenuRole}-warning tab-color-${tabMenuRole}`;
        } else {
            return !appointmentCompletionStatus ? `${activeTabCSS}` : `${activeTabCSS} ${activeTabCSS}-warning`;
        }
    }

    const getStatus = (model, appointmentCompletionStatus) => {
        let status = model.status
        const categorySamples = patient?.appointments.find(x => _.get(x, "category") === model.category)?.categorySamples ?? null;
        const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null

        if (readingStatus) {
            if ([CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4].includes(model?.category) &&
                status === APPT_STATUS_CANCELED && readingStatus.value === APPT_READING_FINISHED) {
                return APPT_READING_FINISHED
            }
            if (checkDocLength(model)) {
                return APPT_STATUS_TOOK_PLACE
            } else if (readingStatus.value === APPT_READING_FINISHED) {
                return APPT_READING_FINISHED
            } else if (readingStatus !== "") {
                return APPT_READING
            }
        } else if (status === APPT_STATUS_TOOK_PLACE) {
            let color = ""
            if(appointmentCompletionStatus) {
                color = ""
            } else color = "red"
            return color === "red" ? APPT_READING : status
        }
        return status
    }

    const translateAppointmentModelStatus = (appointmentModelCategory, status) => {
        const appointmentModel = find(patient?.appointments, (it) => it.category === appointmentModelCategory);
        if (isPatient) {
            if (status === APPT_STATUS_TOOK_PLACE) {
                const categorySamples = get(appointmentModel, "categorySamples", []);
                const readingStatus = find(categorySamples, (it) => it.categoryType === "readingStatus")?.value;
                return readingStatus === "READING_FINISHED" ? t("APPT_STATUS_TOOK_PLACE_READING_COMPLETED") : t("APPT_STATUS_TOOK_PLACE_READING_NOT_COMPLETED");
            }
        }

        return t("APPT_STATUS_" + status);
    }

    const getMenuItem = (appointmentModel, label, tabMenuRole, activeTabCSS, appIncompleteText, appointmentCompletionStatus) => {
        return <>
            <div data-tip data-for={appointmentModel.category}
                 className={getTabCSS(tabMenuRole, activeTabCSS, appointmentCompletionStatus)}>

                <div className={"pb-1of2"}>
                    {t(label)} <br/> ({isPatient ? translateAppointmentModelStatus(appointmentModel.category, appointmentModel.status) : t("APPT_STATUS_" + getStatus(appointmentModel, appointmentCompletionStatus))})
                </div>
                {appointmentModel.status !== APPT_STATUS_NOT_SCHEDULED_YET &&
                <div className={"tab-menu-note"}>{getDateTime(appointmentModel.startDate)}</div>}
                {appointmentCompletionStatus && <ReactTooltip id={appointmentModel.category} type='error'>
                    <ul className={"no-padding-inline"}>{appIncompleteText.map((item, index) => <li
                        key={index}>{item}</li>)}</ul>
                </ReactTooltip>}
            </div>
        </>
    }

    //TODO put tabs into Appointment
    const renderAptTabWithFiles = (label, appointmentModel, patientId) => {
        const tabMenuRole = getTabMenuRole(appointmentModel.category)
        const activeTabCSS = getActiveTabCSS(appointmentModel.category)
        const appIncompleteText = isAppointmentInComplete(appointmentModel)
        const appointmentCompletionStatus = appIncompleteText.length > 0

        return {
            menuItem: (
                <Menu.Item key={label}>
                    {getMenuItem(appointmentModel, label, tabMenuRole, activeTabCSS, appIncompleteText, appointmentCompletionStatus)}
                </Menu.Item>
            ),
            render: () => (
                isAccountingUser ?
                    <></> :
                    <Tab.Pane attached={false}>
                        <Appointment key={label} patientId={patientId} dataModel={appointmentModel}
                                     handleAfterSave={handleOnAppointmentSave} showQuestionnaire={false}
                                     patStatus={patient.status} isControlCohort={isControlCohort}
                                     allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                                     inclusionDate={patient.studyData.inclusionDate} refresh={refresh}
                                     patient={patient} ref={appointmentRef} isReaderTabDisabled={appointmentCompletionStatus} />
                    </Tab.Pane>
            )
        };
    };

    const renderAptTabWithQuestionnaire = (label, appointmentModel, patientId) => {
        const tabMenuRole = getTabMenuRole(appointmentModel.category)
        const activeTabCSS = getActiveTabCSS(appointmentModel.category)
        const appIncompleteText = isAppointmentInComplete(appointmentModel)
        const appointmentCompletionStatus = appIncompleteText.length > 0

        return {
            menuItem: (
                <Menu.Item key={label}>
                    {getMenuItem(appointmentModel, label, tabMenuRole, activeTabCSS, appIncompleteText, appointmentCompletionStatus)}
                </Menu.Item>
            ),
            render: () => (
                isAccountingUser ?
                    <></> :
                    <Tab.Pane attached={false}>
                        <Appointment key={label} patientId={patientId} dataModel={appointmentModel}
                                     handleAfterSave={handleOnAppointmentSave} showQuestionnaire={true}
                                     patStatus={patient.status} ref={appointmentRef}
                                     allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                                     isControlCohort={isControlCohort} inclusionDate={patient.studyData.inclusionDate}
                                     refresh={refresh} patient={patient} isReaderTabDisabled={appointmentCompletionStatus} />
                    </Tab.Pane>
            )
        };
    };

    const renderAptTabWithFilesAndTensio = (label, appointmentModel, patientId, isControlGroup, isExcluded) => {
        const tabMenuRole = getTabMenuRole(`${appointmentModel.category}`)
        const activeTabCSS = getActiveTabCSS(`${appointmentModel.category}`)
        const appIncompleteText = isAppointmentInComplete(appointmentModel)
        const appointmentCompletionStatus = appIncompleteText.length > 0

        return {
            menuItem: (
                <Menu.Item key={label}>
                    {getMenuItem(appointmentModel, label, tabMenuRole, activeTabCSS, appIncompleteText, appointmentCompletionStatus)}
                </Menu.Item>
            ),
            render: () => (
                isAccountingUser ?
                    <></> :
                    <Tab.Pane attached={false}>
                        <AptTabTensioAndChart
                            label={label} dataModel={appointmentModel} patientId={patientId} patient={patient}
                            isControlGroup={isControlGroup} isExcluded={isExcluded} refresh={refresh}// refresh={handleTensioSave}
                            allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                            ref={appointmentRef} patStatus={patient.status} handleAfterSave={handleOnAppointmentSave}
                            isReaderTabDisabled={appointmentCompletionStatus} inclusionDate={patient.studyData.inclusionDate} />
                    </Tab.Pane>
            )
        };
    };

    const panes = [
        {
            menuItem: (
                <Menu.Item key={"medical_history"}>
                    <div
                        className={getActiveTabCSS("MEDICAL_HISTORY") === "" ? "tab-menu-resident-doc tab-color-resident-doc" : getActiveTabCSS("MEDICAL_HISTORY")}>
                        <div className={"pb-1of2"}>{t("tabs_medical_history")}</div>
                    </div>
                </Menu.Item>
            ),
            render: () => {
                return (
                    isAccountingUser ?
                        <></> :
                        <Tab.Pane attached={false}>
                            <MedicalHistory patient={patient} roles={user.roles}/>
                        </Tab.Pane>
                );
            }
        },
        {
            ...renderAptTabWithFiles("tabs_apponit_FIRST", modelFirst, patient.id)
        },
        {
            ...renderAptTabWithQuestionnaire("tabs_apponit_INCLUSION", modelInclusion, patient.id)
        },
        {
            ...isControlCohort ? renderAptTabWithFilesAndTensio("tabs_apponit_TENSIO_AND_CHART_CONTROL", modelControl, patient.id, true, isExcluded) :
                renderAptTabWithFilesAndTensio("tabs_apponit_TENSIO_AND_CHART", modelTensio, patient.id, false, isExcluded)
        },
        {
            ...renderAptTabWithFiles("tabs_apponit_FOLLOWUP1", modelFollowUp1, patient.id)
        },
        {
            ...renderAptTabWithFiles("tabs_apponit_FOLLOWUP2", modelFollowUp2, patient.id)
        },
        {
            ...renderAptTabWithFiles("tabs_apponit_FOLLOWUP3", modelFollowUp3, patient.id)
        },
        {
            ...renderAptTabWithQuestionnaire("tabs_apponit_FINAL", modelFinal, patient.id)
        },
        hasPermission(user.roles, ["study_lead"]) &&
        {
            menuItem: (
                <Menu.Item key={"exclusion"}>
                    <div className={getActiveTabCSS("EXCLUSION") === "" ? "tab-menu" : getActiveTabCSS("EXCLUSION")}>
                        <div className={"pb-1of2"}>{t("tabs_apponit_EXCLUSION")}</div>
                    </div>
                </Menu.Item>
            ),
            render: () => {
                return (
                    isAccountingUser ?
                        <></> :
                        hasPermission(user.roles, ["study_lead"]) &&
                        <Tab.Pane attached={false}>
                            <Exclusion patient={patient} handleAfterSave={handleExclusionSave}/>
                        </Tab.Pane>
                );
            }
        }
    ];

    const renderModalWarning = () => {
        const closeWithoutSaving = () => {
            setShowModalWarning(false);
            setTab(nextTab);
        };
        const brakeChangingTab = () => {
            setShowModalWarning(false);
        };

        return (
            <>
                {!isPatient &&
                    <Modal open={showModalWarning}>
                        <Modal.Header>{t("leave_header")}</Modal.Header>
                        <Modal.Content>
                            <p>{t("leave_without_saving")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={closeWithoutSaving}>{t("leave_yes")}</Button>
                            <Button
                                positive
                                content={t("leave_no")}
                                onClick={brakeChangingTab}
                            />
                        </Modal.Actions>
                    </Modal>
                }
            </>
        );
    };

    const setTab = (index) => {
        window.sessionStorage.setItem("appointmentTabActiveIndexForPatient", index);
        window.sessionStorage.setItem("appointmentActiveIndexInTab", "0");
        setActiveTab(index);
        if (changedTab) {
            changedTab(index);
        }
    };

    const handleTabChanged = (e, data) => {
        if (appointmentRef && appointmentRef.current) {
            if (appointmentRef.current.modelHasChanged()) {
                setShowModalWarning(true);
                setNextTab(data.activeIndex);
            } else {
                setTab(data.activeIndex);
            }
        } else {
            setTab(data.activeIndex);
        }
    };

    return (
        <div>
            {renderModalWarning()}
            <Tab menu={{vertical: true, text: true}} panes={panes} activeIndex={activeTab}
                 onTabChange={handleTabChanged}/>
        </div>

    );
};

export default Tabs;