import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {AuthContext, formatDate, toastError} from "../../services";
import {Button, Divider, Select} from "semantic-ui-react";
import {get} from "lodash";
import DateInput from "../inputs/DateInput";
import {useMutateIntervention} from "../../hooks";
import {useTranslation} from "react-i18next";


const InterventionBlock = ({startDate, patientId, interventions, category, isReadOnly}, ref) => {
    const [newInterventions, setNewIntervention] = useState([]);
    const [tableInterventions, setTableInterventions] = useState(interventions);
    const [errors, ] = useState({
        newInterventions: false,
    });
    const mutateIntervention = useMutateIntervention();
    const {t} = useTranslation();

    const { user } = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    useImperativeHandle(ref, () => ({
        hasChanged () {
            return newInterventions && newInterventions.length > 0;
        },
        save() {
            return saveInterventions();
        },
        hasError() {
            return get(errors, "newInterventions");
        }
    }));

    const interventionOptions = [
        {value: "Ahmed-Implantat", text: "Ahmed-Implantat"},
        {value: "Baerveldt-Implantat", text: "Baerveldt-Implantat"},
        {value: "iStent", text: "iStent"},
        {value: "Kanaloplastik", text: "Kanaloplastik"},
        {value: "Kataraktoperation + iStent", text: "Kataraktoperation + iStent"},
        {value: "Kahook Dual Blade", text: "Kahook Dual Blade"},
        {value: "Nd:YAG Iridotomie", text: "Nd:YAG Iridotomie"},
        {value: "PreserFlo", text: "PreserFlo"},
        {value: "Selektive Lasertrabekuloplastik", text: "Selektive Lasertrabekuloplastik"},
        {value: "Trabekulektomie", text: "Trabekulektomie"},
        {value: "Trabekulotomie", text: "Trabekulotomie"},
        {value: "Zyklokryokoagulation", text: "Zyklokryokoagulation"},
        {value: "Zyklophotokoagulation", text: "Zyklophotokoagulation"},
    ];

    const saveInterventions = () => {
        if(newInterventions.length === 0) return;

        const interventions = newInterventions.map(value => {
            return {
                date: value.date,
                eyeLocation: value.eyeLocation,
                id: value.id,
                name: value.name
            };
        });
        for (let i = 0; i < interventions.length; i++) {
            if (interventions[i].name === null) {
                toastError("Name did not set");
                return;
            }
        }
        return Promise.all(interventions.map(interv => {
            return mutateIntervention.mutateIntervention({
                intervention: {...interv},
                patientId: patientId
            });
        })).then((v) => {
            const interv = v.map((resp) => resp.data.upsertIntervention);
            setTableInterventions(tableInterventions.concat(interv));
            setNewIntervention([]);
        }, reason => {
            toastError(t("error_gql"));
        });
    };

    const renderInterventionTable = () => {
        const handleAddIntervention = () => {
            const lastMedKey = newInterventions.length === 0 ? -1 :
                newInterventions[newInterventions.length - 1].key;
            setNewIntervention([
                ...newInterventions,
                getNewIntervention(lastMedKey + 1)
            ]);
        };

        const getNewIntervention = (key) => {
            return {
                key: key,
                date: startDate || new Date(),
                eyeLocation: "RIGHT_EYE",
                id: null,
                name: interventionOptions[0].text
            };
        };

        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <td><label className={"c-header c-header--small"}>Datum</label></td>
                        <td><label className={"c-header c-header--small"}>Operationsverfahren</label></td>
                        <td><label className={"c-header c-header--small"}>Operationsseite</label></td>
                    </tr>
                    </thead>
                    <tbody>
                    {tableInterventions && tableInterventions?.map((intervention, index) => {
                        return (
                            <tr key={intervention.id}>
                                <td>{formatDate(intervention.date)}</td>
                                <td>{intervention.name}</td>
                                <td>{t(intervention.eyeLocation)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <br/>
                <Button type="button" basic size="tiny" color="teal" content={t("add_intervention")}
                        onClick={() => handleAddIntervention()} disabled={isReadOnly}/>
            </div>
        );
    };

    const renderInterventionFields = () => {
        const radioChanged = (key, value) => {
            newInterventions.filter(intervention => {
                return intervention.key === key;
            }).forEach(intervention => {
                return intervention.eyeLocation = value;
            });
        };
        const selectChanged = (key, value) => {
            newInterventions.filter(intervention => {
                return intervention.key === key;
            }).forEach(intervention => {
                return intervention.name = value;
            });
        };
        const dateChanged = (key, value) => {
            newInterventions.filter(intervention => {
                return intervention.key === key;
            }).forEach(intervention => {
                return intervention.date = value;
            });
            setNewIntervention([...newInterventions]);
        };
        const handleDelete = (key) => {
            if (newInterventions.length === 0) {
                return;
            }
            const updated = newInterventions.filter(value => value.key !== key);
            setNewIntervention([
                ...updated
            ]);
        };

        return (
            <table className={"table-without-border"}>
                <thead/>
                <tbody>
                {!isPatient &&
                    <tr>
                        <td colSpan={5} className={"td-without-border"}>
                            <div className={"label-small"}>Falls genaues Datum nicht bekannt, 01.01. des entsprechenden Jahres eintragen</div>
                        </td>
                    </tr>
                }
                {newInterventions.map(intervention => {
                    return (
                        <tr key={intervention.key}
                            className={get(errors, "newInterventions") ? "patientStatus--data-missing" : ""}>
                            <td className={"td-without-border"}>
                                <DateInput value={intervention.date} type={"date"}
                                           onChange={(v) => dateChanged(intervention.key, v)}/>
                            </td>
                            <td className={"td-without-border"}>
                                <Select
                                    options={interventionOptions}
                                    defaultValue={intervention.name}
                                    onChange={(event) => selectChanged(intervention.key, event.target.textContent)}
                                />
                            </td>
                            <td align="center" className={"td-without-border"}>
                                <div>
                                    <input type="radio" name={"interventionEye_" + intervention.key} value="RIGHT_EYE"
                                           defaultChecked={intervention.eyeLocation === "RIGHT_EYE"} onChange={
                                        (event) => radioChanged(intervention.key, event.target.value)
                                    }/>
                                    <label htmlFor="right">rechtes Auge</label>
                                </div>
                            </td>
                            <td align="center" className={"td-without-border"}>
                                <div>
                                    <input type="radio" name={"interventionEye_" + intervention.key} value="LEFT_EYE"
                                           defaultChecked={intervention.eyeLocation === "LEFT_EYE"} onChange={
                                        (event) => radioChanged(intervention.key, event.target.value)
                                    }/>
                                    <label htmlFor="right">linkes Auge</label>
                                </div>
                            </td>
                            <td align="center" className={"td-without-border"}>
                                <Button type="button" basic size="tiny" color="teal" content={t("remove")}
                                        onClick={() => handleDelete(intervention.key)}/>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    };

    return (
        <div>
            <Divider/>
            <div align="center">
                <div className={"c-header c-header--big"}>{t("interventions")}</div>
            </div>
            {!isPatient &&
                <>
                    <br/>
                    {renderInterventionTable()}
                </>
            }
            <br/>
            {renderInterventionFields()}
        </div>
    );
};

export default forwardRef(InterventionBlock);
