import {useQuery} from "@apollo/react-hooks";
import {STATS} from "../data/queries/queries";

export const useStats = () => {
    //const {t} = useTranslation();
    const {data, refetch} = useQuery(STATS);

    // A lot of errors with  Network error: Could not refresh token.
    /*if (error) {
        toastError(t("error_gql"));
    }*/

    return {data, refetch};
};
