import {copyDataToModel} from "./utils";
import * as _ from "./allSections";


export const createReaderControlTensioAppointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort) => {

    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createControlTensionSection(allAppointmentsCorrelationSamples, userId, role, patientId)
    ];

    return copyDataToModel(model, categorySamples);
}