import gql from 'graphql-tag';
import {appointmentFragment} from '../fragments/appointmentFragment';

export const SYNC_ICARE = gql`
    mutation iCareSync($patientId: ID! $appointmentId: ID) {
        iCareSync(patientId: $patientId appointmentId: $appointmentId) {
            success
            sycMessage
            newSampleCount
            appointment {
                ...appointmentFragment
            }
        }
    }
    ${appointmentFragment}
`;
