import React from "react";
import {find} from "lodash";
import {formatDateTime} from "../../services";
import {useTranslation} from "react-i18next";

const RenderRRTable = ({appointmentModel, leftTime, rightTime}) => {

    const {t} = useTranslation();
    const getQuantities = (salusCorrelationSample, type) => {
        return find(salusCorrelationSample.quantitySamples,
            (it) => it.quantityType === type
        );
    };

    const getCategories = (salusCorrelationSample, type) => {
        return find(salusCorrelationSample.categorySamples,
            (it) => it.categoryType === type
        );
    };

    const getCleanedValue = (value) => {
        if(value === "NaN"){
            return t("no_value");
        }
        else{
            return value;
        }
    };
            return (
                <table>
                    <thead>
                    <tr>
                        <td><label className={"c-header c-header--small"}>Datum</label></td>
                        <td><label className={"c-header c-header--small"}>Systole</label></td>
                        <td><label className={"c-header c-header--small"}>Diastole</label></td>
                        <td><label className={"c-header c-header--small"}>MD</label></td>
                        <td><label className={"c-header c-header--small"}>HF</label></td>
                        <td><label className={"c-header c-header--small"}>Dipper %</label></td>
                        <td><label className={"c-header c-header--small"}>Abweichung Systole %</label></td>
                        <td><label className={"c-header c-header--small"}>Abweichung Diastole %</label></td>
                        <td><label className={"c-header c-header--small"}>Fehler</label></td>
                        <td><label className={"c-header c-header--small"}>Bermerkungen</label></td>
                    </tr>
                    </thead>
                    {(appointmentModel && appointmentModel.correlationSamples &&appointmentModel.correlationSamples.length > 0) &&
                    <tbody>
                    {appointmentModel.correlationSamples?.filter(value => {
                        return (leftTime ? (new Date(value.startDate)).getTime() >= leftTime : true) &&
                            (rightTime ? (new Date(value.startDate)).getTime() <= rightTime : true)
                            && (value.correlationType === "24hBloodPressureImport" || value.correlationType === "24hBloodPressureDipping" );
                    }).sort((a,b) => a.startDate > b.startDate? 1 : -1).map(salusCorrelationSample => {
                        return (
                            <tr key={salusCorrelationSample.id}>
                                <td>{formatDateTime(salusCorrelationSample.startDate)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "bloodPressureSystolic")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "bloodPressureDiastolic")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "md")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "heartRate")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "dipper")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "deviationSystole")?.quantity)}</td>
                                <td>{getCleanedValue(getQuantities(salusCorrelationSample, "deviationDiastole")?.quantity)}</td>
                                <td>{getCategories(salusCorrelationSample, "error")?.value}</td>
                                <td>{getCategories(salusCorrelationSample, "notes")?.value}</td>
                            </tr>
                        );
                    })}
                    </tbody>}
                </table>
            );
    };
export default RenderRRTable;
