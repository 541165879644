import gql from "graphql-tag";
import {categorySampleFragment} from "./categorySampleFragment";
import {salusCorrelationSampleFragment} from "./salusCorrelationSampleFragment";
import {quantitySampleFragment} from "./quantitySampleFragment";
import {documentSampleFragment} from "./documentSampleFragment";

export const appointmentFragment = gql`
    fragment appointmentFragment on Appointment {
        id
        startDate
        endDate
        category
        categorySamples {
            ...categorySampleFragment
        }
        correlationSamples {
            ...salusCorrelationSampleFragment
        }
        quantitySamples {
            ...quantitySampleFragment
        }
        documentSamples {
            ...documentSampleFragment
        }
        comment
        status
        recommendedTherapy{
            therapy
        }
    }
    ${categorySampleFragment}
    ${salusCorrelationSampleFragment}
    ${quantitySampleFragment}
    ${documentSampleFragment}
`;
