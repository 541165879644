import React, {useState} from "react";
import {Input, Radio, Select} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const InputSelect = ({options, placeholder, defaultValue, onChange, error, disabled, className}) => {
    const {t} = useTranslation();

    const [initValue] = useState(defaultValue);

    const [inputValue, setInputValue] = useState(() => {
        const hasValue = defaultValue && !options.find((it) => it.value === initValue);
        return hasValue ? defaultValue : "";
    });

    const [selectedValue, setSelectedValue] = useState(() => {
        const hasValue = !!options.find((it) => it.value === initValue);
        return hasValue ? defaultValue : options[0].value;
    });

    const [checked, setChecked] = useState(!!inputValue);

    const onInputToggle = (checked) => {
        const value = !checked ? selectedValue : inputValue;
        setChecked(checked);
        onChange(value);
    };

    const handleOnSelectChange = (value) => {
        setSelectedValue(value);
        onChange(value);
    };

    const handleOnInputChange = (value) => {
        setInputValue(value);
        onChange(value);
    };

    return (
        <>
            <div className={"pb-1of2"}>
                <Radio className={className} toggle defaultChecked={checked} label={t("user_defined")}
                       onChange={(_, {checked}) => onInputToggle(checked)} disabled={disabled}/>
            </div>

            {checked ? (
                <Input
                    fluid
                    autoComplete="off"
                    defaultValue={inputValue}
                    placeholder={placeholder}
                    error={error}
                    disabled={disabled}
                    className={className}
                    onChange={(e) => {
                        handleOnInputChange(e.target.value);
                    }}
                />
            ) : (
                <Select
                    options={options}
                    value={selectedValue}
                    error={error}
                    disabled={disabled}
                    className={className}
                    onChange={(_, {value}) => handleOnSelectChange(value)}
                />
            )}
        </>
    );
};

export default InputSelect;
