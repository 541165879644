import gql from "graphql-tag";
import {exclusionFragment} from "../fragments/exclusionFragment";

export const UPSERT_EXCLUSION = gql`
    mutation UpsertExclusion($exclusion: ExclusionDataInput!, $patientId: ID!) {
        upsertExclusion(exclusion: $exclusion, patientId: $patientId) {
            ...exclusionFragment
        }
    }
    ${exclusionFragment}
`;
