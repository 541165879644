import gql from "graphql-tag";
import {medicalHistoryFragment} from "./medicalHistoryFragment";
import {medicationFragment} from "./medicationFragment";
import {interventionFragment} from "./interventionFragment";
import {contactInfoFragment} from "./contactInfoFragment";
import {hospitalFragment} from './hospitalFragment';
import {categorySampleFragment} from "./categorySampleFragment";
import {salusCorrelationSampleFragment} from "./salusCorrelationSampleFragment";
import {quantitySampleFragment} from "./quantitySampleFragment";
import {documentSampleFragment} from "./documentSampleFragment";


export const patientSelfFragment = gql`
    fragment patientSelfFragment on Patient {
        id
        patientId
        status
        masterData {
            gender
            surname
            forename
            birthdate
            contact {
                ...contactInfoFragment
            }
            insurance {
                number
                company
            }
            hospital {
                ...hospitalFragment
            }
            practice {
                id
                organisationId
                name
                bsnr
                type
            }
        }
        medicalHistory {
            ...medicalHistoryFragment
        }
        appointments {
            id
            startDate
            endDate
            category
            categorySamples {
                ...categorySampleFragment
            }
            correlationSamples {
                ...salusCorrelationSampleFragment
            }
            quantitySamples {
                ...quantitySampleFragment
            }
            documentSamples {
                ...documentSampleFragment
            }
            comment
            status
        }
        medications {
            ...medicationFragment
        }
        interventions {
            ...interventionFragment
        }
        studyData {
            cohort
            studyid
            inclusionDate
            doctorInCharge{
                lanr
                forename
                surname
            }
        }
    }
    ${medicalHistoryFragment}
    ${medicationFragment}
    ${interventionFragment}
    ${contactInfoFragment}
    ${hospitalFragment}
    ${categorySampleFragment}
    ${salusCorrelationSampleFragment}
    ${quantitySampleFragment}
    ${documentSampleFragment}
`;
