import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import {Button, Divider, Menu, Modal, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {AuthContext, hasPermission, toastError} from "../../services";
import InitiateAppointment from "./InitiateAppointment";
import AttachmentTable from "./RenderAttachmentTable";
import NewAttachment from "./NewAttachment";
import {
    APPT_STATUS_TOOK_PLACE, CAT_FIRST, CAT_CONTROL, CAT_FINAL, CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_INCLUSION, CAT_TENSIO
} from "../../data/mutations/appointments";
import ExaminationTab from "./ExaminationTab";
import QuestionnaireTab from "./QuestionnaireTab";
import _, {get} from 'lodash';import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";

import RenderReaderZone from "../reader/RenderReaderZone";
import DataEntryTab from "../reader/DataEntryTab";
import RenderReaderZoneTensio from "../reader/RenderReaderZoneTensio";
import KonsilKlinikTab from "./KonsilKlinikTab";
import {
    checkReadingStatus, getCurrentTab, getPreviousAppointmentModel, getReaderCComment, idReaderTensioZoneDisabled
} from "../reader/models/utils"

const Appointment = ({patientId, dataModel, handleAfterSave, showQuestionnaire, patStatus, allAppointmentsCorrelationSamples,
                         isControlCohort, inclusionDate, refresh, patient, isReaderTabDisabled = false}, ref) => {

    const {user} = useContext(AuthContext);
    // const isQualityControlUser = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c") || user.roles.includes("data_entry_manager");
    const isReader = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c");
    const isDataEntryManager = user.roles.includes("data_entry_manager")
    const isStudyLead = user.roles.includes("study_lead");

    // Added additional condition for readOnly with regard to the readerStatus
    const isReadOnly = !hasPermission(user.roles, dataModel.__roles) || patStatus === PAT_STATUS_DATA_EXCLUDED || checkReadingStatus(patient, isControlCohort, dataModel)
    const isReadOnlyStudyLead = user.roles.includes("study_lead") ? (patStatus === PAT_STATUS_DATA_EXCLUDED || !hasPermission(["clinician"], dataModel.__roles)) : isReadOnly;
    const readerCComment = (user.roles.includes("clinician") || isDataEntryManager) ? getReaderCComment(patient, getCurrentTab(isControlCohort)) : null

    const isPatient = user.roles.includes("patient");

    const {t} = useTranslation();
    const [examinationTabDisabled, setExaminationTabDisabled] = useState(() => {
        return !(dataModel.startDate && dataModel.status === APPT_STATUS_TOOK_PLACE);
    });
    const currentTab = useRef();
    const [activeTab, setActiveTab] = useState(() => {
        if (dataModel.startDate === null) {
            return 0;
        } else {
            const appointmentActiveIndexInTab = window.sessionStorage.getItem("appointmentActiveIndexInTab");
            return appointmentActiveIndexInTab || 0;
        }
    });
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [nextTab, setNextTab] = useState(0);

    useImperativeHandle(ref, () => ({
        getName () {
            return dataModel.category;
        },
        modelHasChanged () {
            if(checkRef(currentTab)){
                return currentTab.current.hasChanged();
            } else {
                return false;
            }
        },
        cleanLocal () {
            sessionStorage.removeItem(dataModel.category);
        }
    }));

    const checkRef = (ref) => {
        return ref && ref.current;
    };

    const appointmentId = _.get(dataModel, ['id'], null);
    const appointmentExists = _.get(dataModel, ['id'], null) !== null;
    const filesToolTipText =  appointmentExists ? null : t("save_appointment_to_upload_attachments"); // "To upload files, save appointment first."

    const renderHintItem = (...hints) => {
        let items = [];
        for (let i = 0; i < hints.length; i++) {
            items.push(
                <div className="item">
                    <i className="file icon large black"/>
                    <div className="content">
                        <div className="description">
                            {hints[i]}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="ui medium aligned list">
                {items}
            </div>
        );
    };

    const switchHintOnAppointmentCategory = (category) => {
        switch (category) {
            case CAT_INCLUSION:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"), t("hint_opt_papillen"))}

                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_fundus_photo"), t("hint_opt_hrt"))}
                    </div>
                );
            case CAT_CONTROL:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_tensio_profile"))}
                    </div>
                );
            case CAT_FOLLOWUP_T4:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"))}
                    </div>
                );
            case CAT_FINAL:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"), t("hint_opt_papillen"))}

                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_fundus_photo"), t("hint_opt_hrt"))}
                    </div>
                );
            default:
                return "";
        }
    };

    function getCurrentTabIndex () {
        return window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
    }

    const disableReaderAndDEMTab = () => {
        const sample = get(dataModel, "correlationSamples", []);
        const followUp = [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4];

        if (!examinationTabDisabled) {
            if (followUp.includes(dataModel.category)) {
                const previousAppointment = getPreviousAppointmentModel(patient, dataModel.category)
                if (previousAppointment) {
                    let previousAppointmentDate = new Date(previousAppointment?.startDate)
                    previousAppointmentDate.setMonth(previousAppointmentDate.getMonth() + 3);
                    if (!(dataModel?.status === APPT_STATUS_TOOK_PLACE) && previousAppointment?.status === APPT_STATUS_TOOK_PLACE &&
                        new Date(dataModel.startDate).getTime() >= previousAppointmentDate.getTime()) {

                        return true;
                    }
                }
            }
            return sample && sample[0]?.id === null;
        }

        return examinationTabDisabled;
    }

    const panes = [
        {
            menuItem: (
                <Menu.Item key={"appointment"}>
                    <div className={"pb-1of2"}>{t("appointment")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false}>
                    <InitiateAppointment model={dataModel} patientId={patientId} ref={currentTab} patient={patient}
                                         callback={setExaminationTabDisabled} isReadOnly={isReadOnlyStudyLead}
                                         afterSave={handleAfterSave} inclusionDate={inclusionDate} isStudyLead={isStudyLead}/>
                </Tab.Pane>
            )
        },
        {
            menuItem: (
                <Menu.Item key={"examination_type"} disabled={examinationTabDisabled}>
                    <div className={"pb-1of2"}>{t("examination_type")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <ExaminationTab dataModel={dataModel} patientId={patientId} readerCComment={readerCComment}
                                    ref={currentTab} afterSave={handleAfterSave} isReadOnly={isReadOnly || isPatient} patient={patient}/>
                </Tab.Pane>
            )
        },
        !(isReader || isDataEntryManager) && {
            menuItem: (
                <Menu.Item key={"questionnaire"} disabled={examinationTabDisabled}>
                    <div className={"pb-1of2"}>{t("questionnaire")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <QuestionnaireTab dataModel={dataModel} patientId={patientId} readerCComment={readerCComment}
                                      ref={currentTab} afterSave={handleAfterSave} isReadOnly={isReadOnly} />
                </Tab.Pane>
            )
        },
        (dataModel.category === CAT_INCLUSION || dataModel.category === CAT_CONTROL || dataModel.category === CAT_FOLLOWUP_T4 || dataModel.category === CAT_FINAL) &&
        {
            menuItem: (
                <Menu.Item key={"files"} disabled={examinationTabDisabled}>
                    <div className={"pb-1of2"} data-tooltip={filesToolTipText}>{t("files")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <div>
                        {switchHintOnAppointmentCategory(dataModel.category)}
                    </div>
                    <br/>
                    <AttachmentTable model={dataModel} appointmentId={appointmentId} isReadOnly={isReadOnly} refresh={handleAfterSave}/>
                    <br/>
                    <NewAttachment isReadOnly={isReadOnly}
                                   patientId={patientId}
                                   appointment={dataModel} refresh={handleAfterSave}/>
                </Tab.Pane>
            )
        },
        (dataModel.category === CAT_FIRST || dataModel.category === CAT_TENSIO || dataModel.category === CAT_FOLLOWUP_T2 || dataModel.category === CAT_FOLLOWUP_T3 || dataModel.category === CAT_FOLLOWUP_T4) &&
        {
            menuItem: (
                <Menu.Item key={"konsilKlinik"} disabled={false} >
                    <div className={"pb-1of2"}>{t("konsilKlinik")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <KonsilKlinikTab patientId={patientId} dataModel={dataModel} refresh={refresh} patient={patient}/>
                </Tab.Pane>
            )
        },
        (isReader && getCurrentTabIndex() !== "3") && // && getCurrentTabIndex() !== "4") &&
        {
            menuItem: (
                <Menu.Item key={"reader"} disabled={disableReaderAndDEMTab() || isReaderTabDisabled}>
                    <div className={"pb-1of2"}>{"Reader Zone"}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <RenderReaderZone patient={patient} refresh={refresh} dataModel={dataModel} patientId={patientId} allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples} isControlCohort={isControlCohort}/>
                </Tab.Pane>
            )
        },
        (isReader && getCurrentTabIndex() === "3") && // || getCurrentTabIndex() === "4")) &&
        {
            menuItem: (
                <Menu.Item key={"reader-tensio"} disabled={idReaderTensioZoneDisabled(patient)}>
                    <div className={"pb-1of2"}>{"Reader Zone"}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <RenderReaderZoneTensio dataModel={dataModel} allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                                            readerCComment={readerCComment} isControlCohort={isControlCohort}/>
                </Tab.Pane>
            )
        },
        isDataEntryManager &&
        {
            menuItem: (
                <Menu.Item key={"dataEntry"} disabled={disableReaderAndDEMTab() || isReaderTabDisabled}>
                    <div className={"pb-1of2"}>{"Data Entry"}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <DataEntryTab patient={patient} refresh={refresh} dataModel={dataModel} patientId={patientId}
                                  allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                                  readerCComment={readerCComment} isControlCohort={isControlCohort} />
                </Tab.Pane>
            )
        }
    ];

    const onTabChanged = async (e, data) => {
        if (dataModel.startDate === null) {
            console.log("null date!");
            toastError(t("error_date"));
        }

        if (checkRef(currentTab)) {
            if (currentTab.current.hasChanged && currentTab.current.hasChanged()) {
                setShowModalWarning(true);
                setNextTab(data.activeIndex);
            } else {
                setTab(data.activeIndex);
                setActiveTab(data.activeIndex);
            }
        } else {
            setTab(data.activeIndex);
        }

    };
    const setTab = (activeIndex) => {
        setActiveTab(activeIndex);
        window.sessionStorage.setItem("appointmentActiveIndexInTab", activeIndex);
    }

    const renderModalWarning = () => {
        const closeWithoutSaving = () => {
            setShowModalWarning(false);
            setTab(nextTab);
        };
        const brakeChangingTab = () => {
            setShowModalWarning(false);
        };

        return (
            <>
                {!isPatient &&
                    <Modal open={showModalWarning}>
                        <Modal.Header>{t("leave_header")}</Modal.Header>
                        <Modal.Content>
                            <p>{t("leave_without_saving")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={closeWithoutSaving}>{t("leave_yes")}</Button>
                            <Button
                                positive
                                content={t("leave_no")}
                                onClick={brakeChangingTab}
                            />
                        </Modal.Actions>
                    </Modal>
                }
            </>
        );
    };

    return (
        <div>
            {renderModalWarning()}
            <>
                <Tab activeIndex={activeTab} menu={{color: "teal"}} onTabChange={onTabChanged}
                     panes={panes.filter(p => {
                         if (isPatient) {
                             if (p.menuItem != null) {
                                 return p.menuItem.key === "examination_type";
                             } else {
                                 return false;
                             }
                         }
                         if (showQuestionnaire) {
                             return true;
                         } else if (p.menuItem != null) {
                             return p.menuItem.key !== "questionnaire";
                         } else {
                             return false;
                         }
                     })}/>
                <Divider/>
            </>
        </div>
    );
};

export default forwardRef(Appointment);