import React, {useState, forwardRef} from "react";
import {getChartDataFromSamples} from "../../data/models/utils";

import {
    DiscreteColorLegend,
    HorizontalGridLines,
    LineMarkSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";

import {formatDateTime} from "../../services";

const Chart = ({data, leftTime, rightTime}, ref) => {
    const [systoleEnable, setSystoleEnable] = useState(true);
    const [diastoleEnable, setDiastoleEnable] = useState(true);
    const [mdEnable, setMDEnable] = useState(true);
    const [hfEnable, setHFEnable] = useState(true);
    const correlationSamples = (data && data.correlationSamples) || [];

    // Data for 1 Line
    const s1 = getChartDataFromSamples(correlationSamples, "bloodPressureSystolic", leftTime, rightTime);
    // Data for 2 Line
    const s2 = getChartDataFromSamples(correlationSamples, "bloodPressureDiastolic", leftTime, rightTime);
    // Data for 3 Line
    const s3 = getChartDataFromSamples(correlationSamples, "md", leftTime, rightTime);
    // Data for 4 Line
    const s4 = getChartDataFromSamples(correlationSamples, "heartRate", leftTime, rightTime);

    const tickFormatOne = (v) => {
        return formatDateTime(v);
    };

    return (
        <div ref={ref}>
            <XYPlot className="clustered-stacked-bar-chart-example" width={1000} height={400} margin={{bottom: 80, left: 80, top: 20}}>
                <DiscreteColorLegend
                    orientation="horizontal"
                    style={{position: 'absolute', border: '2px solid #c3c3c3', marginTop: '10px'}}
                    items={[
                        {
                            title: "Systole",
                            disabled: !systoleEnable
                        },
                        {
                            title: "Diastole",
                            disabled: !diastoleEnable
                        },
                        {
                            title: "MD",
                            disabled: !mdEnable
                        },
                        {
                            title: "HF",
                            disabled: !hfEnable
                        }
                    ]}
                    onItemClick={(item, index) => {
                        switch (index){
                            case 0:
                                setSystoleEnable(!systoleEnable);
                                break;
                            case 1:
                                setDiastoleEnable(!diastoleEnable);
                                break;
                            case 2:
                                setMDEnable(!mdEnable);
                                break;
                            case 3:
                                setHFEnable(!hfEnable);
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <VerticalGridLines tickTotal={s1.length / 2}/>
                <HorizontalGridLines/>
                <XAxis xType={"time"} tickFormat={(v) => tickFormatOne(v)} tickLabelAngle={-45}/>
                <YAxis/>
                <LineMarkSeries size={3} data={s1} opacity={systoleEnable ? 1 : 0}/>
                <LineMarkSeries size={3} data={s2} opacity={diastoleEnable ? 1 : 0}/>
                <LineMarkSeries size={3} data={s3} opacity={mdEnable ? 1 : 0}/>
                <LineMarkSeries size={3} data={s4} opacity={hfEnable ? 1 : 0}/>
            </XYPlot>
        </div>
    );
};

export default forwardRef(Chart);
