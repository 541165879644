import gql from 'graphql-tag';


export const metaDataFragment = gql`
    fragment metaDataFragment on MetaData {
        changedBy
        changedDate
        createdBy
        creationDate
        deviceName
        deviceVendorName
        comment
        eyeLocation
        examinationType
    }
`;
