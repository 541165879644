import React, {useState} from "react";
import {Button, Divider, Header, List, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const DownloadsResident = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

     return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Button className={"teal"}>{t("footer_menu_downloads")}</Button>}
        >
            <Header>
                {t("footer_menu_downloads")}
            </Header>
            <Modal.Content>
                <div className={"c-header c-header--big"}>
                    Dokumente
                </div>

                <List divided relaxed>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsResident/SALUS_TE_MUSTER.pdf"} download={"SALUS_Teilnahmeerklärung.pdf"}>Teilnahme- und Einwilligungserklärung zur Studie SALUS</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsResident/Studieninformation_SALUS_22112021_V3.pdf"} download={"SALUS_Studieninformation.pdf"}>Studieninformation für Patienten</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsResident/SALUS_Datenschutzmerkblatt_V3__neu_von_Dezember_21.pdf"} download={"SALUS_Datenschutzmerkblatt.pdf"}>Datenschutzmerkblatt für Patienten</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/17_Verfahrensanweisung AUSZUG für Fachärzte in Praxen.pdf"} download={"17_Verfahrensanweisung AUSZUG für Fachärzte in Praxen.pdf"}>Auszug Verfahrensanweisung</a>
                            </List.Header>
                            <List.Description>Auszug aus der Verfahrensanweisung für das SALUS Projekt.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/Leitfaden Patientenaufklärung NAA.pdf"} download={"Leitfaden Patientenaufklärung NAA.pdf"}>Leitfaden zur Patientenaufklärung</a>
                            </List.Header>
                            <List.Description>Zusammenfassung der relevantesten Informationen für das Aufklärungsgespräch vor Studienbeitritt.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/4_ePA Papierformat.pdf"} download={"4_ePA Papierformat.pdf"}>Papierversion der Fallakte</a>
                            </List.Header>
                            <List.Description>Für Ihre <b>interne</b> Dokumentation.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/Anleitung PHYSIO-PORT.pdf"} download={"Anleitung PHYSIO-PORT.pdf"}>Patientenanleitung PHYSIO-PORT</a>
                            </List.Header>
                            <List.Description>Anleitung zum 24h-Blutdruckmessgerät.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/AnleitungPhysioPortWin.pdf"} download={"AnleitungPhysioPortWin.pdf"}>Anleitung PhysioPortWin-Software</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/Geräte-Inventar-Patienten-Sets UND Hinweis_Nutzung_Geräte.pdf"} download={"Geräte-Inventar-Patienten-Sets UND Hinweis_Nutzung_Geräte.pdf"}>Geräteinventarliste Patient</a>
                            </List.Header>
                            <List.Description>Inventarliste der Geräte | Icare HOME Selbsttonometer und 24h-Blutdruckmessgerät</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/Geräterückgabe Formular.pdf"} download={"Geräterückgabe Formular.pdf"}>Geräterückgabeformular</a>
                            </List.Header>
                            <List.Description>Rückgabeformular für die Geräte.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsResident/iCareExport_Installation.pdf"} download={"iCareExport_Installation.pdf.pdf"}>Installationsanleitung Icare-Export</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </List>
                <div className={"c-header c-header--big"}>
                    Software
                </div>

                 <List>
                     <List.Item>
                         <List.Icon name='file' size='large' verticalAlign='middle' />
                         <List.Content>
                             <List.Header>
                                 <a href={"software/IcareEXPORT.exe"} download={"icareEXPORT.exe"}> iCare EXPORT </a>
                             </List.Header>
                             <List.Description>Die iCare EXPORT Software. Sie wird benötigt, um die Gerätedaten des iCare HOME mit dem Server zu synchronisieren.</List.Description>
                         </List.Content>
                     </List.Item>
                     <List.Item>
                         <List.Icon name='file' size='large' verticalAlign='middle' />
                         <List.Content>
                             <List.Header>
                                 <a href={"software/Physioportwin.zip"} download={"Physioportwin.zip"}>PHYSIOPORT Software</a>
                             </List.Header>
                             <List.Description>Die PHYSIOPORT Software. Sie wird benötigt, um die Gerätedaten des PHYSIOPORT Gerätes herunterzuladen.</List.Description>
                         </List.Content>
                     </List.Item>
                 </List>
            <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DownloadsResident;
