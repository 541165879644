import React, {createContext, useEffect, useState} from "react";
import Keycloak from "keycloak-js";

const AuthContext = createContext({});

const config = {
    url: `${process.env.REACT_APP_AUTH_URI}`,
    realm: `${process.env.REACT_APP_AUTH_REALM}`,
    clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
};

const AuthContextProvider = ({children}) => {
    const [context, setContext] = useState({
        authenticated: false,
        keycloak: null,
        user: null,
    });

    useEffect(() => {
        const keycloak = Keycloak(config);
        keycloak.init({onLoad: "login-required", checkLoginIframe: false}).then((authenticated) => {
            keycloak.loadUserInfo().then((userInfo) => {
                setContext({
                    authenticated,
                    keycloak,
                    user: {
                        name: userInfo.name,
                        email: userInfo.email,
                        id: userInfo.sub,
                        roles: userInfo.roles || [],
                        organisation: userInfo.organisation,
                        lanr:userInfo.lanr,
                    },
                    logout: () => {
                        keycloak.logout();
                    },
                });
            });
        });
    }, []);

    return <AuthContext.Provider value={context}>{context.keycloak && children}</AuthContext.Provider>;
};

function hasPermission(rolesUser, rolesToCheck) {
    let permitted = false;
    rolesToCheck.forEach(role => {
        if(rolesUser && Array.isArray(rolesUser) && rolesUser.indexOf(role) >= 0){
            permitted = true;
        }
    });
    return permitted;
}

export {AuthContextProvider, AuthContext, hasPermission};
