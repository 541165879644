import React, {useEffect, useReducer, useState} from "react";
import NoResultsFound from "../NoResultsFound";
import {Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../services";
import {useHistory} from "react-router-dom";
import {
    APPT_READING_FINISHED,
    APPT_STATUS_CANCELED,
    APPT_STATUS_TOOK_PLACE,
    CAT_CONTROL,
    CAT_FINAL,
    CAT_FIRST,
    CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4,
    CAT_INCLUSION,
    CAT_TENSIO
} from "../../data/mutations/appointments";
import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";
import {find, get} from "lodash";
import * as _ from "lodash";
import {checkDocLength, checkRequiredFields} from "../reader/models/utils";
import createModelFollowUpFirst from "../../data/models/appointmentSharedFollowUpFirst";
import createModelFollowUp from "../../data/models/appointmentFollowUp";
import createModelFollowUpT4 from "../../data/models/appointmentFollowupT4";
import createModelControl from "../../data/models/appointmentControl";
import createModelInclusionFinal from "../../data/models/appointmentSharedInclusionFinal";
import createModelFinal from "../../data/models/appointmentFinal";
import createModelTensio from "../../data/models/appointmentTensio";

function reducer(state, action) {
    switch (action.type) {
        case "CHANGE_SORT":
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === "ascending" ? "descending" : "ascending",
                }
            }
            if (action.column === "patientSurname") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.surname > b.masterData.surname ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "city") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.contact.address.city > b.masterData.contact.address.city ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "inclusionDate") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.studyData.inclusionDate > b.studyData.inclusionDate ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "insuranceCompany") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.insurance.company > b.masterData.insurance.company ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "hospital") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.hospital.name > b.masterData.hospital.name ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "doctor") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.studyData.doctorInCharge.surname > b.studyData.doctorInCharge.surname ? 1 : -1),
                    direction: "ascending",
                }
            }
            break;
        default:
            throw new Error();
    }
}

const StudyLeadPatientTable = ({hasResults, patientsData, noData, maxPatients}) => {
    const { t } = useTranslation();
    const [studyLeadTableData, setStudyLeadTableData] = useState(patientsData || []);

    const [state, dispatch] = useReducer(reducer, {
        column: "patientName",
        data: studyLeadTableData.sort((a,b) => a.masterData.surname > b.masterData.surname ? 1 : -1),
        direction: "ascending",
    });

    const { column, data, direction } = state;

    /* eslint-disable */
    useEffect(() => {
        state.data = patientsData;
        setStudyLeadTableData(patientsData);
    }, [patientsData])

    const getCohortStatistics = data => {
      const cohortStatistics = {control: 0, intervention: 0};
      for (const it of data) {
          if (it.studyData.cohort === CAT_CONTROL) {
              cohortStatistics.control += 1;
          } else {
              cohortStatistics.intervention += 1;
          }
      }
      return cohortStatistics;
    };

    const history = useHistory();

    const didPreviousAppointmentTookPlace = (category, modelInclusion, modelFollowUp1, modelFollowUp2) => {
        switch (category) {
            case CAT_FOLLOWUP_T2:
                return modelInclusion?.status === APPT_STATUS_TOOK_PLACE
            case CAT_FOLLOWUP_T3:
                return modelFollowUp1?.status === APPT_STATUS_TOOK_PLACE
            case CAT_FOLLOWUP_T4:
                return modelFollowUp2?.status === APPT_STATUS_TOOK_PLACE
            default:
                return null;
        }
    }

    const checkIfNextAppointmentsTookPlace = (currentAppointment, allModels) => {
        allModels = allModels.filter((item) => item?.category !== currentAppointment?.category)
        return allModels.some((item) => item.status === APPT_STATUS_TOOK_PLACE);
    }

    const getPreviousAppointmentDate = (category, modelInclusion, modelFollowUp1, modelFollowUp2) => {
        switch (category) {
            case CAT_FOLLOWUP_T2:
                return new Date(modelInclusion?.startDate);
            case CAT_FOLLOWUP_T3:
                return new Date(modelFollowUp1?.startDate);
            case CAT_FOLLOWUP_T4:
                return new Date(modelFollowUp2?.startDate);
            default:
                return null;
        }
    }

    const isAppointmentInComplete = (appointment, allModels, modelInclusion, modelFollowUp1, modelFollowUp2) => {
        const sample = get(appointment, "correlationSamples", []);
        const didAppointmentTakePlace = appointment?.status === APPT_STATUS_TOOK_PLACE;
        const didNextAppointmentsTookPlace = checkIfNextAppointmentsTookPlace(appointment, allModels);
        const followUp = [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4]

        if (didAppointmentTakePlace && ((sample && !checkRequiredFields(sample)) || checkDocLength(appointment))) {
            return true
        }

        if (!didAppointmentTakePlace && appointment?.startDate && new Date(appointment?.startDate).getTime() <= new Date().getTime()) {
            return true
        }

        if (!didAppointmentTakePlace && didNextAppointmentsTookPlace) {
            return true
        }

        if (followUp.includes(appointment?.category)) {
            let previousAppointmentDate = getPreviousAppointmentDate(appointment?.category, modelInclusion, modelFollowUp1, modelFollowUp2);
            previousAppointmentDate.setMonth(previousAppointmentDate.getMonth() + 3);
            if (!didAppointmentTakePlace && didPreviousAppointmentTookPlace(appointment?.category, modelInclusion, modelFollowUp1, modelFollowUp2) &&
                new Date().getTime() >= previousAppointmentDate.getTime()) {

                return true
            }
        }
        return false
    }

    function getAppointment(appointments, appointmentName) {
        return _.find(appointments, function(obj){
            return obj.category === appointmentName;
        }) || [];
    }

    function whichModel(modelName, modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelControl, modelInclusion,
                        modelFinal, modelTensio) {
        switch (modelName) {
            case CAT_FIRST:
                return modelFirst
            case CAT_FOLLOWUP_T2:
                return modelFollowUp1
            case CAT_FOLLOWUP_T3:
                return modelFollowUp2
            case CAT_FOLLOWUP_T4:
                return modelFollowUp3
            case CAT_INCLUSION:
                return modelInclusion
            case CAT_FINAL:
                return modelFinal
            case CAT_CONTROL:
                return modelControl
            case CAT_TENSIO:
                return modelTensio
            default:
                return null
        }

    }

    const getStatus = (isControlCohort, isPatientExcluded, modelFirst, modelInclusion, modelControl,
                       modelTensio, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelFinal) => {
        const appointmentNames = [CAT_FIRST, CAT_INCLUSION, CAT_CONTROL, CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];

        let allModels = [modelInclusion, isControlCohort ? modelControl : modelTensio, modelFollowUp1, modelFollowUp2,
            modelFollowUp3, modelFinal]

        if (isPatientExcluded)
        {
            return <i className="green check circle icon"/>
        }
        else
        {
            for(let i = 0; i < appointmentNames.length; i++) {
                if ((appointmentNames[i] === CAT_CONTROL && !isControlCohort) ||
                    (appointmentNames[i] === CAT_TENSIO && isControlCohort)) {
                    continue;
                }
                const currentAppointment = whichModel(appointmentNames[i], modelFirst, modelFollowUp1,
                    modelFollowUp2, modelFollowUp3, modelControl, modelInclusion, modelFinal, modelTensio)

                if ([CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4].includes(appointmentNames[i]) && currentAppointment?.status === APPT_STATUS_CANCELED)
                {
                    continue;
                }
                allModels = allModels.filter((item) => item?.category !== appointmentNames[i])

                if(isAppointmentInComplete(currentAppointment, allModels, modelInclusion, modelFollowUp1, modelFollowUp2))
                {
                    return <i className="red times circle icon"/>
                }
            }
            return <i className="green check circle icon"/>
        }
    }

    const getStatusReading = (appointments, isPatientExcluded, patient) => {

        const isControlCohort = patient && patient.studyData.cohort === "CONTROL"
        const allAppointments = [CAT_FIRST, CAT_INCLUSION, isControlCohort ? CAT_CONTROL : CAT_TENSIO,
            CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL]

        if (isPatientExcluded) {
            return <i className="green check circle icon"/>
        }
        else if (patient?.appointments && Array.isArray(patient?.appointments) && patient?.appointments?.length)
        {
            for (let appointment of allAppointments) {
                const model = find(appointments, (it) => it.category === appointment) || {}
                const readingStatus = model?.categorySamples?.find(x => _.get(x, "categoryType") === "readingStatus") ?? null

                if (!readingStatus || readingStatus.value !== APPT_READING_FINISHED) {
                    return <i className="red times circle icon"/>
                }
            }

            return <i className="green check circle icon"/>
        }
        else if (appointments.length > allAppointments.length)
        {
            for (let appointment of allAppointments) {
                const model = find(appointments, (it) => it.category === appointment) || {}
                if (model.categorySamples) {
                    const readingStatus = model.categorySamples.find(x => _.get(x, "categoryType") === "readingStatus")
                    if (!readingStatus || readingStatus.value !== APPT_READING_FINISHED) {
                        return <i className="red times circle icon"/>
                    }
                }
            }
            return <i className="green check circle icon"/>
        }
        return <i className="red times circle icon"/>
    }

    return (
          <div className={"c-container scroll-y"}>
              {hasResults &&
                    <Table celled striped selectable sortable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "patientSurname" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "patientSurname" })}
                                >
                                    {"Pat. Name"}<br />{"Pat. ID"}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "city" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "city" })}
                                >
                                    {t("city")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{t("cohort")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "inclusionDate" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "inclusionDate" })}
                                >
                                    {t("study_inclusion_date")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{"Status"}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{"Reading"}<br />{"(abschließend)"}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "insuranceCompany" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "insuranceCompany" })}
                                >
                                    {t("insurance_company")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "hospital" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "hospital" })}
                                >
                                    {t("hospital_in_charge")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}
                                    sorted={column === "doctor" ? direction : null}
                                    onClick={() => dispatch({ type: "CHANGE_SORT", column: "doctor" })}
                                >
                                    {"NAA"}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {data.map(it => {
                                const isPatientExcluded = it?.status === PAT_STATUS_DATA_EXCLUDED || false
                                const modelFirst = createModelFollowUpFirst(it, t, CAT_FIRST);
                                const modelInclusion = createModelInclusionFinal(it, t, CAT_INCLUSION);
                                const modelControl = createModelControl(it, t);
                                const modelTensio = createModelTensio(it);
                                const modelFollowUp1 = createModelFollowUp(it, t, CAT_FOLLOWUP_T2);
                                const modelFollowUp2 = createModelFollowUp(it, t, CAT_FOLLOWUP_T3);
                                const modelFollowUp3 = createModelFollowUpT4(it, t, CAT_FOLLOWUP_T4);
                                const modelFinal = createModelFinal(it, t, CAT_FINAL);

                                return (
                                    <Table.Row style={{color: isPatientExcluded  ? "grey" : ""}} key={it.patientId} onClick={() => history.push(`/patient/${it.id}`)}>
                                        <Table.Cell key={`${it.patientId}_name`}>{it.masterData.forename + " " + it.masterData.surname}<br />{it.patientId}</Table.Cell>
                                        <Table.Cell key={`${it.patientId}_city`}>{it.masterData.contact.address.city}</Table.Cell>
                                        <Table.Cell key={`${it.patientId}_cohort`}>{t("COHORT_" + it.studyData.cohort)}</Table.Cell>
                                        <Table.Cell textAlign={"center"} key={`${it.patientId}_inclusionDate`}>{formatDate(it.studyData.inclusionDate)}</Table.Cell>
                                        <Table.Cell textAlign={"center"} key={`${it.patientId}_status`}>
                                            {getStatus(it && it.studyData.cohort === "CONTROL", isPatientExcluded, modelFirst,
                                                modelInclusion, modelControl, modelTensio, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelFinal)}
                                        </Table.Cell>
                                        <Table.Cell textAlign={"center"} key={`${it.patientId}_reading`}>
                                            {getStatusReading(it.appointments, isPatientExcluded, it)}
                                        </Table.Cell>
                                        <Table.Cell key={`${it.patientId}_insuranceCompany`}>{it.masterData.insurance.company}</Table.Cell>
                                        <Table.Cell key={`${it.patientId}_hospital`}>{it.masterData.hospital.name}</Table.Cell>
                                        <Table.Cell key={`${it.patientId}_doctor`}>{it.studyData.doctorInCharge.forename + " " + it.studyData.doctorInCharge.surname}</Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell textAlign={"center"} className={"table-footer"}>{`${data.length} / ${maxPatients}`}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"} className={"table-footer"}>{`K:${getCohortStatistics(data).control} / I:${getCohortStatistics(data).intervention}`}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
              }

              {noData && (
                  <div className={"center-all"}>
                      <NoResultsFound />
                  </div>
              )}
          </div>
    );
};

export default StudyLeadPatientTable;