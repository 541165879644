import {copyOver} from "services";
import {APPT_STATUS_NOT_SCHEDULED_YET, CAT_TENSIO} from "../mutations/appointments";
import _, {find} from "lodash";

const createModel = patient => {
    const category = CAT_TENSIO;
    const appointments = patient.appointments;
    const tensioApp = _.filter(patient.appointments, (it) => it.category === CAT_TENSIO) ?? []
    const payload = tensioApp?.length ? find(tensioApp, (it) => it?.categorySamples?.length) ?? tensioApp?.[0] :
        find(appointments, (it) => it.category === category) || {};

    const model = {
        id: null,
        startDate: null,
        endDate: null,
        category: category,
        status: APPT_STATUS_NOT_SCHEDULED_YET,
        interventions: patient.interventions,
        doctors: [],
        documentSamples: [],
        correlationSamples: [],
        __roles: ["resident", "clinician"],
        recommendedTherapy: {
            therapy: ""
        }
    };

    return copyOver(model, payload);
};

export default createModel;
