import {find, get} from "lodash";
import {formatDate} from "../../services";
import {StandardFonts} from "pdf-lib";
import domtoimage from "dom-to-image";

export async function addHeading(pdfDoc, appointmentName, masterData, appointmentDate) {
    console.log(masterData)
    const getField = path => get(masterData, path, "");
    const insuranceCompany = getField("insurance.company").substring(0, getField("insurance.company").lastIndexOf("("));
    const name = `${getField("forename")} ${getField("surname")}`;
    const street = `${getField("contact.address.street")} ${getField("contact.address.streetno")}`;
    const place = `${getField("contact.address.postalcode")} ${getField("contact.address.city")}`;
    const birthDate = formatDate(getField("birthdate"));
    const insuranceNo = getField("insurance.number");

    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(insuranceCompany, { x: 51, y: 787, size: 10, font: courierFont });

    firstPage.drawText(name, { x: 51, y: 759, size: 10, font: courierFont });
    firstPage.drawText(street, { x: 51, y: 747, size: 10, font: courierFont });
    firstPage.drawText(place, { x: 51, y: 735, size: 10, font: courierFont });

    firstPage.drawText(birthDate, { x: 232, y: 759, size: 10, font: courierFont });

    firstPage.drawText(insuranceNo, { x: 146, y: 713, size: 10, font: courierFont });

    firstPage.drawText(`${getField("hospital.name")}`, { x: 51, y: 686, size: 10, font: courierFont });
    firstPage.drawText(getDate(appointmentDate), { x: 232, y: 686, size: 10, font: courierFont });

    firstPage.drawText(appointmentName, { x: 51, y: 660, size: 10, font: courierFont });

    return pdfDoc;
}

export function getValue(correlationSamples, fieldName) {
    for (let i = 0; i < correlationSamples.length; ++i) {
        for (let j = 0; j < correlationSamples[i]?.quantitySamples?.length; ++j) {
            if (correlationSamples[i]?.quantitySamples[j]?.quantityType === fieldName) {
                const value = correlationSamples[i]?.quantitySamples[j]?.quantity?.toString()
                return value === "-50000" ? "" : value || "";
            }
        }
    }

    return "";
}

export function getCategoryValue(correlationSamples, fieldName) {
    for (let i = 0; i < correlationSamples.length; ++i) {
        for (let j = 0; j < correlationSamples[i]?.categorySamples?.length; ++j) {
            if (correlationSamples[i]?.categorySamples[j]?.categoryType === fieldName) {
                return correlationSamples[i]?.categorySamples[j]?.value?.toString() || "";
            }
        }
    }

    return "";
}

export function getDeviceName(correlationSamples, correlationType) {
    for (let i = 0; i < correlationSamples.length; ++i) {
        if (correlationSamples[i].correlationType === correlationType) {
            return correlationSamples[i]?.metadata?.deviceName || "";
        }
    }

    return "";
}

export function getMethodName(categorySamples, categoryType) {

}

export function getDate(appointmentDate) {
    const date = new Date(appointmentDate);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
}

export const getQuantities = (sample, type) => {
    return find(sample.quantitySamples,(it) => it.quantityType === type);
};

export const getCategories = (sample, type) => {
    return find(sample.categorySamples,(it) => it.categoryType === type);
};

export function printPageNo(pdfDoc, helveticaBoldFont) {
    const pageCount = pdfDoc.getPageCount();
    const pages = pdfDoc.getPages();

    for (let i = 0; i < pageCount; i++) {
        pages[i].drawText(`Seite ${i+1} von ${pageCount}`, {x: 495, y: 55, size: 10, font: helveticaBoldFont});
    }
}

export async function printChart(pdfDoc, domNode) {
    await domtoimage.toPng(domNode).then(async function (dataUrl) {
        const pngImage = await pdfDoc.embedPng(dataUrl);
        const pngDims = pngImage.scale(0.5);

        const page = pdfDoc.getPages()[0];
        page.drawImage(pngImage, {x: 42, y: 325, width: pngDims.width, height: pngDims.height})
    }).catch(function (error) {
        console.log(error);
    });
}

export const getCleanedValue = (value) => {
    return value === "NaN" ? "" : value;
};