import {PATIENT_SELF} from "data/queries/queries";
import {useQuery} from "@apollo/react-hooks";
import {useTranslation} from "react-i18next";
import {toastError} from "services";

export const usePatientSelf = () => {
    const {t} = useTranslation();
    const {loading, error, data, refetch} = useQuery(PATIENT_SELF);

    if (error) {
        toastError(t("error_gql"));
    }

    return {loading, data, refetch};
};
