import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from "assets/salus-logo2.png";
import {Button, Dropdown, Header, Icon, Input} from "semantic-ui-react";
import {AuthContext, hasPermission, isDemo} from "services";
import {useHistory} from "react-router-dom";
import OrganizationProfile from "./profile/OrganizationProfile";
import UserProfile from "./profile/UserProfile";
import DecryptDB from "./profile/DecryptDB";
import Help from "./Help";
import {useStats} from "../hooks";
import {get} from "lodash";
import ExportData from "./profile/ExportData"
import ExporEvaluationtData from "./profile/ExporEvaluationtData"
import ExportDataByPatientIds from "./profile/ExportDataByPatientIds"

/***
 * Due to the back in the DropBox need a hook to force dropdown to be closed while modal is open
 * @param onNewPersonClick
 * @param onSearchClick
 * @param loading
 * @param refresh
 * @returns {JSX.Element}
 * @constructor
 */
const PageHeaderContent = ({onNewPersonClick, onSearchClick, loading, refresh}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const stats = useStats();
    const [searchString, setSearchString] = useState("");
    const {user, logout} = useContext(AuthContext);
    const [dropDownIsOpen, setDropDownOpen] = useState(false);
    const [forcelyClose, setForcelyClose] = useState(false)

    const isStudyLeadUser = hasPermission(user.roles, ["study_lead"])
    const isQualityControlUser = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c") || user.roles.includes("data_entry_manager");

    const handleLogout = () => {
        navigateToHome();
        logout();
    };

    const navigateToHome = () => {
        history.push("/");
    };

    const handleSearch = () => {
        onSearchClick(searchString);
    };

    const handleOnSearchChange = (e) => {
        setSearchString(e.target.value);
    };

    const handleOnSearchKeyDown = (e) => {
        if (e.key === "Enter" && !loading) {
            handleSearch();
        }
    }

    const mayCreatePatient = () => {
        let createPatientPermission = false;
        if (hasPermission(user.roles, ["resident", "clinician"])) {
            createPatientPermission = true;
        }

        const recruitable = get(stats, "data.stats.study.patients.recruitable.total");
        if (recruitable < 1) {
            createPatientPermission = false;
        }
        return !isQualityControlUser && createPatientPermission;
    };

    let decryptDB;
    const databaseUnlocked = get(stats, ['data', 'stats', 'security', 'databaseUnlocked'], false);
    if (hasPermission(user.roles, ["master_data_unlock"]) && !databaseUnlocked)
        decryptDB = <DecryptDB afterSave={refresh} closeDropDown={(v) => setForcelyClose(v)}/>;
    else
        decryptDB = null;

    let exportData = null
    if (isStudyLeadUser)
        exportData = <ExportData afterSave={refresh} closeDropDown={(v) => setForcelyClose(v)} />

    let exportEvaluationData = null
    if (isStudyLeadUser)
        exportEvaluationData = <ExporEvaluationtData afterSave={refresh} closeDropDown={(v) => setForcelyClose(v)} />

    let exportDataByPatientIds = null
    if (isStudyLeadUser)
        exportDataByPatientIds = <ExportDataByPatientIds afterSave={refresh} closeDropDown={(v) => setForcelyClose(v)} />

    const closeDropDown = (v) => {
        setForcelyClose(v);
        setDropDownOpen(false);
    };

    return (
        <div className="Grid page-header elevated padding-normal">
            <div className="Cell -2of12 Grid" style={{"display": "flex", "flexDirection": "column", "flexWrap": "nowrap", "justifyContent": "flex-end", "alignContent": "flex-end", "alignItems": "flex-start"}}>
                <img src={Logo} alt={""} className={"cursor-pointer"} style={{height: "42px"}}
                     onClick={navigateToHome}
                />
                {isDemo() && <Header style={{margin: "-12px 10px", "alignSelf": "flex-end", flex: "0 1 auto"}} as={"h3"} inverted={true} className={"demo-header"}>DEMO</Header>}
            </div>
            <div className="Cell -8of12  Grid">
                <Input
                    value={searchString}
                    onChange={handleOnSearchChange}
                    onKeyDown={handleOnSearchKeyDown}
                    autoComplete="off"
                    loading={loading}
                    iconPosition={"left"}
                    action={{
                        color: "yellow",
                        icon: "search",
                        onClick: handleSearch
                    }}
                    style={{paddingRight: "1rem", flex: "1"}}
                    placeholder={isQualityControlUser ? t("search_reader_view") : t("search")}
                />
                {mayCreatePatient() && <Button inverted onClick={onNewPersonClick} disabled={true} className={"button-plain"}>
                    <Icon name={"add"}/> <label>{t("newPatientDisabled")}</label>
                </Button>}
                <Help/>
            </div>
            <div className="Cell -2of12 Grid -right">
                {user && (
                    <Dropdown text={user.name} open={dropDownIsOpen && !forcelyClose}
                              onClick={() => setDropDownOpen(!dropDownIsOpen)}
                              onBlur={() => setDropDownOpen(false)}
                    >
                        <Dropdown.Menu
                            direction="left"
                            style={{
                                width: "100%",
                                top: "1.5rem"
                            }}
                        >

                            <UserProfile closeDropDown={(v) => closeDropDown(v)} refresh={refresh}/>
                            <OrganizationProfile closeDropDown={(v) => closeDropDown(v)}  refresh={refresh}/>
                            {decryptDB}
                            {exportData}
                            {exportEvaluationData}
                            {exportDataByPatientIds}
                            <Dropdown.Item onClick={handleLogout} icon="sign-out" text={t("logout")}/>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <br/>
            </div>
        </div>
    );
};

const PageHeaderContentForPatientView = ({nameOfUser}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {user, logout} = useContext(AuthContext);
    const [dropDownIsOpen, setDropDownOpen] = useState(false);
    const [forcelyClose, ] = useState(false);

    const handleLogout = () => {
        navigateToHome();
        logout();
    };

    const navigateToHome = () => {
        history.push("/");
    };

    return (
        <div className="Grid page-header elevated padding-normal">
            <div className="Cell -2of12 Grid" style={{"display": "flex", "flexDirection": "column", "flexWrap": "nowrap", "justifyContent": "flex-end", "alignContent": "flex-end", "alignItems": "flex-start"}}>
                <img src={Logo} alt={""} className={"cursor-pointer"} style={{height: "42px"}}
                     onClick={navigateToHome}
                />
                {isDemo() && <Header style={{margin: "-12px 10px", "alignSelf": "flex-end", flex: "0 1 auto"}} as={"h3"} inverted={true} className={"demo-header"}>DEMO</Header>}
            </div>
            <div className="Cell -8of12  Grid">{}</div>
            <div className="Cell -2of12 Grid -right">
                {user && (
                    <Dropdown text={nameOfUser} open={dropDownIsOpen && !forcelyClose}
                              onClick={() => setDropDownOpen(!dropDownIsOpen)}
                              onBlur={() => setDropDownOpen(false)}
                    >
                        <Dropdown.Menu
                            direction="left"
                            style={{
                                width: "100%",
                                top: "1.5rem"
                            }}
                        >
                            <Dropdown.Item onClick={handleLogout} icon="sign-out" text={t("logout")}/>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <br/>
            </div>
        </div>
    );

};

const PageHeader = ({onNewPersonClick, onSearchClick, loading, refresh, nameOfUser}) => {
    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    return(
        <>
            {isPatient
                ?
                <PageHeaderContentForPatientView nameOfUser={nameOfUser}/>
                :
                <PageHeaderContent
                    onNewPersonClick={onNewPersonClick}
                    onSearchClick={onSearchClick}
                    loading={loading}
                    refresh={refresh}
                />
            }
        </>
    );
};

export default PageHeader;
