import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon} from "semantic-ui-react";
import {AuthContext, formatDate} from "../../services";
import QRCode from "qrcode";
import {get} from "lodash";
import {PDFDocument, StandardFonts} from "pdf-lib";
import download from "downloadjs";
import {insurance_companies_no_list} from "../../data/models/utils";

const AgreementDownload = () => {
    const { t } = useTranslation();

    const {user} = useContext(AuthContext);
    const isStudyLead = user.roles.includes("study_lead");
    const isClinician = user.roles.includes("clinician");

    const patientInfo = getPatientInfo(user, isStudyLead);

    const getButton = () => {
        return (
            <div>
                <Icon name={"file pdf outline"} size={"big"} color={"red"} />
                <Button basic color={"blue"} onClick={() => modifySalusPdf(patientInfo, t("ss_file_name"), isClinician)}>{isStudyLead ? t("salus_study_download") : t("agreement_download")}</Button>
            </div>
        );
    }

    return (
        isStudyLead
            ? getButton()
            :
            <div style={{ height: "12rem" }} className={"Grid -middle -around"}>
                <div>{t("agreement_description")} <br/> <br/> <b>{t("agreement_warning")}</b></div>
                {getButton()}
            </div>
    );
};

/**
 * Renders the info box and QR code in the salus PDF
 *
 * @param patientInfo object containing details about the patient
 * @param fileName name of the file for `GENERAL` and `INTERVENTION` mode
 * @param isClinician whether the user who is creating new patient is clinician or not
 */

export async function modifySalusPdf(patientInfo, fileName, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/salus.pdf" :
        (
            insurance_companies_no_list.includes(patientInfo.insuranceCompanyNo) ? "pdftemplates/salus.pdf":"pdftemplates/SALUS_TE_630a.pdf"
        )
    const salusBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(salusBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 45, y: 795, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 45, y: 760, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 45, y: 750, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 45, y: 740, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 45, y: 730, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 245, y: 760, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 45, y: 698, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 145, y: 698, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 45, y: 673, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 145, y: 673, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 240, y: 673, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo, "GENERAL"));
    firstPage.drawImage(qrCodeImage, { x: 480, y: 660, width: 60, height: 60, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, fileName, "application/pdf");
}

export function getPatientInfo(user, isStudyLead){
    const key = isStudyLead ? "patient_to_be_edited" : "new_patient";
    const patient = JSON.parse(window.localStorage.getItem(key) || "{}");
    const getField = path => get(patient.masterData, path, "");

    return {
        foreName: getField("forename").trim(),
        lastName: getField("surname").trim(),
        street: getField("contact.address.street").trim(),
        streetNo: getField("contact.address.streetno").trim(),
        zipCode: getField("contact.address.postalcode").trim(),
        place: getField("contact.address.city").trim(),
        birthDate: formatDate(getField("birthdate")),
        insuranceCompany: getField("insurance.company")
            .substring(0, getField("insurance.company").lastIndexOf("(")).trim(),
        insuranceCompanyNo: getField("insurance.company")
            .substring(getField("insurance.company").lastIndexOf("(") + 1,
                getField("insurance.company").lastIndexOf(")")).replace(/\s/g, ''),
        insuranceNo: getField("insurance.number").trim(),
        practiceid: isStudyLead ? getField("practice.bsnr") : user.organisation.bsnr.trim(),
        lanr: isStudyLead ? get(patient, "studyData.doctorInCharge.lanr", "") : patient.studyData.doctorInCharge.lanr.trim(),
    };
}

/**
 * @param patientInfo object containing details about the patient
 * @param mode `GENERAL` for salus agreement form and `INTERVENTION` for insurance form
 * @returns data URI of the QR code
 */

export function getQRCodeURI(patientInfo, mode="INTERVENTION") {
    const qrCodeStringLastPart = "#PVON:" + patientInfo.foreName + "#"
    + "#PNAN:" + patientInfo.lastName + "#"
    + "#PSTR:" + patientInfo.street + " " + patientInfo.streetNo + "#"
    + "#PPLZ:" + patientInfo.zipCode + "#"
    + "#PORT:" + patientInfo.place + "#"
    + "#PKVK:" + patientInfo.insuranceNo + "#"
    + "#PGEB:" + patientInfo.birthDate.split(".").join("") + "#"
    + "#LANR:" + patientInfo.lanr + "#"
    + "#BSNR:" + patientInfo.practiceid + "#"
    + "#KNAM:" + patientInfo.insuranceCompany + "#"
    + "#KIKN:" + patientInfo.insuranceCompanyNo + "#";

    const qrCodeString = (mode === "GENERAL" ? "#DOKART:TESAL#" : "#DOKART:DAPVM##DOKT:SALUS#") + qrCodeStringLastPart;
    const qrCodeOptions = {errorCorrectionLevel: "Q", type: "image/png", margin: 1};

    let qrCodeURI = "";
    QRCode.toDataURL(qrCodeString, qrCodeOptions, function (err, url) {
        if (err) throw err;
        qrCodeURI = url;
    });

    return qrCodeURI;
}

export function getTodayDate() {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
}

export default AgreementDownload;
