import React from "react";
import {Image} from "semantic-ui-react";
import NotFound from "assets/not_found.svg";
import {useTranslation} from "react-i18next";

const NoResultsFound = () => {
    const {t} = useTranslation();
    return (
        <div className={"flex-column flex-centered flex"}>
            <Image src={NotFound} style={{maxWidth: "10rem"}} />

            <div className={"flex-column flex-centered"}>
                <br />
                <h2 className={"margin-small"}>{t("search_no_results")}</h2>
                <h4 className={"margin-small"}>{t("search_no_results_hint")}</h4>
            </div>
        </div>
    );
};

export default NoResultsFound;
