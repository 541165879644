import {useMutation} from "@apollo/react-hooks";
import {CREATE_PATIENT, UPDATE_PATIENT} from "../data/mutations/mutations";
import {useTranslation} from "react-i18next";
import {toastSuccess, toastError} from '../services';
import {verifyGraphQlResponse} from "../services";
import {cloneDeep, isObject} from "lodash";

export const useMutateMasterData = ({isEditMode}) => {
    const {t} = useTranslation();
    const [createOrEditMasterData, {loading}] = useMutation(isEditMode ? UPDATE_PATIENT : CREATE_PATIENT);

    const getVariables = (vars) => {
        const variables = cloneDeep(vars);
        if (isObject(variables.masterData)) {
            delete variables.masterData['practice']; // MasterDataInput has no field practice (must not be changed!)

            if (isObject(variables.masterData.hospital)) {
                variables.masterData.hospital = variables.masterData.hospital.id;
            }
        }
        return variables;
    };

    const mutateMasterData = (vars) => {
        if (loading) {
            return Promise.reject("Loading in progress...");
        }

        const variables = getVariables(vars);

        return createOrEditMasterData({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return isEditMode ? response.data.updateMasterData.id : response.data.createPatient.id;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
            });
    };

    return {mutateMasterData, loading};
};
