import React from 'react';
import {
    CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4, CAT_FINAL, CAT_CONTROL, CAT_TENSIO,
} from "../../data/mutations/appointments";
import RenderReaderZoneFull from "./RenderReaderZoneFull";


const RenderReaderZone = ({dataModel, patientId, allAppointmentsCorrelationSamples, refresh, patient, isControlCohort, readerCComment}) => {

    // const rightSuffix = "Right";
    // const leftSuffix = "Left";

    function getCurrentTab () {
        const tabsSorted = [CAT_FIRST, CAT_INCLUSION, isControlCohort ? CAT_CONTROL : CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];
        const currentTabIndex = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
        return tabsSorted[currentTabIndex - 1];
    }

    // const readerSections = [
    //     {
    //         section: 'Dezimalvisus',
    //         device: null,
    //         appointmentNames: [CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL],
    //         values: getValues('dezimalvisus', [{fieldName: "decimalvisus", text: "Visus"}])
    //     },
    //     {
    //         section: 'Tensiomessung',
    //         device: getDevice('tensioMeasurementManual'),
    //         appointmentNames: [CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL],
    //         values: getValues('tensioMeasurementManual', [{fieldName: "tensioMeasurementManual", text: "Tensiomessung"}])
    //     },
    //     {
    //         section: 'Individueller Zieldruck',
    //         device: null,
    //         appointmentNames: [isControlCohort ? CAT_INCLUSION : CAT_FIRST],
    //         values: getValues('targetPressure', [{fieldName: "targetPressure", text: "Zieldruck"}])
    //     },
    //     {
    //         section: 'Refraktion',
    //         device: null,
    //         appointmentNames: [CAT_FIRST],
    //         values: getValues('refraction', [
    //                 {fieldName: "sphere", text: "Sphäre"},
    //                 {fieldName: "cylinder", text: "Zylinder"},
    //                 {fieldName: "axis", text: "Achse"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'BMO-MRW',
    //         device: getDevice('sdOct'),
    //         appointmentNames: [CAT_INCLUSION, CAT_FINAL],
    //         values: getValues("sdOct", [
    //                 {fieldName: "bmoArea", text: "BMO Area"},
    //                 {fieldName: "bmoMrwGlobal", text: "Global"},
    //                 {fieldName: "bmoMrwNasalSuperior", text: "Nasal Superior"},
    //                 {fieldName: "bmoMrwNasal", text: "Nasal"},
    //                 {fieldName: "bmoMrwNasalInferior", text: "Nasal Inferior"},
    //                 {fieldName: "bmoMrwTemporalInferior", text: "Temporal Inferior"},
    //                 {fieldName: "bmoMrwTemporal", text: "Temporal"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'Perimetrie (Gesichtsfeld)',
    //         device: getDevice('30perimetry'),
    //         appointmentNames: [CAT_INCLUSION, CAT_FOLLOWUP_T4, CAT_FINAL],
    //         values: getValues('30perimetry', [
    //                 {fieldName: "visualFieldIndex", text: "Visual Field Index (VFI)"},
    //                 {fieldName: "averageDefectDepth", text: "Mittlere Defekttiefe (MD)"},
    //                 {fieldName: "psd", text: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD)"},
    //                 {fieldName: "sLV", text: "sLV"},
    //                 {fieldName: "CsLV", text: "CsLV"},
    //                 {fieldName: "meanSensitivity", text: "Mean Sensitivity"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'Pachymetrie',
    //         device: getDevice('pachymtery'),
    //         appointmentNames: [CAT_INCLUSION],
    //         values: getValues('pachymtery', [
    //                 {fieldName: "pachymetry", text: "Pachymetrie"}
    //             ]
    //         )
    //     },
    //     {
    //         section: 'HRT',
    //         device: null,
    //         appointmentNames: [CAT_INCLUSION, CAT_FINAL],
    //         values: getValues('hrt', [
    //                 {fieldName: "discArea", text: "Disc Area"},
    //                 {fieldName: "rimArea", text: "Rim Area"},
    //                 {fieldName: "rimVolume", text: "Rim Volume"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'Gonioskopie',
    //         device: null,
    //         appointmentNames: [CAT_INCLUSION],
    //         values: getValues('gonioscopy', [
    //                 {fieldName: "shaffer", text: "Shaffer 0,I-IV"}
    //             ]
    //         )
    //     },
    //     {
    //         section: 'RFNL-OCT 3,5 mm',
    //         device: getDevice('sdOct'),
    //         appointmentNames: [CAT_INCLUSION, CAT_FINAL],
    //         values: getValues("sdOct", [
    //                 {fieldName: "rfnlOct35Global", text: "Global"},
    //                 {fieldName: "rfnlOct35Superior", text: "Superior"},
    //                 {fieldName: "rfnlOct35Inferior", text: "Inferior"},
    //                 {fieldName: "rfnlOct35NasalSuperior", text: "Nasal Superior"},
    //                 {fieldName: "rfnlOct35Nasal", text: "Nasal"},
    //                 {fieldName: "rfnlOct35NasalInferior", text: "Nasal Inferior"},
    //                 {fieldName: "rfnlOct35TemporalInferior", text: "Temporal Inferior"},
    //                 {fieldName: "rfnlOct35Temporal", text: "Temporal"},
    //                 {fieldName: "rfnlOct35TemporalSuperior", text: "Temporal Superior"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'RFNL-OCT 4,1 mm',
    //         device: getDevice('sdOct'),
    //         appointmentNames: [CAT_INCLUSION, CAT_FINAL],
    //         values: getValues("sdOct", [
    //                 {fieldName: "rfnlOct41Global", text: "Global"},
    //                 {fieldName: "rfnlOct41Superior", text: "Superior"},
    //                 {fieldName: "rfnlOct41Inferior", text: "Inferior"},
    //                 {fieldName: "rfnlOct41NasalSuperior", text: "Nasal Superior"},
    //                 {fieldName: "rfnlOct41Nasal", text: "Nasal"},
    //                 {fieldName: "rfnlOct41NasalInferior", text: "Nasal Inferior"},
    //                 {fieldName: "rfnlOct41TemporalInferior", text: "Temporal Inferior"},
    //                 {fieldName: "rfnlOct41Temporal", text: "Temporal"},
    //                 {fieldName: "rfnlOct41TemporalSuperior", text: "Temporal Superior"},
    //             ]
    //         )
    //     },
    //     {
    //         section: 'RFNL-OCT 4,7 mm',
    //         device: getDevice('sdOct'),
    //         appointmentNames: [CAT_INCLUSION, CAT_FINAL],
    //         values: getValues("sdOct", [
    //                 {fieldName: "rfnlOct47Global", text: "Global"},
    //                 {fieldName: "rfnlOct47Superior", text: "Superior"},
    //                 {fieldName: "rfnlOct47Inferior", text: "Inferior"},
    //                 {fieldName: "rfnlOct47NasalSuperior", text: "Nasal Superior"},
    //                 {fieldName: "rfnlOct47Nasal", text: "Nasal"},
    //                 {fieldName: "rfnlOct47NasalInferior", text: "Nasal Inferior"},
    //                 {fieldName: "rfnlOct47TemporalInferior", text: "Temporal Inferior"},
    //                 {fieldName: "rfnlOct47Temporal", text: "Temporal"},
    //                 {fieldName: "rfnlOct47TemporalSuperior", text: "Temporal Superior"},
    //             ]
    //         )
    //     },
    // ];
    //
    // function getDevice (correlationType) {
    //     const correlations = extractCorrelation(correlationType);
    //     let values = [];
    //
    //     for (let i = 0; i < correlations.length; ++i) {
    //         values.push(
    //             {
    //                 appointmentName: correlations[i].appointmentName,
    //                 deviceName: get(correlations[i].correlationSamples, "metadata.deviceName","")
    //             }
    //         );
    //     }
    //
    //     return values;
    // }
    //
    // // console.log(getDevice('sdOct'));
    //
    // function extractCorrelation (correlationType) {
    //     let quantities = [];
    //
    //     for (let i = 0; i < allAppointmentsCorrelationSamples.length; ++i) {
    //         for (let j = 0; j < allAppointmentsCorrelationSamples[i].correlationSamples.length; ++j) {
    //             if (allAppointmentsCorrelationSamples[i].correlationSamples[j].correlationType === correlationType) {
    //                 quantities.push({appointmentName: allAppointmentsCorrelationSamples[i].appointmentName,
    //                     correlationSamples: allAppointmentsCorrelationSamples[i].correlationSamples[j]});
    //             }
    //         }
    //     }
    //     return quantities;
    // }
    //
    // function getIndex(fields, fieldName) {
    //     for (let i = 0; i < fields.length; ++i) {
    //         if (fields[i].fieldName === fieldName) {
    //             return i;
    //         }
    //     }
    //     return -1;
    // }
    //
    // function getValues(correlationType, fields) {
    //     const correlations = extractCorrelation(correlationType)
    //     let values = [];
    //
    //     for (let i = 0; i < fields.length; ++i) {
    //         values.push({...fields[i], quantity: []});
    //     }
    //     for (let i = 0; i < correlations.length; ++i) {
    //         let rightValue = null;
    //         let leftValue = null;
    //
    //         for (let j = 0; j < correlations[i].correlationSamples.quantitySamples.length; ++j) {
    //             if (!correlations[i].correlationSamples.quantitySamples[j].quantityType) continue;
    //
    //             const suffix = correlations[i].correlationSamples.quantitySamples[j].quantityType.endsWith("Right") ? rightSuffix : leftSuffix;
    //             const index = getIndex(fields, correlations[i].correlationSamples.quantitySamples[j].quantityType.slice(0, -suffix.length));
    //
    //             const quantity = correlations[i].correlationSamples.quantitySamples[j].quantity;
    //
    //             if (suffix === rightSuffix) {
    //                 rightValue = correlationType === "gonioscopy" ? intToRoman(parseInt(quantity)) : quantity;
    //             } else {
    //                 leftValue = correlationType === "gonioscopy" ? intToRoman(parseInt(quantity)) : quantity;
    //             }
    //
    //             if (index !== -1 && rightValue !== null && leftValue !== null) {
    //                 values[index].quantity.push(
    //                     {
    //                         appointmentName: correlations[i].appointmentName,
    //                         rightValue: rightValue,
    //                         leftValue: leftValue
    //                     }
    //
    //                 );
    //                 rightValue = null;
    //                 leftValue = null;
    //             }
    //         }
    //     }
    //     return values;
    // }

    return (
        <>
            {/*{getCurrentTab() === CAT_FIRST &&*/}
            {/*<ReaderInitialSave dataModel={dataModel}*/}
            {/*                   patientId={patientId}*/}
            {/*                   isControlCohort={isControlCohort}*/}
            {/*                   refresh={refresh}*/}
            {/*                   patient={patient}*/}
            {/*                   allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}/>*/}
            {/*}*/}
            {[CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL].includes(getCurrentTab()) &&
            <RenderReaderZoneFull dataModel={dataModel}
                                  patientId={patientId}
                                  refresh={refresh}
                                  patient={patient}
                                  readerCComment={readerCComment}
                                  allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}/>
            }

            {/*{readerSections.map( it => {*/}
            {/*    return (*/}
            {/*        <>*/}
            {/*            {getCurrentTab() !== CAT_FIRST && getCurrentTab() !== CAT_FOLLOWUP_T2 && getCurrentTab() !== CAT_FOLLOWUP_T3 && getCurrentTab() !== CAT_FOLLOWUP_T4 && <RenderReaderZoneTable tableData={it} />}*/}
            {/*            {it.appointmentNames.includes(getCurrentTab()) &&*/}
            {/*                <>*/}
            {/*                    <br /><br />*/}
            {/*                    {getCurrentTab() !== CAT_FIRST && getCurrentTab() !== CAT_FOLLOWUP_T2 && getCurrentTab() !== CAT_FOLLOWUP_T3 && getCurrentTab() !== CAT_FOLLOWUP_T4 && <RenderReaderZoneTableStatus patientId={patientId} section={it.section} isControlCohort={isControlCohort}/>}*/}
            {/*                    <br />*/}
            {/*                </>*/}
            {/*            }*/}
            {/*        </>*/}
            {/*    )*/}
            {/*})}*/}

            {/*{(getCurrentTab() === CAT_INCLUSION || getCurrentTab() === CAT_FINAL) &&*/}
            {/*<>*/}
            {/*    <Papillenphoto />*/}
            {/*</>*/}
            {/*}*/}

            {/*{(getCurrentTab() !== CAT_TENSIO &&  getCurrentTab() !== CAT_INTERVENTION && getCurrentTab() !== CAT_CONTROL && getCurrentTab() !== CAT_FOLLOWUP_T2 && getCurrentTab() !== CAT_FOLLOWUP_T3 && getCurrentTab() !== CAT_FIRST && getCurrentTab() !== CAT_FOLLOWUP_T4) &&*/}
            {/*    <>*/}
            {/*        <Divider />*/}

            {/*        <div className={"Grid -right"}>*/}
            {/*            <Button basic type="submit" size="tiny" color="teal" content={t("save")}/>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*}*/}
        </>
    );
};

export default RenderReaderZone;
