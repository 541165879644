import {Button, Dimmer, Divider, Form, Header, Loader, Modal, Select} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {createOptions} from "../../data/models/utils";
import SelectInputForm from "../inputs/SelectInputForm";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {AuthContext, stringToBoolean, toastError, toastSuccess} from "../../services";
import {get} from "lodash";

export const MAX_FILE_SIZE = 100;

const NewAttachment = ({isReadOnly, patientId, appointment, refresh}) => {
    const {t} = useTranslation();
    const form = useForm({mode: "all"});
    const [open, setOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const authContext = useContext(AuthContext);

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        if(open === true){
            form.trigger();
        } else {
            handleCancel();
        }
    }, [open]);
    /* eslint-enable */

    const close = () => {
        setOpen(false);
    };

    const eyeLocationOption = [
        {key: "not_specified", text: t("NAN"), value: "NAN"},
        {key: "left_eye", text: t("LEFT_EYE"), value: "LEFT_EYE"},
        {key: "right_eye", text: t("RIGHT_EYE"), value: "RIGHT_EYE"},
        {key: "both_eyes", text: t("BOTH_EYES"), value: "BOTH_EYES"},
    ];

    const examinations = createOptions([
        "24h RR",
        "30°-Perimetrie",
        "Funduskamera",
        "Gonioskopie",
        "HRT",
        "Pentacam",
        "RNFL-OCT",
    ], {
        __placeholder: t("examination_type"),
        __optional: false,
    });

    const devices24hRR = createOptions([
        "Custoscreen 200/300",
        "Physio-Port",
        "Spacelabs"
    ], {
        __placeholder: t("device_name"),
        __optional: false,
    });

    const devicesFundus = createOptions([
        "Funduskamera",
    ], {__placeholder: t("device_name"), __optional: false,});

    const devicesGonioscopy = createOptions([
        "Gonioskopiegerät",
    ], {__placeholder: t("device_name"), __optional: false,});

    const devicesHRT = createOptions([
        "HRT-Gerät",
    ], {__placeholder: t("device_name"), __optional: false,});

    const devicesPentacam = createOptions([
        "Pentacam",
    ], {__placeholder: t("device_name"), __optional: false,});

    const deviceSDOCT = createOptions([
        "Heidelberg Spectralis",
        "Optopol Copernicus",
        "Zeiss Cirrus",
    ], {__placeholder: t("device_name"), __optional: false,});

    const devicePerimetrie = createOptions([
        "DiconPerimeter",
        "Humphrey Field Analyzer bis 1994",
        "Humphrey Field Analyzer ab 1995",
        "Kowa AP-5000C",
        "Octopus 1-2-3",
        "Octopus 101",
        "Octopus 301",
        "Octopus 500 EZ, 2000R",
        "Octopus 900",
        "Perimat 206",
        "Peristat 433",
        "TAP cc",
        "TAP 2000",
        "TAP 2000ct",
        "TEC cc",
        "Twinfield"
    ], {__placeholder: t("device_name"), __optional: false,});

    const [uploadFile, setUploadFile] = useState(new FormData());

    const [deviceOptions, setDevicesOptions] = useState(devices24hRR);
    const [comment, setComment] = useState("");

    const [errorMessage, setErrorMessage] = useState(null);

    const handleExaminationChange = (e) => {
        switch (e) {
            case  "24h RR" : {
                setDevicesOptions(devices24hRR);
                break;
            }
            case "30°-Perimetrie" : {
                setDevicesOptions(devicePerimetrie);
                break;
            }
            case "Funduskamera" : {
                setDevicesOptions(devicesFundus);
                break;
            }
            case "Gonioskopie" : {
                setDevicesOptions(devicesGonioscopy);
                break;
            }
            case "HRT" : {
                setDevicesOptions(devicesHRT);
                break;
            }
            case "Pentacam" : {
                setDevicesOptions(devicesPentacam);
                break;
            }
            case "RNFL-OCT" : {
                setDevicesOptions(deviceSDOCT);
                break;
            }
            default: {
                setDevicesOptions(createOptions(["Sonstiges"], {__placeholder: t("Sonstiges"), __optional: false,}));
                break;
            }

        }
    };

    const handleFileChange = (event) => {
        if(event.target.files.length > 0) {
            if(get(event, "target.files[0].size")/1024/1024 >= MAX_FILE_SIZE){
                toastError("Attachment upload error: File is bigger than " + MAX_FILE_SIZE);
                form.setValue("file_input", "",  { shouldValidate: true });
            } else {
                const formData = new FormData();
                formData.set('file', event.target.files[0], get(event, "target.files[0].name"));
                setUploadFile(formData);
                console.log("FormData:", uploadFile);
            }
        } else {
            // No file was selected
        }
    };

    const handleCancel = () => {
        setErrorMessage(null);
        setUploadFile(new FormData());
        close();
    };

    const isUserDefinedExam = (data) => {
        for (let i = 0; i < examinations.__options.length; i++){
            if (data.examination_type === examinations.__options[i]["key"]){
                return false;
            }
        }
        return true;
    }

    const isUserDefinedDevice = (data) => {
        const allDevices = [...devices24hRR.__options, ...devicePerimetrie.__options, ...devicesFundus.__options, ...devicesGonioscopy.__options, ...devicesHRT.__options, ...devicesPentacam.__options, ...deviceSDOCT.__options];

        for (let i = 0; i < allDevices.length; i++){
            if (data.device === allDevices[i]["key"] || data.device === "Sonstiges"){
                return false;
            }
        }
        return true;
    }

    const hasError = (data) => {
        if (!isUserDefinedExam(data) && !isUserDefinedDevice(data)) {
            let correctDeviceSelection = false;
            for (let i = 0; i < deviceOptions.__options.length; i++){
                if (data.device === deviceOptions.__options[i]["key"]){
                    correctDeviceSelection = true;
                    break;
                }
            }
            return !form.formState.isValid || !correctDeviceSelection;
        }

        return !form.formState.isValid;
    };

    const handleSave = async (data) => {
        if (!hasError(data)) {
            console.log("Save attachment....", patientId, appointment);

            setUploading(true);

            uploadFile.set('patient-id', patientId);
            uploadFile.set('appointment-id', appointment.id);
            uploadFile.set('device', data.device);
            uploadFile.set('eye', data.eye);
            uploadFile.set('examination-type', data.examination_type);

            if (comment)
                uploadFile.set('comment', comment);

            axios.post(
                `${process.env.REACT_APP_SERVER_URI}/api/attachment`,
                uploadFile,
                {headers: {Authorization: "Bearer " + authContext.keycloak.token}})
                .then(function(response) {
                    // handle success
                    toastSuccess(t("attachment_upload_success"));
                    refresh();
                    close();
                })
                .catch(function(error) {
                    toastError(t("attachment_upload_error"), error);
                })
                .finally(() => setUploading(false));
        } else {
            toastError(t("error_fields_incorrect"));
        }
    };

    const onError = () => {
        toastError(t("error_fields_incorrect"));
    };

    const handleOpenModal = () => {
        if (stringToBoolean(process.env.REACT_APP_FILE_UPLOAD_ACTIVATED)) {
                setOpen(true);
        }
        else{
            toastError(t("error_not_implemented"));
        }
    };

    //todo: implement save
    return (
        <div>
            <Modal open={open} trigger={<Button type="button" basic size="tiny" color="teal" content={t("new_attachment")}
                                                onClick={handleOpenModal} disabled={isReadOnly}/>}>
                <Dimmer active={uploading}>
                    <Loader />
                </Dimmer>
                <Header>{t("new_attachment")}</Header>
                <Modal.Content>
                    <Form id="new-attachment-form" onSubmit={form.handleSubmit(handleSave, onError)}>
                        <div className={"label-small"}>{t("hint_nopatientrelateddatainfiles")}</div>
                        <Form.Field error={!!form.errors.file_input}>
                            <input type="file" id="file-input" name="file_input"
                                   onChange={handleFileChange} ref={form.register({required: true})}/>
                        </Form.Field>
                        <Divider/>
                        <div className={"pb-1 width-1-2"}>
                            <div className={"Grid"}>
                                <div className={"note"}>{t("examination_type")}</div>
                            </div>
                            <SelectInputForm
                                register={form.register}
                                unregister={form.unregister}
                                name={"examination_type"}
                                defaultValue={""}
                                model={examinations}
                                onChange={handleExaminationChange}
                                error={!!form.errors.examination_type}
                                control={form.control}
                            />
                        </div>
                        <div className={"pb-1 width-1-2"}>
                            <div className={"Grid"}>
                                <div className={"note"}>{t("device")}</div>
                            </div>
                            <SelectInputForm
                                register={form.register}
                                unregister={form.unregister}
                                defaultValue={""}
                                name={"device"}
                                onChange={() => {}}
                                model={deviceOptions}
                                error={!!form.errors.device}
                                control={form.control}
                            />
                        </div>

                        <div>
                            <div className={"note form-input-label"}>
                                <label>{t("eye")}</label>
                            </div>
                            <Controller
                                control={form.control}
                                name={"eye"}
                                rules={{ required: true }}
                                render={({ onChange}) => (
                                    <Select
                                        name="eye"
                                        control={form.control}
                                        options={eyeLocationOption}
                                        width={4}
                                        error={!!form.errors.eye}
                                        defaultValue={""}
                                        onChange={(_, {value}) => {
                                            onChange(value)
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <br/>
                        <div className={"label"}>{t("comment")}</div>
                        <div className={"label-small"}>{t("hint_nopatientrelateddata")}</div>
                        <Form.TextArea ref={form.register} name={"comment"}
                                       onChange={(event) => setComment(event.target.value)}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <div style={{
                        display: "inline-block",
                        float: "left",
                        color: "red",
                        fontWeight: "bold"
                    }}>{errorMessage}</div>
                    <Button type="button" negative onClick={handleCancel}>{t("cancel")}</Button>
                    <Button
                        type="submit"
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content={t("save")}
                        form="new-attachment-form"
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default NewAttachment;
