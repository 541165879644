import gql from "graphql-tag";
import {addressFragment} from './addressFragment';
import {organisationContactFragment} from './organisationContactFragment';

export const organisationFragment = gql`
    fragment organisationFragment on Organisation {
        id
        organisationId
        name
        bsnr
        type
        address {
            ...addressFragment
        }
        contact {
            ...organisationContactFragment
        }
    }
    ${addressFragment}
    ${organisationContactFragment}
`;
