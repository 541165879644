import * as _ from "lodash";
import {
    CAT_FIRST, CAT_INCLUSION, CAT_CONTROL, CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4,
    CAT_FINAL, APPT_INQUIRY, APPT_READING_FINISHED, APPT_READING, APPT_DISCREPANCY
} from "../../../data/mutations/appointments";
import {intToRoman} from "../../../data/models/utils";
import {Divider, TextArea} from "semantic-ui-react";
import React from "react";

const RIGHT_SUFFIX = "Right";
const LEFT_SUFFIX = "Left";

export const regExs = {
    "REGEX_FRACTION" : new RegExp("^$|\\d+\\/\\d+$"),
    "REGEX_DECIMAL_0_100": new RegExp("^$|^(?![+-])(100(?:\\.00)?|0(?:\\.\\d\\d)?|\\d?\\d(?:\\.\\d\\d)?)$"),
    "REGEX_DECIMAL_WITH_SIGN": new RegExp("^[-+]?(\\d{1,2})(\\.\\d{1,2})?$"),
    "REGEX_INTEGER" : new RegExp("^\\d+$"),
    "REGEX_DURATION_HOUR_MINUTE_30" : new RegExp("^([0-9][0-9]):[03][0]$"),
    "REGEX_DECIMAL": new RegExp("^-?(?![.])(?=.+)(?:[1-9]\\d?|0)?(?:\\.\\d{1,2})?$"),
};

export const readerAAndBFieldsToBeChecked = () => {
    return [
        "HinweisAufGlaukomschaden", // Hinweis auf Glaukomschaden
        "WiederholungErbeten", // Wiederholung erbeten
        "KorrekturfaktorBeiIODMessung", // Korrekturfaktor bei IOD-Messung
        "papillenphotoPapillenrandblutung", // Papillenrandblutung
        "NichtGlaukomatoeseOptikusatrophie", // Nicht-glaukomatöse Optikusatrophie
        "VergroesserteAtrophiezone", // Vergrößerte Atrophiezone
        "KerbeImRandsaum", // Right and Left - Kerbe im Randsaum
        "VorliegenEinesCilioretinalenGefaesses", // Vorliegen eines cilioretinalen Gefäßes
        "AnzahlDerCilioretinalenGefaesse", // Anzahl der cilioretinalen Gefäße
        "LageDerCilioretinalenGefaesse", // Lage der cilioretinalen Gefäße
        "HinweisAufProgression" // Hinweis auf Progression
    ]
}

const appointmentWithDocs = [ CAT_INCLUSION, CAT_CONTROL, CAT_FOLLOWUP_T4, CAT_FINAL ]

const docLengthMap = {
    "INCLUSION": 2,
    "CONTROL": 0,
    "FOLLOWUP_T4": 1,
    "FINAL": 2
}

export const checkDocLength = (appointment) => {
    return appointment?.category && appointmentWithDocs.includes(appointment?.category) &&
        appointment?.documentSamples?.length < docLengthMap[appointment?.category]
}

export function checkReadingStatus(patient, isControlCohort, dataModel = null) {
    const categorySamples = patient?.appointments.find(x => _.get(x, "category") === getCurrentTab(isControlCohort))?.categorySamples ?? null;
    const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null

    if (readingStatus && readingStatus.value === APPT_READING) {
        return !!(dataModel && !checkDocLength(dataModel))
    }
    return !!(readingStatus && readingStatus.value !== "" && readingStatus.value !== APPT_INQUIRY);
}

export const getCurrentTab = (isControlCohort = false) => {
    const tabs = ["MEDICAL_HISTORY", CAT_FIRST, CAT_INCLUSION, isControlCohort ? CAT_CONTROL : CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];
    const currentTabIndex = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
    return tabs[currentTabIndex];
};

export const createCategorySample = (categoryType, userId, role, patientId, value, id) => {
    return {
        categoryType: `${categoryType}_${userId}_${role}`,
        endDate: new Date().toISOString(),
        id: id,
        metadata: {
            changedBy: null,
            changedDate: null,
            comment: null,
            createdBy: null,
            creationDate: new Date().toISOString(),
            deviceName: null,
            deviceVendorName: null,
            examinationType: null,
            eyeLocation: null,
            __typename: "MetaData",
        },
        sampleType: "category",
        startDate: new Date().toISOString(),
        subject: patientId,
        value: value,
        __typename: "CategorySample",
    };
};

export const copyDataToModel = (model, categorySamples) => {
    if (categorySamples === null) return model;
    for (let i = 0; i < model.length; ++i) {
        if (model[i].sectionField.tableStatusField) {
            for (let j = 0; j < model[i].sectionField.tableStatusField.length; ++j) {
                const fieldName = (model?.[i].sectionField?.tableStatusField?.[j].dataStorageField.categoryType?.split("_"))?.filter((_, index) => index !== 1)
                const dataFromDB = categorySamples.find(x => _.isEqual(_.get(x, "categoryType")?.split("_")?.filter((_, index) => index !== 1), fieldName))

                // const dataFromDB = categorySamples.find(x => _.get(x, "categoryType") === model[i].sectionField.tableStatusField[j].dataStorageField.categoryType)
                model[i].sectionField.tableStatusField[j].dataStorageField = dataFromDB ? dataFromDB : model[i].sectionField.tableStatusField[j].dataStorageField
            }
        }

        for (let j = 0; j < model[i].statusField.length; ++j) {
            const fieldName = (model?.[i].statusField?.[j].dataStorageField?.categoryType?.split("_"))?.filter((_, index) => index !== 1)
            const dataFromDB = categorySamples.find(x => _.isEqual(_.get(x, "categoryType")?.split("_")?.filter((_, index) => index !== 1), fieldName))

            // const dataFromDB = categorySamples.find(x => _.get(x, "categoryType") === model[i].statusField[j].dataStorageField.categoryType)
            model[i].statusField[j].dataStorageField = dataFromDB ? dataFromDB : model[i].statusField[j].dataStorageField

        }
    }
    return model;
}

const getIndex = (fields, fieldName) => {
    for (let i = 0; i < fields.length; ++i) {
        if (fields[i].fieldName === fieldName) {
            return i;
        }
    }
    return -1;
}

const extractCorrelation = (allAppointmentsCorrelationSamples, correlationType) => {
    const quantities = [];

    for (let i = 0; i < allAppointmentsCorrelationSamples.length; ++i) {
        for (let j = 0; j < allAppointmentsCorrelationSamples[i].correlationSamples.length; ++j) {
            if (allAppointmentsCorrelationSamples[i].correlationSamples[j].correlationType === correlationType) {
                quantities.push({appointmentName: allAppointmentsCorrelationSamples[i].appointmentName,
                    correlationSamples: allAppointmentsCorrelationSamples[i].correlationSamples[j]});
            }
        }
    }
    return quantities;
}

export const getDevice = (allAppointmentsCorrelationSamples, correlationType) => {
    const correlations = extractCorrelation(allAppointmentsCorrelationSamples, correlationType);
    const values = [];

    for (let i = 0; i < correlations.length; ++i) {
        values.push(
            {
                appointmentName: correlations[i].appointmentName,
                deviceName: _.get(correlations[i].correlationSamples, "metadata.deviceName","")
            }
        );
    }

    return values;
}

const getMeterVisusValue = (quantity, categorySamples, suffix) => {
    const meterVisus = categorySamples?.find(_x => _x?.categoryType === `meterVisus${suffix}`)?.value
    if (meterVisus) {
        return meterVisus
    } else {
        const decimalVisusOption = categorySamples?.find(_x => _x?.categoryType === `decimalVisusOption${suffix}`)?.value
        if (decimalVisusOption) {
            return decimalVisusOption
        }
    }
    return quantity
}


export const getValues = (allAppointmentsCorrelationSamples, correlationType, fields) => {
    const correlations = extractCorrelation(allAppointmentsCorrelationSamples, correlationType)
    let values = [];

    for (let i = 0; i < fields.length; ++i) {
        values.push({...fields[i], quantity: []});
    }
    for (let i = 0; i < correlations.length; ++i) {
        let rightValue = null;
        let leftValue = null;
        let whichDezimalVisus = 1

        for (let j = 0; j < correlations[i].correlationSamples.quantitySamples.length; ++j) {
            if (!correlations[i].correlationSamples.quantitySamples[j].quantityType) continue;

            const suffix = correlations[i].correlationSamples.quantitySamples[j].quantityType.endsWith("Right") ? RIGHT_SUFFIX : LEFT_SUFFIX;
            const index = getIndex(fields, correlations[i].correlationSamples.quantitySamples[j].quantityType.slice(0, -suffix.length));

            let quantity = correlations[i].correlationSamples.quantitySamples[j].quantity;

            // if (suffix === RIGHT_SUFFIX) {
            if (correlationType === "dezimalvisus" && (!quantity || quantity === -50000)) {
                whichDezimalVisus = 2
                quantity = getMeterVisusValue(quantity, correlations[i].correlationSamples?.categorySamples, suffix)
            }
            // }

            if (suffix === RIGHT_SUFFIX) {
                rightValue = correlationType === "gonioscopy" ? (quantity === -50000 ? quantity : intToRoman(parseInt(quantity))) : quantity;
            } else {
                leftValue = correlationType === "gonioscopy" ? (quantity === -50000 ? quantity : intToRoman(parseInt(quantity))) : quantity;
            }

            if (index !== -1 && rightValue !== null && leftValue !== null) {
                values[index].quantity.push(
                    {
                        appointmentName: correlations[i].appointmentName,
                        rightValue: values[index].type === "float" ?
                            (correlationType === "dezimalvisus" && whichDezimalVisus === 2) ? rightValue :
                                parseFloat(rightValue).toFixed(2) : values[index].type === "string" ?
                                rightValue : parseInt(rightValue),
                        leftValue: values[index].type === "float" ?
                            (correlationType === "dezimalvisus" && whichDezimalVisus === 2) ? leftValue :
                                parseFloat(leftValue).toFixed(2) : values[index].type === "string" ?
                                leftValue : parseInt(leftValue),
                        unit: correlations[i].correlationSamples.quantitySamples[j].unit
                    }
                );
            }

            if (suffix === LEFT_SUFFIX) {
                rightValue = null;
                leftValue = null;
            }
        }
    }
    return values;
}

export const checkRequiredFields = (data) => {
    let allFieldsFilled = true
    data.forEach((x) => {
        if (!allFieldsFilled) {
            return
        }
        if (allFieldsFilled && x.quantitySamples && x.quantitySamples.length) {
            if (allFieldsFilled)
            {
                x.quantitySamples.forEach((q_x) => {
                    if (!allFieldsFilled) {
                        return
                    }
                    if (q_x.hasOwnProperty("id") && q_x.hasOwnProperty("__optional") && !q_x.__optional && (!q_x?.quantity?.toString() || q_x.quantity === -50000)) {
                        allFieldsFilled = false
                    }
                })
            }
            if (allFieldsFilled) {
                x.categorySamples.forEach((q_x) => {
                    if (!allFieldsFilled) {
                        return
                    }
                    if (q_x.hasOwnProperty("id") && q_x.hasOwnProperty("__optional") && !q_x.__optional && !q_x?.value?.toString()) {
                        allFieldsFilled = false
                    }
                })
            }
        }
    })
    return allFieldsFilled
}

export const getPreviousAppointmentModel = (patient, category) => {
    switch (category) {
        case CAT_FOLLOWUP_T2:
            return _.find(patient?.appointments, (it) => it.category === CAT_INCLUSION) || null
        case CAT_FOLLOWUP_T3:
            return _.find(patient?.appointments, (it) => it.category === CAT_FOLLOWUP_T2) || null
        case CAT_FOLLOWUP_T4:
            return _.find(patient?.appointments, (it) => it.category === CAT_FOLLOWUP_T3) || null
        default:
            return null;
    }
}

export const idReaderTensioZoneDisabled = (patient) => {
    const readerFillStatus = {CAT_FIRST: false, CAT_INCLUSION: false, CAT_FOLLOWUP_T2: false, CAT_FOLLOWUP_T3: false, CAT_FOLLOWUP_T4: false, CAT_FINAL: false}

    if (patient.appointments) {
        for (let i = 0; i < patient.appointments.length; ++i) {
            if (patient.appointments[i].categorySamples.length > 0 && patient.appointments[i].category !== CAT_TENSIO && patient.appointments[i].category !== CAT_CONTROL) {
                readerFillStatus[patient.appointments[i].category] = true
            }
        }
    }

    for (const property in readerFillStatus) {
        if (!readerFillStatus[property]) {
            return true
        }
    }
    return true
}

export const isFieldDisabled = (role, readingStatus, readerAOrBReqField = false) => {
    if (readingStatus) {
        if (role === "data_entry_manager" && (readingStatus.value === APPT_READING_FINISHED ||
            readingStatus.value === APPT_READING || readingStatus.value === APPT_DISCREPANCY)) {
            return true
        } else if ((role === "reader_a" || role === "reader_b") && (readingStatus.value === APPT_READING_FINISHED ||
            readingStatus.value === APPT_DISCREPANCY || readingStatus.value === APPT_INQUIRY || readingStatus.value === "")) {
            return true
        } else if (role === "reader_c" && (readingStatus.value === APPT_READING_FINISHED ||
            readingStatus.value === APPT_READING || readingStatus.value === APPT_INQUIRY || readingStatus.value === "")) {
            return true
        } else if ((role === "reader_a" || role === "reader_b") && readingStatus.value === APPT_READING && readerAOrBReqField) {
            return true
        }
        return false
    }
    if (role === "data_entry_manager") {
        if (!readingStatus) {
            return false
        }
    }
    return true
}

export function isSectionDisabled(user, dataEntryManager, statusField) {
    return (user && dataEntryManager && (user?.roles?.includes("reader_a") || user?.roles?.includes("reader_b") || user?.roles?.includes("reader_c")) && statusField && statusField?.length > 1) ?
        getNotAvailableFieldValue(dataEntryManager, statusField?.[statusField.length - 2]?.dataStorageField) === "nichtVorhanden" : false
}

export function getStatusStatesRequiredFields(model, isReaderC, user = null, dataEntryManager = null) {
    const requiredFields = []
    for (let i = 0; i < model.length; ++i) {
        const isSectionDis =  isSectionDisabled(user, dataEntryManager, model?.[i]?.statusField) || false
        for (let j = 0; j < model[i]?.statusField?.length; ++j) {

            if (model[i]?.sectionField?.section?.trim() !== "ReaderC") {
                requiredFields.push({
                    categoryType: model[i]?.statusField[j]?.dataStorageField.categoryType,
                    optional: model[i]?.statusField[j]?.displayField.__optional || isSectionDis
                })
            }
            else if (model[i]?.sectionField?.section?.trim() === "ReaderC" && isReaderC) {
                requiredFields.push({
                    categoryType: model[i]?.statusField[j]?.dataStorageField.categoryType,
                    optional: model[i]?.statusField[j]?.displayField.__optional || isSectionDis
                })
            }
        }
    }
    return requiredFields
}

export function getReaderStatusStates(model, isReaderC = false) {
    const status = []
    for (let i = 0; i < model.length; ++i) {
        for (let j = 0; j < model[i]?.statusField?.length; ++j) {
            if (model[i]?.sectionField?.section?.trim() !== "ReaderC") {
                status.push(model[i]?.statusField[j]?.dataStorageField)
            }
            else if (model[i]?.sectionField?.section?.trim() === "ReaderC" && isReaderC) {
                status.push(model[i]?.statusField[j]?.dataStorageField)
            }
        }
    }
    return status
}

export const getReaderCategorySamples = (catSamples, reader) => {
    let returnedSamples = []
    catSamples.forEach(x => {
        const value = _.get (x, "categoryType")
        if (value.includes(reader) && (value.includes("Status") || value.includes("HinweisAufGlaukomschaden") ||
            value.includes("WiederholungErbeten") || value.includes("KorrekturfaktorBeiIODMessung") ||
            value.includes("papillenphotoPapillenrandblutung") || value.includes("NichtGlaukomatoeseOptikusatrophie") ||
            value.includes("VergroesserteAtrophiezone") || value.includes("papillenphotoKerbeImRandsaumRight") ||
            value.includes("papillenphotoKerbeImRandsaumLeft") || value.includes("VorliegenEinesCilioretinalenGefaesses") ||
            value.includes("AnzahlDerCilioretinalenGefaesse") || value.includes("LageDerCilioretinalenGefaesse") ||
            value.includes("HinweisAufProgression")))
        {
            if (returnedSamples.findIndex(_val => _val?.categoryType === value) < 0) {
                returnedSamples.push(x)
            }
        }
    })
    return returnedSamples
}

export const getReaderValue = (readerX, label) => {
    const _x = readerX.filter((x) => x?.categoryType.split("_")[0] === label)
    if (_x && _x.length) {
        return _x?.[0].value
    }
    return ""
}

export const getNotAvailableFieldValue = (dataEntryManager, field) => {
    const notAvailableFields = ["dezimalvisusStatus", "pachymteryStatus", "gonioscopyStatus", "hrtStatus",
        "papillenphotoStatus", "bmoMrwStatus", "rfnlOct35Status", "rfnlOct41Status", "rfnlOct47Status"]

    if (field?.categoryType?.includes(notAvailableFields[0])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[0])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[1])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[1])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[2])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[2])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[3])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[3])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[4])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[4])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[5])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[5])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[6])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[6])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[7])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[7])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.includes(notAvailableFields[8])) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[8])
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    return ""
}

export const getStatusFieldSection = (dataEntryManager, field) => {
    const notAvailableFields = [
        { starts: "dezimalvisus", value: "dezimalvisusStatus" },
        { starts: "pachymtery", value: "pachymteryStatus" },
        { starts: "gonioscopy", value: "gonioscopyStatus" },
        { starts: "hrt", value: "hrtStatus" },
        { starts: "papillenphoto", value: "papillenphotoStatus" },
        { starts: "bmoMrw", value: "bmoMrwStatus" },
        { starts: "rfnlOct35", value: "rfnlOct35Status" },
        { starts: "rfnlOct41", value: "rfnlOct41Status" },
        { starts: "rfnlOct47", value: "rfnlOct47Status" }
    ]

    if (field?.categoryType?.startsWith(notAvailableFields[0]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[0]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[1]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[1]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[2]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[2]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[3]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[3]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[4]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[4]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[5]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[5]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[6]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[6]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[7]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[7]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    else if (field?.categoryType?.startsWith(notAvailableFields[8]?.starts)) {
        let fieldVal = getReaderValue(dataEntryManager, notAvailableFields[8]?.value)
        if (fieldVal && fieldVal === "nichtVorhanden")
            return fieldVal
        else return ""
    }
    return ""
}

export const getReaderCComment = (model, currentTab) => {
    const categorySamples = model?.appointments.find(x => _.get(x, "category") === currentTab)?.categorySamples ?? null;
    const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null
    const readerCComment = (categorySamples && readingStatus && readingStatus.value === APPT_INQUIRY) ? categorySamples.find(x => {
        const categoryType = _.get(x, "categoryType")
        return categoryType.toString().startsWith("readerCComment") && categoryType.toString().endsWith("reader_c")
    }) : null

    return readerCComment ? readerCComment.value : null
}

export const renderReaderCComment = (label, value, key) => {
    return (
        <div key={`${key}_${label}`}>
            <Divider/>
            <label className={"pr-1"}><b>{label}</b></label>
            <TextArea
                key={key}
                value={value}
                rows={5}
                disabled={true}
            />
            <Divider/>
        </div>
    );
};

export const getStatusOptions = (role) => {
    return (
        [
            {key: "empty", value: "", text: ""},
            {key: "korrekt", value: "korrekt", text: "Korrekt / Plausibel"},
            {key: "nichtKorrekt", value: "nichtKorrekt", text: "Nicht korrekt / Nicht plausibel"},
            ...role !== "reader_a" && role !== "reader_b" ? [{key: "nichtVorhanden", value: "nichtVorhanden", text: "Nicht Vorhanden"}] : [],
        ]
    );
};

export const getReaderCStatusOptions = () => {
    return (
        [
            {key: "empty", value: "", text: ""},
            {key: "correction", value: "korrektur", text: "Korrektur"},
            {key: "without_correction", value: "ohneKorrektur", text: "Ohne Korrektur"}
        ]
    );
};

export const getOverallStatusOptions = () => {
    return (
        [
            {key: 'empty', value: 'empty', text: ''},
            {key: 'comlete', value: 'complete', text: 'Vollständig'},
            {key: 'inquiry', value: 'inquiry', text: 'Rückfrage'},
        ]
    );
};

export const getYesNoOptions = () => {
    return (
        [
            { key: "empty", value:"", text:"" },
            { key: "ja", value: "ja", text: "Ja" },
            { key: "nein", value: "nein", text: "Nein" },
        ]
    );
};

export const getCilioretinalOptions = () => {
    return (
        [
            { key: "empty", value: "empty", text:"" },
            { key: "temporal", value: "temporal", text: "Temporal" },
            { key: "nasal", value: "nasal", text: "Nasal" },
            { key: "temporalAndNasal", value: "temporalAndNasal", text: "Temporal und Nasal" },
        ]
    );
};

export const getCilioretinalPlusOptions = () => {
    return (
        [
            { key: "empty", value: "empty", text:"" },
            { key: "temporal", value: "temporal", text: "Temporal" },
            { key: "nasal", value: "nasal", text: "Nasal" },
            { key: "temporalAndNasal", value: "temporalAndNasal", text: "Temporal und Nasal" },
            { key: "nichtVorhanden", value: "nichtVorhanden", text: "Nicht Vorhanden" }
        ]
    );
};

export const getBuildQualityOptions = () => {
    return (
        [
            { key: "empty", value: "", text:"" },
            { key: "plus", value: "plus", text: "+" },
            { key: "minus", value: "minus", text: "-" },
            { key: "unlesbar", value: "unlesbar", text: "Unlesbar" },
        ]
    );
};

export const getGhtOptions = () => {
    return (
        [
            { key: "empty", value: "", text:"" },
            { key: "innerhalbnormalerGrenzen", value: "innerhalbnormalerGrenzen", text:"Innerhalb normaler Grenzen" },
            { key: "grenzfall", value: "grenzfall", text:"Grenzfall" },
            { key: "ausserhalbNormalerGrenzen", value: "ausserhalbNormalerGrenzen", text:"Außerhalb normaler Grenzen" },
            { key: "generelleReduktionderEmpfindlichkeit", value: "generelleReduktionderEmpfindlichkeit", text:"Generelle Reduktion der Empfindlichkeit" },
            { key: "annormalHoheEmpfindlichkeit", value: "annormalHoheEmpfindlichkeit", text:"Annormal hohe Empfindlichkeit" },
        ]
    );
};

export const getHRTTableOptions = () => {
    return (
        [
            { key: "empty", value: "", text:"" },
            { key: "normal", value: "normal", text:"Normal" },
            { key: "makropupille", value: "makropupille", text: "Makropupille" },
            { key: "mikropupille", value: "mikropupille", text: "Mikropupille" },
            { key: "nichtAuswertbar", value: "nichtAuswertbar", text: "Nicht auswertbar" },
        ]
    );
};

export const getTableOptions = () => {
    return (
        [
            { key: "empty", value: "", text:"" },
            { key: "normal", value: "normal", text:"Normal" },
            { key: "borderline", value: "borderline", text: "Borderline" },
            { key: "reduziert", value: "reduziert", text: "Reduziert" },
            { key: "nichtAuswertbar", value: "nichtAuswertbar", text: "Nicht auswertbar" },
        ]
    );
};

export const getNeueAusduennungDesRandsaumsOptions = () => {
    return (
      [
          { key: "empty", value: "", text: "" },
          { key: "ja", value: "ja", text: "Ja" },
          { key: "wahrscheinlich", value: "wahrscheinlich", text: "Wahrscheinlich" },
          { key: "fraglich", value: "fraglich", text: "Fraglich" },
          { key: "nein", value: "nein", text: "Nein" },
          { key: "fokal", value: "fokal", text: "Fokal" },
          { key: "diffus", value: "diffus", text: "Diffus" }
      ]
    );
};

export const getPapillenphotoProgressionOptions = () => {
    return (
        [
            { key: "empty", value: "", text: "" },
            { key: "ja", value: "ja", text: "Ja" },
            { key: "wahrscheinlich", value: "wahrscheinlich", text: "Wahrscheinlich" },
            { key: "fraglich", value: "fraglich", text: "Fraglich" },
            { key: "nein", value: "nein", text: "Nein" },
        ]
    );
}