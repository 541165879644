import gql from 'graphql-tag';

export const UNLOCK_DATABASE = gql`
    mutation UnlockDatabase($password: String! ) {
        unlockDatabase(password: $password) {
            success
            message
        }
    }
`;
