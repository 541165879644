import React, {useContext} from "react";
import {Button, Grid, Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {usePatient, useStudyData} from "../../hooks";
import {insurance_companies} from "../../data/models/utils";
import {get} from "lodash";
import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import AgreementDownload, {getPatientInfo, getQRCodeURI, getTodayDate} from "./AgreementDownload";
import {AuthContext} from "../../services";

const Finish = () => {
    const {user} = useContext(AuthContext);
    const isStudyLead = user.roles.includes("study_lead");
    const isClinician = user.roles.includes("clinician");

    const key = isStudyLead ? "patient_to_be_edited" : "new_patient";

    const id = isStudyLead ? JSON.parse(window.localStorage.getItem(key) || "{}").id : window.localStorage.getItem("new_patient_id") || "";

    const {t} = useTranslation();
    const study = useStudyData(id);
    const studyData = (study.data && study.data.patientByID && study.data.patientByID.studyData) || {};
    const patTemp = JSON.parse(window.localStorage.getItem(key) || "{}");
    const masterData = get(patTemp,"masterData");

    const patientInfo = getPatientInfo(user, isStudyLead);

    const showDownload = () => {
        if (isStudyLead) {
            return (patTemp.studyData && patTemp.studyData.cohort) === "INTERVENTION";
        } else {
            return (studyData && studyData.cohort) === "INTERVENTION";
        }
    }

    const patient = usePatient(id);
    const patData = (patient.data && patient.data.patientByID) || {};
    const other = [insurance_companies.AOK, insurance_companies.BARMER, insurance_companies.DAK, insurance_companies.IKK, insurance_companies.KKH, insurance_companies.KNAPPSCHAFT, insurance_companies.TK, insurance_companies.VIACTIV];

    const downloadButton = () => {
        if(showDownload()) {
            return (
                <div>
                    <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                    {masterData.insurance.company === insurance_companies.AOK &&
                        <Button basic color={"blue"}  onClick={() => modifyBarmerPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.BARMER &&
                        <Button basic color={"blue"}  onClick={() => modifyBarmerPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.DAK &&
                        <Button basic color={"blue"}  onClick={() => modifyBarmerPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.IKK &&
                        <Button basic color={"blue"}  onClick={() => modifyBarmerPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.KKH &&
                        <Button basic color={"blue"}  onClick={() => modifyTekSalusPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.KNAPPSCHAFT &&
                    <Button basic color={"blue"}  onClick={() => modifyKnappschaftPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.TK &&
                        <Button basic color={"blue"}  onClick={() => modifyTkPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {masterData.insurance.company === insurance_companies.VIACTIV &&
                        <Button basic color={"blue"}  onClick={() => modifyViactivPdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    {!(other.includes(masterData.insurance.company)) &&
                        <Button basic color={"blue"} onClick={() => modifyUserDefinedInsurancePdf(patientInfo, isClinician)}>{isStudyLead ? t("insurance_specific_template") : t("agreement_download")}</Button>
                    }
                    <br/>
                    <br/>
                    {masterData.insurance.company === insurance_companies.TK &&
                    <div>
                        <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                        <Button basic color={"blue"}  href={"pdftemplates/TK_520046_Versicherteninformation.pdf"} download={"TK_520046_Versicherteninformation.pdf"}>{t("insurance_specific_pdf")}</Button>
                    </div>
                    }
                    {masterData.insurance.company === insurance_companies.VIACTIV &&
                    <div>
                        <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                        <Button basic color={"blue"}  href={"pdftemplates/Patienteninformationen-VIACTIV.pdf"} download={"Patienteninformationen-VIACTIV.pdf"}>{t("insurance_specific_pdf")}</Button>
                    </div>
                    }
                    {masterData.insurance.company === insurance_companies.KNAPPSCHAFT &&
                    <div>
                        <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                        <Button basic color={"blue"}  href={"pdftemplates/Versicherteninformation_KNAPPSCHAFT_2022_02_15.pdf"} download={"Versicherteninformation_Knappschaft.pdf"}>{t("insurance_specific_pdf")}</Button>
                    </div>
                    }
                    {(masterData.insurance.company === insurance_companies.AOK || masterData.insurance.company === insurance_companies.BARMER
                        || masterData.insurance.company === insurance_companies.DAK || masterData.insurance.company === insurance_companies.IKK)&&
                    <div>
                        <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                        <Button basic color={"blue"}  href={"pdftemplates/barmerAOKDAKIKK_Patienteninformationen.pdf"} download={"barmerAOKDAKIKK_Patienteninformationen.pdf"}>{t("insurance_specific_pdf")}</Button>
                    </div>
                    }
                    {!(other.includes(masterData.insurance.company)) &&
                    <div>
                        <Icon name={"file pdf outline"} size={"big"} color={"red"}/>
                        <Button basic color={"blue"} href={"pdftemplates/SALUS_Patienteninformation_zur_besonderen_Versorgung_22112021.pdf"} download={"SALUS_Patienteninformation_zur_besonderen_Versorgung.pdf"}>{t("insurance_specific_pdf")}</Button>
                    </div>
                    }
                    <br/>
                    <br/>
                    {!isStudyLead && <p>{t("hint_download_BV")}</p>}
                </div>
            )
        }
    };


    return (
        <Grid style={{height: "20rem"}} divided='vertically'>
            <Grid.Row columns={"equal"}>
                <Grid.Column>
                    {/*Success*/}
                    <div className={"Grid"}>
                        <div>
                            {!isStudyLead && <Icon name={"thumbs up outline"} size={"big"} color={"green"}/>}
                        </div>
                        <div>
                            <div>
                                <div>
                                    {isStudyLead
                                        ? <label className={"font-medium"}>{t("study_lead_save_patient")}</label>
                                        : <label className={"font-medium"}>{t("patient_created")}</label>
                                    }
                                </div>
                                <br/>
                                <div><b>{t("study_id")}</b></div>
                                <div>{isStudyLead ? patTemp.patientId : patData.patientId}</div>
                            </div>
                            <br/>
                            <div>
                                <div><b>{t("cohort")}</b></div>
                                <div>{isStudyLead ? patTemp.studyData.cohort : studyData.cohort}</div>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column width={"isStudyLead" ? 11 : 8}>
                    {isStudyLead && <><AgreementDownload /> <br /></>}
                    {downloadButton()}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

async function modifyTekSalusPdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/20201009 TEK Salus.pdf" : "pdftemplates/20201009 TEK Salus.pdf";
    const tekSalusBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(tekSalusBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 42, y: 815, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 42, y: 788, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 42, y: 780, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 42, y: 770, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 42, y: 760, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 225, y: 778, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 42, y: 738, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 135, y: 738, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 42, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 135, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 225, y: 711, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 500, y: 670, width: 60, height: 60, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "20201009 TEK Salus.pdf", "application/pdf");
}

async function modifyBarmerPdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/AOKIKKDAKBarmer.pdf" : "pdftemplates/AOKIKKDAKBarmer.pdf";
    const barmerBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(barmerBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 45, y: 795, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 45, y: 770, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 45, y: 760, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 45, y: 750, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 45, y: 740, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 206, y: 770, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 45, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 128, y: 711, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 45, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 125, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 204, y: 688, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 500, y: 690, width: 57, height: 57, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "AOKIKKDAKBarmer.pdf", "application/pdf");
}

async function modifyTkPdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/TK_520046_TE-BeVo_Download-Print.pdf" : "pdftemplates/TK_520046_TE-BeVo_Download-Print.pdf";
    const tkBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(tkBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 30, y: 810, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 30, y: 783, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 30, y: 773, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 30, y: 763, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 30, y: 753, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 188, y: 783, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 30, y: 727, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 110, y: 727, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 30, y: 701, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 108, y: 701, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 188, y: 701, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 360, y: 660, width: 57, height: 57, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "TK_520046_TE-BeVo_Download-Print", "application/pdf");
}

async function modifyViactivPdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/TE-EWE-VIACTIV.pdf" : "pdftemplates/TE-EWE-VIACTIV.pdf";
    const viactivBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(viactivBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 45, y: 795, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 45, y: 768, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 45, y: 758, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 45, y: 748, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 45, y: 738, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 206, y: 768, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 45, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 128, y: 711, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 45, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 125, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 204, y: 688, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 310, y: 630, width: 60, height: 60, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "TE-EWE-VIACTIV.pdf", "application/pdf");
}

async function modifyKnappschaftPdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/TE_KNAPPSCHAFT.pdf" : "pdftemplates/TE_KNAPPSCHAFT.pdf";
    const knappschaftBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(knappschaftBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 45, y: 795, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 45, y: 768, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 45, y: 758, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 45, y: 748, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 45, y: 738, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 206, y: 753, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 45, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 128, y: 711, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 45, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 125, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 204, y: 688, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 310, y: 630, width: 60, height: 60, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "TE_KNAPPSCHAFT.pdf", "application/pdf");
}

async function modifyUserDefinedInsurancePdf(patientInfo, isClinician) {
    const pdfTemplatePath = isClinician ? "clinicianpdftemplates/SALUS_Erklärung_zur_Teilnahme_an_der_besonderen_Versorgung_22112021.pdf"
        : "pdftemplates/SALUS_Erklärung_zur_Teilnahme_an_der_besonderen_Versorgung_22112021.pdf";
    const userDefinedInsuranceBase64String = await fetch(pdfTemplatePath).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(userDefinedInsuranceBase64String);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(patientInfo.insuranceCompany, { x: 45, y: 795, size: 10, font: courierFont });

    firstPage.drawText(patientInfo.lastName, { x: 45, y: 768, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.foreName, { x: 45, y: 758, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.street + " " + patientInfo.streetNo, { x: 45, y: 748, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.zipCode + " " + patientInfo.place, { x: 45, y: 738, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.birthDate, { x: 206, y: 768, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.insuranceCompanyNo, { x: 45, y: 711, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.insuranceNo, { x: 128, y: 711, size: 10, font: courierFont, });

    firstPage.drawText(patientInfo.practiceid, { x: 45, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(patientInfo.lanr, { x: 125, y: 688, size: 10, font: courierFont, });
    firstPage.drawText(getTodayDate(), { x: 204, y: 688, size: 10, font: courierFont, });

    // QRCode embedding
    const qrCodeImage = await pdfDoc.embedPng(getQRCodeURI(patientInfo));
    firstPage.drawImage(qrCodeImage, { x: 310, y: 630, width: 60, height: 60, });

    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "SALUS_Erklärung_zur_Teilnahme_an_der_besonderen_Versorgung.pdf", "application/pdf");
}

export default Finish;
