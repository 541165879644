import {useMutation} from "@apollo/react-hooks";
import {REMOVE_ATTACHMENT} from "../data/mutations/appointments";
import {removePrivateFields, toastError, toastSuccess, verifyGraphQlResponse} from "../services";
import {cloneDeep} from "lodash";
import {useTranslation} from "react-i18next";

export const useRemoveAttachment = () => {
    const {t} = useTranslation();
    const [remove, {loading: syncing}] = useMutation(REMOVE_ATTACHMENT);

    const getVariables = (variables) => {
        return removePrivateFields(cloneDeep(variables));
    };

    const removeDocumentSample = (vars) => {
        const variables = getVariables(vars);
        return remove({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
                throw e;
            });
    };

    return {removeDocumentSample, syncing}
}
