import React, {useState} from "react";
import {Button, Divider, Header, List, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const DownloadsHospital = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

     return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Button className={"teal"}>{t("footer_menu_downloads")}</Button>}
        >
            <Header>
                {t("footer_menu_downloads")}
            </Header>
            <Modal.Content>
                <div className={"c-header c-header--big"}>
                    Dokumente
                </div>

                <List divided relaxed>
                        <List.Item>
                            <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>
                                    <a href={"/downloadsHospital/Studieninformation.pdf"} download={"SALUS_Studieninformation.pdf"}>Studieninformation</a>
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsHospital/SALUS_Datenschutzmerkblatt.pdf"} download={"SALUS_Datenschutzmerkblatt.pdf"}>Datenschutzmerkblatt</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/WeitergabeInteressenbekundung.pdf"} download={"WeitergabeInteressenbekundung.pdf"}>Weitergabe Interessensbekundnung</a>
                            </List.Header>
                            <List.Description>Aufklärung und Einwilligung zur Weitergabe der Interessensbekundung an die niedergelassene Augenärztin/ den niedergelassenen Augenarzt.</List.Description>
                        </List.Content>
                    </List.Item>
                        <List.Item>
                            <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>
                                    <a href={"downloadsHospital/Anleitung PHYSIO-PORT.pdf"} download={"Anleitung PHYSIO-PORT.pdf"}>Anleitung PHYSIO-PORT</a>
                                </List.Header>
                                <List.Description>Anleitung zum 24h-Blutdruckmessgerät.</List.Description>
                            </List.Content>
                        </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/AnleitungPhysioPortWin.pdf"} download={"AnleitungPhysioPortWin.pdf"}>Anleitung PhysioPortWin</a>
                            </List.Header>
                            <List.Description>Eine Anleitung zur PhysioPortWin-Software.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Patiententagebuch PHYSIOPORT.pdf"} download={"Patiententagebuch PHYSIOPORT.pdf"}>Patiententagebuch PHYSIO-PORT</a>
                            </List.Header>
                            <List.Description>Tagebuch zur Nutzung des 24h-Blutdruckmessgerätes.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Aufklärung_Einwilligung_Interventionsgruppe.pdf"} download={"Aufklärung_Einwilligung_Interventionsgruppe.pdf"}>Aufklärung Einwilligung Interventionsgruppe</a>
                            </List.Header>
                            <List.Description>Aufklärungsbogen für die Teilnahme an der Interventionsgruppe.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/EQ-5D-5L Paper Interviewer Administration v1.0.pdf"} download={"EQ-5D-5L Paper Interviewer Administration v1.0.pdf"}>EQ-5D-5L Fragebogen - Interviewer</a>
                            </List.Header>
                            <List.Description>Gesundheitsfragebogen EQ-5D-5L für den Interviewer</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle'/>
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/EQ-5D-5L Paper Self-Complete v1.0.pdf"} download={"EQ-5D-5L Paper Self-Complete v1.0.pdf"}>EQ -5D-5L Fragebogen - Patient</a>
                            </List.Header>
                            <List.Description>Gesundheitsfragebogen EQ-5D-5L für den Patienten.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle'/>
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Gebrauchsanweisung_CLINIC_EXPORT_PATIENTAPP.pdf"} download={"Gebrauchsanweisung_CLINIC_EXPORT_PATIENTAPP.pdf"}>Gebrauchsanweisung iCare CLINIC und iCare EXPORT</a>
                            </List.Header>
                            <List.Description>Gebrauchsanweisung für Fachpersonal im Gesundheitswesen für Icare CLINIC und Icare EXPORT.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle'/>
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Icare_HOME_patient_guide.pdf"} download={"Icare_HOME_patient_guide.pdf"}>Icare HOME Patientenanleitung</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle'/>
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/KurzeinweisungicareHOME.pdf"} download={"KurzeinweisungicareHOME.pdf"}>Kurzeinweisung Icare HOME für Patienten</a>
                            </List.Header>
                            <List.Description>Case-Einleger Tonometer.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Geräte-Inventar-Patienten-Sets UND Hinweis_Nutzung_Geräte.pdf"} download={"Geräte-Inventar-Patienten-Sets UND Hinweis_Nutzung_Geräte.pdf"}>Geräteinventarliste Patient</a>
                            </List.Header>
                            <List.Description>Inventarliste der Geräte | Icare HOME Selbsttonometer und 24h-Blutdruckmessgerät</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Muster-Leihvertrag.pdf"} download={"Muster-Leihvertrag.pdf"}>Musterleihvertrag für Geräte der Patienten</a>
                            </List.Header>
                            <List.Description>Muster-Leihvertrag zwischen dem kooperierenden Krankenhaus und der Patientin / dem Patienten.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Geräterückgabe Formular.pdf"} download={"Geräterückgabe Formular.pdf"}>Geräterückgabeformular</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Reinigungsplan Messgebereinsatz icare HOME.pdf"} download={"Reinigungsplan Messgebereinsatz icare HOME.pdf"}>Reinigungsplan Icare HOME</a>
                            </List.Header>
                            <List.Description>Reinigungsplan Messgebereinsatz Icare HOME Tonometer</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file excel' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Template_24-h-RR_stationär.xlsx"} download={"Template_24-h-RR_stationär.xlsx"}>Tabelle für 24h-RR-Werte</a>
                            </List.Header>
                            <List.Description>Tabelle zum manuellen Import der stationär gemessenen 24h-RR-Werte.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file excel' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Template_Tensiomessungen_stationär.csv"} download={"Template_Tensiomessungen_stationär.csv"}>Tabelle für stationäre Tensiomessungen.</a>
                            </List.Header>
                            <List.Description>Tabelle zum manuellen Import der stationär gemessenen Tensiomesswerte.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file excel' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"downloadsHospital/Geräte-Rückgabe-Übersicht.xlsx"} download={"Geräte-Rückgabe-Übersicht.xlsx"}>Tabelle für Rückgabetermine der Geräte</a>
                            </List.Header>
                            <List.Description>Automatisierte Übersicht der Geräterückgaben 2021 – 2023</List.Description>
                        </List.Content>
                    </List.Item>
                </List>

                <div className={"c-header c-header--big"}>
                    Software
                </div>

                 <List>
                     <List.Item>
                         <List.Icon name='file' size='large' verticalAlign='middle' />
                         <List.Content>
                             <List.Header>
                                 <a href={"../../software/IcareEXPORT.exe"} download={"icareEXPORT.exe"}> Icare EXPORT Software </a>
                             </List.Header>
                             <List.Description>Sie wird benötigt, um die Gerätedaten des Icare HOME mit dem Server zu synchronisieren.</List.Description>
                         </List.Content>
                     </List.Item>
                     <List.Item>
                         <List.Icon name='file' size='large' verticalAlign='middle' />
                         <List.Content>
                             <List.Header>
                                 <a href={"software/Physioportwin.zip"} download={"Physioportwin.zip"}>PHYSIO-PORT Software</a>
                             </List.Header>
                             <List.Description>Sie wird benötigt, um die Gerätedaten des PHYSIOPORT Gerätes herunterzuladen.</List.Description>
                         </List.Content>
                     </List.Item>
                 </List>
            <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DownloadsHospital;
