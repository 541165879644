import React from 'react';
import RenderReaderZoneTensioControl from "./RenderReaderZoneTensioControl";
import RenderReaderZoneTensioIntervention from "./RenderReaderZoneTensioIntervention";
import {CAT_CONTROL, CAT_TENSIO} from "../../data/mutations/appointments";

const RenderReaderZoneTensio = ({dataModel, patientId, allAppointmentsCorrelationSamples, refresh, patient, isControlCohort, readerCComment}) => {
    const appointmentName = isControlCohort ? CAT_CONTROL : CAT_TENSIO;
    const readerCorrelationSample = allAppointmentsCorrelationSamples.filter(correlationSample => correlationSample.appointmentName === appointmentName)[0] || {}

    return (
        <div>
            {isControlCohort ?
                <RenderReaderZoneTensioControl
                    dataModel={dataModel}
                    patientId={patientId}
                    refresh={refresh}
                    patient={patient}
                    readerCComment={readerCComment}
                    allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                    readerCorrelationSample={readerCorrelationSample} /> :
                <RenderReaderZoneTensioIntervention
                    dataModel={dataModel}
                    patientId={patientId}
                    refresh={refresh}
                    patient={patient}
                    readerCComment={readerCComment}
                    allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                    readerCorrelationSample={readerCorrelationSample}/>}
        </div>
    );
};

export default RenderReaderZoneTensio;