import React from "react";
import {Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const PageHeaderSimple = ({onClick}) => {
    const {t} = useTranslation();

    return (
        <div className="Grid -middle page-header elevated">
            <h3 className={"Cell -fill"}>
                <div className={"Grid -between"}>
                    <div className={"cursor-pointer padding-normal"} onClick={onClick}>
                        <Icon name={"arrow left"} inverted /> {t("back")}
                    </div>
                    <div className={"cursor-pointer padding-normal"} onClick={onClick}>
                        <Icon name={"close"} inverted />
                    </div>
                </div>
            </h3>
        </div>
    );
};

export default PageHeaderSimple;
