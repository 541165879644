import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import DateInput from "./inputs/DateInput";
import {Controller, useForm} from "react-hook-form";
import {useMutateExclusion} from "../hooks";
import {get} from "lodash";
import {toastError} from "../services";
import {PAT_STATUS_DATA_EXCLUDED} from "../data/mutations/mutations";

const checkBoxModel = {
    title: "Erklärung unterschrieben",
    field: "hasStatement",
    value: false
};

const Exclusion = ({patient, handleAfterSave}) => {
    const {t} = useTranslation();
    const {mutateExclusion, loading} = useMutateExclusion();
    const [open, setOpen] = useState(false);
    const isReadOnly = patient.status === PAT_STATUS_DATA_EXCLUDED;
    const form = useForm({mode: "onChange"});

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        form.trigger();
    }, [patient]);
    /* eslint-enable */

    const handleClickOpen = () => {
        if (!loading && form.formState.isValid) {
            setOpen(true);
        } else {
            toastError(t("error_fields_incorrect"));
        }
    };

    const renderReallyExcludeDialog = () => {
        return (<Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>{t("exclusion_header")}</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    {t("exclusion_description")}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" color='red' onClick={() => setOpen(false)} content={t("cancel")}/>
                <Button
                    content={t("exclusion_yes")}
                    type="submit"
                    form="exclusion-form"
                    icon='checkmark'
                    positive
                />
            </Modal.Actions>
        </Modal>);
    };

    const handleSave = (data) => {
        setOpen(false);

        if (loading) {
            return;
        }
        return mutateExclusion({
            ...data,
            patientId: patient.id
        }).then(() => {
            //patient.studyData.exclusion = model;
            handleAfterSave();
        });
    };

    const onSubmitError = () => {
        toastError(t("error_fields_incorrect"));
    };

    return (
        <Form id="exclusion-form" onSubmit={form.handleSubmit(handleSave, onSubmitError)}>
            <div className={"Grid -right"}>
                <Button type="button" basic size="tiny" color="teal" content={t("save")} onClick={handleClickOpen}
                        disabled={isReadOnly}/>
            </div>
            <Divider/>
            <div className={"pb-2"}>
                <Form.Field className={"Grid -middle"} error={!!get(form, "errors.exclusion.date")}>
                    <label className={"pr-1"}>{t("exclusion_date") + " *"}</label>
                    <Controller
                        control={form.control}
                        name={"exclusion.date"}
                        rules={{required: true}}
                        defaultValue={get(patient, "studyData.exclusion.date", null)}
                        render={({onChange, value}) => (
                            <DateInput value={value} type={"date"}
                                       onChange={onChange}
                                       error={!!get(form, "errors.exclusion.date")}
                                       additionalProps={{disabled: isReadOnly}}/>
                        )}
                    />
                </Form.Field>
                <br/>
                <Form.Field error={!!get(form, "errors.exclusion.hasStatement")}>
                    <div className="ui checkbox">
                        <input type="checkbox"
                               tabIndex="0"
                               name={"exclusion.hasStatement"}
                               ref={form.register({required: true})}
                               disabled={isReadOnly}
                               id={"hasStatementCheckBox"}
                               defaultChecked={get(patient, "studyData.exclusion.hasStatement", false)}
                        />
                        <label htmlFor={"hasStatementCheckBox"}>{checkBoxModel.title + " *"}</label>
                    </div>
                </Form.Field>
            </div>
            <div className={"pr-1"}>Begründung *</div>
            <Form.Field error={!!get(form, "errors.exclusion.reason")}>
                <div className={"label-small"}>{t("hint_nopatientrelateddata")}</div>
                <textarea
                  className={"width-1-2"}
                  name={"exclusion.reason"}
                  defaultValue={get(patient, "studyData.exclusion.reason", "")}
                  placeholder={""}
                  autoComplete="off"
                  ref={form.register({required: true})}
                  disabled={isReadOnly}
              />
            </Form.Field>
            <Divider/>
            <div className={"Grid -right"}>
                <Button type="button" basic size="tiny" color="teal" content={t("save")} onClick={handleClickOpen}
                        disabled={isReadOnly}/>
            </div>
            {renderReallyExcludeDialog()}
        </Form>
    );
};

export default Exclusion;
