import {copyDataToModel} from "./utils";
import * as _ from "./allSections";


export const createReaderFollowup2Appointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples) => {
    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createDezimalvisusSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createTensiomessungSection(allAppointmentsCorrelationSamples, userId, role, patientId)
    ];

    return copyDataToModel(model, categorySamples);
}