import {useMutation} from "@apollo/react-hooks";
import {UPSERT_APPOINTMENT} from "../data/mutations/appointments";
import {removePrivateFields, toastError, toastSuccess, verifyGraphQlResponse} from "../services";
import {cloneDeep} from "lodash";
import {useTranslation} from "react-i18next";

export const useSaveAppointment = () => {
    const {t} = useTranslation();
    const [mutate, {loading: syncing}] = useMutation(UPSERT_APPOINTMENT);

    const getVariables = (variables) => {
        return removePrivateFields(cloneDeep(variables));
    };

    const update = (vars) => {
        const variables = getVariables(vars);
        return mutate({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
                throw e;
            });
    };

    return {update, syncing}
}