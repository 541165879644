import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {CAT_INTERVENTION} from "../../data/mutations/appointments";
import {addHeading, getValue, getCategoryValue, getDeviceName} from "./util";


export async function initialAppointmentSummary(masterData, cohort, correlationSamples, appointmentDate) {
    const pdfTemplateName = cohort === CAT_INTERVENTION ? "Erstuntersuchung_Intervention_Summary.pdf" : "Erstuntersuchung_Control_Summary.pdf";
    const initialSummaryBase64String = await fetch(`pdftemplates/patientSummary/${pdfTemplateName}`).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Erstuntersuchung", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const pages = pdfDocWithHeading.getPages();
    const firstPage = pages[0];

    // Visus
    firstPage.drawText(getValue(correlationSamples, "decimalvisusRight"), { x: 420, y: 573, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "decimalvisusLeft"), { x: 420, y: 557, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusRight"), { x: 420, y: 541, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusLeft"), { x: 420, y: 525, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionRight"), { x: 350, y: 509, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionLeft"), { x: 350, y: 493, size: 10, font: courierFont });

    // Refraktion
    firstPage.drawText(getValue(correlationSamples, "sphereRight"), { x: 420, y: 407, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "sphereLeft"), { x: 420, y: 391, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "cylinderRight"), { x: 420, y: 375, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "cylinderLeft"), { x: 420, y: 359, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "axisRight"), { x: 420, y: 343, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "axisLeft"), { x: 420, y: 327, size: 10, font: courierFont });

    // Tensiomessung
    firstPage.drawText(getDeviceName(correlationSamples, "tensioMeasurementManual"), {x: 120, y: 273, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurmentManualRight"), { x: 420, y: 241, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurementManualLeft"), { x: 420, y: 225, size: 10, font: courierFont });

    if (cohort === CAT_INTERVENTION) {
        // Individueller Zieldruck
        firstPage.drawText(getCategoryValue(correlationSamples, "targetPressureMethod"), {x: 93, y: 171, size: 10, font: courierFont});
        firstPage.drawText(getValue(correlationSamples, "targetPressureRight"), { x: 420, y: 139, size: 10, font: courierFont });
        firstPage.drawText(getValue(correlationSamples, "targetPressureLeft"), { x: 420, y: 123, size: 10, font: courierFont });
    }

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Erstuntersuchung_Summary", "application/pdf");
}

