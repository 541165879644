import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";


const agreement_insurance_content_de = {
    ai_download: "Krankenkassenspezifische Teilnahmeerklärung herunterladen",
    ai_file_name: "Krankenkassenspezifische-Teilnahmeerklärung.pdf",
    ai_title: "Krankenkassenspezifische Teilnahmeerklärung",
    ai_patient_data: `
     Name: {{name}}

     Geburtstag: {{birthdate}}

     Adresse:
     {{street}}, {{screetno}}
     {{postalcode}}, {{city}} 

     KVK
     Name: {{insurance_company}}
     Numer: {{insurance_number}}
     
     
    
    `,
    ai_section1_title: "I. Teilnahmeerklärung",
    ai_section1_header: "Ich erkläre hiermit meine Teilnahme.",
    ai_section1_content: `
     Ich möchte gemäß dem mir vorgestellten Versorgungsangebot im Rahmen der besonderen Versorgung behandelt werden.
     Ich wurde über die Inhalte des Versorgungsangebotes und über die daran beteiligten Leistungserbringer informiert.
     Mit der Behandlung durch die beteiligten Leistungserbringer bin ich einverstanden. Ich wurde über den Zweck und die Freiwilligkeit der Teilnahme aufgeklärt. 
     Für den zu versorgenden Behandlungsfall bin ich bis zum Behandlungsende an die beteiligten Leistungserbringer gebunden.
     Im Informationsblatt zu diesem Versorgungsangebot wird das Behandlungsen-
     de näher erläutert. Ich bin mit dem Inhalt der ''Versicherteninformation zur Besonderen Versorgung'' und der Information zum Versorgungs-angebot einverstanden.
    `,
    ai_section2_title: "II. Einverständnis zur Datenverarbeitung",
    ai_section2_header: "Einwilligung zum Umgang mit meinen Daten",
    ai_section2_content: `
     Ich bin mit der umseitig im Abschnitt ''Umgang mit Ihren Daten'' beschriebenen Verarbeitung meiner Daten einverstanden. 
     Hierbei handelt es sich um personenbezogene Daten (Name, Vorname, Kontaktdaten, Geschlecht, Geburtsdatum), versicherungsbezogene Daten (Versichertennummer, Kassenkennzeichen, Versichertenstatus), Teilnahmedaten, 
     Vertragsdaten sowie Gesundheitsdaten (Art der Inanspruchnahme, Behandlungstag, Vergütungsbezeichnungen und ihren Wert, dokumentierte Leistungen, ggf. Operations- und Prozedurenschlüssel, Verordnungsdaten und Diagnosen nach ICD 10).
    `,
};

//overwrite keys for EN version here
const agreement_insurance_content_en = {
    ...agreement_insurance_content_de,
    ai_download: "Download Insurance declaration of participation",
    ai_file_name: "Insurance-declaration.pdf",
};


const terms_conditions_de = {
    tc_title: "Teilnahmeerklärung",
    tc_header: "Teilnahmeerklärung",
    tc_content_section_1_title: `Einschlusskriterien`,
    tc_content_section_1_content: `
        Vollendetes 18. Lebensjahr
        Bedarf einer stationären Tages- und Nachtmessung 
        Gesetzlich krankenversichert 
        Gesicherte und Verdacht auf (V. a.) Glaukom-diagnose mit V. a. Druckschwankungen und -spitzen oder nicht erreichtem Zieldruck oder V. a. Glaukomprogression (H40.0, H40.1, H40.2 und H42.-) 
        Bereitschaft zur Behandlung in einer der teilnehmenden Kliniken 
        Ausreichende Deutschkenntnisse 
        Rechtsfähigkeit des Versicherten, der Studienteilnahme zuzustimmen.`,
    tc_content_section_2_title: `Ausschlusskriterien`,
    tc_content_section_2_content: `
        Noch nicht vollendetes 18. Lebensjahr
        Patienten außerhalb des Einzugsbereichs der teilnehmenden Kliniken.
        Überdeckende Erkrankungen der Psychiatrie, Neurologie oder anderen Indikationen (z. B. Beeinträchtigungen der Selbstständigkeit), welche eine Selbsttonometrie unmöglich machen
        Starke Kommunikationshemmnisse, die die Einweisung zur Durchführung Intervention nicht ermöglichen
        Unklare Rechtsfähigkeit der potenziellen Studienteilnehmer.`,
};


//create your EN version here. for now, EN is same as DE.
const terms_conditions_en = terms_conditions_de;

// Necessary fields for rendering agreement PDF
const salus_study_content_de = {
    ss_download: "Teilnahmeerklärung herunterladen",
    ss_file_name: "SALUS_Teilnahmeerklärung.pdf",
};

const salus_study_content_en = salus_study_content_de;

const attachment_hints = {
    hint_status_mandatory: "Verpflichtend:",
    hint_status_optional: "Optional:",

    hint_opt_perimetry: "Perimetrie (PDF oder Bildformat)",
    hint_opt_papillen: "Papillen-OCT (gerne E2E-Datei, alternativ PDF)",
    hint_opt_pachymetry: "Pachymetrie (PDF)",
    hint_opt_fundus_photo: "Fundusfoto (Bildformat)",
    hint_opt_hrt: "HRT (gerne E2E-Datei, alternativ PDF)",
    hint_opt_tensio_profile: "Tensioprofil (PDF)",
};

const study_lead_insurance_download_de = {
    salus_study_download: "Teilnahmeerklärung zur Studie herunterladen",
    insurance_specific_template: "Teilnahmeerklärung zum Vertrag zur Besonderen Versorgung herunterladen",
    insurance_specific_pdf: "Versicherteninformation herunterladen"
};

const study_lead_insurance_download_en = study_lead_insurance_download_de;

const patient_view_de = {
    print: "Drucken",
    APPT_STATUS_TOOK_PLACE_READING_NOT_COMPLETED: "Stattgefunden - nicht qualitätsgesichert",
    APPT_STATUS_TOOK_PLACE_READING_COMPLETED: "Stattgefunden - qualitätsgesichert"
}

const patient_view_en = {
    print: "Print",
    APPT_STATUS_TOOK_PLACE_READING_NOT_COMPLETED: "Took place - reading not completed",
    APPT_STATUS_TOOK_PLACE_READING_COMPLETED: "Took place - reading completed"
}

const initI18n = () => {
    i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                de: {
                    translations: {
                        ...agreement_insurance_content_de,
                        ...agreement_insurance_content_de,
                        ...terms_conditions_de,
                        ...salus_study_content_de,
                        ...attachment_hints,
                        ...study_lead_insurance_download_de,
                        ...patient_view_de,
                        APPT_STATUS_CANCELED: "Abgesagt",
                        APPT_STATUS_SCHEDULED: "Geplant",
                        APPT_STATUS_NOT_SCHEDULED_YET: "Noch nicht geplant",
                        APPT_STATUS_TOOK_PLACE: "Stattgefunden",
                        APPT_STATUS_READING: "Reading",
                        APPT_STATUS_READING_FINISHED: "Reading abgeschlossen",
                        E_INVALID: "*",
                        E_REQUIRED: "*",
                        LEFT_EYE: "Linkes Auge",
                        RIGHT_EYE: "Rechtes Auge",
                        NOT_APPLICABLE: "Nicht anwendbar",
                        BOTH_EYES: "Beide Augen",
                        NAN: "Keine Angabe",
                        add: "+",
                        add_intervention: "Voroperation hinzufügen",
                        add_medication: "Medikament hinzufügen",
                        add_measurement: "Messung hinzufügen",
                        address: "Adresse",
                        agreement_description: "Bitte drucken Sie die Studienteilnahmeerklärung aus und lassen diese vom Patienten unterschreiben, " +
                            "bevor Sie auf „Speichern und Fortfahren“ klicken. "+
                            "Die Studieninformation und das Datenschutzmerkblatt sollten dem Patienten vorher ausgehändigt werden.",
                        agreement_download: "Teilnahmeerklärung herunterladen",
                        agreement_title: "Teilnahme- und Einwilligungserklärung zur Studie SALUS + Einwilligung zur Datenerhebung und -verarbeitung",
                        agreement_warning: "Dieses PDF steht Ihnen später NICHT mehr zum Download zur Verfügung.",
                        appointment:"Termin",
                        appointment_recommendation: "Therapieempfehlung und allgemeine Bemerkungen",
                        appointment_start_date: "Termin am",
                        appointment_status: "Status",
                        attachment_remove_header: "Datei entfernen: ",
                        attachment_remove_description: "Wenn Sie Ja drücken, wird die Datei endgültig entfernt.",
                        attachment_remove_yes:"Ja, entfernen",
                        attachment_upload_success:"Datei erfolgreich hochgeladen!",
                        attachment_upload_error: "Fehler beim Hochladen der Datei: ",
                        back: "Zurück",
                        bloodpressure: "24h RR",
                        cancel: "Abbrechen",
                        city: "Ort",
                        close: "Schliessen",
                        cohort: "Kohorte",
                        COHORT_CONTROL: "Kontrollgruppe",
                        COHORT_INTERVENTION: "Interventionsgruppe",
                        comment: "Kommentar",
                        conservative_therapy: "Konservative Therapie",
                        contact_data: "Kontaktdaten",
                        correctione: "cum / sine correctione",
                        country: "Land",
                        current_medication: "Aktuelle Medikation",
                        date: "Datum",
                        date_dd: "TT",
                        date_mm: "MM",
                        date_of_birth: "Geburtsdatum",
                        date_yy: "YYYY",
                        decimalvisus_toolow: "Dezimalvisus ist kleiner 0.05",
                        decrypt_description:"Bitte geben Sie den Schlüssel ein, um die Datenbank zu entsperren.",
                        decrypt_header: "Datenbank entsperren",
                        decrypt_keyfield:"Schlüssel",
                        device: "Messinstrument",
                        device_name: "Gerätename",
                        disclaimer: "Impressum",
                        doctor: "Arzt",
                        downloads: "Dokumente & Software",
                        edit: "Bearbeiten",
                        end_date: "Enddatum",
                        email: "E-Mail-Addresse",
                        more_numbers_and_emails: "Mehrere Telefonnummern bzw. Emailadressen können durch Komma getrennt eingegeben werden. Beispiel: 011111, 055555",
                        error_date: "Sie haben vergessen, das Datum festzulegen!",
                        error_name: "Sie haben vergessen, den Namen festzulegen!",
                        error_gql: "Ein unerwarteter Fehler ist aufgetreten",
                        error_mandatory_fields: "Bitte füllen Sie alle Pflichtfelder aus",
                        error_fields_incorrect: "Bitte prüfen Sie, ob alle Felder korrekt ausgefüllt sind.",
                        error_wrong_password: "Das Passwort ist nicht korrekt.",
                        error_not_implemented: "Funktion derzeit nicht verfügbar.",
                        error_comment_missing: "Bitte fügen Sie einen Kommentar zu den als inkorrekt markierten Feldern hinzu.",
                        examination_type: "Untersuchung",
                        exclusion_date:"Ausschlussdatum",
                        exclusion_description:"Wenn Sie Ja drücken, wird der Patient endgültig ausgeschlossen und kann nicht mehr bearbeitet werden!",
                        exclusion_header:"Sind Sie wirklich sicher, dass der Patient ausgeschlossen werden soll",
                        exclusion_reason: "Ausschlussgrund",
                        exclusion_yes:"Ja, Patient ausschliessen!",
                        eye:"Auge",
                        filename: "Dateiname",
                        files: "Dateien",
                        file_size_hint: "Dateien dürfen die folgende Größe nicht überschreiten: ",
                        fillDemoPatientData: "Demo Patient",
                        finish: "Fertig",
                        finish_patient_created: "Patient erstellt",
                        first_name: "Vorname",
                        footer_menu_contact: "Support",
                        footer_menu_disclaimer: "Impressum",
                        footer_menu_downloads: "Dokumente & Software",
                        gender: "Geschlecht",
                        gender_divers: "Divers",
                        gender_female: "Weiblich",
                        gender_male: "Männlich",
                        help: "Hilfe",
                        hint_download_BV: "Bitte drucken Sie die krankenkassenspezifische Teilnahme- und " +
                            "Einwilligungserklärung zur Datenverarbeitung sowie die Patienteninformation " +
                            "zur Teilnahme an der Besonderen Versorgung SALUS aus. Die unterschriebene Teilnahme- und " +
                            "Einwilligungserklärung geht an Sie zurück. Die Patienteninformation verbleibt beim Patienten.",
                        hint_nopatientrelateddata: "Bitte geben Sie keine personenbezogenen Daten oder andere Daten ein, die Rückschlüsse auf die Patientin oder den Patienten zulassen!",
                        hint_nopatientrelateddatainfiles: "Bite achten Sie darauf, dass die Dateien und Dateinamen keine personenbezogenen Daten enthalten!",
                        hint_appointmentdateafterinclusion: "Datum muss nach dem Einschlussdatum liegen.",
                        hospital_in_charge: "Behandelndes Krankenhaus",
                        import: "Import",
                        inclusion_description:"Bitte bestätigen Sie, dass die Teilnahmeerklärung vom Patienten unterschrieben wurde. " +
                            "Der Einschluss des Patienten und die Randomisierung können nicht rückgängig gemacht werden!",
                        inclusion_header:"Teilnahmeerklärung unterschrieben?",
                        inclusion_yes:" Ja, Teilnahmeerklärung unterschrieben",
                        insurance: "KVK",
                        insurance_company: "Krankenkasse",
                        insurance_company_no: "Kostenträgerkennung",
                        insurance_number: "Versichertennummer",
                        intervention: "Operationsverfahren",
                        interventions: "Glaukomvoroperationen",
                        konsilKlinik: "Konsil Klinik",
                        last_name: "Nachname",
                        leave_header:"Fortfahren ohne Änderungen zu speichern?",
                        leave_no:"Nein, auf Seite bleiben",
                        leave_without_saving: "Wollen Sie wirklich fortfahren ohne zu speichern?",
                        leave_yes: "Ja, fortfahren",
                        logout: "Abmelden",
                        medical_history_modal_header: "Ihre Daten wurden erfolgreich gespeichert!",
                        medical_history_modal_content: "Bitte denken Sie daran, die Erstuntersuchung ebenfalls auszufüllen!",
                        medical_history_modal_redirect_initial_exam: "Zur Erstuntersuchung wechseln",
                        medication: "Medikament",
                        medication_end_date: "Enddatum",
                        medication_history: "Medikationshistorie",
                        medication_id_is_missing: "Sie müssen zuerst neue Medikamente speichern",
                        newPatient: "Neuer Patient",
                        newPatientDisabled: "Einschluss beendet",
                        next: "Weiter",
                        new_attachment: "Datei hinzufügen",
                        no: "Nein",
                        no_selection: "Keine Auswahl",
                        no_value: "----",
                        number: "Hausnr",
                        oct_group_hint:"Möglicherweise sind je nach verwendetem Gerät nicht alle Werte verfügbar. Bitte tragen Sie alle Werte ein, die Ihnen zur Verfügung stehen.",
                        optional: "(optional)",
                        organization_profile_header: "Betriebsprofil ändern",
                        organization_BSNR:"BSNR",
                        organization_name:"Name der Praxis / des Krankenhauses",
                        organization_responsible:"Kontaktperson",
                        organization_responsible_position:"Position",
                        patient_created: "Patient erfolgreich angelegt",
                        patient_status: "Status",
                        PAT_STATUS_ACTIVE: "Aktiv",
                        PAT_STATUS_DATA_MISSING: "Daten fehlen",
                        PAT_STATUS_EXCLUDED: "Ausgeschlossen",
                        profile: "Profilinfos ändern",
                        profile_decrypt: "Datenbank entsperren",
                        profile_orga: "Organisationsinfos ändern",
                        questionnaire: "Fragebogen",
                        reader_view_ttp_tensio_profile: "TTP - Tensio Profil",
                        reader_view_ttp_24h_rr: "TTP - 24h-RR",
                        remove: "-",
                        remove_label: "Entfernen",
                        remove_success:"Entfernen der Datei erfolgreich!",
                        save: "Speichern",
                        saveAndContinue: "Speichern und Fortfahren",
                        save_appointment_to_upload_attachments: "Um Dateien hochzuladen, Termin zuerst speichern.",
                        save_success: "Daten gespeichert",
                        search: "Suchen Sie Patienten nach Vor- oder Nachnamen oder Studien-ID",
                        search_study_lead: "Suchen Sie Patienten nach Vor- oder Nachnamen, Studien-ID, Ort, Krankenkasse, Klinik oder NAA",
                        search_reader_view: "Suchen Sie Patienten nach Studien-ID",
                        search_no_results: "Keine Ergebnisse gefunden",
                        search_no_results_hint: "Versuchen Sie es mit einer anderen Suchabfrage",
                        select_file:"Datei auswählen",
                        show_medication_history: "Medikationshistorie anzeigen",
                        start_date: "Startdatum",
                        stop_medication: "Absetzen",
                        street: "Straße",
                        study_id: "Studiennummer",
                        study_inclusion_date: "Einschlussdatum",
                        study_lead_save_patient:"Drücken Sie Speichern, um die Änderungen zu übernehmen.",
                        tabs_apponit_CONTROL: "Stationäres TTP - Untersuchung / 24h RR",
                        tabs_apponit_EXCLUSION: "Ausschluss",
                        tabs_apponit_FINAL: "Abschlussuntersuchung",
                        tabs_apponit_FIRST: "Erstuntersuchung",
                        tabs_apponit_FOLLOWUP1: "Kontrolluntersuchung 1",
                        tabs_apponit_FOLLOWUP2: "Kontrolluntersuchung 2",
                        tabs_apponit_FOLLOWUP3: "Kontrolluntersuchung 3",
                        tabs_apponit_INCLUSION: "Einschlussuntersuchung",
                        tabs_apponit_INTERVENTION: "Ambulantes TTP - Interventionsgruppe",
                        tabs_apponit_TENSIO: "Ambulantes TTP - Untersuchung",
                        tabs_apponit_TENSIOCHART: "Ambulantes TTP – Tensio Profil / 24h-RR",
                        tabs_apponit_TENSIOCHART_CONTROL: "Stationäres TTP – Tensio Profil",
                        tabs_apponit_TENSIO_AND_CHART: "Ambulantes TTP - Untersuchung / Tensioprofil/ 24h-RR",
                        tabs_apponit_TENSIO_AND_CHART_CONTROL: "Stationäres TTP - Untersuchung/ Tensioprofil/ 24h-RR",
                        tabs_apponit_CHART_legend_click: "Wertereihen können durch Anklicken des Namens in der Legende ein- und ausgeblendet werden",
                        tabs_medical_history: "Anamnese",
                        telephone: "Mobilnummer/Festnetznummer",
                        tensio: "Tensioprofil",
                        therapy_recommendation: "Therapieempfehlung",
                        time: "Zeit",
                        title:"Titel",
                        tooltip_decimal: "Bitte geben Sie eine Dezimalzahl ein. Beispiel: 1.0",
                        tooltip_decimal_with_sign: "Bitte geben Sie eine Dezimalzahl mit Vorzeichen ein. Beispiel: +1.23",
                        tooltip_device:"Bitte wählen Sie ein Gerät aus",
                        tooltip_duration:"Bitte geben Sie eine Dauer in Stunden und Minuten ein. Beispiel: 25:01",
                        tooltip_fraction:"Bitte geben Sie einen Bruch ein. Beispiel: 1/35",
                        tooltip_integer: "Bitte geben Sie eine Ganzzahl ein. Beispiel: 23",
                        tooltip_integer_round: "Bitte geben Sie eine Ganzzahl ein, Dezimalstellen bitte runden. Beispiel: 23",
                        tooltip_shaffer: "Bitte geben Sie eine 0 oder eine römische Zahl zwischen I und IV ein.",
                        unlock: "Entsperren",
                        unlock_failed: "Datenbank entsperren fehlgeschlagen.",
                        unlock_success: "Datenbank entsperrt.",
                        user_defined: "Benutzerdefiniert",
                        user_LANR: "LANR",
                        user_profile_header:"Benutzerprofil ändern",
                        warning_fill_profiles: "Bitte ergänzen Sie zunächst Ihr Benutzer- und Organisationsprofil!",
                        yes: "Ja",
                        zip: "PLZ",
                        first_rule_violated: "Der Termin fand statt, aber Pflichteingaben fehlen oder sind unvollständig.",
                        second_rule_violated: "Der Termin fand noch nicht statt, aber das Termindatum liegt vor dem aktuellen Datum.",
                        third_rule_violated: "Der Termin fand noch nicht statt, aber ein Nachfolgetermin hat den Status Stattgefunden",
                        fourth_rule_violated: "Der Vorgängertermin liegt länger als 3 Monate zurück.",
                        correction_or_not: "Korrektur oder nicht",
                        correction: "Korrektur",
                        without_correction: "Ohne Korrektur",
                        reader_c_comment: "ReaderC Kommentar",
                        dem_contact_study_nurse: "Bitte setzen Sie sich mit dem SALUS-Backoffice in Verbindung, bevor Sie fortfahren, da ReaderC um Änderungen gebeten hat, die vorher umgesetzt werden sollen. Kommentar von ReaderC:",
                        export_txt: "Export",
                        exporting_txt: "Exportieren",
                        data_export_kv: "Datenexport KV",
                        data_export_eval: "Datenexport Evaluation",
                        data_export_by_patient_ids: "Datenexport nach Patienten-ID(s)",
                        write_down_patient_ids: "Schreiben Sie eine oder mehrere durch Komma getrennte Patienten-ids auf und exportieren Sie diese Patienten (z. B. PATIENT001,PATIENT002).",
                        select_an_kv: "Bitte wählen Sie eine Krankenversicherung",
                        exported_X_patients: "{{X_patients}} Patient/en exportiert"
                    }
                },
                en: {
                    translations: {
                        ...agreement_insurance_content_en,
                        ...terms_conditions_en,
                        ...terms_conditions_en,
                        ...salus_study_content_en,
                        ...study_lead_insurance_download_en,
                        ...patient_view_en,
                        APPT_STATUS_CANCELED: "Canceled",
                        APPT_STATUS_SCHEDULED: "Scheduled",
                        APPT_STATUS_NOT_SCHEDULED_YET: "Not scheduled yet",
                        APPT_STATUS_TOOK_PLACE: "Took place",
                        APPT_STATUS_READING: "Reading",
                        APPT_STATUS_READING_FINISHED: "Reading finished",
                        E_INVALID: "*",
                        E_REQUIRED: "*",
                        LEFT_EYE: "Left eye",
                        RIGHT_EYE: "Right eye",
                        NOT_APPLICABLE: "Not applicable",
                        BOTH_EYES: "Both eyes",
                        NAN: "Not specified",
                        add: "+",
                        add_intervention: "Add intervention",
                        add_medication: "Add medication",
                        add_measurement: "Add measurement",
                        address: "Address",
                        agreement_download: "Download declaration of participation",
                        agreement_title: "Declaration of participation",
                        agreement_warning: "This PDF is NOT accessible in a later step.",
                        appointment: "Appointment",
                        appointment_recommendation: "Therapy recommendation and general notes",
                        appointment_start_date: "Appointment date",
                        appointment_status: "Status",
                        attachment_remove_header: "Remove file: ",
                        attachment_remove_description: "When you click Yes, the file is removed permanently.",
                        attachment_remove_yes: "Yes, remove file",
                        attachment_upload_success:"File uploaded successfully!",
                        attachment_upload_error:"Error uploading file: ",
                        back: "Back",
                        bloodpressure: "24h RR",
                        cancel: "Cancel",
                        city: "City",
                        close: "Close",
                        cohort: "Cohort",
                        comment: "Comment",
                        country: "Country",
                        conservative_therapy: "Conservative Therapy",
                        contact_data: "Contact",
                        copyMedication: "Copy Medication",
                        correctione: "cum / sine correctione",
                        current_medication: "Current medication",
                        date: "Datum",
                        date_dd: "DD",
                        date_mm: "MM",
                        date_of_birth: "Date of Birth",
                        date_yy: "YYYY",
                        decimalvisus_toolow: "Decimal visus below 0.05",
                        decrypt_description:"Please enter the key to decrypt the database.",
                        decrypt_header: "Unlock database",
                        decrypt_keyfield:"Key",
                        device: "Device",
                        device_name: "Device label",
                        doctor: "Doctor",
                        edit: "Edit",
                        end_date: "End date",
                        email: "Email",
                        more_numbers_and_emails: "Multiple phone numbers or email addresses can be entered separated by commas. Example: 011111, 055555",
                        error_date: "The date is not set!",
                        error_name: "The label is not set!",
                        error_gql: "An unexpected error has occurred",
                        error_mandatory_fields: "Please complete all required fields",
                        error_not_implemented: "Feature not implemented yet.",
                        error_fields_incorrect: "Please fill all fields correctly.",
                        error_wrong_password: "The password is not correct.",
                        error_comment_missing: "Please add a comment for the fields marked as incomplete.",
                        examination_type: "Examination type",
                        exclusion_date:"Exclusion date",
                        exclusion_description:"If you click Yes patient will be excluded permanently and cannot be edited anymore!",
                        exclusion_header:"Are you really sure the patient should be excluded?",
                        exclusion_reason: "Exclusion reason",
                        exclusion_yes:"Yes, exclude patient!",
                        eye:"Eye",
                        filename: "File label",
                        files: "Files",
                        file_size_hint: "Files cannot exceed the following size: ",
                        fillDemoPatientData: "Demo patient",
                        finish: "Finish",
                        finish_patient_created: "Patient created",
                        first_name: "First Name",
                        footer_menu_contact: "Support",
                        footer_menu_disclaimer: "Disclaimer",
                        footer_menu_downloads: "Documents & Software",
                        gender: "Gender",
                        gender_divers: "Diverse",
                        gender_female: "Female",
                        gender_male: "Male",
                        help: "Help",
                        hint_download_BV: "Please print the insurance company specific contract and the agreement " +
                            "to data processing as well as the patient information " +
                            "The signed agreement is collected by you. The patient information is kept by the patient.",
                        hint_appointmentdateafterinclusion: "Date has to be after inclusion date.",
                        hint_nopatientrelateddata: "Please do not enter personal data or other data which can reveal the patient's identity!",
                        hint_nopatientrelateddatainfiles: "Please take care, that no personal data is included in the files or the file names!",
                        hospital_in_charge: "Hospital in charge",
                        import: "Import",
                        insurance: "Insurance",
                        insurance_company: "Insurance Company",
                        insurance_company_no: "Insurance Company No",
                        insurance_number: "Insurance Number",
                        intervention: "Intervention procedure",
                        interventions: "Glaucoma Interventions",
                        konsilKlinik: "Konsil Klinik",
                        last_name: "Last Name",
                        leave_header:"Continue without saving?",
                        leave_no:"No, stay",
                        leave_without_saving: "Do you really want to leave the form without saving?",
                        leave_yes: "Yes, leave",
                        logout: "Sign out",
                        medical_history_modal_header: "Fill out initial examination",
                        medical_history_modal_content: "Please remember to fill out the initial examination as well!",
                        medical_history_modal_redirect_initial_exam: "Go to initial examination",
                        medication: "Medication",
                        medication_end_date: "End date",
                        medication_history: "Medication history",
                        medication_id_is_missing: "You need to save new medication first",
                        new_attachment: "Add attachment",
                        newPatient: "New Patient",
                        newPatientDisabled: "Inclusion finished",
                        next: "Next",
                        no: "No",
                        no_selection: "No selection",
                        no_value: "----",
                        number: "Number",
                        oct_group_hint:"(Möglicherweise sind je nach verwendetem OCT-Gerät nicht alle Werte verfügbar. Bitte tragen Sie alle Werte ein, die Ihnen zur Verfügung stehen.)",
                        optional: "(optional)",
                        organization_profile_header: "Edit my organization profile",
                        organization_BSNR:"BSNR",
                        organization_name:"Name of practice / hospital",
                        organization_responsible:"Contact person",
                        patient_created: "Patient successfully created",
                        patient_status: "Status",
                        PAT_STATUS_ACTIVE: "Active",
                        PAT_STATUS_DATA_MISSING: "Daten fehlen",
                        PAT_STATUS_EXCLUDED: "Ausgeschlossen",
                        profile: "Benutzerprofil ändern",
                        profile_decrypt: "Unlock database",
                        profile_orga: "Organisationsprofil ändern",
                        questionnaire: "Questionnaire",
                        reader_view_ttp_tensio_profile: "TTP - Tensio Profil",
                        reader_view_ttp_24h_rr: "TTP - 24h-RR",
                        remove: "-",
                        remove_label: "Remove",
                        remove_success:"Successfully removed file!",
                        save: "Save",
                        saveAndContinue: "Save and Continue",
                        save_appointment_to_upload_attachments: "To upload files, save appointment first.",
                        save_success: "Data saved",
                        search: "Search patient by forename, surname, or study id.",
                        search_study_lead: "Search patient by forename, surname, study id, city, insurance company, hospital, or doctor.",
                        search_reader_view: "Search patient by study id",
                        search_no_results: "No results found",
                        search_no_results_hint: "Try different search query",
                        select_file:"Select file",
                        show_medication_history: "Show medication history",
                        start_date:"Start date",
                        stop_medication: "stop",
                        street: "Street",
                        study_id: "Study ID",
                        study_inclusion_date: "Inclusion date",
                        tabs_apponit_CONTROL: "Clinical TTP - Examination / 24h RR",
                        tabs_apponit_EXCLUSION: "Exclusion",
                        tabs_apponit_FINAL: "Final examination",
                        tabs_apponit_FIRST: "Initial Examination",
                        tabs_apponit_FOLLOWUP1: "Followup 1",
                        tabs_apponit_FOLLOWUP2: "Followup 2",
                        tabs_apponit_FOLLOWUP3: "Followup 3",
                        tabs_apponit_FOLLOWUP: "Followup T2",
                        tabs_apponit_INCLUSION: "Inclusion investigation",
                        tabs_apponit_INTERVENTION: "Ambulant TTP - Intervention Group",
                        tabs_apponit_TENSIOCHART_CONTROL: "Clinical TTP – Tensio Profil",
                        tabs_apponit_TENSIO: "Ambulant TTP - Examinations",
                        tabs_apponit_TENSIOCHART: "Ambulant TTP - Tensio Profile",
                        tabs_apponit_TENSIO_AND_CHART: "Ambulant TTP - Examinations / Tensio Profile/ 24h-RR",
                        tabs_apponit_TENSIO_AND_CHART_CONTROL: "Clinical TTP - Examinations / Tensio Profile/ 24h-RR",
                        tabs_apponit_CHART_legend_click: "Value series can be shown and hidden by pressing on the name in the Legend",
                        tabs_medical_history: "Medical history",
                        telephone: "Mobile Number/Landline Number",
                        tensio: "Tensio Profile",
                        therapy_recommendation: "Therapy recommendation",
                        time: "Time",
                        tooltip_decimal: "Please enter a floating point number. Example: 1.0",
                        tooltip_decimal_with_sign: "Please enter a floating point number with leading sign. Example: +1.0",
                        tooltip_device:"Please select a device.",
                        tooltip_duration:"Please enter a duration in hours and minutes. Example: 25:01",
                        tooltip_fraction:"Please enter a fraction. Example: 1/35",
                        tooltip_integer: "Please enter an integer. Example: 23",
                        tooltip_shaffer: "Please enter a 0 or a latin number between I and IV.",
                        unlock: "Unlock",
                        unlock_failed: "Database unlock failed.",
                        unlock_success: "Database unlocked.",
                        user_defined: "User defined",
                        user_LANR: "LANR",
                        user_profile_header:"Edit user profile",
                        warning_fill_profiles: "Please first complete your user and organization profile!",
                        yes: "Yes",
                        zip: "ZIP",
                        first_rule_violated: "The appointment TOOK PLACE, but the mandatory data is missing or incomplete.",
                        second_rule_violated: "The appointment did not TOOK PLACE and the appointment date is less than the current date.",
                        third_rule_violated: "The appointment did not TOOK PLACE, but one of the appointment after this appointment TOOK PLACE.",
                        fourth_rule_violated: "The period between the appointments must be lower or equal to 3 months.",
                        correction_or_not: "Correction Or Not",
                        correction: "Correction",
                        without_correction: "Without Correction",
                        reader_c_comment: "ReaderC Comment",
                        dem_contact_study_nurse: "Before you continue, please contact the SALUS backoffice, because reader C requested changes which need to be implemented first. Comment Reader C:",
                        export_txt: "Export",
                        exporting_txt: "Exporting",
                        data_export_kv: "HI Data Export",
                        data_export_eval: "Data Export Evaluation",
                        data_export_by_patient_ids: "Data Export By Patient id(s)",
                        write_down_patient_ids: "Write down one or more patient-ids separated by comma and exports these patients (ex: PATIENT001,PATIENT002).",
                        select_an_kv: "Please select an Insurance Company",
                        exported_X_patients: "Exported {{X_patients}} patient/s"
                    }
                }
            },
            fallbackLng: "de",
            debug: false,
            ns: ["translations"],
            defaultNS: "translations",
            keySeparator: false, // we use content as keys
            interpolation: {
                escapeValue: false,
            },
        });
};

export {initI18n};
