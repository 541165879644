import * as _ from "lodash";
import {parseISO} from "date-fns";

export const createCorrelation = (overwrite) => {
    const device = overwrite.__deviceModel;
    const metadata = device && {deviceName: ""};

    return {
        ...{
            id: null,
            sampleType: "correlation",
            subject: "",
            //todo handle end data
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            metadata: metadata,
        },
        ...overwrite,
    };
};

export const createQuantity = (overwrite) => {
    return {
        ...{
            id: null,
            sampleType: "quantity",
            unit: null,
            quantity: null,
            //todo handle dates
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            metadata: null,
            __type: "",
            __field: "quantitySamples",
            __valueField: "quantity",
            __optional: true,
            __description:"",
        },
        ...overwrite,
    };
};

export const createSample = (overwrite) => {
    return {
        ...{
            id: null,
            sampleType: "category",
            value: null,
            subject: "",
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            metadata: null,
            __type: "",
            __field: "categorySamples",
            __valueField: "value",
            __description:"",
        },
        ...overwrite,
    };
};

export const createOptions = (options, rest = {}) => {
    return {
        __type: "select",
        __v: {
            validate: (value) => typeof(value) === "string" || "*",
        },
        __options: options.map((it) => ({key: it, value: it, text: it})),
        ...rest,
    };
};

export const createZieldruck = (patientId, t) => {
    const options = createOptions([
            "",
            "Absoluter Cut-off-Wert",
            "Prozentuale Reduktion",
            "Formelbasiert",
        ]);
    return [
        {
            ...createCorrelation({
                subject: patientId,
                __label: "Individueller Zieldruck",
                correlationType: "targetPressure"
            }),
            quantitySamples: [
                createQuantity({
                    quantityType: "targetPressureRight",
                    unit: "mmHg",
                    __label: "Zieldruck rechtes Auge",
                    __css: "c-column-left",
                    __placeholder: "ZZ",
                    __v: {
                        validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                    },
                    __optional: false,
                    __description: t("tooltip_integer_round")
                }),
                createQuantity({
                    quantityType: "targetPressureLeft",
                    unit: "mmHg",
                    __label: "Zieldruck linkes Auge",
                    __css: "c-column-right",
                    __placeholder: "ZZ",
                    __v: {
                        validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                    },
                    __optional: false,
                    __description: t("tooltip_integer_round")
                })
            ],
            categorySamples: [
                createSample({
                    subject: patientId,
                    categoryType: "targetPressureMethod",
                    __type: "select",
                    __label: "Methode",
                    __css: "c-column-left",
                    __placeholder: t("no_selection"),
                    __optional: false,
                    __description: "Bitte wählen Sie eine Option aus",
                    __v: {
                        validate: (value) => typeof(value) === "string" || t("E_INVALID")
                    },
                    ...options
                })

            ]
        }
    ]
};

// Consider adding filtering by correlationType
export const getChartDataFromSamples = (correlationSamples, quantityType, startTime, endTime) => {

    const quantitySamples = correlationSamples
      .flatMap(cs => cs.quantitySamples)
      .filter(quantitySample => quantitySample.quantityType === quantityType);

    if (quantitySamples && quantitySamples.length>0){
        return _.zipWith(
            quantitySamples.map(cs => parseISO(cs.startDate).getTime()),
            quantitySamples
                .map(quantitySample => Number.parseFloat(quantitySample.quantity)),
            function (a, b) {
                return {
                    x: a,
                    y: b,
                };
            },
        ).filter(value => {
            return (startTime ? value.x >= startTime : true) && (endTime ? value.x <= endTime : true) && (value.y > -1);
        });
    } else {
        return [{
            x: startTime,
            y: -1
        }]
    }
};

export const romanToInt = (string) => {
    const values = new Map([
        ['I', 1],
        ['V', 5],
        ['X', 10]
    ]);

    if (string === "0"){
        return parseFloat(string);
    }
    let result = 0,
        current, previous = 0;
    for (const char of string.split("").reverse()) {
        current = values.get(char);
        if (current >= previous) {
            result += current;
        } else {
            result -= current;
        }
        previous = current;
    }
    return result;
};

//Insurance companies list
export const insurance_companies ={ "AOK":"AOK NordWest (103 411 401)",
                                    "BARMER":"Barmer (104 940 005)",
                                    "DAK" : "DAK Gesundheit (105 830 016)",
                                    "IKK":"IKK classic (107 202 793)",
                                    "KKH":"KKH Kaufmännische Krankenkasse (102 171 012)",
                                    "KNAPPSCHAFT":"Knappschaft (109 905 003)",
                                    "TK": "Techniker Krankenkasse (101 575 519)",
                                    "VIACTIV": "VIACTIV (104 526 376)"};

export const insurance_companies_no_list =["103411401", "104940005", "105830016",
    "107202793", "102171012", "109905003", "101575519", "104526376"];

export const intToRoman = (num) => {
    if (num === 0){
        return "0";
    }
    else {
        let digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return (Array(digits.join("")).join("M") + roman).toString();
    }
};

export const regExs = {
    "REGEX_DECIMAL": new RegExp("^-?(?![.])(?=.+)(?:[1-9]\\d?|0)?(?:\\.\\d{1,2})?$"),
    "REGEX_DECIMAL_0_100": new RegExp("^-?(?![.])(?=.+)(?:[1-9]\\d?|0)?(?:\\.\\d{1,2})?$|^-?(100)$"),
    "REGEX_INTEGER" : new RegExp("^\\d+$"),
    "REGEX_INTEGER_0_100" : new RegExp("^[0-9][0-9]?$|^100$"),
    "REGEX_INTEGER_OR_EMPTY": new RegExp("^\\s*\\d*\\s*$"),
    "REGEX_FRACTION" : new RegExp("^$|\\d+\\/\\d+$"),
    "REGEX_ROMAN_1To4" : new RegExp("^0$|^[I]{1,3}$|^IV$"),
    "REGEX_LANR" : new RegExp("^\\d{7}$"),
    "REGEX_INSURANCENO" : new RegExp("^[A-Z0-9]{10}$"),
    "REGEX_ZIP" : new RegExp("^[A-Z0-9]{5}$"),
    "REGEX_EMAIL": new RegExp("\\S+@\\S+\\.\\S+"),
    "REGEX_DECIMAL_WITH_SIGN": new RegExp("^[-+]?(\\d{1,2})(\\.\\d{1,2})?$"),
    "REGEX_DURATION_HOUR_MINUTE" : new RegExp("^([0-9][0-9]):[0-9][0-9]$")
};
