import React, {useState} from "react";
import {Form, Radio, Select} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";

const SelectInputForm = ({model, defaultValue, name, register, unregister, onChange, disabled, error, control, className}) => {
    const {t} = useTranslation();
    const [initValue] = useState(defaultValue);

    const [inputValue, setInputValue] = useState(() => {
        const hasValue = defaultValue && !model.__options.find((it) => it.value === initValue);
        return hasValue ? defaultValue : "";
    });

    const [selectedValue, setSelectedValue] = useState(() => {
        const hasValue = defaultValue && !!model.__options.find((it) => it.value === initValue);
        return hasValue ? defaultValue : "";
    });

    const [checked, setChecked] = useState(!!inputValue);

    const onInputToggle = (checked) => {
        const value = !checked ? selectedValue : inputValue;
        setChecked(checked);
        if (!checked) {
            unregister(name);
        }
        onChange(value);
    };

    const handleOnSelectChange = (value) => {
        setSelectedValue(value);
        onChange(value);
    };

    const handleOnInputChange = (value) => {
        setInputValue(value);
        onChange(value);
    };

    return (
        <>
            <div className={"pb-1of2"}>
                <Radio className={className} toggle defaultChecked={checked} disabled={disabled} label={t("user_defined")} onChange={(_, {checked}) => onInputToggle(checked)} />
            </div>

            <Form.Field error={error} disabled={disabled}>
                {checked ? (
                    <input
                        autoComplete="off"
                        className={`width-1-2 ${className}`}
                        defaultValue={inputValue}
                        name={name}
                        ref={register({
                            ...model.__v,
                            required: {
                                value: !model.__optional,
                                message: t("E_INVALID"),
                            },
                            validate: value => value.trim().length !== 0,
                        })}
                        placeholder={model.__placeholder}
                        onChange={(e) => {
                            handleOnInputChange(e.target.value);
                        }}
                        disabled ={disabled}
                    />
                ) : (
                    <Controller
                        control={control}
                        name={name}
                        rules={{ required: !model.__optional }}
                        defaultValue={selectedValue}
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                className={`width-1-2 ${className}`}
                                options={model.__options}
                                value={selectedValue}
                                onChange={(_, {value}) => {
                                    handleOnSelectChange(value);
                                    onChange(value);
                                }}
                                placeholder = {model.__placeholder}
                                name={name}
                            />
                        )}
                    />
                )}
            </Form.Field>
        </>
    );
};

export default SelectInputForm;
