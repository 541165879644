import React from "react";
import {Card, Grid, GridColumn, Header} from "semantic-ui-react";
import {get} from "lodash";
import {useTranslation} from "react-i18next";

const statusEnum = {
    ACTIVE: "patientStatus--active",
    EXCLUDED: "patientStatus--excluded",
    DATA_MISSING: "patientStatus--data-missing",
};

const Patient = ({patient, onClick}) => {
    const getField = (path) => get(patient.masterData, path);
    const {t} = useTranslation();
    //real status
    const statusClass = statusEnum[patient.status] || statusEnum.DATA_MISSING ;

    return (
        <div className={`patient-wrapper-list  cursor-pointer patientStatus ${statusClass}`} onClick={onClick}>
            <Card style={{width: "100%", minHeight: "10rem"}}>
                <Card.Content>
                    <Grid>
                        <GridColumn width={5}>
                            <Header color={"teal"}>{getField("forename") + " " + getField("surname")}</Header>
                            <b>{patient.patientId}</b>
                            <br/>
                            <b>{t("COHORT_" + get(patient, "studyData.cohort"))}</b>
                        </GridColumn>

                        <GridColumn width={5}>
                            <div>{getField("contact.address.street") + " " + getField("contact.address.streetno")}</div>
                            <div>{getField("contact.address.postalcode") + " " + getField("contact.address.city")}</div>
                            <div>{getField("insurance.company") }</div>
                            <div>{getField("insurance.number")}</div>
                        </GridColumn>
                    </Grid>
                </Card.Content>
            </Card>
        </div>
    );
};

export default Patient;
