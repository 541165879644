import React from "react";
import {Form, Popup} from "semantic-ui-react";

const FormInput = ({label, unit, obligatory, error, className, popupText, properties, disabled, patientView}) => (
    <div className={className}>
        <div className={"Grid"}>
            <div className={"note form-input-label" + (patientView ? " patient-view-font" : "")}>
                {label}
                {obligatory && <label className={"label-error"}>{obligatory}</label>}
            </div>
        </div>

        <Form.Field className={"Grid -bottom"} error={!!error} disabled={disabled}>
            {popupText &&
            <Popup
                content={popupText}
                key={label}
                position='bottom left'
                trigger={ <input className={"width-1-2" + (patientView ? " patient-view-font" : "")} {...properties} autoComplete="off" />}
            />}
            {!popupText && <input className={"width-1-2" + (patientView ? " patient-view-font" : "")} {...properties} disabled={disabled} autoComplete="off" />}
            {unit && <label className={"pl-1" + (patientView ? " patient-view-font" : "")}>{unit}</label>}
        </Form.Field>
    </div>
);

export default FormInput;
