import React, {useContext, useState} from "react";
import {Button, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import {AuthContext, formatDateTime, toastError, toastSuccess} from "../../services";
import axios from "axios";
import {MAX_FILE_SIZE} from "./NewAttachment";
import {useRemoveAttachment} from "../../hooks"

const AttachmentTable = ({model, appointmentId, isReadOnly, refresh}) => {
    const {t} = useTranslation();
    //const [attachments, setAttachments] = useState(get(model, "documentSamples", ""));
    const attachments = get(model, "documentSamples", "");
    const authContext = useContext(AuthContext);
    const [token, setToken] = useState("");
    const [open, setOpen] = useState(false);
    const [attachmentToRemove, setAttachmentToRemove] = useState(null);
    const {removeDocumentSample} = useRemoveAttachment();


    //todo: need to wrap in authContext.keycloak.updateToken(5) ...
    axios.get(
        `${process.env.REACT_APP_SERVER_URI}/api/download-token`,
        {headers: {Authorization: 'Bearer ' + authContext.keycloak.token}})
        .then(function (response) {
            if (response.status === 200) {
                setToken(response.data);
            } else {
                setToken("");
                toastError("Failed to get download-token.");
            }
        })
        .catch(function (error) {
            toastError('Failed to get download-token.');
        });

    const handleDeleteFile = (documentid) => {
        return removeDocumentSample({
            appointmentId: appointmentId,
            sampleId: documentid,
            comment: null
        }).then(value => {
            //setAttachments(get(value.data, "documentSamples", ""));
            setOpen(false);
            toastSuccess(t("remove_success"));
            setAttachmentToRemove(null);
            refresh();
        }, reason => {
            toastError(t("error_gql"));
        });
    };

    const renderReallyRemoveAttachment = () => {
        return (<Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>{t("attachment_remove_header") + (attachmentToRemove && attachmentToRemove.documentName)}</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    {t("attachment_remove_description")}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" color='red' onClick={() => setOpen(false)} content={t("cancel")}/>
                <Button
                    content={t("attachment_remove_yes")}
                    type="submit"
                    icon='checkmark'
                    onClick={() => handleDeleteFile(attachmentToRemove.id)}
                    positive
                />
            </Modal.Actions>
        </Modal>);
    };

    return (
        <div>
            <div className={"note"}>
                <label>{t("file_size_hint")}{MAX_FILE_SIZE} MB </label>
            </div>

            <br/>

            <table>
                <thead>
                <tr>
                    <td><label className={"c-header c-header--small"}>{t("date")}</label></td>
                    <td><label className={"c-header c-header--small"}>{t("filename")}</label></td>
                    <td><label className={"c-header c-header--small"}>{t("device")}</label></td>
                    <td><label className={"c-header c-header--small"}>{t("examination_type")}</label></td>
                    <td><label className={"c-header c-header--small"}>{t("eye")}</label></td>
                    <td><label className={"c-header c-header--small"}>{t("comment")}</label></td>
                    <td><label className={"c-header c-header--small"}></label></td>
                </tr>
                </thead>
                <tbody>
                {attachments && attachments?.map((attachment) => {
                    return (
                        <tr key={attachment.id}>
                            <td>{formatDateTime(attachment.startDate)}</td>
                            <td><a
                                href={`${process.env.REACT_APP_SERVER_URI}` + attachment.dataURL + "?authorization=" + token}>{attachment.documentName}</a>
                            </td>
                            <td>{attachment.metadata.deviceName}</td>
                            <td>{attachment.metadata.examinationType}</td>
                            <td>{t(attachment.metadata.eyeLocation)}</td>
                            <td>{attachment.metadata.comment}</td>
                            <td><Button type="button" basic size="tiny" color="teal" content={t("remove_label")}
                                        onClick={function(){setOpen(true); setAttachmentToRemove(attachment)}} disabled={isReadOnly}/></td>
                        </tr>);
                })}
                </tbody>
            </table>
            {renderReallyRemoveAttachment()}
        </div>
    );
};

export default AttachmentTable;
