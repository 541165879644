import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Dropdown, Form, Header, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useMutateUserProfile, useUserProfile} from "../../hooks";
import {get} from "lodash";
import {useForm} from "react-hook-form";
import {AuthContext, toastError} from "../../services";

const UserProfile = ({closeDropDown, refresh}) => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const form = useForm({mode: "all"});
    const {data, refetch} = useUserProfile();
    const {user} = useContext(AuthContext);
    const {mutateUserProfile, loadingMutation} = useMutateUserProfile();

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        if(modalOpen === true){
            form.trigger();
            closeDropDown(true);
        } else {
            closeDropDown(false);
        }
    }, [modalOpen]);
    /* eslint-enable */

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSave = (data) => {
        if (loadingMutation || !form.formState.isValid) {
            return;
        }
        return mutateUserProfile(data).then(() => {
            setModalOpen(false);
            refetch();
            refresh();
        }, () => {
            // rejection
        })
    };

    const handleOpen = () => {
        setModalOpen(true);
    };

    const getProps = (path) => {
        return {
            name: path,
            defaultValue: get(data, path),
            ref: form.register(),
        };
    };

    const onSubmitError = () => {
        toastError(t("error_fields_incorrect"));
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => handleOpen()}
               closeOnDimmerClick={false}
               closeOnEscape={false}
               open={modalOpen}
               trigger={<Dropdown.Item icon="doctor" text={t("profile")}/>}
        >
            <Header>
                {t("user_profile_header")}
            </Header>
            <Modal.Content>
                <Form id="user-profile-form" onSubmit={form.handleSubmit(handleSave, onSubmitError)}>
                    <Form.Group>
                        <Form.Field>
                            <label>{t("title")}</label>
                            <input {...getProps("profile.title")}/>
                        </Form.Field>
                        <Form.Field error={!!get(form, "errors.profile.forename")}>
                            <label>{t("first_name")}</label>
                            <input
                                name={"profile.forename"}
                                defaultValue={get(data, "profile.forename")}
                                ref={form.register({
                                    validate: (value) => !!String(value).trim(),
                                    required: true
                                })}
                            />
                        </Form.Field>
                        <Form.Field error={!!get(form, "errors.profile.surname")}>
                            <label>{t("last_name")}</label>
                            <input name={"profile.surname"}
                                   defaultValue={get(data, "profile.surname")}
                                   ref={form.register({
                                       validate: (value) => !!String(value).trim(),
                                       required: true
                                   })}
                            />
                        </Form.Field>
                    </Form.Group>
                    <div className={"pb-2 width-1-2"}>
                        <label>{t("user_LANR")}</label>
                        <Form.Input
                            value={get(user, "lanr")}
                            readOnly={true}
                            disabled
                        />
                    </div>
                    <Form.Group>
                        <Form.Field>
                            <label>{t("country")}</label>
                            <input {...getProps("profile.address.country")} width={4}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t("street")}</label>
                            <input {...getProps("profile.address.street")} width={4}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t("number")}</label>
                            <input {...getProps("profile.address.streetno")} width={2}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t("zip")}</label>
                            <input {...getProps("profile.address.postalcode")} width={2}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{t("city")}</label>
                            <input {...getProps("profile.address.city")} width={4}/>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button negative type="button" onClick={() => handleClose()}>{t("cancel")}</Button>
                <Button positive type="submit" form="user-profile-form" content={t("save")}/>
            </Modal.Actions>
        </Modal>
    );
};

export default UserProfile;
