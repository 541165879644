import {copyOverModified} from "services";
import {createCorrelation, createOptions, createQuantity, createSample, regExs} from "./utils";
import {find} from "lodash";
import {APPT_STATUS_NOT_SCHEDULED_YET} from "../mutations/appointments";

const createModelFollowUpT4 = (patient, t, category) => {
    const payload = find(patient.appointments, (it) => it.category === category) || {};

    const __deviceModel = createOptions(["","Goldmann-Applanationstonometrie", "iCarePro1", "iCarePro2", "iCareHome", "NCT"],
        {
            __placeholder: t("no_selection"),
            __optional: false,
            __description: t("tooltip_device")
        });

    const __decimalVisusOptions = createOptions(
        [
            "",
            "HBW (Handbewegungen)",
            "FZ (Fingerzählen)",
            "LSW (Lichtscheinwahrnehmungen)",
            "NL (Nulla Lux)"
        ],
        {__placeholder: t("no_selection"),
            __optional: true});

    const __deviceModelPerimetrie = createOptions([
        "",
        "DiconPerimeter",
        "Humphrey Field Analyzer bis 1994",
        "Humphrey Field Analyzer ab 1995",
        "Kowa AP-5000C",
        "Octopus 1-2-3",
        "Octopus 101",
        "Octopus 301",
        "Octopus 500 EZ, 2000R",
        "Octopus 900",
        "Perimat 206",
        "Peristat 433",
        "TAP cc",
        "TAP 2000",
        "TAP 2000ct",
        "TEC cc",
        "Twinfield"
    ], {
        __placeholder: t("no_selection"),
        __optional: false
    });

    const modelTemplate = {
        id: null,
        startDate: null,
        endDate: null,
        category: category,
        status: APPT_STATUS_NOT_SCHEDULED_YET,
        doctors: [],
        __roles:["resident", "clinician"],
        documentSamples : [],
        recommendedTherapy: {
            therapy: "",
        },
        correlationSamples: [
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "Visus",
                    correlationType: "dezimalvisus",
                    __hint: "Falls Dezimalvisus unter 0.05, bitte Metervisus ODER Unter Metervisus ausfüllen!",
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "decimalvisusRight",
                        __label: "Dezimalvisus rechts (bestkorrigierter Wert)",
                        __css: "c-column-left",
                        __placeholder: "Z.ZZ",
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal")
                    }),
                    createQuantity({
                        quantityType: "decimalvisusLeft",
                        __label: "Dezimalvisus links (bestkorrigierter Wert)",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal")
                    })
                ],
                categorySamples: [
                    createSample({
                        subject: patient.id,
                        categoryType: "meterVisusRight",
                        __label: "Metervisus rechts",
                        __css: "c-column-left",
                        __placeholder: "x/y",
                        __optional: true,
                        __description: "Bitte geben Sie einen Bruch ein. Beispiel: 1/45",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_FRACTION)|| t("E_INVALID")
                        }
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "meterVisusLeft",
                        __label: "Metervisus links",
                        __css: "c-column-right",
                        __placeholder: "x/y",
                        __optional: true,
                        __description: "Bitte geben Sie einen Bruch ein. Beispiel: 1/45",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_FRACTION) || t("E_INVALID")
                        }
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "decimalVisusOptionRight",
                        __type: "select",
                        __label: "Unter Metervisus rechts",
                        __css: "c-column-left",
                        __placeholder: "",
                        __optional: true,
                        __description: "Bitte wählen Sie eine Option aus",
                        __v: {
                            validate: (value) => typeof(value) === "string" || t("E_INVALID")
                        },
                        ...__decimalVisusOptions
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "decimalVisusOptionLeft",
                        __type: "select",
                        __label: "Unter Metervisus links",
                        __css: "c-column-right",
                        __placeholder: "",
                        __optional: true,
                        __description: "Bitte wählen Sie eine Option aus",
                        __v: {
                            validate: (value) => typeof(value) === "string" || t("E_INVALID")
                        },
                        ...__decimalVisusOptions
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "Tensiomessung",
                    __deviceModel,
                    correlationType: "tensioMeasurementManual"
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "tensioMeasurmentManualRight",
                        unit: "mmHg",
                        __label: "Tensiomessung rechts",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: false,
                        __description: t("tooltip_integer_round")
                    }),
                    createQuantity({
                        quantityType: "tensioMeasurementManualLeft",
                        unit: "mmHg",
                        __label: "Tensiomessung Links",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: false,
                        __description: t("tooltip_integer_round")
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "30° Perimetrie",
                    __deviceModel: __deviceModelPerimetrie,
                    correlationType: "30perimetry",
                    __hint: t("oct_group_hint")
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "visualFieldIndexRight",
                        unit: "%",
                        __label: "Visual Field Index (VFI) rechts",
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        __label: "Visual Field Index (VFI) links",
                        quantityType: "visualFieldIndexLeft",
                        unit: "%",
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "averageDefectDepthRight",
                        __label: "Mittlere Defekttiefe (MD) rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_decimal_with_sign"),
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "averageDefectDepthLeft",
                        __label: "Mittlere Defekttiefe (MD) links",
                        unit: "dB",
                        __css: "c-column-right",
                        __optional: true,
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal_with_sign"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "psdRight",
                        __label: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD) rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "psdLeft",
                        __label: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD) links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "sLVRight",
                        __label: "sLV rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "sLVLeft",
                        __label: "sLV links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "CsLVRight",
                        __label: "CsLV rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "CsLVLeft",
                        __label: "CsLV links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "meanSensitivityRight",
                        __label: "Mean Sensitivity rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "meanSensitivityLeft",
                        __label: "Mean Sensitivity links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                ]
            },
        ],
        interventions: patient.interventions
    };

    return copyOverModified(modelTemplate, payload);
};

export default createModelFollowUpT4;
