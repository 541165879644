import React, {useEffect, useState} from "react";
import {Button, Divider, Dropdown, Form, Header, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";
import {UNLOCK_DATABASE} from "../../data/mutations/unlockDatabase";
import {useMutation} from '@apollo/react-hooks';
import {toastError, toastSuccess, verifyGraphQlResponse} from '../../services';
import _, {get} from "lodash";
import {useForm} from "react-hook-form";

const DecryptDB = ({afterSave, closeDropDown}) => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const form = useForm();
    const [UnlockDatabase, {loading}] = useMutation(UNLOCK_DATABASE);

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        if(modalOpen === true){
            closeDropDown(true);
        } else {
            closeDropDown(false);
        }
    }, [modalOpen]);
    /* eslint-enable */


    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSave = (data) => {
        if (loading) {
            return Promise.reject("Unlocking in progress...");
        }
        const variables = data;
        return UnlockDatabase({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                console.log(response);
                const success = _.get(response, ['data', 'unlockDatabase', 'success'], false);
                const message = _.get(response, ['data', 'unlockDatabase', 'message'], "");
                if (success) {
                    console.info("Database successfully unlocked: " + message);
                    toastSuccess(t("unlock_success"));
                    setModalOpen(false);
                    afterSave();
                } else {
                    console.warn("Database unlock failed: " + message);
                    toastError(t("unlock_failed"));
                    //@todo: the messate to the dialog ...
                }
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_wrong_password"));
            });
    };

    const onSubmitError = (errors, e) => {
        toastError(t("error_wrong_password"));
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Dropdown.Item icon="unlock" text={t("profile_decrypt")}/>}
        >
            <Header>
                {t("decrypt_header")}
            </Header>
            <Modal.Content>
                <Form id={"unlock-db-form"} onSubmit={form.handleSubmit(handleSave, onSubmitError)}>
                    <div>
                        {t("decrypt_description")}
                    </div>
                    <br/>
                    <Form.Field error={!!get(form, "errors.password")} width={6}>
                        <label className={"pr-1"}>{t("decrypt_keyfield")}</label>
                        <input
                            name={"password"}
                            type={"password"}
                            ref={form.register({
                                required: true
                            })}
                        />
                    </Form.Field>
                </Form>
                <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button type='button' negative onClick={() => handleClose()}>{t("cancel")}</Button>
                <Button type='submit' positive form={"unlock-db-form"}>{t("unlock")} </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DecryptDB;
