import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/react-hooks";
import {UPDATE_ORGANISATION_PROFILE} from "./organisationProfile";
import {toastError, toastSuccess, verifyGraphQlResponse} from "../../services";

export const useMutateMedication = () => {
    const {t} = useTranslation();
    const [mutate, {loading}] = useMutation(UPDATE_ORGANISATION_PROFILE);

    const mutateOrganisation = (variables) => {
        if (loading) {
            return Promise.reject("Loading in progress...");
        }
        return mutate({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
                throw e;
            });
    };

    return {mutateOrganisation, loading};
};
