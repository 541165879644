import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../services";

const ShowMedicationHistory = ({medications}) => {
    const {t} = useTranslation();

    const renderList = () => {
        if (medications) {
            return (
                <table>
                    <thead>
                    <tr>
                        <td><label className={"c-header c-header--small"}>Startdatum</label></td>
                        <td><label className={"c-header c-header--small"}>Endddatum</label></td>
                        <td><label className={"c-header c-header--small"}>Name</label></td>
                        <td><label className={"c-header c-header--small"}>Morgens</label></td>
                        <td><label className={"c-header c-header--small"}>Mittags</label></td>
                        <td><label className={"c-header c-header--small"}>Abends</label></td>
                        <td><label className={"c-header c-header--small"}>Auge</label></td>
                        <td><label className={"c-header c-header--small"}>Kommentar</label></td>
                    </tr>
                    </thead>
                    <tbody>
                    {medications.map(med =>
                        <tr key={med.id}>
                            <td align="center" >{formatDate(med.startDate)}</td>
                            <td align="center" >{(med.endDate && formatDate(med.endDate)) || "-"}</td>
                            <td>{med.name}</td>
                            <td>{med.dosageAtMorning}</td>
                            <td>{med.dosageAtNoon}</td>
                            <td>{med.dosageAtEvening}</td>
                            <td>{t(med.eyeLocation)}</td>
                            <td>{med.comment}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            );
        }
    };

    return (
        <div>
            <Modal trigger={<Button type="button" basic size="tiny" color="teal" content={t("show_medication_history")}/>}>
                <Modal.Header>{t("medication_history")}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <ul>
                            {renderList()}
                        </ul>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default ShowMedicationHistory;
