import gql from 'graphql-tag';
import {profileFragment} from "../fragments/profileFragment";

export const UPDATE_USER_PROFILE = gql`
    mutation UpdateProfile($profile: ProfileInput!) {
        updateProfile(profile: $profile) {
            ...profileFragment
        }
    }
    ${profileFragment}
`;
