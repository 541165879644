import React, {useContext, useState, useEffect} from 'react';
import {createReaderFollowup3Appointment} from './models/readerFollowup3Appointment';
import {createReaderFollowup1Appointment} from "./models/readerFollowup1Appointment";
import {createReaderFollowup2Appointment} from "./models/readerFollowup2Appointment";
import {AuthContext, toastError} from "../../services";
import RenderReaderZoneTable from "./RenderReaderZoneTable";
import {Button, Divider, Form, Select, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import * as _ from "lodash";
import {useMutateAppointment} from "../../hooks";
import {get} from "lodash";
import {
    getCurrentTab, getNotAvailableFieldValue,
    getReaderCategorySamples,
    getReaderStatusStates, getReaderValue, getStatusFieldSection, getStatusOptions,
    getStatusStatesRequiredFields,
    isFieldDisabled, isSectionDisabled
} from "./models/utils";
import {
    CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL, CAT_FIRST,
    APPT_STATUS_TOOK_PLACE, APPT_INQUIRY, APPT_READING, APPT_READING_FINISHED, APPT_DISCREPANCY
} from "../../data/mutations/appointments";
import {createReaderInclusionAppointment} from "./models/readerInclusionAppointment";
import {createReaderFinalAppointment} from "./models/readerFinalAppointment";
import {createReaderInitialAppointment} from "./models/readerInitialAppointment";
import DateInput from "../inputs/DateInput";
import FormInput from "../inputs/FormInput";

const RenderReaderZoneFull = ({dataModel, patientId, allAppointmentsCorrelationSamples, refresh, patient, readerCComment}) => {
    const { t } = useTranslation()
    const [mutateData] = useMutateAppointment();

    const { user } = useContext(AuthContext);
    const userId = user.id;
    const role = user.roles[0];
    const categorySamples = patient?.appointments.find(x => _.get(x, "category") === getCurrentTab())?.categorySamples ?? null;
    const cohort = patient.studyData.cohort;
    const readingStatus = categorySamples?.find(x => _.get(x, "categoryType") === "readingStatus")
    const isDataEntryManager = user.roles.includes("data_entry_manager");
    const isReaderC = user.roles.includes("reader_c");

    const allAppointmentsCategorySamples = [
        {
            appointmentName: CAT_INCLUSION,
            categorySamples: patient?.appointments.find(appointment => _.get(appointment, "category") === CAT_INCLUSION)?.categorySamples ?? null
        },
        {
            appointmentName: CAT_FINAL,
            categorySamples: patient?.appointments.find(appointment => _.get(appointment, "category") === CAT_FINAL)?.categorySamples ?? null
        },
    ];

    function getLocalId (model)  {
        let idCounter = 1
        for (let i = 0; i < model.length; ++i) {
            for (let j = 0; j < model[i]?.statusField?.length; ++j) {
                if(model[i]?.statusField[j]?.dataStorageField?.id === null) {
                    model[i].statusField[j].dataStorageField.id = `local_${idCounter++}`
                }

                if (model[i].sectionField.tableStatusField) {
                    for (let j = 0; j < model[i].sectionField.tableStatusField.length; ++j) {
                        if (model[i].sectionField.tableStatusField[j].dataStorageField.id === null) {
                            model[i].sectionField.tableStatusField[j].dataStorageField.id = `local_${idCounter++}`
                        }
                    }
                }
            }
        }

        return model
    }

    const model = () => {
        if (getCurrentTab() === CAT_FIRST)
            return getLocalId(createReaderInitialAppointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort))
        else if (getCurrentTab() === CAT_INCLUSION)
            return getLocalId(createReaderInclusionAppointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort))
        else if (getCurrentTab() === CAT_FOLLOWUP_T2)
            return getLocalId(createReaderFollowup1Appointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples))
        else if (getCurrentTab() === CAT_FOLLOWUP_T3)
            return getLocalId(createReaderFollowup2Appointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples))
        else if (getCurrentTab() === CAT_FOLLOWUP_T4)
            return getLocalId(createReaderFollowup3Appointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples))
        else if (getCurrentTab() === CAT_FINAL)
            return getLocalId(createReaderFinalAppointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples))
    }

    function getTableStatusStates (model) {
        const tableStatus = []
        for (let i = 0; i < model.length; ++i) {
            if (model[i].sectionField.tableStatusField) {
                for (let j = 0; j < model[i].sectionField.tableStatusField.length; ++j) {
                    tableStatus.push(model[i].sectionField.tableStatusField[j].dataStorageField)
                }
            }
        }
        return tableStatus
    }

    function getTableStatusStatesRequiredFields (model) {
        const requiredFields = []
        for (let i = 0; i < model.length; ++i) {
            if (model[i].sectionField.tableStatusField) {
                for (let j = 0; j < model[i].sectionField.tableStatusField.length; ++j) {
                    requiredFields.push({
                        categoryType: model[i].sectionField.tableStatusField[j].dataStorageField.categoryType,
                        optional: model[i].sectionField.tableStatusField[j]?.displayField.__optional
                    })
                }
            }
        }
        return requiredFields
    }

    const getReaderCCategorySamples = (catSamples, reader) => {
        return catSamples.filter(x => {
            const value = _.get(x, "categoryType")
            // return (value.includes("Status") || value.includes("Comment")) && value.includes(reader)
            return value.includes(reader)
        })
    }

    const [statusStates, setStatusStates] = useState(getReaderStatusStates(model(), isReaderC))
    const [tableStatusStates, setTableStatusStates] = useState(getTableStatusStates(model()))
    const dataEntryManager = getReaderCCategorySamples(categorySamples, "data_entry_manager")
    const readerA = getReaderCCategorySamples(categorySamples, "reader_a")
    const readerB = getReaderCCategorySamples(categorySamples, "reader_b")
    const [ readerAOrBReqField, setReaderAOrBReqField ] = useState(false)

    /* eslint-disable */
    useEffect(() => {
        setStatusStates(getReaderStatusStates(model(), isReaderC))
    }, [patient])
    useEffect(() => {
        if (user.roles.includes("reader_a") || user.roles.includes("reader_b")) {
            setReaderAOrBReqField(checkAllRequiredFields())
        }
    }, [])
    // eslint-enable

    const getValue = (id) => {
        return statusStates.find(x => _.get(x, "id") === id)?.value
    }

    const getValueByCategoryType = (categoryType) => {
        return statusStates.find(x => _.get(x, "categoryType") === categoryType).value;
    }

    const getCategoryTypeById = (id) => {
        return statusStates.find(x => _.get(x, "id") === id)?.categoryType
    }

    const setValue = (id, value) => {
        const sample = statusStates.find(x => _.get(x, "id") === id)
        if (sample && sample?.categoryType.includes("VorliegenEinesCilioretinalenGefaesses") && value === "nein") {
            let _x, _y
            if (sample?.categoryType.includes("Right")) {
                _x = statusStates.find(x => _.get(x, "categoryType")?.includes("AnzahlDerCilioretinalenGefaesseRight"))
                _y = statusStates.find(x => _.get(x, "categoryType")?.includes("LageDerCilioretinalenGefaesseRight"))
            } else {
                _x = statusStates.find(x => _.get(x, "categoryType")?.includes("AnzahlDerCilioretinalenGefaesseLeft"))
                _y = statusStates.find(x => _.get(x, "categoryType")?.includes("LageDerCilioretinalenGefaesseLeft"))
            }
            if (_x) {
                _x.value = "0"
            }
            if (_y) {
                _y.value = "nichtVorhanden"
            }
        }
        if (sample) {
            sample.value = value
        }
    }

    const handleChange = (id, value) => {
        setValue(id, value)
        setStatusStates([...statusStates])
    }

    // const getTableStatusValue = (id) => {
    //     return tableStatusStates.find(x => _.get(x, "id") === id)?.value
    // }

    const setTableStatusValue = (id, value) => {
        const sample = tableStatusStates.find(x => _.get(x, "id") === id)
        sample.value = value
    }

    const handleTableStatusChange = (id, value) => {
        setTableStatusValue(id, value)
        setTableStatusStates([...tableStatusStates])
    }

    const getCorrespondingTimeField = (categoryType) => {
        switch (categoryType) {
            case "papillenphotoUhrzeitPapillenrandblutungRight":
                return "papillenphotoPapillenrandblutungRight"
            case "papillenphotoUhrzeitPapillenrandblutungLeft":
                return "papillenphotoPapillenrandblutungLeft"
            case "papillenphotoKerbeImRandsaumUhrzeitRight":
                return "papillenphotoKerbeImRandsaumRight"
            case "papillenphotoKerbeImRandsaumUhrzeitLeft":
                return "papillenphotoKerbeImRandsaumLeft"
            case "papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitRight":
                return "papillenphotoProgressionNeueKerbeImRandsaumRight"
            case "papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitLeft":
                return "papillenphotoProgressionNeueKerbeImRandsaumLeft"
            default:
                return null
        }
    }

    const isApplicableForTimeField = (categoryType) => {
        const timeFields = [
            "papillenphotoUhrzeitPapillenrandblutungRight",
            "papillenphotoUhrzeitPapillenrandblutungLeft",
            "papillenphotoKerbeImRandsaumUhrzeitRight",
            "papillenphotoKerbeImRandsaumUhrzeitLeft",
            "papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitRight",
            "papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitLeft"
        ]

        return timeFields.includes(categoryType)
    }

    const getErrorForInput = (field, key) => {
        const categoryType = getCategoryTypeById(key)
        if (!categoryType) {
            return false
        }
        const categoryTypePrefix = categoryType.slice(0, categoryType.indexOf("_"))

        if (isApplicableForTimeField(categoryTypePrefix)) {
            const correspondingTimeFieldCategoryType = getCorrespondingTimeField(categoryTypePrefix) + categoryType.slice(categoryType.indexOf("_"))
            const correspondingTimeFieldValue = getValueByCategoryType(correspondingTimeFieldCategoryType)
            const currentFieldValue = getValueByCategoryType(categoryType)

            if (correspondingTimeFieldValue === "ja" && currentFieldValue === "") return true;
            if (field.__optional && currentFieldValue === "") return false
        }
        return !getValue(key).match(field.__validationRegEx)
    }

    const handleSave = () => {
        if ((isDataEntryManager && checkReaderStatusCommentFields()) ||
            ((user.roles.includes("reader_a") || user.roles.includes("reader_b")) && checkReaderStatusCommentFields()) ||
            (isReaderC && checkReaderCStatusCommentFields())) {

            toastError(t("error_comment_missing"))
            return
        }

        const categorySamples = [...statusStates, ...tableStatusStates, ...getReadingStatus()]

        for (let i = 0; i < categorySamples.length; i++) {
            if (categorySamples[i].id && categorySamples[i].id.startsWith('local')) {
                categorySamples[i].id = null
            }
        }

        const toSave = {
            id: dataModel.id,
            startDate: dataModel.startDate,
            status: dataModel.status,
            category: dataModel.category,
            recommendedTherapy: get(dataModel, "recommendedTherapy"),
            categorySamples: categorySamples
        };

        return mutateData({
            appointment: toSave,
            patientId
        }).then(() => {
            refresh();
            if (user.roles.includes("reader_a") || user.roles.includes("reader_b")) {
                setReaderAOrBReqField(checkAllRequiredFields())
            }
        }, () => {
            toastError(t("error_gql"));
        });
    }

    const renderSelectOptions = (field, key, isDisabled, fieldCatType) => {
        const fieldVal = (user.roles.includes("reader_a") || user.roles.includes("reader_b")) ? getNotAvailableFieldValue(dataEntryManager, fieldCatType) : ""
        const fieldNotAvailable = fieldVal === "nichtVorhanden"

        if (fieldNotAvailable) {
            setValue(key, "nichtVorhanden")
        }

        return (
            <div className={"width-1-2"} key={`${key}_${field.__label}`}>
                {/*<label className={"pr-1"}>{field.__label}</label>*/}
                {field.__label}{!field.__optional && <span style={{color: "red"}}>{" *"}</span>}
                <br />
                <Select
                    value={getValue(key)}
                    key={key}
                    disabled={isDisabled || fieldNotAvailable}
                    error={!field.__optional && getValue(key) === ""}
                    options={fieldNotAvailable ? getStatusOptions("data_entry_manager") : field.__options}
                    onChange={(e, d) => {
                        handleChange(key, fieldNotAvailable ? "nichtVorhanden" : d.value)
                    }}
                />
            </div>
        );
    };

    const renderTextArea = (field, key, isDisabled=false) => {
        return (
            <div key={`${key}_${field.__label}`}>
                <br/>
                <label className={"pr-1"}>{field.__label}</label>
                <Form>
                    <TextArea
                        key={key}
                        value={getValue(key)}
                        rows={5}
                        disabled={isDisabled}
                        onChange={(e, d) => {
                            handleChange(key, d.value)
                        }}
                    />
                </Form>
            </div>
        );
    };

    const renderDateTime = (field, key) => {
        return (
            <div className={"width-1-2"}>
                <Form>
                    {/*<label className={"pr-1"}>{field.__label}</label>*/}
                    {field.__label}{!field.__optional && <span style={{color: "red"}}>{" *"}</span>}
                    <DateInput
                        key={key}
                        error={!field.__optional && getValue(key) === ""}
                        value={getValue(key)}
                        type={"datetime"}
                        onChange={(value) => {
                            handleChange(key, value)
                        }}/>
                </Form>
                <br/>
            </div>
        );
    };

    const renderDoubleFields = (fieldRight, fieldLeft, keyRight, keyLeft, disabled = false) => {
        return (
            <div className={"r-grid"} key={`${keyRight}_${keyLeft}`}>
                <div className={"pb-1 r-column-full r-column-left"}>
                    <br/>
                    {fieldRight.__label}{!fieldRight.__optional && <span style={{color: "red"}}>{" *"}</span>}
                </div>
                <div className={"pb-1 r-column-full r-column-middle"}>
                    <div className={"note form-input-label"}>
                        {"Rechtes Auge"} <br/>
                        {fieldRight.__type === "select" &&
                        <Select
                            key={keyRight}
                            value={getValue(keyRight)}
                            error={!fieldLeft.__optional && getValue(keyLeft) === ""}
                            options={fieldRight.__options}
                            onChange={(e, d) => {
                                handleChange(keyRight, d.value)
                            }}
                            disabled={disabled || isDataEntryManager}
                        />
                        }
                        {fieldRight.__type === "input" &&
                        <FormInput
                            key={keyRight}
                            error={getErrorForInput(fieldRight, keyRight)}
                            properties={
                                {
                                    placeholder: fieldRight.__placeholder,
                                    value: getValue(keyRight),
                                    onChange: (e) => handleChange(keyRight, e.target.value)
                                }
                            }
                            disabled={disabled || isDataEntryManager}
                        />}
                    </div>
                </div>
                <div className={"pb-1 r-column-full r-column-right"}>
                    <div className={"note form-input-label"}>
                        {"Linkes Auge"} <br/>
                        {fieldLeft.__type === "select" &&
                        <Select
                            key={keyLeft}
                            value={getValue(keyLeft)}
                            error={!fieldLeft.__optional && getValue(keyLeft) === ""}
                            options={fieldLeft.__options}
                            onChange={(e, d) => {
                                handleChange(keyLeft, d.value)
                            }}
                            disabled={disabled || isDataEntryManager}
                        />
                        }
                        {fieldLeft.__type === "input" &&
                        <FormInput
                            key={keyLeft}
                            error={getErrorForInput(fieldLeft, keyLeft)}
                            properties={
                                {
                                    placeholder: fieldLeft.__placeholder,
                                    value: getValue(keyLeft),
                                    onChange: (e) => handleChange(keyLeft, e.target.value)
                                }
                            }
                            disabled={disabled || isDataEntryManager}
                        />}
                    </div>
                </div>
            </div>
        )
    }
    
    const isDiscrepancyField = (field) => {
        return !!(field.includes("Status") || field.includes("HinweisAufGlaukomschaden") ||
            field.includes("WiederholungErbeten") || field.includes("KorrekturfaktorBeiIODMessung") ||
            field.includes("papillenphotoPapillenrandblutung") || field.includes("NichtGlaukomatoeseOptikusatrophie") ||
            field.includes("VergroesserteAtrophiezone") || field.includes("papillenphotoKerbeImRandsaumRight") ||
            field.includes("papillenphotoKerbeImRandsaumLeft") || field.includes("VorliegenEinesCilioretinalenGefaesses") ||
            field.includes("AnzahlDerCilioretinalenGefaesse") || field.includes("LageDerCilioretinalenGefaesse") ||
            field.includes("HinweisAufProgression"))
    }

    const renderReaderCDoubleFields = (fieldRight, fieldLeft, keyRight, keyLeft, labelRight, labelLeft) => {
        const readerARightVal = getReaderValue(readerA, labelRight)
        const readerALeftVal = getReaderValue(readerA, labelLeft)

        const readerBRightVal = getReaderValue(readerB, labelRight)
        const readerBLeftVal = getReaderValue(readerB, labelLeft)

        let areLeftValuesUnEqual = false, areRightValuesUnEqual = false

        if (isDiscrepancyField(labelRight))
        {
            areRightValuesUnEqual = readerARightVal !== readerBRightVal
            areLeftValuesUnEqual = readerALeftVal !== readerBLeftVal

            const readerARightValNr = parseFloat(readerARightVal)
            const readerBRightValNr = parseFloat(readerBRightVal)
            const readerALeftValNr = parseFloat(readerALeftVal)
            const readerBLeftValNr = parseFloat(readerBLeftVal)

            if (!isNaN(readerARightValNr) && !isNaN(readerBRightValNr)) {
                areRightValuesUnEqual = readerARightValNr !== readerBRightValNr
            }
            if (!isNaN(readerALeftValNr) && !isNaN(readerBLeftValNr)) {
                areLeftValuesUnEqual = readerALeftValNr !== readerBLeftValNr
            }
        }

        return (
            <div className={"r-grid"} key={`${keyRight}_${keyLeft}`}>
                <div className={"pb-1 r-column-full r-column-left"}>
                    <br/>
                    {fieldRight.__label}{!fieldRight.__optional && <span style={{color: "red"}}>{" *"}</span>}
                </div>
                <div className={"pb-1 r-column-full r-column-middle"}>
                    <div className={`note form-input-label ${areRightValuesUnEqual ? "red-text-color" : ""}`}>
                        {"Reader A - Rechtes Auge"} <br/>
                        {fieldRight.__type === "select" &&
                        <Select
                            key={keyRight}
                            value={readerARightVal}
                            disabled={true}
                            options={fieldRight.__options}
                        />
                        }
                        {fieldRight.__type === "input" &&
                        <FormInput
                            key={keyRight}
                            properties={
                                {
                                    value: readerARightVal,
                                    disabled: true
                                }
                            }
                            disabled={true}
                        />}
                    </div>

                    <div className={`note form-input-label ${areRightValuesUnEqual ? "red-text-color" : ""}`}>
                        {"Reader B - Rechtes Auge"} <br/>
                        {fieldRight.__type === "select" &&
                        <Select
                            key={keyRight}
                            value={readerBRightVal}
                            disabled={true}
                            options={fieldRight.__options}
                        />
                        }
                        {fieldRight.__type === "input" &&
                        <FormInput
                            key={keyRight}
                            properties={
                                {
                                    value: readerBRightVal,
                                    disabled: true
                                }
                            }
                            disabled={true}
                        />}
                    </div>
                </div>

                <div className={"pb-1 r-column-full r-column-right"}>
                    <div className={`note form-input-label ${areLeftValuesUnEqual ? "red-text-color" : ""}`}>
                        {"Reader A - Linkes Auge"} <br/>
                        {fieldLeft.__type === "select" &&
                        <Select
                            key={keyLeft}
                            value={readerALeftVal}
                            options={fieldLeft.__options}
                            disabled={true}
                        />
                        }
                        {fieldLeft.__type === "input" &&
                        <FormInput
                            key={keyLeft}
                            error={getErrorForInput(fieldLeft, keyLeft)}
                            properties={
                                {
                                    value: readerALeftVal,
                                    disabled: true
                                }
                            }
                            disabled={true}
                        />}
                    </div>
                    <div className={`note form-input-label ${areLeftValuesUnEqual ? "red-text-color" : ""}`}>
                        {"Reader B - Linkes Auge"} <br/>
                        {fieldLeft.__type === "select" &&
                        <Select
                            key={keyLeft}
                            value={readerBLeftVal}
                            options={fieldLeft.__options}
                            disabled={true}
                        />
                        }
                        {fieldLeft.__type === "input" &&
                        <FormInput
                            key={keyLeft}
                            error={getErrorForInput(fieldLeft, keyLeft)}
                            properties={
                                {
                                    value: readerBLeftVal,
                                    disabled: true
                                }
                            }
                            disabled={true}
                        />}
                    </div>
                </div>
            </div>
        )
    }

    const renderField = (field, key, isDisabled= false, fieldCatType) => {
        if (field.__type === "select") {
            return renderSelectOptions(field, key, isDisabled, fieldCatType);
        } else if (field.__type === "textarea") {
            return renderTextArea(field, key, isDisabled);
        } else if (field.__type === "datetime") {
            return renderDateTime(field, key);
        }
    }

    const renderReaderCFields = (field, key, label) => {
        const readerAVal = getReaderValue(readerA, label)
        const readerBVal = getReaderValue(readerB, label)

        return (
            <div className={"half-grid"}>
                <div className={"pb-1 r-column-full r-column-left"}>
                    {field?.__type === "select" &&
                    <div align="center">
                        <div className={"readerGroupSeparator"}>{"Reader A"}</div>
                    </div>}
                    <div className={`${(field?.__type === "select" && readerAVal !== readerBVal) ? "red-text-color" : ""}`}>
                        <label className={"pr-1"}>{field.__label}</label>
                        <br/>
                        {field?.__type === "select" ?
                            <Select
                                value={readerAVal}
                                key={key}
                                disabled={true}
                                className={`${(field?.__type === "select" && readerAVal !== readerBVal) ? "red-text-color" : ""}`}
                                options={field.__options}
                            /> :
                            <TextArea
                                key={key}
                                value={readerAVal}
                                rows={5}
                                className={"center-all textareaResizeNone"}
                                disabled={true}
                            />
                        }
                    </div>
                </div>
                <div className={"pb-1 r-column-full r-column-middle"}>
                    {field?.__type === "select" &&
                    <div align="center">
                        <div className={"readerGroupSeparator"}>{"Reader B"}</div>
                    </div>}
                    <div className={`${(field?.__type === "select" && readerAVal !== readerBVal) ? "red-text-color" : ""}`}>
                        <label className={`pr-1`}>{field.__label}</label>
                        <br/>
                        {field?.__type === "select" ?
                            <Select
                                value={readerBVal}
                                key={key}
                                disabled={true}
                                className={`${(field?.__type === "select" && readerAVal !== readerBVal) ? "red-text-color" : ""}`}
                                options={field.__options}
                            /> :
                            <TextArea
                                key={key}
                                value={readerBVal}
                                rows={5}
                                className={"center-all textareaResizeNone"}
                                disabled={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderStatusField = (statusField) => {
        const displayItems = []
        const isSectionForReadersDisabled = isSectionDisabled(user, dataEntryManager, statusField)

        for (let i = 0; i < statusField.length; ++i) {
            if (statusField[i]?.displayField?.__css === "") {
                if (role === "reader_c") {
                    displayItems.push(renderReaderCFields(statusField[i]?.displayField, statusField[i]?.dataStorageField?.id,
                        statusField[i]?.dataStorageField?.categoryType.split("_")[0]))
                } else {
                    displayItems.push(renderField(statusField[i]?.displayField, statusField[i]?.dataStorageField?.id,
                        isFieldDisabled(role, readingStatus, readerAOrBReqField) || isSectionForReadersDisabled, statusField[i]?.dataStorageField))
                }
            } else if (statusField[i]?.displayField?.__css === "right" || statusField[i]?.displayField?.__css === "left") { //TODO && !isDataEntryManager) {
                if(statusField[i]?.displayField?.__css === "right") {
                    if (role === "reader_c") {
                        displayItems.push(renderReaderCDoubleFields(statusField[i]?.displayField, statusField[i+1]?.displayField,
                            statusField[i]?.dataStorageField?.id, statusField[i+1]?.dataStorageField?.id,
                            statusField[i]?.dataStorageField?.categoryType.split("_")[0], statusField[i+1]?.dataStorageField?.categoryType.split("_")[0]))
                    } else {
                        displayItems.push(renderDoubleFields(statusField[i]?.displayField, statusField[i+1]?.displayField,
                            statusField[i]?.dataStorageField?.id, statusField[i+1]?.dataStorageField?.id,
                            isFieldDisabled(role, readingStatus, readerAOrBReqField) || isSectionForReadersDisabled))
                    }
                }
            }
        }
        return displayItems
    }

    const checkReaderStatusCommentFields = () => {
        const inCompleteStatus = ["incorrect", "nichtKorrekt"]
        for (let i = 0; i < statusStates.length; ++i) {
            if(statusStates[i].categoryType.includes("Status") && inCompleteStatus.includes(statusStates[i].value)) {
                const fieldName = statusStates[i].categoryType.split("Status")[0] + "Comment"
                const commentField = statusStates.find((item) => item.categoryType.includes(fieldName))

                if (!commentField.value.trim()) {
                    return true
                }
            }
        }
        return false
    }

    const checkReaderCStatusCommentFields = () => {
        for (let i = 0; i < statusStates.length; ++i) {
            if(statusStates[i].categoryType.includes("readerCStatus") && statusStates[i].value === "korrektur") {
                const fieldName = statusStates[i].categoryType.split("Status")[0] + "Comment"
                const commentField = statusStates.find((item) => item.categoryType.includes(fieldName))
                if (!commentField.value.trim()) {
                    return true
                }
            }
        }
        return false
    }

    const notAvailableAcceptedFields = (field) => {
        const notAvailableFields = ["dezimalvisusStatus", "pachymteryStatus", "gonioscopyStatus", "hrtStatus",
            "papillenphotoStatus", "bmoMrwStatus", "rfnlOct35Status", "rfnlOct41Status", "rfnlOct47Status"]

        return field?.categoryType.includes(notAvailableFields[0]) || field?.categoryType.includes(notAvailableFields[1]) ||
            field?.categoryType.includes(notAvailableFields[2]) || field?.categoryType.includes(notAvailableFields[3]) ||
            field?.categoryType.includes(notAvailableFields[4]) || field?.categoryType.includes(notAvailableFields[5]) ||
            field?.categoryType.includes(notAvailableFields[6]) || field?.categoryType.includes(notAvailableFields[7]) ||
            field?.categoryType.includes(notAvailableFields[8])
    }

    const checkAllRequiredFields = () => {
        const completeStatus = ["complete", "korrekt"]
        const requiredFields = getStatusStatesRequiredFields(model(), isReaderC, user, dataEntryManager)

        for (let i = 0; i < statusStates.length; ++i) {
            if (statusStates[i].categoryType === requiredFields[i].categoryType) {

                const fieldVal = getStatusFieldSection(dataEntryManager, statusStates[i])

                if ((!requiredFields[i].optional && (!statusStates[i].value || (statusStates[i].categoryType.includes("Status")
                    && statusStates[i].categoryType.includes("data_entry_manager") && !completeStatus.includes(statusStates[i].value.trim()))))
                     || (fieldVal !== "nichtVorhanden" && isDiscrepancyField(statusStates[i].categoryType) && !statusStates[i].value)) {

                    return false
                }
            }
        }
        return true
    }

    const checkAllTableStatusStatesRequiredFields = () => {
        const acceptedStatuses = ["complete", "korrekt"]
        const acceptedStatuses2 = ["complete", "korrekt", "nichtVorhanden"]
        const statusStatesRequiredFields = getStatusStatesRequiredFields(model(), isReaderC, user, dataEntryManager)
        const tableRequiredFields = getTableStatusStatesRequiredFields(model())

        for (let i = 0; i < tableStatusStates.length; ++i) {
            if (tableStatusStates[i].categoryType === tableRequiredFields[i].categoryType) {
                if (!tableRequiredFields[i].optional && !tableStatusStates[i].value) {
                    return false
                }
            }
        }

        for (let i = 0; i < statusStates.length; ++i) {
            if (statusStates[i].categoryType === statusStatesRequiredFields[i].categoryType) {
                if (!statusStatesRequiredFields[i].optional && statusStates[i].categoryType.includes("Status") &&
                    statusStates[i].categoryType.includes("data_entry_manager")) {

                    const notAvaField = notAvailableAcceptedFields(statusStates[i])
                    if(!(notAvaField ? acceptedStatuses2.includes(statusStates[i].value.trim()) : acceptedStatuses.includes(statusStates[i].value.trim()))) {
                        return false
                    }
                }
            }
        }
        return true
    }

    const didAppointmentTookPlace = () => {
        return dataModel?.status === APPT_STATUS_TOOK_PLACE
    }

    const getRStatus = (value) => {
        return [{
            "id": readingStatus ? readingStatus?.id : null,
            "categoryType": "readingStatus",
            "startDate": readingStatus ? readingStatus.startDate : (new Date()).toISOString(),
            "endDate": (new Date()).toISOString(),
            "sampleType": "category",
            "value": value
        }]
    }

    const getDEMStatus = () => {
        if (checkAllTableStatusStatesRequiredFields()) {
            return getRStatus(APPT_READING)
        }
        return getRStatus(APPT_INQUIRY)
    }

    const getDEMReadingStatus = () => {
        if (didAppointmentTookPlace()) {
            if (readingStatus) {
                if (readingStatus.value === "" || readingStatus.value === APPT_INQUIRY) {
                    return getDEMStatus()
                }
                return [readingStatus]
            }
            return getDEMStatus()
        }
        return getRStatus("")
    }

    const getReaderReadingStatus = () => {
        if (readingStatus) {
            const reader = getReaderCategorySamples(categorySamples, role === "reader_a" ? "reader_b" : "reader_a")
            let isPlausible = "finished"
            const completeStatus = ["complete", "korrekt"]
            const requiredFields = getStatusStatesRequiredFields(model(), isReaderC, user, dataEntryManager)

            if (reader.length > 0 && readingStatus.value === APPT_READING && checkAllRequiredFields()) {
                reader.forEach((item, index, array) => {
                    const itemCatType = `${item.categoryType.split("_")[0]}_${userId}_${role}`
                    if (requiredFields.findIndex(_val => _val?.categoryType === itemCatType) < -0) { return }

                    const otherItem = statusStates.find((elem) => elem.categoryType === itemCatType)

                    if (item && item?.value && otherItem && otherItem?.value && item?.categoryType.includes("Status")) {
                        if (!item.categoryType.includes("abschlissendeBewertung") &&
                            getStatusFieldSection(dataEntryManager, item) !== "nichtVorhanden" &&
                            (!completeStatus.includes(item.value.trim()) || !completeStatus.includes(otherItem?.value.trim()))) {

                            isPlausible = "discrepancy"
                            array.length = index + 1
                        }
                    } else if (item && item?.value && otherItem && otherItem?.value && !item?.categoryType.includes("Status")) {
                        if (item?.value !== otherItem?.value) {

                            isPlausible = "discrepancy"
                            array.length = index + 1
                        }
                    } else if (getStatusFieldSection(dataEntryManager, item) === "nichtVorhanden" && !item?.categoryType.includes("Status")) {

                    } else {
                        isPlausible = "old"
                        array.length = index + 1
                    }
                })
                switch (isPlausible) {
                    case "finished":
                        return getRStatus(APPT_READING_FINISHED)
                    case "discrepancy":
                        return getRStatus(APPT_DISCREPANCY)
                    default:
                        return [readingStatus]
                }
            }
            return [readingStatus]
        }
        return getRStatus("")
    }

    const restoreValues = (readerX) => {
        readerX.forEach((item) => {
            item.value = ""
        })
        return readerX
    }

    const getReaderCReadingStatus = () => {
        if (readingStatus) {
            if (readingStatus.value === APPT_DISCREPANCY) {
                if (statusStates.find((item) => item.categoryType.startsWith("readerCStatus")).value === "korrektur") {
                    return [...getRStatus(APPT_INQUIRY), ...restoreValues(readerA), ...restoreValues(readerB)]
                } else {
                    return getRStatus(APPT_READING_FINISHED)
                }
            }
            return [readingStatus]
        }
        return getRStatus("")
    }

    const getReadingStatus = () => {
        if (readingStatus && readingStatus.value === APPT_READING_FINISHED) {
            return [readingStatus]
        }
        switch (role) {
            case "data_entry_manager":
                return getDEMReadingStatus()
            case "reader_a":
            case "reader_b":
                return getReaderReadingStatus()
            case "reader_c":
                return getReaderCReadingStatus()
            default:
                return getRStatus("")
        }
    }

    const renderReaderCReadingStatus = (statusField) => {
        const fieldStatus = statusField[0]?.displayField
        const keyStatus = statusField[0]?.dataStorageField?.id
        const fieldComment = statusField[1]?.displayField
        const keyComment = statusField[1]?.dataStorageField?.id

        return <>
            <div className={"width-1-2"} key={`${keyStatus}_${fieldStatus.__label}`}>
                {t("correction_or_not")}<span style={{color: "red"}}>{" *"}</span>
                <br />
                <Select
                    value={getValue(keyStatus)}
                    key={keyStatus}
                    error={!fieldStatus.__optional && getValue(keyStatus) === ""}
                    options={fieldStatus.__options}
                    onChange={(e, d) => {
                        handleChange(keyStatus, d.value)
                    }}
                />
            </div>
            <div key={`${keyComment}_${fieldComment.__label}`}>
                <br/>
                <label className={"pr-1"}>{fieldComment.__label}</label>
                <Form>
                    <TextArea
                        key={keyComment}
                        value={getValue(keyComment)}
                        rows={5}
                        disabled={getValue(keyStatus) !== "korrektur"}
                        onChange={(e, d) => {
                            handleChange(keyComment, d.value)
                        }}
                    />
                </Form>
            </div>
            <Divider /><br/>
        </>
    }

    const renderReaderCComment = (label, value, key) => {
        return (
            <div key={`${key}_${label}`}>
                <Divider/>
                <label className={"pr-1"}><b>{label}</b></label>
                <TextArea
                    key={key}
                    value={value}
                    rows={5}
                    disabled={true}
                />
                <Divider/>
            </div>
        );
    };

    return (
        <div>
            {(readerCComment && isDataEntryManager && readingStatus && [APPT_DISCREPANCY, APPT_INQUIRY]?.includes(readingStatus?.value)) ?
                renderReaderCComment(t("dem_contact_study_nurse"), readerCComment, "dem_contact_study_nurse") : null}

            {
                model().map((x, index) => {
                    return (
                        x.sectionField.section === "ReaderC" ?
                            role && role === "reader_c" && readingStatus && readingStatus.value === APPT_DISCREPANCY && renderReaderCReadingStatus(x.statusField) :
                        <div key={index}>
                            <div align="center">
                                <div className={"groupSeparator"}>{x.sectionField.section}</div>
                            </div>
                            {x.sectionField.device &&
                            <div>
                                <br/>
                                {`Device used: ${x.sectionField.device.filter(i => {return i.appointmentName === getCurrentTab()})[0].deviceName}`}
                                <br/>
                            </div>
                            }
                            <RenderReaderZoneTable tableData={x.sectionField} handleTableStatusChange={handleTableStatusChange}
                                                   values={allAppointmentsCategorySamples} readingStatus={readingStatus} />
                            <br />
                            {renderStatusField(x.statusField)}
                            <br />
                        </div>
                    );
                })
            }

            <Divider />

            <div className={"Grid -right"}>
                <Button basic type="submit" size="tiny" color="teal" content={t("save")} onClick={handleSave}/>
            </div>
        </div>
    );
};

export default RenderReaderZoneFull;
