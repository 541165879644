import cogoToast from "cogo-toast";

const options = {
    position: "top-right",
    hideAfter: 2,
    bar: {size: "0px"},
};

export const toastSuccess = (text) => cogoToast.success(text, options);
export const toastError = (text) => cogoToast.error(text, options);
