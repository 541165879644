import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getCategoryValue, getDeviceName, getValue} from "./util";
import {formatDateTime} from "../../services";


export async function clinicalTTPExaminationControlSummary(masterData, correlationSamples, appointmentDate) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Clinical_TTP_Examination_Control_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Stationäres TTP - Untersuchung", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const pages = pdfDocWithHeading.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];

    // 24h Blutdruckmessungen
    firstPage.drawText(getDeviceName(correlationSamples, "24hrrControlGroup"), {x: 127, y: 605, size: 10, font: courierFont});
    firstPage.drawText(formatDateTime(getCategoryValue(correlationSamples, "startdate24hrr")), { x: 390, y: 573, size: 10, font: courierFont });
    firstPage.drawText(formatDateTime(getCategoryValue(correlationSamples, "enddate24hrr")), { x: 390, y: 557, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "duration24hrr"), { x: 410, y: 541, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "successfulMeasurementsNumber"), { x: 420, y: 525, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "successfulMeasurementsPercentage"), { x: 420, y: 509, size: 10, font: courierFont });

    // Gesamtübersicht
    firstPage.drawText(getValue(correlationSamples, "systoleAverageOverall"), { x: 420, y: 423, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "systoleStdDeviationOverall"), { x: 420, y: 407, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "systoleMinimumOverall"), { x: 420, y: 391, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "systoleMaximumOverall"), { x: 420, y: 375, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "systoleDippingOverall"), { x: 420, y: 359, size: 10, font: courierFont });

    firstPage.drawText(getValue(correlationSamples, "diastoleAverageOverall"), { x: 420, y: 343, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "diastoleStdDeviationOverall"), { x: 420, y: 327, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "diastoleMinimumOverall"), { x: 420, y: 311, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "diastoleMaximumOverall"), { x: 420, y: 295, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "diastoleDippingOverall"), { x: 420, y: 279, size: 10, font: courierFont });

    firstPage.drawText(getValue(correlationSamples, "madAverageOverall"), { x: 420, y: 263, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "madStdDeviationOverall"), { x: 420, y: 247, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "madMinimumOverall"), { x: 420, y: 231, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "madMaximumOverall"), { x: 420, y: 215, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "madDippingOverall"), { x: 420, y: 199, size: 10, font: courierFont });

    firstPage.drawText(getValue(correlationSamples, "pulsePressureAverageOverall"), { x: 420, y: 183, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "pulsePressureStdDeviationOverall"), { x: 420, y: 165, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "pulsePressureMinimumOverall"), { x: 420, y: 149, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "pulsePressureMaximumOverall"), { x: 420, y: 133, size: 10, font: courierFont });

    firstPage.drawText(getValue(correlationSamples, "hfAverageOverall"), { x: 420, y: 117, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfStdDeviationOverall"), { x: 420, y: 804, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfMinimumOverall"), { x: 420, y: 788, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfMaximumOverall"), { x: 420, y: 772, size: 10, font: courierFont });

    // Wachphase
    secondPage.drawText(getValue(correlationSamples, "systoleAverageAwake"), { x: 420, y: 686, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleStdDeviationAwake"), { x: 420, y: 670, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleMinimumAwake"), { x: 420, y: 654, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleMaximumAwake"), { x: 420, y: 638, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "diastoleAverageAwake"), { x: 420, y: 622, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleStdDeviationAwake"), { x: 420, y: 606, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleMinimumAwake"), { x: 420, y: 590, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleMaximumAwake"), { x: 420, y: 574, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "madAverageAwake"), { x: 420, y: 558, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madStdDeviationAwake"), { x: 420, y: 542, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madMinimumAwake"), { x: 420, y: 526, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madMaximumAwake"), { x: 420, y: 510, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "pulsePressureAverageAwake"), { x: 420, y: 494, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "pulsePressureStdDeviationAwake"), { x: 420, y: 478, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "pulsePressureMinimumAwake"), { x: 420, y: 462, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "pulsePressureMaximumAwake"), { x: 420, y: 446, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "hfAverageAwake"), { x: 420, y: 430, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfStdDeviationAwake"), { x: 420, y: 414, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfMinimumAwake"), { x: 420, y: 398, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "hfMaximumAwake"), { x: 420, y: 382, size: 10, font: courierFont });

    // Schlafphase
    secondPage.drawText(getValue(correlationSamples, "systoleAverageAsleep"), { x: 420, y: 295, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleStdDeviationAsleep"), { x: 420, y: 279, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleMinimumAsleep"), { x: 420, y: 263, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "systoleMaximumAsleep"), { x: 420, y: 247, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "diastoleAverageAsleep"), { x: 420, y: 231, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleStdDeviationAsleep"), { x: 420, y: 215, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleMinimumAsleep"), { x: 420, y: 199, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "diastoleMaximumAsleep"), { x: 420, y: 183, size: 10, font: courierFont });

    secondPage.drawText(getValue(correlationSamples, "madAverageAsleep"), { x: 420, y: 167, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madStdDeviationAsleep"), { x: 420, y: 151, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madMinimumAsleep"), { x: 420, y: 135, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "madMaximumAsleep"), { x: 420, y: 119, size: 10, font: courierFont });

    thirdPage.drawText(getValue(correlationSamples, "pulsePressureAverageAsleep"), { x: 420, y: 804, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "pulsePressureStdDeviationAsleep"), { x: 420, y: 788, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "pulsePressureMinimumAsleep"), { x: 420, y: 772, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "pulsePressureMaximumAsleep"), { x: 420, y: 756, size: 10, font: courierFont });

    thirdPage.drawText(getValue(correlationSamples, "hfAverageAsleep"), { x: 420, y: 740, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "hfStdDeviationAsleep"), { x: 420, y: 724, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "hfMinimumAsleep"), { x: 420, y: 708, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "hfMaximumAsleep"), { x: 420, y: 692, size: 10, font: courierFont });

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Stationäres_TTP_Examinations_Summary", "application/pdf");
}
