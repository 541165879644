import gql from "graphql-tag";
import {patientFragment} from "../fragments/patientFragment";
import {medicationFragment} from "../fragments/medicationFragment";
import {hospitalFragment} from "../fragments/hospitalFragment";
import {studyDataFragment} from "../fragments/studyDataFragment";
import {cohortCountFragment} from "../fragments/cohortCountFragment";
import {profileFragment} from "../fragments/profileFragment";
import {organisationFragment} from "../fragments/organisationFragment";
import {doctorFragment} from "../fragments/doctorFragment";
import {patientSelfFragment} from "../fragments/patientSelfFragment";

export const PATIENTS = gql`
    query Patients($filter: String, $offset: Int, $limit: Int) {
        patients(filter: $filter, offset: $offset, limit: $limit) {
            data {
                ...patientFragment
            }
            total
            offset
            limit
        }
    }
    ${patientFragment}
`;

export const PATIENT = gql`
    query Patient($id: ID!) {
        patientByID(id: $id) {
            ...patientFragment
        }
    }
    ${patientFragment}
`;

export const PATIENT_SELF = gql`
    query PatientSelf {
        patientSelf {
            ...patientSelfFragment
        }
    }
    ${patientSelfFragment}
`;

export const MEDICATIONS = gql`
    query Patient($id: ID!) {
        patientByID(id: $id) {
            medications {
                ...medicationFragment
            }
        }
    }${medicationFragment}
`;

export const MEDICATIONS_PATIENT_SELF = gql`
    query PatientSelf {
        patientSelf {
            medications {
                ...medicationFragment
            }
        }
    }${medicationFragment}
`;

export const HOSPITALS = gql`
    query Hospitals {
        hospitals {
            ...hospitalFragment
        }
    }${hospitalFragment}
`;

export const STUDY_DATA = gql`
    query StudyData($id: ID!) {
        patientByID(id: $id) {
            studyData {
                ...studyDataFragment
            }
        }
    }${studyDataFragment}
`;

export const STATS = gql`
    query Stats {
        stats {
            security {
                databaseUnlocked
            }
            study {
                patients {
                    active {
                        ...cohortCountFragment
                    }
                    dataMissing {
                        ...cohortCountFragment
                    }
                    excluded {
                        ...cohortCountFragment
                    }
                    recruitable {
                        ...cohortCountFragment
                    }
                }
            }
        }
    }${cohortCountFragment}
`;

export const USER_PROFILE = gql`
    query Profile {
        profile {
            ...profileFragment
        }
    }${profileFragment}
`;

export const ORGANISATION_PROFILE = gql`
    query Organisation {
        organisation {
            ...organisationFragment
        }
    }
    ${organisationFragment}
`;

export const DOCTOR_SEARCH = gql`
    query DoctorSearch($searchString : String!) {
        doctorSearch(searchString: $searchString) {
            ...doctorFragment
        }
    }
    ${doctorFragment}
`;