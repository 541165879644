import React, {useContext} from "react";
import DownloadsResident from "./DownloadsResident";
import DownloadsHospital from "./DownloadsHospital";
import Disclaimer from "./Disclaimer";
import Support from "./Support";
import {Icon} from "semantic-ui-react";
import _ from "lodash";
import {AuthContext, hasPermission} from "../../services";
import DownloadsPatient from "./DownloadsPatient";

const PageFooter = ({stats, loading, userRoles}) => {
    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    if (loading) {
        return (<div/>);
    }

    const patientsStats = _.get(stats, ['stats', 'study', 'patients'], null);

    const recruitedPatients = _.get(patientsStats, ['active', 'total'], 0) +
                              _.get(patientsStats, ['dataMissing', 'total'], 0) +
                              _.get(patientsStats, ['excluded', 'total'], 0);

    const maxPatients = recruitedPatients + _.get(patientsStats, ['recruitable', 'total'], 0)

    const excluded = _.get(patientsStats, ['excluded', 'total'], 0);

    const lockIcon = _.get(stats, ['stats', 'security', 'databaseUnlocked'], false) ? "lock open" : "lock";

    return (
    <div>
        <div className="Grid page-footer elevated  padding-normal">
            <div className="Cell -left -2of12  Grid">
                {!isPatient &&
                    <div>
                        <Icon name={"user"}/>
                        {recruitedPatients} / {maxPatients}
                        <br/>
                        (davon ausgeschlossen: {excluded})
                    </div>
                }
            </div>
            <div className="Cell -center -9of12  Grid">
                {hasPermission(userRoles,["resident"]) && !isPatient && <DownloadsResident/>}
                {hasPermission(userRoles,["clinician"]) && !isPatient && <DownloadsHospital/>}
                {isPatient && <DownloadsPatient />}
                <Disclaimer/>
                <Support/>
            </div>
            <div className="Cell -right -1of12  Grid" style={{flexDirection: "column", alignItems: "flex-end"}}>
                {!isPatient && <Icon className="-right" name={lockIcon}/>}
                <span style={{float: "right", fontSize: "x-small", paddingTop:"1ex"}}>
                    {`${process.env.REACT_APP_VERSION}`}
                </span>
            </div>
        </div>
    </div>
    );
};

export default PageFooter;
