import {CAT_FIRST, CAT_INTERVENTION} from "../../../data/mutations/appointments";
import {copyDataToModel} from "./utils";
import * as _ from "./allSections";


export const createReaderInitialAppointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort) => {

    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createDezimalvisusSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createTensiomessungSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createRefraktionSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        ...cohort === CAT_INTERVENTION ? [_.createIndividuellerZieldruckSection(allAppointmentsCorrelationSamples, CAT_FIRST, userId, role, patientId)] : []
    ];

    return copyDataToModel(model, categorySamples);
}