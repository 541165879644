import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getValue, getCategoryValue, getDeviceName} from "./util" ;


export async function followup3Summary(masterData, correlationSamples, appointmentDate) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Kontrolluntersuchung_3_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Kontrolluntersuchung 3", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const pages = pdfDocWithHeading.getPages();
    const firstPage = pages[0];

    // Visus
    firstPage.drawText(getValue(correlationSamples, "decimalvisusRight"), { x: 420, y: 573, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "decimalvisusLeft"), { x: 420, y: 557, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusRight"), { x: 420, y: 541, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusLeft"), { x: 420, y: 525, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionRight"), { x: 350, y: 509, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionLeft"), { x: 350, y: 493, size: 10, font: courierFont });

    // Tensiomessung
    firstPage.drawText(getDeviceName(correlationSamples, "tensioMeasurementManual"), {x: 120, y: 439, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurmentManualRight"), { x: 420, y: 407, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurementManualLeft"), { x: 420, y: 391, size: 10, font: courierFont });

    // 30 degree perimetrie
    firstPage.drawText(getDeviceName(correlationSamples, "30perimetry"), {x: 120, y: 337, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "visualFieldIndexRight"), { x: 420, y: 305, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "visualFieldIndexLeft"), { x: 420, y: 289, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "averageDefectDepthRight"), { x: 420, y: 273, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "averageDefectDepthLeft"), { x: 420, y: 257, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "psdRight"), { x: 420, y: 236, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "psdLeft"), { x: 420, y: 205, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "sLVRight"), { x: 420, y: 179, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "sLVLeft"), { x: 420, y: 163, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "CsLVRight"), { x: 420, y: 147, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "CsLVLeft"), { x: 420, y: 132, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "meanSensitivityRight"), { x: 420, y: 115, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "meanSensitivityLeft"), { x: 420, y: 99, size: 10, font: courierFont });

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Kontrolluntersuchung_3_Summary", "application/pdf");
}