import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Dimmer, Divider, Form, Loader, Menu, Modal, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {AuthContext, hasPermission, stringToBoolean, toastError, toastSuccess, user_roles} from "../../services";
import InitiateAppointment from "../appointment/InitiateAppointment";
import AttachmentTable from "../appointment/RenderAttachmentTable";
import NewAttachment, {MAX_FILE_SIZE} from "../appointment/NewAttachment";
import {
    APPT_STATUS_TOOK_PLACE, CAT_TENSIO, CAT_CONTROL, CAT_FINAL,
    CAT_FOLLOWUP_T4, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3
} from "../../data/mutations/appointments";
import ExaminationTab from "../appointment/ExaminationTab";
import _, {get} from 'lodash';
import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";
import KonsilKlinikTab from "../appointment/KonsilKlinikTab";
import {useMutateAppointment, useSaveAppointment} from "../../hooks";
import axios from "axios";
import DateInput from "../inputs/DateInput";
import ChartTensio from "../appointment/RenderTensioChart";
import RenderTensioTableControl from "../appointment/RenderTensioTableControl";
import RenderTensioTable from "../appointment/RenderTensioTable";
import Chart from "../appointment/RenderRRChart";
import RenderRRTable from "../appointment/RenderRRTable";
import {clinicalTTPTensioProfileControl} from "../patientSummary/ClinicalTTPTensioProfileControl";
import {ambulantTTPTensioProfileIntervention} from "../patientSummary/AmbulantTTPTensioProfileIntervention";
import {ambulantTTP24hRRIntervention} from "../patientSummary/AmbulantTTP24hRRInterventionSummary";
import DataEntryTab from "../reader/DataEntryTab"
import RenderReaderZoneTensio from "../reader/RenderReaderZoneTensio"
import {
    checkReadingStatus, checkRequiredFields, getCurrentTab, getPreviousAppointmentModel, getReaderCComment
} from "../reader/models/utils"

const MIN_DATE_TIME = "1900-01-01T00:00:00.000Z";

const AptTabTensioAndChart = ({ label, isExcluded, isControlGroup, patientId, dataModel, handleAfterSave, patStatus,
                                  inclusionDate, refresh, patient, allAppointmentsCorrelationSamples,
                                  isReaderTabDisabled = false}, ref) => {

    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");
    const isReadOnly = !hasPermission(user.roles, dataModel.__roles) || patStatus === PAT_STATUS_DATA_EXCLUDED || checkReadingStatus(patient, isControlGroup, dataModel)
    const isReadOnlyStudyLead = user.roles.includes("study_lead") ? (patStatus === PAT_STATUS_DATA_EXCLUDED || !hasPermission(["clinician"], dataModel.__roles)) : isReadOnly;
    const {t} = useTranslation();
    const [examinationTabDisabled, setExaminationTabDisabled] = useState(() => {
        return !(dataModel.startDate && dataModel.status === APPT_STATUS_TOOK_PLACE);
    });
    const currentTab = useRef();
    const [activeTab, setActiveTab] = useState(() => {
        if (dataModel.startDate === null) {
            return 0;
        } else {
            const appointmentActiveIndexInTab = window.sessionStorage.getItem("appointmentActiveIndexInTab");
            return appointmentActiveIndexInTab || 0;
        }
    });
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [nextTab, setNextTab] = useState(0);
    const isReader = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c");
    const isDataEntryManager = user.roles.includes("data_entry_manager");
    const readerCComment = (user.roles.includes("clinician") || isDataEntryManager) ? getReaderCComment(patient, getCurrentTab(isControlGroup)) : null

    const tensioChartRef = useRef(null);
    const bloodPressureChartRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getName () {
            return dataModel.category;
        },
        modelHasChanged () {
            if(checkRef(currentTab)){
                return currentTab.current.hasChanged();
            } else {
                return false;
            }
        },
        cleanLocal () {
            sessionStorage.removeItem(dataModel.category);
        }
    }));

    const checkRef = (ref) => {
        return ref && ref.current;
    };

    const appointmentId = _.get(dataModel, ['id'], null);
    const appointmentExists = _.get(dataModel, ['id'], null) !== null;
    const filesToolTipText =  appointmentExists ? null : t("save_appointment_to_upload_attachments");

    const [checkTime,] = useState((new Date()).getTime());
    const [uploading, setUploading] = useState(false);
    const [appointmentModel, setAppointmentModel] = useState(dataModel);
    const [, mutateSyncWithICare, , , , syncing] = useMutateAppointment(dataModel);
    const {syncing: isAppointmentLoading} = useSaveAppointment();
    const tensioCorrelationSamples = (get(appointmentModel, "correlationSamples", []).filter(sample => {
        return ((sample.correlationType==="tensioMeasurement") || (sample.correlationType === "tensioControlImport"))
            && (new Date(sample.startDate).toISOString() !== new Date(MIN_DATE_TIME).toISOString())
    })).sort((a,b) => a.startDate > b.startDate? 1 : -1) || [];
    const initialTensioLeftDate = tensioCorrelationSamples.length === 0 ? checkTime : (new Date(tensioCorrelationSamples[0].startDate)).getTime();
    const initialTensioRightDate = tensioCorrelationSamples.length === 0 ? checkTime : (new Date(tensioCorrelationSamples[tensioCorrelationSamples.length - 1].startDate)).getTime();

    const [tensioLeftTime, setTensioLeftTime] = useState(initialTensioLeftDate);
    const [tensioRightTime, setTensioRightTime] = useState(initialTensioRightDate);
    const bloodPressureCorrelationSamples = (get(appointmentModel, "correlationSamples", []).filter(sample => {return (sample.correlationType==="24hBloodPressureImport") || (sample.correlationType === "24hBloodPressureDipping")})).sort((a,b) => a.startDate > b.startDate? 1 : -1) || [];
    const initialBloodPressureLeftDate = bloodPressureCorrelationSamples.length === 0 ? checkTime : (new Date(bloodPressureCorrelationSamples[0].startDate)).getTime();
    const initialBloodPressureRightDate = bloodPressureCorrelationSamples.length === 0 ? checkTime : (new Date(bloodPressureCorrelationSamples[bloodPressureCorrelationSamples.length - 1].startDate)).getTime();

    const [bloodPressureLeftTime, setBloodPressureLeftTime] = useState(initialBloodPressureLeftDate);
    const [bloodPressureRightTime, setBloodPressureRightTime] = useState(initialBloodPressureRightDate);
    const [uploadFile, setUploadFile] = useState(new FormData());
    const auth = useContext(AuthContext);

    /* eslint-disable */
    useEffect(() => {
        setAppointmentModel(dataModel);
        let changedTensioCorrelationSamples = (get(dataModel, "correlationSamples", []).filter(sample => {
            return ((sample.correlationType==="tensioMeasurement") || (sample.correlationType === "tensioControlImport"))
                && (new Date(sample.startDate).toISOString() !== new Date(MIN_DATE_TIME).toISOString())
        })).sort((a,b) => a.startDate > b.startDate? 1 : -1) || [];
        setTensioLeftTime(changedTensioCorrelationSamples.length === 0 ? checkTime : (new Date(changedTensioCorrelationSamples[0].startDate)).getTime());
        setTensioRightTime(changedTensioCorrelationSamples.length === 0 ? checkTime : (new Date(changedTensioCorrelationSamples[changedTensioCorrelationSamples.length - 1].startDate)).getTime());

        let changedBloodPressureCorrelationSamples = (get(dataModel, "correlationSamples", []).filter(sample => {return (sample.correlationType==="24hBloodPressureImport") || (sample.correlationType === "24hBloodPressureDipping")})).sort((a,b) => a.startDate > b.startDate? 1 : -1) || [];
        setBloodPressureLeftTime(changedBloodPressureCorrelationSamples.length === 0 ? checkTime : (new Date(changedBloodPressureCorrelationSamples[0].startDate)).getTime());
        setBloodPressureRightTime(changedBloodPressureCorrelationSamples.length === 0 ? checkTime : (new Date(changedBloodPressureCorrelationSamples[changedBloodPressureCorrelationSamples.length - 1].startDate)).getTime());
    }, [dataModel]);
    /* eslint-enable */

    if (tensioCorrelationSamples.length > 0) {
        if (tensioLeftTime === checkTime && initialTensioLeftDate !== checkTime) {
            setTensioLeftTime(initialTensioLeftDate);
        }
        if (tensioRightTime === checkTime && initialTensioRightDate !== checkTime) {
            setTensioRightTime(initialTensioRightDate);
        }
    }

    const key = `${label}bloodpressure`;

    function getCurrentTabNr () {
        return window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
    }

    const handleICareResponse = (appointment) => {
        const newApp = {
            ...appointmentModel,
            ...appointment
        };
        setAppointmentModel(newApp);
    };

    const handleFileChange = (event) => {
        if(event.target.files.length > 0) {
            if(get(event, "target.files[0].size")/1024/1024 >= MAX_FILE_SIZE){
                toastError("Attachment upload error: File is bigger than " + MAX_FILE_SIZE);
            } else {
                const formData = new FormData();
                formData.set('file', event.target.files[0], get(event, "target.files[0].name"));
                setUploadFile(formData);
                console.log("FormData:", uploadFile);
            }
        } else {
            toastError("Keine Datei ausgewählt!");
        }
    };

    const handleICareSync = () => {
        if (stringToBoolean(process.env.REACT_APP_ICARE_IMPORT_ACTIVATED)) {
            if (!syncing && !isAppointmentLoading) {
                mutateSyncWithICare({
                        appointmentId: appointmentModel.id,
                        patientId: patientId
                    }
                ).then(v => {
                    if (v && v.data && v.data.iCareSync.success) {
                        if (v && v.data && v.data.iCareSync && v.data.iCareSync.appointment)
                            handleICareResponse(v.data.iCareSync.appointment);
                        toastSuccess("Es wurden " + v.data.iCareSync.newSampleCount + " neue Daten importiert!");
                    } else {
                        let message = get(v, "data.iCareSync.sycMessage", "");
                        if (message === "decrypt-value unexpected format"){
                            message = "Nicht authorisiert!";
                        }
                        toastError(v && v.data && "Import fehlgeschlagen: " + message);
                    }
                });
            }
        }
        else{
            toastError(t("error_not_implemented"));
        }
    };

    const uploadCSVFile=(appointmentId) =>{

        if (uploadFile.get("file") === null){
            toastError("Keine Datei ausgewählt!");
            return;
        }
        if (appointmentId === null){
            toastError("Bitte legen Sie zunächst die Untersuchung an und setzen Sie diese auf Stattgefunden!");
            return;
        }
        console.log("Save csv file....", patientId, appointmentId);
        uploadFile.set('patient-id', patientId);
        uploadFile.set('appointment-id', appointmentId);
        uploadFile.set('device', "PhysioPort");
        uploadFile.set('eye', "NOT_APPLICABLE");
        uploadFile.set('examination-type', "24hBloodPressureImport");

        setUploading(true);
        axios.post(
            `${process.env.REACT_APP_SERVER_URI}/api/24hrr`,
            uploadFile,
            {headers: {Authorization: "Bearer " + auth.keycloak.token}})
            .then(function(response) {
                // handle success

                if (response.status === 201) {
                    if (response && response.data && response.data.success){
                        toastSuccess("Es wurden " + response.data.newSampleCount + " neue Daten importiert!");
                        refresh();
                    }
                    if (response && response.data && !response.data.success){
                        toastError("Import fehlgeschlagen: " + response.data.syncMessage);
                    }

                }
                else {
                    toastError("Import fehlgeschlagen: " + response.statusText);
                }
            })
            .catch(function(error) {
                toastError("Importfehler: ", error);
            })
            .finally(() => setUploading(false));
    };

    const handle24hRRImport = () => {
        if (stringToBoolean(process.env.REACT_APP_24HRR_IMPORT_ACTIVATED)){
            if (!syncing && !isAppointmentLoading) {
                uploadCSVFile(appointmentModel.id);
            }
        }
    };

    const uploadTensioCSV = (appointmentId) => {
        if (uploadFile.get("file") === null){
            toastError("Keine Datei ausgewählt!");
            return;
        }
        if (appointmentId === null){
            toastError("Bitte legen Sie zunächst die Untersuchung an und setzen Sie diese auf Stattgefunden!");
            return;
        }
        uploadFile.set('patient-id', patientId);
        uploadFile.set('appointment-id', appointmentId);
        uploadFile.set('examination-type', "TensioControlImport");
        console.log(`${process.env.REACT_APP_SERVER_URI}/api/tensiocontrol`);
        setUploading(true);
        axios.post(
            `${process.env.REACT_APP_SERVER_URI}/api/tensiocontrol`,
            uploadFile,
            {headers: {Authorization: "Bearer " + auth.keycloak.token}})
            .then(function(response) {
                // handle success

                if (response.status === 201) {
                    if (response && response.data && response.data.success){
                        toastSuccess("Es wurden " + response.data.newSampleCount + " neue Daten importiert!");
                        refresh();
                    }
                    if (response && response.data && !response.data.success){
                        toastError("Import fehlgeschlagen: " + response.data.syncMessage);
                    }

                }
                else {
                    toastError("Import fehlgeschlagen: " + response.statusText);
                }
            })
            .catch(function(error) {
                toastError("Importfehler: ", error);
            })
            .finally(() => setUploading(false));
    };

    const handleTensioImport = () => {
        if(!stringToBoolean(process.env.REACT_APP_TENSIO_IMPORT_CONTROLGROUP_ACTIVATED)) {
            toastError(t("error_not_implemented"));
        }
        else{
            uploadTensioCSV(appointmentModel.id);
        }
    };

    const isReadOnlyTensio = () => {
        return isExcluded ||
            !(isControlGroup ? hasPermission(user.roles, [user_roles.ROLE_CLINICIAN]) : hasPermission(user.roles, [user_roles.ROLE_RESIDENT, user_roles.ROLE_CLINICIAN]))
    };

    const renderHintItem = (...hints) => {
        let items = [];
        for (let i = 0; i < hints.length; i++) {
            items.push(
                <div key={i} className="item">
                    <i className="file icon large black"/>
                    <div className="content">
                        <div className="description">
                            {hints[i]}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="ui medium aligned list">
                {items}
            </div>
        );
    };

    const switchHintOnAppointmentCategory = (category) => {
        switch (category) {
            case CAT_INCLUSION:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"), t("hint_opt_papillen"))}

                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_fundus_photo"), t("hint_opt_hrt"))}
                    </div>
                );
            case CAT_CONTROL:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_tensio_profile"))}
                    </div>
                );
            case CAT_FOLLOWUP_T4:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"))}
                    </div>
                );
            case CAT_FINAL:
                return (
                    <div>
                        <div className="ui small header">{t("hint_status_mandatory")}</div>
                        {renderHintItem(t("hint_opt_perimetry"), t("hint_opt_papillen"))}

                        <div className="ui small header">{t("hint_status_optional")}</div>
                        {renderHintItem(t("hint_opt_fundus_photo"), t("hint_opt_hrt"))}
                    </div>
                );
            default:
                return "";
        }
    };

    async function printSummary () {
        if (isControlGroup) {
            await clinicalTTPTensioProfileControl(patient.masterData, tensioCorrelationSamples, dataModel.startDate, tensioChartRef.current);
        } else {
            const activeTab = window.sessionStorage.getItem("appointmentActiveIndexInTab");
            if (activeTab === "1") {
                await ambulantTTPTensioProfileIntervention(patient.masterData, tensioCorrelationSamples, dataModel.startDate, tensioChartRef.current);
            }
            else if (activeTab === "2") {
                await ambulantTTP24hRRIntervention(patient.masterData, bloodPressureCorrelationSamples, dataModel.startDate, bloodPressureChartRef.current);
            }
        }

    }

    const isReaderAndDEMTabDisabled = () => {
        const sample = get(dataModel, "correlationSamples", [])
        const followUp = [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4]

        if (!examinationTabDisabled) {
            if (followUp.includes(dataModel.category)) {
                const previousAppointment = getPreviousAppointmentModel(patient, dataModel.category)
                if (previousAppointment) {
                    let previousAppointmentDate = new Date(previousAppointment?.startDate)
                    previousAppointmentDate.setMonth(previousAppointmentDate.getMonth() + 3);
                    if (!(dataModel?.status === APPT_STATUS_TOOK_PLACE) && previousAppointment?.status === APPT_STATUS_TOOK_PLACE &&
                        new Date(dataModel.startDate).getTime() >= previousAppointmentDate.getTime()) {

                        return true;
                    }
                }
            }
            return !!(sample && !checkRequiredFields(sample))
        }

        return examinationTabDisabled;
    }

    const panes = [
        !isPatient && {
            menuItem: (
                <Menu.Item key={"appointment"}>
                    <div className={"pb-1of2"}>{t("appointment")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false}>
                    <InitiateAppointment model={dataModel} patientId={patientId} ref={currentTab}
                                         callback={setExaminationTabDisabled} isReadOnly={isReadOnlyStudyLead} afterSave={handleAfterSave}
                                         inclusionDate={inclusionDate}/>
                </Tab.Pane>
            )
        },
        {
            menuItem: (
                <Menu.Item key={"examination_type"} disabled={examinationTabDisabled}>
                    <div className={"pb-1of2"}>{t("examination_type")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <ExaminationTab dataModel={dataModel} patientId={patientId} isReadOnly={isReadOnly}
                                    readerCComment={readerCComment} ref={currentTab} afterSave={handleAfterSave} patient={patient}/>
                </Tab.Pane>
            )
        },
        {
            menuItem: (
                <Menu.Item key={label}>
                    <div className={"pb-1of2"}>{t("tensio")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false}>
                    {isPatient &&
                    <>
                        <div className={"Grid -right"}>
                            <Button basic type="submit" size="tiny" color="teal" content={t("print")} onClick={() => printSummary()}/>
                        </div>
                        <Divider />
                    </>
                    }
                    {!(isControlGroup || isPatient) &&
                    <Button basic type="submit" size="tiny" color="teal"
                            content={t("import")}
                            onClick={isControlGroup ? handleTensioImport : handleICareSync}
                            disabled={isReadOnlyTensio()}
                    />
                    }
                    <br/>
                    <br/>
                    <div className={"ui grid"}>
                        <DateInput
                            name="tensioFirstThreshold"
                            value={tensioLeftTime}
                            label={t("start_date")}
                            type="datetime"
                            onChange={(value) => setTensioLeftTime(value)}
                            className={isPatient ? "patient-view-date-font" : ""}
                        />
                        <DateInput
                            name="tensioSecondThreshold"
                            value={tensioRightTime}
                            label={t("end_date")}
                            type="datetime"
                            onChange={(value) => setTensioRightTime(value)}
                            className={isPatient ? "patient-view-date-font" : ""}
                        />
                    </div>
                    <br/>
                    <div className={"ui grid"}>{t("tabs_apponit_CHART_legend_click")}</div>
                    <ChartTensio data={appointmentModel} leftTime={tensioLeftTime} rightTime={tensioRightTime} ref={tensioChartRef}/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {isControlGroup && <RenderTensioTableControl appointmentModel={appointmentModel} leftTime={tensioLeftTime} rightTime={tensioRightTime} isReadOnly={isReadOnlyTensio()} patientId={patientId} refresh={refresh}/>}
                    {!isControlGroup && <RenderTensioTable appointmentModel={appointmentModel} leftTime={tensioLeftTime} rightTime={tensioRightTime}/>}
                </Tab.Pane>
            )
        },
        ((dataModel.category === CAT_INCLUSION || dataModel.category === CAT_CONTROL) && !isPatient) &&
        {
            menuItem: (
                <Menu.Item key={"files"} disabled={examinationTabDisabled} >
                    <div className={"pb-1of2"} data-tooltip={filesToolTipText}>{t("files")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <div>
                        {switchHintOnAppointmentCategory(dataModel.category)}
                    </div>
                    <br/>
                    <AttachmentTable model={dataModel} appointmentId={appointmentId} isReadOnly={isReadOnly} refresh={handleAfterSave}/>
                    <br/>
                    <NewAttachment isReadOnly={isReadOnly}
                                   patientId={patientId}
                                   appointment={dataModel} refresh={handleAfterSave}/>
                </Tab.Pane>
            )
        },
        !isControlGroup &&{
            menuItem: (
                <Menu.Item key={key}>
                    <div className={"pb-1of2"}>{t("bloodpressure")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Dimmer active={uploading}>
                        <Loader />
                    </Dimmer>

                    {isPatient &&
                        <>
                            <div className={"Grid -right"}>
                                <Button basic type="submit" size="tiny" color="teal" content={t("print")} onClick={() => printSummary()}/>
                            </div>
                            <Divider />
                        </>
                    }

                    {!isPatient &&
                        <>
                            <Form id={"file-bloodpressure-form"}>
                                <Form.Input
                                    type={"file"}
                                    id={"getfile"}
                                    onChange={handleFileChange}
                                    disabled={isReadOnlyTensio()}
                                />
                            </Form>

                            <br/>
                            <Button basic type="submit" size="tiny" color="teal"
                                    content={t("import")}
                                    onClick={handle24hRRImport}
                                    disabled={isReadOnlyTensio()}
                            />
                        </>
                    }

                    <br/>
                    <br/>
                    <div className={"ui grid"}>
                        <DateInput
                            name="bloodPressureFirstThreshold"
                            value={bloodPressureLeftTime}
                            label={t("start_date")}
                            type="datetime"
                            onChange={(value) => setBloodPressureLeftTime(value)}
                            className={isPatient ? "patient-view-date-font" : ""}
                        />
                        <DateInput
                            name="bloodPressureSecondThreshold"
                            value={bloodPressureRightTime}
                            label={t("end_date")}
                            type="datetime"
                            onChange={(value) => setBloodPressureRightTime(value)}
                            className={isPatient ? "patient-view-date-font" : ""}
                        />
                    </div>
                    <br/>
                    <div className={"ui grid"}>{t("tabs_apponit_CHART_legend_click")}</div>
                    <Chart data={appointmentModel} leftTime={bloodPressureLeftTime} rightTime={bloodPressureRightTime} ref={bloodPressureChartRef}/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <RenderRRTable appointmentModel={appointmentModel} rightTime={bloodPressureRightTime} leftTime={bloodPressureLeftTime}/>
                </Tab.Pane>
            )
        },
        (dataModel.category === CAT_TENSIO  && !isPatient) && {
            menuItem: (
                <Menu.Item key={"konsilKlinik"} disabled={false} >
                    <div className={"pb-1of2"}>{t("konsilKlinik")}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <KonsilKlinikTab patientId={patientId} dataModel={dataModel} refresh={refresh} patient={patient}/>
                </Tab.Pane>
            )
        },
        (isReader && getCurrentTabNr() === "3" && !isPatient) &&
        {
            menuItem: (
                <Menu.Item key={"reader-tensio"} disabled={isReaderAndDEMTabDisabled() || isReaderTabDisabled}>
                    <div className={"pb-1of2"}>{"Reader Zone"}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <RenderReaderZoneTensio
                        patientId={patientId} isControlCohort={isControlGroup} refresh={refresh} dataModel={dataModel}
                        allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples} patient={patient} />
                </Tab.Pane>
            )
        },
        (isDataEntryManager && !isPatient) &&
        {
            menuItem: (
                <Menu.Item key={"dataEntry"} disabled={isReaderAndDEMTabDisabled() || isReaderTabDisabled}>
                    <div className={"pb-1of2"}>{"Data Entry"}</div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <DataEntryTab readerCComment={readerCComment} patient={patient} refresh={refresh} dataModel={dataModel}
                                  patientId={patientId} allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                                  isControlCohort={isControlGroup}/>
                </Tab.Pane>
            )
        }
    ];

    const onTabChanged = async (e, data) => {
        if (dataModel.startDate === null) {
            toastError(t("error_date"));
        }

        if (checkRef(currentTab)) {
            if (currentTab.current.hasChanged && currentTab.current.hasChanged()) {
                setShowModalWarning(true);
                setNextTab(data.activeIndex);
            } else {
                setTab(data.activeIndex);
                setActiveTab(data.activeIndex);
            }
        } else {
            setTab(data.activeIndex);
        }

    };
    const setTab = (activeIndex) => {
        setActiveTab(activeIndex);
        window.sessionStorage.setItem("appointmentActiveIndexInTab", activeIndex);
    }

    const renderModalWarning = () => {
        const closeWithoutSaving = () => {
            setShowModalWarning(false);
            setTab(nextTab);
        };
        const brakeChangingTab = () => {
            setShowModalWarning(false);
        };

        return (
            <>
                {!isPatient &&
                    <Modal open={showModalWarning}>
                        <Modal.Header>{t("leave_header")}</Modal.Header>
                        <Modal.Content>
                            <p>{t("leave_without_saving")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={closeWithoutSaving}>{t("leave_yes")}</Button>
                            <Button
                                positive
                                content={t("leave_no")}
                                onClick={brakeChangingTab}
                            />
                        </Modal.Actions>
                    </Modal>
                }
            </>
        );
    };

    return (
        <div>
            {renderModalWarning()}
            <>
                <Tab activeIndex={activeTab} menu={{color: "teal"}} onTabChange={onTabChanged}
                     panes={panes.filter(p => {
                         if (p.menuItem != null) {
                             return p.menuItem.key !== "questionnaire";
                         } else {
                             return false;
                         }
                     })}/>
                <Divider/>
            </>
        </div>
    );
};

export default forwardRef(AptTabTensioAndChart);
