import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Dropdown, Form, Header, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useOrganizationProfile} from "../../hooks";
import {useForm} from "react-hook-form";
import {get} from "lodash";
import {useMutateMedication} from "../../data/mutations/mutateOrganisation";
import {AuthContext, toastError} from "../../services";

const OrganizationProfile = ({closeDropDown, refresh}) => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(false);
    const {data, refetch} = useOrganizationProfile();
    const form = useForm({mode: "onChange"});
    const mutation = useMutateMedication();

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        if (modalOpen === true) {
            form.trigger();
            closeDropDown(true);
        } else {
            closeDropDown(false);
        }
    }, [modalOpen]);
    /* eslint-enable */

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSave = (data) => {
        if (form.formState.isValid)
            return mutation.mutateOrganisation(data).then(() => {
                setModalOpen(false);
                refetch();
                refresh();
            }, () => {
                // rejection
            });
    };

    const onSubmitError = () => {
        toastError(t("error_fields_incorrect"));
    };

    return (
        <Modal
            onClose={() => setModalOpen(false)}
            onOpen={() => setModalOpen(true)}
            open={modalOpen}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            trigger={<Dropdown.Item icon="hospital" text={t("profile_orga")}/>}
        >
            <Header>
                {t("organization_profile_header")}
            </Header>
            <Modal.Content>
                <Form id={"organisation-profile-form"} onSubmit={form.handleSubmit(handleSave, onSubmitError)}>
                    <div className={"pb-2 width-1-2"}>
                        <Form.Field error={!!get(form, "errors.organisation.name")}>
                            <label className={"pr-1"}>{t("organization_name")}</label>
                            <input
                                name={"organisation.name"}
                                //where is the right place for refetch when by saving user profile outside the organization maybe updated?
                                defaultValue={refetch() && get(data, "organisation.name")}
                                ref={form.register({
                                    validate: (value) => !!String(value).trim(),
                                    required: true
                                })}
                            />
                        </Form.Field>
                    </div>
                    <div className={"pb-2 width-1-2"}>
                        <Form.Field>
                            <label className={"pr-1"}>{t("organization_BSNR")}</label>
                            <input
                                name={"organisation.bsnr"}
                                defaultValue={get(user, "organisation.bsnr")}
                                disabled
                            />
                        </Form.Field>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Field error={!!get(form, "errors.organisation.address.street")}>
                                <label className={"pr-1"}>{t("street")}</label>
                                <input
                                    width={4}
                                    name={"organisation.address.street"}
                                    defaultValue={get(data, "organisation.address.street")}
                                    ref={form.register({
                                        validate: (value) => !!String(value).trim(),
                                        required: true
                                    })}
                                />
                            </Form.Field>
                            <Form.Field error={!!get(form, "errors.organisation.address.streetno")}>
                                <label className={"pr-1"}>{t("number")}</label>
                                <input
                                    width={2}
                                    name={"organisation.address.streetno"}
                                    defaultValue={get(data, "organisation.address.streetno")}
                                    ref={form.register({
                                        validate: (value) => !!String(value).trim(),
                                        required: true
                                    })}
                                />
                            </Form.Field>
                            <Form.Field error={!!get(form, "errors.organisation.address.postalcode")}>
                                <label className={"pr-1"}>{t("zip")}</label>
                                <input
                                    width={2}
                                    name={"organisation.address.postalcode"}
                                    defaultValue={get(data, "organisation.address.postalcode")}
                                    ref={form.register({
                                        validate: (value) => !!String(value).trim(),
                                        required: true
                                    })}
                                />
                            </Form.Field>
                            <Form.Field error={!!get(form, "errors.organisation.address.city")}>
                                <label className={"pr-1"}>{t("city")}</label>
                                <input
                                    width={4}
                                    name={"organisation.address.city"}
                                    defaultValue={get(data, "organisation.address.city")}
                                    ref={form.register({
                                        validate: (value) => !!String(value).trim(),
                                        required: true
                                    })}
                                />
                            </Form.Field>
                            <Form.Field error={!!get(form, "errors.organisation.address.country")}>
                                <label className={"pr-1"}>{t("country")}</label>
                                <input
                                    width={4}
                                    name={"organisation.address.country"}
                                    defaultValue={get(data, "organisation.address.country")}
                                    ref={form.register({
                                        validate: (value) => !!String(value).trim(),
                                        required: true
                                    })}
                                />
                            </Form.Field>
                        </Form.Group>
                    </div>
                    <br/>
                    <div className={"c-header"}>{t("organization_responsible")}</div>
                    <Form.Group>
                        <Form.Field>
                            <label className={"pr-1"}>{t("title")}</label>
                            <input name={"organisation.contact.title"}
                                   defaultValue={get(data, "organisation.contact.prefix")} ref={form.register()}/>
                        </Form.Field>
                        <Form.Field error={!!get(form, "errors.organisation.contact.forename")}>
                            <label className={"pr-1"}>{t("first_name")}</label>
                            <input name={"organisation.contact.forename"}
                                   defaultValue={get(data, "organisation.contact.forename")}
                                   ref={form.register({
                                       validate: (value) => !!String(value).trim(),
                                       required: true
                                   })}/>
                        </Form.Field>
                        <Form.Field error={!!get(form, "errors.organisation.contact.surname")}>
                            <label className={"pr-1"}>{t("last_name")}</label>
                            <input name={"organisation.contact.surname"}
                                   defaultValue={get(data, "organisation.contact.surname")}
                                   ref={form.register({
                                       validate: (value) => !!String(value).trim(),
                                       required: true
                                   })}/>
                        </Form.Field>
                    </Form.Group>
                    <div className={"pb-2 width-1-2"}>
                        <Form.Field error={!!get(form, "errors.organisation.contact.jobTitle")}>
                            <label className={"pr-1"}>{t("organization_responsible_position")}</label>
                            <input name={"organisation.contact.jobTitle"}
                                   defaultValue={get(data, "organisation.contact.jobTitle")}
                                   ref={form.register({
                                       validate: (value) => !!String(value).trim(),
                                       required: true
                                   })}/>
                        </Form.Field>
                    </div>
                </Form>
                <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" onClick={handleClose} negative>{t("cancel")}</Button>
                <Button type="submit" form="organisation-profile-form" positive>{t("save")} </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default OrganizationProfile;
