import {useMutation} from "@apollo/react-hooks";
import {UPSERT_MED_HISTORY} from "data/mutations/medicalHistory";
import {useTranslation} from "react-i18next";
import {toastSuccess, toastError, verifyGraphQlResponse} from "services";

export const useMutateMedicalHistory = () => {
    const {t} = useTranslation();
    const [upsert, {loading}] = useMutation(UPSERT_MED_HISTORY);

    const mutate = (variables) => {
        if (loading) {
            return Promise.reject("Loading in progress...");
        }
        return upsert({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
            });
    };

    return {mutate, loading};
};
