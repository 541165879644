import React, {useEffect, useReducer, useState} from "react";
import NoResultsFound from "../NoResultsFound";
import {Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../services";
import {useHistory} from "react-router-dom";
import {
    APPT_STATUS_TOOK_PLACE, CAT_CONTROL, CAT_FINAL, CAT_FIRST,
    CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_INCLUSION
} from "../../data/mutations/appointments";
import {get} from "lodash";
import {checkDocLength, checkRequiredFields} from "../reader/models/utils";
import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";
import createModelFollowUpFirst from "../../data/models/appointmentSharedFollowUpFirst";
import createModelFollowUp from "../../data/models/appointmentFollowUp";
import createModelFollowUpT4 from "../../data/models/appointmentFollowupT4";
import createModelControl from "../../data/models/appointmentControl";
import createModelInclusionFinal from "../../data/models/appointmentSharedInclusionFinal";
import createModelFinal from "../../data/models/appointmentFinal";
import createModelTensio from "../../data/models/appointmentTensio";

function reducer(state, action) {
    switch (action.type) {
        case "CHANGE_SORT":
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === "ascending" ? "descending" : "ascending",
                }
            }
            if (action.column === "patientSurname") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.surname > b.masterData.surname ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "city") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.contact.address.city > b.masterData.contact.address.city ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "inclusionDate") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.studyData.inclusionDate > b.studyData.inclusionDate ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "insuranceCompany") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.insurance.company > b.masterData.insurance.company ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "hospital") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.masterData.hospital.name > b.masterData.hospital.name ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "doctor") {
                return {
                    column: action.column,
                    data: state.data.sort((a,b) => a.studyData.doctorInCharge.surname > b.studyData.doctorInCharge.surname ? 1 : -1),
                    direction: "ascending",
                }
            }
            break;
        default:
            throw new Error();
    }
}

const AccountingPatientTable = ({hasResults, patientsData, noData, maxPatients}) => {
    const { t } = useTranslation();
    const [accountingTableData, setAccountingTableData] = useState(patientsData || []);

    const [state, dispatch] = useReducer(reducer, {
        column: "patientName",
        data: accountingTableData.sort((a,b) => a.masterData.surname > b.masterData.surname ? 1 : -1),
        direction: "ascending",
    });

    const { column, data, direction } = state;

    /* eslint-disable */
    useEffect(() => {
        state.data = patientsData;
        setAccountingTableData(patientsData);
    }, [patientsData])

    const getCohortStatistics = data => {
        const cohortStatistics = {control: 0, intervention: 0};
        for (const it of data) {
            if (it.studyData.cohort === CAT_CONTROL) {
                cohortStatistics.control += 1;
            } else {
                cohortStatistics.intervention += 1;
            }
        }
        return cohortStatistics;
    };

    const history = useHistory();

    const didAppointmentTookPlace = (status) => {
        return status === APPT_STATUS_TOOK_PLACE;
    }

    const checkIfAnyNextAppointmentsTookPlace = (nextModels) => {
        return nextModels.some((item) => item.status === APPT_STATUS_TOOK_PLACE);
    }

    const isAppointmentInComplete = (appointment, nextAppointments, isFollowUpApp, previousDate, didPrevAppTookPlace = true) => {
        const sample = get(appointment, "correlationSamples", []);
        const didAppointmentTakePlace = didAppointmentTookPlace(appointment?.status);

        const appointmentIncomplete = (didAppointmentTakePlace && ((sample && !checkRequiredFields(sample)) || checkDocLength(appointment))) ||
            (!didAppointmentTakePlace && appointment.startDate && new Date(appointment.startDate).getTime() <= new Date().getTime()) ||
            (!didAppointmentTakePlace && checkIfAnyNextAppointmentsTookPlace(nextAppointments));

        if (isFollowUpApp) {
            previousDate.setMonth(previousDate.getMonth() + 3);
            return appointmentIncomplete || (!didAppointmentTakePlace && didPrevAppTookPlace && new Date().getTime() >= previousDate.getTime());
        } else {
            return appointmentIncomplete;
        }
    }

    const getStatusNAA = (modelFirst, modelInclusion, modelControl, modelTensio, modelFollowUp1, modelFollowUp2,
                          modelFollowUp3, modelFinal, isControlCohort) => {
        const returnVal = (!isAppointmentInComplete(modelFirst, [modelInclusion, modelFollowUp1,
                isControlCohort ? modelControl : modelTensio, modelFollowUp2, modelFollowUp3, modelFinal] , false) &&
            !isAppointmentInComplete(modelFollowUp1, [modelFollowUp2, modelFollowUp3, modelFinal], true,
                new Date(modelInclusion?.startDate ? modelInclusion?.startDate : null),
                didAppointmentTookPlace(modelInclusion?.status)) &&
            !isAppointmentInComplete(modelFollowUp2,[modelFollowUp3, modelFinal], true,
                new Date(modelFollowUp1?.startDate ? modelFollowUp1.startDate : null),
                didAppointmentTookPlace(modelFollowUp1?.status)) &&
            !isAppointmentInComplete(modelFollowUp3, [modelFinal], true,
                new Date(modelFollowUp2?.startDate ? modelFollowUp2.startDate : null),
                didAppointmentTookPlace(modelFollowUp2?.status)))

        if (!isControlCohort) {
            return returnVal &&
                !isAppointmentInComplete(modelTensio, [modelFollowUp1, modelFollowUp2, modelFollowUp3, modelFinal], false)
        }
        return returnVal
    }

    const getStatusKliniken = (modelFirst, modelInclusion, modelControl, modelTensio, modelFollowUp1, modelFollowUp2,
                               modelFollowUp3, modelFinal, isControlCohort) => {
        const returnVal = !isAppointmentInComplete(modelInclusion, [modelFollowUp1, modelFollowUp2, modelFollowUp3,
                isControlCohort ? modelControl : modelTensio, modelFinal],false) &&
            !isAppointmentInComplete(modelFinal, [], false)

        if (isControlCohort) {
            return returnVal && !isAppointmentInComplete(modelControl, [modelFollowUp1, modelFollowUp2, modelFollowUp3, modelFinal], false)
        }
        return returnVal
    }

    return (
        <div className={"c-container scroll-y"}>
            {hasResults &&
            <Table celled striped selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={"center"}
                                          sorted={column === "patientSurname" ? direction : null}
                                          onClick={() => dispatch({ type: "CHANGE_SORT", column: "patientSurname" })}
                        >
                            {"Pat. Name"}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{t("cohort")}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}
                                          sorted={column === "inclusionDate" ? direction : null}
                                          onClick={() => dispatch({ type: "CHANGE_SORT", column: "inclusionDate" })}
                        >
                            {t("study_inclusion_date")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{"Status NAA"}<br />{"Untersuchung"}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{"Status Kliniken"}<br />{"Untersuchung"}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}
                                          sorted={column === "insuranceCompany" ? direction : null}
                                          onClick={() => dispatch({ type: "CHANGE_SORT", column: "insuranceCompany" })}
                        >
                            {t("insurance_company")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}
                                          sorted={column === "hospital" ? direction : null}
                                          onClick={() => dispatch({ type: "CHANGE_SORT", column: "hospital" })}
                        >
                            {t("hospital_in_charge")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}
                                          sorted={column === "doctor" ? direction : null}
                                          onClick={() => dispatch({ type: "CHANGE_SORT", column: "doctor" })}
                        >
                            {"NAA"}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map(it=> {
                        const isPatientExcluded = it?.status === PAT_STATUS_DATA_EXCLUDED
                        const isControlCohort = it && it.studyData.cohort === "CONTROL";
                        const modelFirst = createModelFollowUpFirst(it, t, CAT_FIRST);
                        const modelFollowUp1 = createModelFollowUp(it, t, CAT_FOLLOWUP_T2);
                        const modelFollowUp2 = createModelFollowUp(it, t, CAT_FOLLOWUP_T3);
                        const modelFollowUp3 = createModelFollowUpT4(it, t, CAT_FOLLOWUP_T4);
                        const modelControl = createModelControl(it, t);
                        const modelInclusion = createModelInclusionFinal(it, t, CAT_INCLUSION);
                        const modelFinal = createModelFinal(it, t, CAT_FINAL);
                        const modelTensio = createModelTensio(it);

                        return (
                            <Table.Row style={{color: isPatientExcluded ? "grey" : ""}} key={it.patientId}
                                       onClick={() => history.push(`/patient/${it.id}`)}>
                                <Table.Cell key={`${it.patientId}_name`}>{it.masterData.forename + " " + it.masterData.surname}</Table.Cell>
                                <Table.Cell key={`${it.patientId}_cohort`}>{t("COHORT_" + it.studyData.cohort)}</Table.Cell>
                                <Table.Cell textAlign={"center"} key={`${it.patientId}_inclusionDate`}>{formatDate(it.studyData.inclusionDate)}</Table.Cell>
                                <Table.Cell textAlign={"center"} key={`${it.patientId}_status_naa`}>
                                    {getStatusNAA(modelFirst, modelInclusion, modelControl, modelTensio, modelFollowUp1,
                                        modelFollowUp2, modelFollowUp3, modelFinal, isControlCohort) ?
                                        <i className="green check circle icon"/> :
                                        <i className="red times circle icon"/>}
                                </Table.Cell>
                                <Table.Cell textAlign={"center"} key={`${it.patientId}_status_kliniken`}>
                                    {getStatusKliniken(modelFirst, modelInclusion, modelControl, modelTensio,
                                        modelFollowUp1, modelFollowUp2, modelFollowUp3, modelFinal, isControlCohort) ?
                                        <i className="green check circle icon"/> :
                                        <i className="red times circle icon"/>}
                                </Table.Cell>
                                <Table.Cell key={`${it.patientId}_insuranceCompany`}>{it.masterData.insurance.company}</Table.Cell>
                                <Table.Cell key={`${it.patientId}_hospital`}>{it.masterData.hospital.name}</Table.Cell>
                                <Table.Cell key={`${it.patientId}_doctor`}>{it.studyData.doctorInCharge.forename + " " + it.studyData.doctorInCharge.surname}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign={"center"} className={"table-footer"}>{`${data.length} / ${maxPatients}`}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"} className={"table-footer"}>{`K:${getCohortStatistics(data).control} / I:${getCohortStatistics(data).intervention}`}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>{}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            }

            {noData && (
                <div className={"center-all"}>
                    <NoResultsFound />
                </div>
            )}
        </div>
    );
};

export default AccountingPatientTable;