import {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {UPSERT_APPOINTMENT} from "data/mutations/appointments";
import {SYNC_ICARE} from "data/mutations/iCareSync";
import {useTranslation} from "react-i18next";
import {removePrivateFields, toastError, toastSuccess, verifyGraphQlResponse} from "services";
import {cloneDeep} from "lodash";

export const useMutateAppointment = (initialModel) => {
    const [model, setModel] = useState(initialModel);
    const {t} = useTranslation();
    const [upsert, { loading: upsertLoading, }] = useMutation(UPSERT_APPOINTMENT);
    const [iCareSync, {loading: syncing}] = useMutation(SYNC_ICARE);

    const getVariables = (variables) => {
        return removePrivateFields(cloneDeep(variables));
    };

    const mutateData = (vars) => {
        if (upsertLoading) {
            return Promise.reject("Loading in progress...");
        }

        const variables = getVariables(vars);
        return upsert({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
                throw e;
            });
    };

    const mutateSyncWithICare = (vars) => {
        if (syncing) {
            return Promise.reject("Syncing in progress...");
        }

        const variables = getVariables(vars);
        return iCareSync({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                //toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
            });
    };

    return [mutateData, mutateSyncWithICare, model, setModel, upsertLoading, syncing];
};
