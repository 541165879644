import React, {useState} from "react";
import {Button, Header, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const Disclaimer = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Button className={"teal"}>{t("footer_menu_disclaimer")}</Button>}
        >
            <Header>
                 {t("footer_menu_disclaimer")}
            </Header>
            <Modal.Content className={"c-center"}>
                <b> Weiterführende Informationen finden Sie auf der Projektwebsite unter:</b>
                <br/>
                <br/>
                <a href="https://www.ukm.de/index.php?id=salus-glaukom" target="_blank" rel={"noopener noreferrer"}>https://www.ukm.de/index.php?id=salus-glaukom</a>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default Disclaimer;
