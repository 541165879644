import gql from "graphql-tag";
import {metaDataFragment} from "./metaDataFragment";

export const quantitySampleFragment =  gql`
    fragment quantitySampleFragment on QuantitySample {
        endDate
        id
        metadata {
            ...metaDataFragment
        }
        quantity
        quantityType
        sampleType
        startDate
        subject
        unit
    }
    ${metaDataFragment}
`;