import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getCategories, getQuantities, printPageNo} from "./util";
import {formatDateTime} from "../../services";
import {printChart} from "./util";

export async function ambulantTTPTensioProfileIntervention(masterData, correlationSamples, appointmentDate, domNode) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Ambulant_TTP_Tensio_Profile_Intervention_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Ambulantes TTP - Tensioprofil", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const helveticaBoldFont = await pdfDocWithHeading.embedFont(StandardFonts.HelveticaBold);
    const page = pdfDocWithHeading.getPages()[0];

    // adding heading to first page

    page.drawLine({start: { x: 40, y: 270 }, end: { x: 555, y: 270 }, thickness: 0.1});
    page.drawText("Datum", { x: 70, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 148, y: 270 }, end: { x: 148, y: 245 }, thickness: 0.1});
    page.drawText("IOP (OD)", { x: 153, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 200, y: 270 }, end: { x: 200, y: 245 }, thickness: 0.1});
    page.drawText("Messqualität OD", { x: 230, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 353, y: 270 }, end: { x: 353, y: 245 }, thickness: 0.1});
    page.drawText("IOP (OS)", { x: 358, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 405, y: 270 }, end: { x: 405, y: 245 }, thickness: 0.1});
    page.drawText("Messqualität OS", { x: 445, y: 255, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 40, y: 245 }, end: { x: 555, y: 245 }, thickness: 0.1});


    const tableData = convertToTable(correlationSamples);

    await printChart(pdfDocWithHeading, domNode);

    printTable(tableData, pdfDocWithHeading, courierFont, helveticaBoldFont);

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Ambulantes_TTP_Tensio_Profile_Summary", "application/pdf");
}

function convertToTable(correlationSamples) {
    return correlationSamples?.map(sample => {
        return [
            formatDateTime(sample.startDate),
            getQuantities(sample, "intraocularPressureRight")?.quantity.toFixed(1).toString() || "",
            getCategories(sample, "measurementQualityEstimateRightEye")?.value || "",
            getQuantities(sample, "intraocularPressureLeft")?.quantity.toFixed(1).toString() || "",
            getCategories(sample, "measurementQualityEstimateLeftEye")?.value || ""
        ]
    });
}

function printTable(tableData, pdfDoc, courierFont, helveticaBoldFont) {
    let yValue = 230;
    const interval = 25;
    const maxYValue = 100;
    let page = pdfDoc.getPages()[0];

    for (let i = 0; i < tableData.length; ++i) {

        page.drawText(tableData[i][0].toString(), { x: 42, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 148, y: yValue+15 }, end: { x: 148, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][1].toString(), { x: 160, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 200, y: yValue+15 }, end: { x: 200, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][2].toString(), { x: 210, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 353, y: yValue+15 }, end: { x: 353, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][3].toString(), { x: 365, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 405, y: yValue+15 }, end: { x: 405, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][4].toString(), { x: 415, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 40, y: yValue-10 }, end: { x: 555, y: yValue-10 }, thickness: 0.1});

        yValue -= 25;

        if (yValue - interval < maxYValue) {
            page = pdfDoc.addPage();
            printTableHeading(page, helveticaBoldFont);
            yValue = 760;
        }
    }

    printPageNo(pdfDoc, helveticaBoldFont);
}

function printTableHeading(page, helveticaBoldFont) {
    page.drawLine({start: { x: 40, y: 800 }, end: { x: 555, y: 800 }, thickness: 0.1});
    page.drawText("Datum", { x: 70, y: 785, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 148, y: 800 }, end: { x: 148, y: 775 }, thickness: 0.1});
    page.drawText("IOP (OD)", { x: 153, y: 785, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 200, y: 800 }, end: { x: 200, y: 775 }, thickness: 0.1});
    page.drawText("Messqualität OD", { x: 230, y: 785, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 353, y: 800 }, end: { x: 353, y: 775 }, thickness: 0.1});
    page.drawText("IOP (OS)", { x: 358, y: 785, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 405, y: 800 }, end: { x: 405, y: 775 }, thickness: 0.1});
    page.drawText("Messqualität OS", { x: 445, y: 785, size: 10, font: helveticaBoldFont });
    page.drawLine({start: { x: 40, y: 775 }, end: { x: 555, y: 775 }, thickness: 0.1});
}
