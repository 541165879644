import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/react-hooks";
import {toastError, toastSuccess, verifyGraphQlResponse} from "../services";
import {UPSERT_MEDICATION} from "../data/mutations/medication";

export const useMutateMedication = () => {
    const {t} = useTranslation();
    const [mutate, {loading}] = useMutation(UPSERT_MEDICATION);

    const mutateMedication = (variables) => {
        if (loading) {
            return Promise.reject("Loading in progress...");
        }
        return mutate({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
            });
    };

    return {mutateMedication, loading};
};
