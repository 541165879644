import React, {useEffect, useState} from "react";
import {Input, Grid} from "semantic-ui-react";
import {useTranslation} from "react-i18next";


const InsuranceCompanyInput = ({value, handleCustomizedInsuranceInput, disabled}) => {
    const { t } = useTranslation();

    const valueInsuranceCompany = value && value.substring(0, value.lastIndexOf("(")).trim();
    const valueInsuranceCompanyNo = value && value.substring(value.lastIndexOf("(") + 1, value.lastIndexOf(")")).replace(/\s/g, '');

    const insuranceCompanyRegEx = new RegExp("^(?!\\s*$).+");
    const insuranceCompanyNoRegEx = new RegExp("^\\d{9}$");

    const [insuranceCompany, setInsuranceCompany] = useState(valueInsuranceCompany || "");
    const [insuranceCompanyNo, setInsuranceCompanyNo] = useState( valueInsuranceCompanyNo || "")
    const [insuranceCompanyError, setInsuranceCompanyError] = useState(!valueInsuranceCompany.match(insuranceCompanyRegEx));
    const [insuranceCompanyNoError, setInsuranceCompanyNoError] = useState(!valueInsuranceCompanyNo.match(insuranceCompanyNoRegEx));

    /* eslint-disable */
    useEffect(() => {
        if (insuranceCompanyError || insuranceCompanyNoError) {
            handleCustomizedInsuranceInput("");
        } else {
            handleCustomizedInsuranceInput(`${insuranceCompany.trim()} (${insuranceCompanyNo.trim()})`);
        }
    }, [insuranceCompany, insuranceCompanyNo])
    /* eslint-enable */

    return (
      <Grid columns={"equal"} container={true} fluid>
          <Grid.Column width={8}>
              <div className={insuranceCompanyError ? "field error" : (disabled ? "disabled field" : "")}>
                  <label className={"insurance-label"}>{t("insurance_company")}</label>
                  <Input
                      fluid={true}
                      autoComplete={"off"}
                      value={insuranceCompany}
                      name={"insuranceCompany"}
                      onChange={(_, data) => {
                          setInsuranceCompany(data.value);
                          setInsuranceCompanyError(!data.value.match(insuranceCompanyRegEx))
                      }}
                  />
              </div>
          </Grid.Column>
          <Grid.Column width={4}>
              <div className={insuranceCompanyNoError ? "field error" : (disabled ? "disabled field" : "")}>
                  <label className={"insurance-label"}>{t("insurance_company_no")}</label>
                  <Input
                      fluid={true}
                      autoComplete={"off"}
                      value={insuranceCompanyNo}
                      name={"insuranceCompanyNo"}
                      onChange={(_, data) => {
                          setInsuranceCompanyNo(data.value);
                          setInsuranceCompanyNoError(!data.value.match(insuranceCompanyNoRegEx))
                      }}
                  />
              </div>
          </Grid.Column>
      </Grid>
    );
};

export default InsuranceCompanyInput;
