import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Header, Modal, TextArea} from "semantic-ui-react";
import DateInput from "../inputs/DateInput";
import {toastError} from "../../services";

const StopMedication = ({medication, mutation, patientId, after, disabled}) => {
    const {t} = useTranslation();
    const [comment, setComment] = useState("");
    const [open, setOpen] = useState(false);
    const [endTime, setEndTime] = useState(new Date());

    const close = () => setOpen(false);

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        if (medication.id) {
            const value = {
                id: medication.id,
                endDate: endTime,
                comment: comment
            };
            mutation.mutateMedication({
                medication: {...value},
                patientId: patientId
            }).then(() => after());
            handleClose();
        } else {
            toastError(t("medication_id_is_missing"));
        }
    };

    const renderDateInput = () => {
        return (
            <DateInput
                key={medication.name}
                value={endTime}
                label={t("medication_end_date")}
                type="datetime"
                additionalProps={{minDate : new Date(medication.startDate)}}
                onChange={(value) => setEndTime(value)}
            />
        );
    };

    return (
        <div>
            <Modal open={open} trigger={<Button type="button" basic size="tiny" color="teal" onClick={() => setOpen(true)}
                                                content={t("stop_medication")} disabled={disabled}/>}>
                <Header>
                    {t("stop_medication")}: {medication.name}
                </Header>
                <Modal.Content>
                    <Form>
                        {renderDateInput()}
                        <br/>
                        <div className={"c-header"}>{t("comment")}</div>
                        <div className={"label-small"}>{t("hint_nopatientrelateddata")}</div>
                        <Form.Field
                            control={TextArea}
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                            placeholder=''
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button type="button" negative onClick={() => handleClose()}>{t("cancel")}</Button>
                    <Button
                        type="button"
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content={t("save")}
                        onClick={() => handleSave()}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default StopMedication;
