import React, {useCallback, useEffect, useState} from "react";
import {Grid, Search} from "semantic-ui-react";
import {useLazyQuery} from "@apollo/react-hooks";
import * as _ from "lodash";


const SearchInput = ({ queryString, label, initialValue, fieldName, title, description, handleSelection }) => {
    const [value, setValue] = useState(initialValue);
    const [getQueryResult, { loading, data }] = useLazyQuery(queryString);
    const [, setSelection] = useState("");
    const [error, setError] = useState(true);

    const onChange = useCallback((e, search) => {
        setValue(search.value.trim().length === 0 ? " " : search.value.trim());
        getQueryResult({ variables: { searchString: search.value.trim().length === 0 ? " " : search.value.trim() } });
    }, [setValue, getQueryResult]);

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        if (value === initialValue && initialValue !== "") getQueryResult({variables: {searchString: initialValue}});
        const selectedData = _.get(data, fieldName, []).filter(x => (x[description] === value.trim()) && !(x[description].endsWith("-mfa")))[0] || {};
        const selectedValue = _.get(selectedData, description, "");
        setSelection(selectedValue);
        handleSelection(selectedData);
        setError(selectedValue.length === 0);
    }, [_.get(data, fieldName, []).length]);
    /* eslint-enable */

    return (
        <Grid>
            <Grid.Column width={20}>
                <div className={error ? "error field" : ""}>
                    <label>{label}</label>
                    <Search
                        loading={loading}
                        onResultSelect={(e, val) => {
                            setValue(val.result.description);
                            setSelection(val.result.description);
                            setError(false);
                            handleSelection(data[fieldName].filter(x => x[description] === val.result.description)[0]);
                        }}
                        onSearchChange={onChange}
                        results={data && _.get(data, fieldName, []).filter(x => x[description] && ((value === x[description].slice(0, value.length)) || value.trim().length === 0) && !(x[description].endsWith("-mfa"))).map(x => {
                            return {
                                title: title.map(i => {return x[i]}).join(" "),
                                description: `${x[description]}`
                            }
                        })}
                        value={value}
                    />
                </div>
            </Grid.Column>
        </Grid>
    )
};

export default SearchInput;
