import {
    CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL, CAT_CONTROL, CAT_TENSIO,
} from "../../../data/mutations/appointments";
import * as _ from "./utils";

export const createControlTensionSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Control",
            device: null,
            appointmentNames: [CAT_CONTROL, CAT_TENSIO],
            values: null,
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("controlStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("controlComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createReaderCSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "ReaderC",
            device: null,
            appointmentNames: [CAT_FIRST, CAT_CONTROL, CAT_TENSIO, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples,"readerC", [{fieldName: "readerC", text: "Visus", type: "float"}]),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("readerCStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getReaderCStatusOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("readerCComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createDezimalvisusSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Dezimalvisus",
            device: null,
            appointmentNames: [CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples,"dezimalvisus", [{fieldName: "decimalvisus", text: "Visus", type: "float"}]),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("dezimalvisusStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("dezimalvisusComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createTensiomessungSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Tensiomessung",
            device: _.getDevice(allAppointmentsCorrelationSamples, "tensioMeasurementManual"),
            appointmentNames: [CAT_FIRST, CAT_INCLUSION, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "tensioMeasurementManual", [{fieldName: "tensioMeasurementManual", text: "Tensiomessung", type: "integer"}]),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("tensiomessungStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("tensiomessungComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createRefraktionSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Refraktion",
            device: null,
            appointmentNames: [CAT_FIRST],
            values: _.getValues(allAppointmentsCorrelationSamples, "refraction", [
                    {fieldName: "sphere", text: "Sphäre", type: "float"},
                    {fieldName: "cylinder", text: "Zylinder", type: "float"},
                    {fieldName: "axis", text: "Achse", type: "integer"},
                ]
            ),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("refractionStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("refractionComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createPerimetrieSection = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Perimetrie (Gesichtsfeld)",
            device: _.getDevice(allAppointmentsCorrelationSamples,"30perimetry"),
            appointmentNames: [CAT_INCLUSION, CAT_FOLLOWUP_T4, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "30perimetry", [
                    {fieldName: "visualFieldIndex", text: "Visual Field Index (VFI)", type: "integer"},
                    {fieldName: "averageDefectDepth", text: "Mittlere Defekttiefe (MD)", type: "float"},
                    {fieldName: "psd", text: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD)", type: "float"},
                    {fieldName: "sLV", text: "sLV", type: "float"},
                    {fieldName: "CsLV", text: "CsLV", type: "float"},
                    {fieldName: "meanSensitivity", text: "Mean Sensitivity", type: "float"},
                ]
            ),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("30perimetryFixationsverlusteRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Fixationsverluste",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: _.regExs.REGEX_FRACTION,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryFixationsverlusteLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Fixationsverluste",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: _.regExs.REGEX_FRACTION,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryFalsePositiveRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Falsch-positiv",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL_0_100,
                    __optional: true,
                    __unit: "%"
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryFalsePositiveLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Falsch-positiv",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL_0_100,
                    __optional: true,
                    __unit: "%"
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryFalseNegativeRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Falsch-negativ",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL_0_100,
                    __optional: true,
                    __unit: "%"
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryFalseNegativeLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Falsch-negativ",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL_0_100,
                    __optional: true,
                    __unit: "%"
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryTestzuverlassigkeitRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Testzuverlässigkeit",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryTestzuverlassigkeitLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Testzuverlässigkeit",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryGhtRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "GHT",
                    __css: "right",
                    __type: "select",
                    __options: _.getGhtOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryGhtLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "GHT",
                    __css: "left",
                    __type: "select",
                    __options: _.getGhtOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryReliabilityFactorRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Reliability Factor - RF",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryReliabilityFactorLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Reliability Factor - RF",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryHinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryHinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ? [{
                dataStorageField: _.createCategorySample("30perimetryHinweisAufProgressionRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryHinweisAufProgressionLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            }] : [],
            {
                dataStorageField: _.createCategorySample("30perimetryStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: "%"
                }
            },
            {
                dataStorageField: _.createCategorySample("30perimetryComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: "%"
                }
            }
        ]
    }
};

export const createIndividuellerZieldruckSection = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Individueller Zieldruck",
            device: null,
            appointmentNames: [tab],
            values: _.getValues(allAppointmentsCorrelationSamples,"targetPressure", [{fieldName: "targetPressure", text: "Zieldruck", type: "integer"}]),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("targetPressureStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("targetPressureComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createPachymetrieSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    const sectionValues = _.getValues(allAppointmentsCorrelationSamples,"pachymtery", [{fieldName: "pachymetry", text: "Pachymetrie", type: "integer"}])
    const isOptional = sectionValues?.[0]?.quantity?.[0]?.rightValue === -50000 && sectionValues?.[0]?.quantity?.[0]?.leftValue === -50000

    return {
        sectionField: {
            section: "Pachymetrie",
            device: _.getDevice(allAppointmentsCorrelationSamples, "pachymtery"),
            appointmentNames: [CAT_INCLUSION],
            values: sectionValues,
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("pachymteryKorrekturfaktorBeiIODMessungRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Korrekturfaktor bei IOD-Messung",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: _.regExs.REGEX_DECIMAL_WITH_SIGN,
                    __optional: isOptional,
                    __unit: "mmHg"
                }
            },
            {
                dataStorageField: _.createCategorySample("pachymteryKorrekturfaktorBeiIODMessungLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Korrekturfaktor bei IOD-Messung",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: _.regExs.REGEX_DECIMAL_WITH_SIGN,
                    __optional: isOptional,
                    __unit: "mmHg"
                }
            },
            {
                dataStorageField: _.createCategorySample("pachymteryStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("pachymteryComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createGonioskopieSection = (allAppointmentsCorrelationSamples, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Gonioskopie",
            device: null,
            appointmentNames: [CAT_INCLUSION],
            values: _.getValues(allAppointmentsCorrelationSamples,"gonioscopy", [{fieldName: "shaffer", text: "Shaffer 0,I-IV", type: "string"}]),
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("gonioscopyStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("gonioscopyComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createHRTSection = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "HRT",
            device: null,
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples,"hrt", [
                {fieldName: "discArea", text: "Disc Area", type: "float"},
                {fieldName: "rimArea", text: "Rim Area", type: "float"},
                {fieldName: "rimVolume", text: "Rim Volume", type: "float"}
            ]),
            tableStatusField: [
                {
                    dataStorageField: _.createCategorySample("discAreaAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getHRTTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("discAreaAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getHRTTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rimAreaAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rimAreaAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rimVolumeAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rimVolumeAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
            ]
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("konturlinieKorrektRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Konturlinie korrekt?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("konturlinieKorrektLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Konturlinie korrekt?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtHinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtHinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ?
            [{
                dataStorageField: _.createCategorySample("hrtHinweisAufProgressionRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtHinweisAufProgressionLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            }] : [],
            {
                dataStorageField: _.createCategorySample("hrtBildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtBildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtWiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtWiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("hrtComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createBMOMRWSection = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "BMO-MRW",
            device: _.getDevice(allAppointmentsCorrelationSamples,"sdOct"),
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "sdOct", [
                {fieldName: "bmoArea", text: "BMO Area", type: "float"},
                {fieldName: "bmoMrwGlobal", text: "Global", type: "integer"},
                {fieldName: "bmoMrwNasalSuperior", text: "Nasal Superior", type: "integer"},
                {fieldName: "bmoMrwNasal", text: "Nasal", type: "integer"},
                {fieldName: "bmoMrwNasalInferior", text: "Nasal Inferior", type: "integer"},
                {fieldName: "bmoMrwTemporalInferior", text: "Temporal Inferior", type: "integer"},
                {fieldName: "bmoMrwTemporal", text: "Temporal", type: "integer"}
            ]),
            tableStatusField: [
                {
                    dataStorageField: _.createCategorySample("bmoAreaAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoAreaAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwGlobalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwGlobalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwNasalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwTemporalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwTemporalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwTemporalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("bmoMrwTemporalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
            ]
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("bmoMrwHinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwHinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ?
                [{
                    dataStorageField: _.createCategorySample("bmoMrwHinweisAufProgressionRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Progression",
                        __css: "right",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                },
                    {
                        dataStorageField: _.createCategorySample("bmoMrwHinweisAufProgressionLeft", userId, role, patientId, "", null),
                        displayField: {
                            __label: "Hinweis auf Progression",
                            __css: "left",
                            __type: "select",
                            __options: _.getYesNoOptions(),
                            __placeholder: null,
                            __validationRegEx: null,
                            __optional: false,
                            __unit: null
                        }
                    }] : [],
            {
                dataStorageField: _.createCategorySample("bmoMrwBildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwBildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwWiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwWiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("bmoMrwComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createRFNLOCT35Section = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "RFNL-OCT 3,5 mm",
            device: _.getDevice(allAppointmentsCorrelationSamples,"sdOct"),
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "sdOct", [
                {fieldName: "rfnlOct35Global", text: "Global", type: "integer"},
                {fieldName: "rfnlOct35Superior", text: "Superior", type: "integer"},
                {fieldName: "rfnlOct35Inferior", text: "Inferior", type: "integer"},
                {fieldName: "rfnlOct35NasalSuperior", text: "Nasal Superior", type: "integer"},
                {fieldName: "rfnlOct35Nasal", text: "Nasal", type: "integer"},
                {fieldName: "rfnlOct35NasalInferior", text: "Nasal Inferior", type: "integer"},
                {fieldName: "rfnlOct35TemporalInferior", text: "Temporal Inferior", type: "integer"},
                {fieldName: "rfnlOct35Temporal", text: "Temporal", type: "integer"},
                {fieldName: "rfnlOct35TemporalSuperior", text: "Temporal Superior", type: "integer"},
            ]),
            tableStatusField: [
                {
                    dataStorageField: _.createCategorySample("rfnlOct35GlobalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35GlobalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35SuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35SuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35InferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35InferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35NasalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct35TemporalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
            ]
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("rfnlOct35SegmentierungKorrektRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35SegmentierungKorrektLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35HinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35HinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ?
                [{
                    dataStorageField: _.createCategorySample("rfnlOct35HinweisAufProgressionRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Progression",
                        __css: "right",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                },
                    {
                        dataStorageField: _.createCategorySample("rfnlOct35HinweisAufProgressionLeft", userId, role, patientId, "", null),
                        displayField: {
                            __label: "Hinweis auf Progression",
                            __css: "left",
                            __type: "select",
                            __options: _.getYesNoOptions(),
                            __placeholder: null,
                            __validationRegEx: null,
                            __optional: false,
                            __unit: null
                        }
                    }] : [],
            {
                dataStorageField: _.createCategorySample("rfnlOct35BildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35BildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35WiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35WiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35Status", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct35Comment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createRFNLOCT41Section = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "RFNL-OCT 4,1 mm",
            device: _.getDevice(allAppointmentsCorrelationSamples,"sdOct"),
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "sdOct", [
                {fieldName: "rfnlOct41Global", text: "Global", type: "integer"},
                {fieldName: "rfnlOct41Superior", text: "Superior", type: "integer"},
                {fieldName: "rfnlOct41Inferior", text: "Inferior", type: "integer"},
                {fieldName: "rfnlOct41NasalSuperior", text: "Nasal Superior", type: "integer"},
                {fieldName: "rfnlOct41Nasal", text: "Nasal", type: "integer"},
                {fieldName: "rfnlOct41NasalInferior", text: "Nasal Inferior", type: "integer"},
                {fieldName: "rfnlOct41TemporalInferior", text: "Temporal Inferior", type: "integer"},
                {fieldName: "rfnlOct41Temporal", text: "Temporal", type: "integer"},
                {fieldName: "rfnlOct41TemporalSuperior", text: "Temporal Superior", type: "integer"},
            ]),
            tableStatusField: [
                {
                    dataStorageField: _.createCategorySample("rfnlOct41GlobalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41GlobalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41SuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41SuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41InferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41InferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41NasalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct41TemporalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
            ]
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("rfnlOct41SegmentierungKorrektRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41SegmentierungKorrektLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41HinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41HinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ?
                [{
                    dataStorageField: _.createCategorySample("rfnlOct41HinweisAufProgressionRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Progression",
                        __css: "right",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                },
                    {
                        dataStorageField: _.createCategorySample("rfnlOct41HinweisAufProgressionLeft", userId, role, patientId, "", null),
                        displayField: {
                            __label: "Hinweis auf Progression",
                            __css: "left",
                            __type: "select",
                            __options: _.getYesNoOptions(),
                            __placeholder: null,
                            __validationRegEx: null,
                            __optional: false,
                            __unit: null
                        }
                    }] : [],
            {
                dataStorageField: _.createCategorySample("rfnlOct41BildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41BildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41WiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41WiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41Status", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct41Comment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createRFNLOCT47Section = (allAppointmentsCorrelationSamples, tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "RFNL-OCT 4,7 mm",
            device: _.getDevice(allAppointmentsCorrelationSamples,"sdOct"),
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: _.getValues(allAppointmentsCorrelationSamples, "sdOct", [
                {fieldName: "rfnlOct47Global", text: "Global", type: "integer"},
                {fieldName: "rfnlOct47Superior", text: "Superior", type: "integer"},
                {fieldName: "rfnlOct47Inferior", text: "Inferior", type: "integer"},
                {fieldName: "rfnlOct47NasalSuperior", text: "Nasal Superior", type: "integer"},
                {fieldName: "rfnlOct47Nasal", text: "Nasal", type: "integer"},
                {fieldName: "rfnlOct47NasalInferior", text: "Nasal Inferior", type: "integer"},
                {fieldName: "rfnlOct47TemporalInferior", text: "Temporal Inferior", type: "integer"},
                {fieldName: "rfnlOct47Temporal", text: "Temporal", type: "integer"},
                {fieldName: "rfnlOct47TemporalSuperior", text: "Temporal Superior", type: "integer"},
            ]),
            tableStatusField: [
                {
                    dataStorageField: _.createCategorySample("rfnlOct47GlobalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47GlobalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47SuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47SuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47InferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47InferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47NasalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalInferiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalInferiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalSuperiorAuswertungRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "right",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("rfnlOct47TemporalSuperiorAuswertungLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: null,
                        __css: "left",
                        __type: "select",
                        __options: _.getTableOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: true,
                        __unit: null
                    }
                },
            ]
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("rfnlOct47SegmentierungKorrektRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47SegmentierungKorrektLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Segmentierung korrekt?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47HinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47HinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_FINAL ?
                [{
                    dataStorageField: _.createCategorySample("rfnlOct47HinweisAufProgressionRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Progression",
                        __css: "right",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                },
                    {
                        dataStorageField: _.createCategorySample("rfnlOct47HinweisAufProgressionLeft", userId, role, patientId, "", null),
                        displayField: {
                            __label: "Hinweis auf Progression",
                            __css: "left",
                            __type: "select",
                            __options: _.getYesNoOptions(),
                            __placeholder: null,
                            __validationRegEx: null,
                            __optional: false,
                            __unit: null
                        }
                    }] : [],
            {
                dataStorageField: _.createCategorySample("rfnlOct47BildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47BildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47WiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47WiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47Status", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("rfnlOct47Comment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createPapillenphotoSection = (tab, userId, role, patientId) => {
    return {
        sectionField: {
            section: "Papillenphoto",
            device: null,
            appointmentNames: [CAT_INCLUSION, CAT_FINAL],
            values: null,
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("papillenphotoBildqualitaetRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "right",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoBildqualitaetLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Bildqualität",
                    __css: "left",
                    __type: "select",
                    __options: _.getBuildQualityOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoWiederholungErbetenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoWiederholungErbetenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Wiederholung erbeten?",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoPapillenrandblutungRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Papillenrandblutung",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoPapillenrandblutungLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Papillenrandblutung",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoUhrzeitPapillenrandblutungRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Uhrzeit Papillenrandblutung",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoUhrzeitPapillenrandblutungLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Uhrzeit Papillenrandblutung",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoNichtGlaukomatoeseOptikusatrophieRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Nicht-glaukomatöse Optikusatrophie",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoNichtGlaukomatoeseOptikusatrophieLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Nicht-glaukomatöse Optikusatrophie",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVergroesserteAtrophiezoneRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vergrößerte Atrophiezone",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVergroesserteAtrophiezoneLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vergrößerte Atrophiezone",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVertikaleCDRRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vertikale CDR",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVertikaleCDRLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vertikale CDR",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoHorizontaleCDRRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Horizontale CDR",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoHorizontaleCDRLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Horizontale CDR",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoKerbeImRandsaumRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kerbe im Randsaum",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoKerbeImRandsaumLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kerbe im Randsaum",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoKerbeImRandsaumUhrzeitRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kerbe im Randsaum Uhrzeit",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoKerbeImRandsaumUhrzeitLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kerbe im Randsaum Uhrzeit",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                },
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVorliegenEinesCilioretinalenGefaessesRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vorliegen eines cilioretinalen Gefäßes",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoVorliegenEinesCilioretinalenGefaessesLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vorliegen eines cilioretinalen Gefäßes",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoAnzahlDerCilioretinalenGefaesseRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Anzahl der cilioretinalen Gefäße",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "ZZ",
                    __validationRegEx: _.regExs.REGEX_INTEGER,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoAnzahlDerCilioretinalenGefaesseLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Anzahl der cilioretinalen Gefäße",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "ZZ",
                    __validationRegEx: _.regExs.REGEX_INTEGER,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoLageDerCilioretinalenGefaesseRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Lage der cilioretinalen Gefäße",
                    __css: "right",
                    __type: "select",
                    __options: _.getCilioretinalPlusOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoLageDerCilioretinalenGefaesseLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Lage der cilioretinalen Gefäße",
                    __css: "left",
                    __type: "select",
                    __options: _.getCilioretinalPlusOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            ...tab === CAT_INCLUSION ? [
                {
                    dataStorageField: _.createCategorySample("papillenphotoHinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Glaukomschaden",
                        __css: "right",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                },
                {
                    dataStorageField: _.createCategorySample("papillenphotoHinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                    displayField: {
                        __label: "Hinweis auf Glaukomschaden",
                        __css: "left",
                        __type: "select",
                        __options: _.getYesNoOptions(),
                        __placeholder: null,
                        __validationRegEx: null,
                        __optional: false,
                        __unit: null
                    }
                }] : [],
            {
                dataStorageField: _.createCategorySample("papillenphotoStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};

export const createPapillenphotoProgressionSection = (userId, role, patientId) => {
    return {
        sectionField: {
            section: "Papillenphoto Progression",
            device: null,
            appointmentNames: [CAT_FINAL],
            values: null,
            tableStatusField: null
        },
        statusField: [
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueAusduennungDesRandsaumsRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Ausdünnung des Randsaums",
                    __css: "right",
                    __type: "select",
                    __options: _.getNeueAusduennungDesRandsaumsOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueAusduennungDesRandsaumsLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Ausdünnung des Randsaums",
                    __css: "left",
                    __type: "select",
                    __options: _.getNeueAusduennungDesRandsaumsOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionMaximumRandsaumRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Maximum Randsaum",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionMaximumRandsaumLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Maximum Randsaum",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionRangeRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Range",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionRangeLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Range",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "Z.ZZ",
                    __validationRegEx: _.regExs.REGEX_DECIMAL,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueKerbeImRandsaumRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Kerbe im Randsaum",
                    __css: "right",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueKerbeImRandsaumLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Kerbe im Randsaum",
                    __css: "left",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Ausdünnung des Randsaums Uhrzeit",
                    __css: "right",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionNeueAusduennungDesRandsaumsUhrzeitLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Neue Ausdünnung des Randsaums Uhrzeit",
                    __css: "left",
                    __type: "input",
                    __options: null,
                    __placeholder: "HH:MM",
                    __validationRegEx: _.regExs.REGEX_DURATION_HOUR_MINUTE_30,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVermehrteFokaleBlaesseRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vermehrte fokale Blässe",
                    __css: "right",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVermehrteFokaleBlaesseLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vermehrte fokale Blässe",
                    __css: "left",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVermehrteDiffuseBlaesseRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vermehrte diffuse Blässe",
                    __css: "right",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVermehrteDiffuseBlaesseLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Vermehrte diffuse Blässe",
                    __css: "left",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVeraenderungInDerCDRRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Veränderung in der CDR",
                    __css: "right",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionVeraenderungInDerCDRLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Veränderung in der CDR",
                    __css: "left",
                    __type: "select",
                    __options: _.getPapillenphotoProgressionOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionHinweisAufGlaukomschadenRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionHinweisAufGlaukomschadenLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Glaukomschaden",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionHinweisAufProgressionRight", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "right",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionHinweisAufProgressionLeft", userId, role, patientId, "", null),
                displayField: {
                    __label: "Hinweis auf Progression",
                    __css: "left",
                    __type: "select",
                    __options: _.getYesNoOptions(),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            }, {
                dataStorageField: _.createCategorySample("papillenphotoProgressionStatus", userId, role, patientId, "", null),
                displayField: {
                    __label: "Status",
                    __css: "",
                    __type: "select",
                    __options: _.getStatusOptions(role),
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: false,
                    __unit: null
                }
            },
            {
                dataStorageField: _.createCategorySample("papillenphotoProgressionComment", userId, role, patientId, "", null),
                displayField: {
                    __label: "Kommentar",
                    __css: "",
                    __type: "textarea",
                    __options: null,
                    __placeholder: null,
                    __validationRegEx: null,
                    __optional: true,
                    __unit: null
                }
            }
        ]
    }
};