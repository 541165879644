import React from "react";
import NoResultsFound from "../NoResultsFound";
import RenderReaderTable from "./RenderReaderTable";

const ReaderView = ({patients, loading, searchString}) => {
    const noData = !patients.length && !loading;
    const hasResults = !!(patients && patients.length);

    const filterPatients = () => {
        return patients.filter((patient) => patient.patientId.toUpperCase().includes(searchString.toUpperCase()));
    };

    return (
        <div className={"c-container scroll-y"}>
            {hasResults && <RenderReaderTable tableData={filterPatients()}/>}
            {noData && (
                <div className={"center-all"}>
                    <NoResultsFound />
                </div>
            )}
        </div>
    );
};

export default ReaderView;