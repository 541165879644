import gql from "graphql-tag";
import {addressFragment} from './addressFragment';

export const organisationContactFragment = gql`
    fragment organisationContactFragment on OrganisationContact {
        id
        prefix
        forename
        surname
        suffix
        jobTitle
        gender
        address {
            ...addressFragment
        }
        email
        telephoneno    }
    ${addressFragment}
`;
