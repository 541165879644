import {copyOverModified} from "services";
import {APPT_STATUS_NOT_SCHEDULED_YET, CAT_CONTROL} from "../mutations/appointments";
import {find} from "lodash";
import {createCorrelation, createOptions, createQuantity, createSample, regExs} from "./utils";

const createModel = (patient,t) => {
    const appointments = patient.appointments;
    const payload = find(appointments, (it) => it.category === CAT_CONTROL) || {};

    const __24hrrdeviceModel = createOptions([
        "",
        "Custoscreen 200/300",
        "PhysioPort",
        "Spacelabs",
        ], {
        __placeholder: t("no_selection"),
        __optional: false,
    });

    const model = {
        id: null,
        startDate: null,
        endDate: null,
        category: CAT_CONTROL,
        __roles: ["clinician"],
        doctors: [],
        correlationSamples: [
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "24h Blutdruckmessungen",
                    correlationType: "24hrrControlGroup",
                    __deviceModel:__24hrrdeviceModel,
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "successfulMeasurementsNumber",
                        __label: "Erfolgreiche Messungen (Anzahl)",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer")
                    }),
                    createQuantity({
                        quantityType: "successfulMeasurementsPercentage",
                        __label: "Erfolgreiche Messungen",
                        __css: "c-column-right",
                        __placeholder: "ZZ.Z",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL_0_100) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "%"
                    }),
                    /*************************Gesamtübersicht*******************/
                    {__separator: {title: "Gesamtübersicht" }},
                    {__separator:{subgroup: "Systole"}},
                    createQuantity({
                        quantityType: "systoleAverageOverall",
                        __label: "Systole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleStdDeviationOverall",
                        __label: "Systole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMinimumOverall",
                        __label: "Systole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMaximumOverall",
                        __label: "Systole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleDippingOverall",
                        __label: "Dipping systolisch",
                        __css: "c-column-left",
                        __placeholder: "-ZZ.Z",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal_with_sign"),
                        unit : "%"
                    }),
                    {__separator:{subgroup: "Diastole"}},
                    createQuantity({
                        quantityType: "diastoleAverageOverall",
                        __label: "Diastole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleStdDeviationOverall",
                        __label: "Diastole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMinimumOverall",
                        __label: "Diastole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMaximumOverall",
                        __label: "Diastole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleDippingOverall",
                        __label: "Dipping diastolisch",
                        __css: "c-column-left",
                        __placeholder: "-ZZ.Z",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal_with_sign"),
                        unit : "%"
                    }),
                    {__separator:{subgroup: "Mittlerer aterieller Druck (MAD)"}},
                    createQuantity({
                        quantityType: "madAverageOverall",
                        __label: "MAD Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madStdDeviationOverall",
                        __label: "MAD Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMinimumOverall",
                        __label: "MAD Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMaximumOverall",
                        __label: "MAD Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madDippingOverall",
                        __label: "Dipping MAD",
                        __css: "c-column-left",
                        __placeholder: "-ZZ.Z",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal_with_sign"),
                        unit : "%"
                    }),
                    {__separator:{subgroup: "Pulsdruck"}},
                    createQuantity({
                        quantityType: "pulsePressureAverageOverall",
                        __label: "Pulsdruck Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureStdDeviationOverall",
                        __label: "Pulsdruck Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMinimumOverall",
                        __label: "Pulsdruck Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMaximumOverall",
                        __label: "Pulsdruck Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Herzfrequenz"}},
                    createQuantity({
                        quantityType: "hfAverageOverall",
                        __label: "Herzfrequenz Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfStdDeviationOverall",
                        __label: "Herzfrequenz Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMinimumOverall",
                        __label: "Herzfrequenz Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMaximumOverall",
                        __label: "Herzfrequenz Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    /*************************Wachphase*******************/
                    {__separator: {title: "Wachphase" }},
                    {__separator:{subgroup: "Systole"}},
                    createQuantity({
                        quantityType: "systoleAverageAwake",
                        __label: "Systole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleStdDeviationAwake",
                        __label: "Systole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMinimumAwake",
                        __label: "Systole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMaximumAwake",
                        __label: "Systole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Diastole"}},
                    createQuantity({
                        quantityType: "diastoleAverageAwake",
                        __label: "Diastole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleStdDeviationAwake",
                        __label: "Diastole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMinimumAwake",
                        __label: "Diastole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMaximumAwake",
                        __label: "Diastole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Mittlerer aterieller Druck (MAD)"}},
                    createQuantity({
                        quantityType: "madAverageAwake",
                        __label: "MAD Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madStdDeviationAwake",
                        __label: "MAD Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMinimumAwake",
                        __label: "MAD Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMaximumAwake",
                        __label: "MAD Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Pulsdruck"}},
                    createQuantity({
                        quantityType: "pulsePressureAverageAwake",
                        __label: "Pulsdruck Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureStdDeviationAwake",
                        __label: "Pulsdruck Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMinimumAwake",
                        __label: "Pulsdruck Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMaximumAwake",
                        __label: "Pulsdruck Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Herzfrequenz"}},
                    createQuantity({
                        quantityType: "hfAverageAwake",
                        __label: "Herzfrequenz Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfStdDeviationAwake",
                        __label: "Herzfrequenz Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMinimumAwake",
                        __label: "Herzfrequenz Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMaximumAwake",
                        __label: "Herzfrequenz Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    /*************************Schlafphase*******************/
                    {__separator: {title: "Schlafphase" }},
                    {__separator:{subgroup: "Systole"}},
                    createQuantity({
                        quantityType: "systoleAverageAsleep",
                        __label: "Systole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleStdDeviationAsleep",
                        __label: "Systole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMinimumAsleep",
                        __label: "Systole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "systoleMaximumAsleep",
                        __label: "Systole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Diastole"}},
                    createQuantity({
                        quantityType: "diastoleAverageAsleep",
                        __label: "Diastole Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleStdDeviationAsleep",
                        __label: "Diastole Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMinimumAsleep",
                        __label: "Diastole Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "diastoleMaximumAsleep",
                        __label: "Diastole Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Mittlerer aterieller Druck"}},
                    createQuantity({
                        quantityType: "madAverageAsleep",
                        __label: "MAD Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madStdDeviationAsleep",
                        __label: "MAD Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMinimumAsleep",
                        __label: "MAD Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "madMaximumAsleep",
                        __label: "MAD Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Pulsdruck"}},
                    createQuantity({
                        quantityType: "pulsePressureAverageAsleep",
                        __label: "Pulsdruck Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureStdDeviationAsleep",
                        __label: "Pulsdruck Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMinimumAsleep",
                        __label: "Pulsdruck Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    createQuantity({
                        quantityType: "pulsePressureMaximumAsleep",
                        __label: "Pulsdruck Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "mmHg"
                    }),
                    {__separator:{subgroup: "Herzfrequenz"}},
                    createQuantity({
                        quantityType: "hfAverageAsleep",
                        __label: "Herzfrequenz Mittel",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfStdDeviationAsleep",
                        __label: "Herzfrequenz Standardabweichung",
                        __css: "c-column-right",
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMinimumAsleep",
                        __label: "Herzfrequenz Minimum",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),
                    createQuantity({
                        quantityType: "hfMaximumAsleep",
                        __label: "Herzfrequenz Maximum",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: true,
                        __description: t("tooltip_integer"),
                        unit : "BPM"
                    }),

                ],
                categorySamples:[
                    createSample({
                        subject: patient.id,
                        categoryType: "startdate24hrr",
                        __label: "Startdatum und -uhrzeit",
                        __css: "c-column-left",
                        __type: "datetime",
                        __optional: true,
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "enddate24hrr",
                        __label: "Enddatum und -uhrzeit",
                        __css: "c-column-right",
                        __type: "datetime",
                        __optional: true,
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "duration24hrr",
                        __label: "Dauer",
                        __css: "c-column-left",
                        __placeholder: "HH:MM",
                        __optional: true,
                        __description: t("tooltip_duration"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DURATION_HOUR_MINUTE) || t("E_INVALID")
                        }
                    }),
                ]

            }
        ],
        status: APPT_STATUS_NOT_SCHEDULED_YET,
        interventions: patient.interventions,
        documentSamples: [],
        recommendedTherapy: {
            therapy: ""
        },
    };

    return copyOverModified(model, payload);
};

export default createModel;
