import gql from "graphql-tag";
import {metaDataFragment} from './metaDataFragment';

export const categorySampleFragment =  gql`
    fragment categorySampleFragment on CategorySample {
        id
        categoryType
        endDate
        metadata {
            ...metaDataFragment
        }
        sampleType
        startDate
        subject
        value
    }
    ${metaDataFragment}
`;
