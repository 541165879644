import gql from "graphql-tag";
import {exclusionFragment} from "./exclusionFragment";

export const studyDataFragment = gql`
    fragment studyDataFragment on StudyData {
        cohort
        studyid
        exclusion {
            ...exclusionFragment
        }
        inclusionDate
        doctorInCharge{
            lanr
            forename
            surname
        }
    }
    ${exclusionFragment}
`;
