import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/react-hooks";
import {MEDICATIONS} from "../data/queries/queries";
import {toastError} from "../services";

export const useMedications = (id) => {
    const {t} = useTranslation();
    const {loading, error, data, refetch} = useQuery(MEDICATIONS, {variables: {id}});

    if (error) {
        toastError(t("error_gql"));
    }

    const reload = (id) => {
        refetch({id});
    };
    return {loading, data, reload};
};
