import React, {useState} from "react";
import {isDemo} from "../services/utils";
import {Button, Icon, Modal} from "semantic-ui-react";

const DemoModalWarning = () => {
    const [showModalWarning, setShowModalWarning] = useState(isDemo());

    if(isDemo())
        return (
            <Modal
                basic
                onClose={() => setShowModalWarning(false)}
                onOpen={() => setShowModalWarning(true)}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={showModalWarning}
                size='small'>
                <Modal.Content>
                    <p>
                        Wir weisen ausdrücklich darauf hin, dass diese eine Demoversion ist und bei diesem System keine personenbezogenen
                        Daten besonderer Kategorien nach Art. 9 Abs. 1 DSG-VO, d.h. insbesondere keine
                        personenbezogenen oder medizinischen Daten, über Dritte, wie Patienten,
                        eingegeben und gespeichert werden dürfen und wir keine rechtliche Verantwortung bei
                        Zuwiderhandlung übernehmen.
                        Nach Beendigung der Testphase werden die gespeicherten Testdaten gelöscht, längstens aber für ein
                        halbes Jahr gespeichert.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted onClick={() => setShowModalWarning(false)}>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    else {
        return (
            <>
            </>
        )
    }
};

export default DemoModalWarning;
