import React, {useState} from "react";
import {Button, Header, Icon, List, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const Help = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={
                   <Button inverted className={"button-plain"}>
                        <Icon name={"help"}/> <label>{t("help")}</label>
                   </Button>
               }
        >
            <Header>
                {t("help")}
            </Header>
            <Modal.Content className={"c-center"}>
                <List divided relaxed>
                    <List.Item>
                        <List.Icon name='video' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/videos/SALUS_PatientErstellen.mp4"} target={"_blank"} rel={"noopener noreferrer"}>Patienten in die Studie einschließen</a>
                            </List.Header>
                            <List.Description>Video, das den Einschluss eines neuen Patienten zeigt.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/videos/SALUS_Untersuchung.mp4"} target={"_blank"} rel={"noopener noreferrer"}>Eine Untersuchung ausfüllen</a>
                            </List.Header>
                            <List.Description>Video, das das Ausfüllen einer Untersuchung zeigt.</List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default Help;
