import React, {useContext, useState} from "react";
import {Button, Icon, Modal, Header} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import NewPatientForm from "./patientNew/NewPatientForm";
import Finish from "./agreement/Finish";
import {AuthContext} from "../services";
import {useMutateMasterData} from "../hooks";

const STEPS = {
    EDIT_PATIENT: "EDIT_PATIENT",
    FINISH: "FINISH"
};

const PatientMasterDataEdit = ({patient, onClose}) => {
    const {t} = useTranslation();
    let saveCallback;
    const [step, setStep] = useState(STEPS.EDIT_PATIENT);
    const {user} = useContext(AuthContext);
    const isStudyLead = user.roles.includes("study_lead");
    const {mutateMasterData, loading} = useMutateMasterData({isEditMode: true});

    const handleClose = (refreshData) => {
        onClose(refreshData);
    };

    const onSaveRequested = () => {
        if (saveCallback) {
            saveCallback();
        }
    };

    const backVisible = () => {
        if (!isStudyLead) return false;
        return step !== STEPS.EDIT_PATIENT;
    };

    const nextVisible = () => {
        if (!isStudyLead) return false;
        return step !== STEPS.FINISH;
    };

    const getLabelForNext = () => {
        return t("next");
    };

    const handleSave = () => {
        if (saveCallback) {
            return saveCallback();
        } else {
            return true;
        }
    };

    const registerSaveCallback = (saveFn) => {
        saveCallback = saveFn;
    };

    const handleNext = () => {
        switch (step) {
            case STEPS.EDIT_PATIENT:
                if (handleSave()) {
                    setStep(STEPS.FINISH);
                }
                break;
            default:
                console.warn("Unhandled case in NewPatient handleNext:", step);
        }
    };

    const handleBack = () => {
        switch (step) {
            case STEPS.FINISH:
                setStep(STEPS.EDIT_PATIENT);
                break;
            default:
                console.warn("Unhandled case in NewPatient handleBack:", step);
        }
    };

    const saveData = () => {
        if (!loading) {
            const patientEdited = JSON.parse(window.localStorage.getItem("patient_to_be_edited") || "{}");

            const variables = {
                masterData: patientEdited.masterData,
                patientId: patientEdited.id
            };

            mutateMasterData(variables).then(() => {
                handleClose(true);
            });
        } else {
            return false;
        }
    };

    return (
        <div>
            <Modal open onClose={() => handleClose(false)}>
                <Header>
                    <Icon name={"pencil square"} inverted /> {t("edit")}
                </Header>

                <Modal.Content scrolling={!isStudyLead}>
                    <Modal.Description>
                        {step === "EDIT_PATIENT" &&
                            <NewPatientForm
                                registerSaveCallback={registerSaveCallback}
                                patientData={patient}
                                isEditMode={true}
                                onSaveFinished={() => handleClose(true)}
                            />
                        }

                        {step === STEPS.FINISH && <Finish/>}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button basic onClick={() => handleClose(false)}>
                        {t("cancel")}
                    </Button>

                    {nextVisible() &&
                    <Button color={"teal"} onClick={handleNext}>
                        {getLabelForNext()}
                        <Icon name='chevron right'/>
                    </Button>
                    }

                    {backVisible() &&
                    <Button basic={true} onClick={handleBack}>
                        <Icon name='chevron left'/> {t("back")}
                    </Button>
                    }

                    {(step !== "EDIT_PATIENT" || !isStudyLead) &&
                        <Button color={"teal"} onClick={isStudyLead ? saveData : onSaveRequested}>
                            {t("save")}
                        </Button>
                    }
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default PatientMasterDataEdit;
