import flatten from "flat";
import * as _ from "lodash";
import {format} from "date-fns";
import {CAT_CONTROL} from "../data/mutations/appointments";

export const removePrivateFields = (obj) => {
    for (const prop in obj) {
        if (prop.indexOf("__") === 0) {
            delete obj[prop];
        } else if (typeof obj[prop] === "object") {
            removePrivateFields(obj[prop]);
        }
    }
    return obj;
};

export const verifyGraphQlResponse = (response) => {
    if (response.errors && response.errors.length) {
        response.errors.forEach((e) => {
            console.error(JSON.stringify(e))

        });
        throw Error("GraphQL response errors found.");
    }
};

//copy over values from object matching the paths defined in templateObject
export const copyOver = (templateObject, object) => {
    const result = {};
    const templateFlatten = flatten(templateObject);
    const tfKeys = _.keys(templateFlatten);

    tfKeys.forEach((key) => {
        const objectValue = _.get(object, key, templateFlatten[key]);
        _.set(result, key, objectValue);
    });
    return result;
};

// copy over values from object matching the paths defined in templateObject
// the function goes over "correlationType", the should have different names!
// do not use for TENSIO!
export const copyOverModified = (template, payload) => {
    const result = {};
    // fast copy
    const copy = JSON.parse(JSON.stringify(template));
    // delete correlationSamples
    if ("correlationSamples" in template) {
        delete copy.correlationSamples
    }
    const templateFlatten = flatten(copy);
    const tfKeys = _.keys(templateFlatten);

    tfKeys.forEach((key) => {
        const objectValue = _.get(payload, key, templateFlatten[key]);
        _.set(result, key, objectValue);
    });

    result["correlationSamples"] = copyOverCorrelationSamples(
        _.get(template, "correlationSamples", []),
        _.get(payload, "correlationSamples", [])
    );

    if (_.get(result, "category", "") === CAT_CONTROL) {
        let tensioFileData = _.get(payload, "correlationSamples", []).filter(sample => {return (sample.correlationType==="tensioMeasurement") || (sample.correlationType === "tensioControlImport")}) || [];

        if(tensioFileData.length !== 0) {
            tensioFileData.forEach(val => result.correlationSamples.push(val));
        }
    }

    return result;
};

// correlationType should have different names
// do not use for TENSIO
const copyOverCorrelationSamples = (templateArray, payloadArray) => {
    const result = [];
    templateArray.forEach(tCs => {
        const r = {};
        const tCorrelationTypeName = _.get(tCs, "correlationType");
        const payloadCs = payloadArray.find( o => _.get(o, "correlationType") === tCorrelationTypeName) || {};

        const copy = JSON.parse(JSON.stringify(tCs));
        if ("quantitySamples" in copy) {
            delete copy.quantitySamples
        }
        if ("categorySamples" in copy) {
            delete copy.categorySamples
        }

        const templateFlatten = flatten(copy);
        const tfKeys = _.keys(templateFlatten);

        tfKeys.forEach((key) => {
            const objectValue = _.get(payloadCs, key, templateFlatten[key]);
            _.set(r, key, objectValue);
        });

        const qs = copyOverArrayByKey(
            _.get(tCs, "quantitySamples", []),
            _.get(payloadCs, "quantitySamples", []),
            "quantityType"
        );
        const categorySamples = copyOverArrayByKey(
            _.get(tCs, "categorySamples", []),
            _.get(payloadCs, "categorySamples", []),
            "categoryType"
        );
        _.set(r, "quantitySamples", qs);
        _.set(r, "categorySamples", categorySamples);
        result.push(r);
    })
    return result;
}

const copyOverArrayByKey = (templateArray, payloadArray, key) => {
    const result = [];
    templateArray.forEach(templateElement => {
        const r = {};
        const name = _.get(templateElement, key);
        const payloadCatType = payloadArray.find( o => _.get(o, key) === name) || {};

        const templateFlatten = flatten(templateElement);
        const tfKeys = _.keys(templateFlatten);

        tfKeys.forEach((key) => {
            const objectValue = _.get(payloadCatType, key, templateFlatten[key]);
            _.set(r, key, objectValue);
        });
        result.push(r);
    });
    return result;
}

//Date time formatting
export const FORMAT_DATE = "dd.MM.yyyy";
export const FORMAT_DATE_TIME = "dd.MM.yyyy HH:mm";

export const formatDate = (dateString) => {
    if(dateString) {
        return     format(new Date(dateString), FORMAT_DATE);
    } else {
        console.warn("services.utils.formatDate called with null/undefined.");
        return "";
    }
};

export const formatDateTime = (dateString) => {
    if(dateString) {
        return format(new Date(dateString), FORMAT_DATE_TIME);
    } else {
        console.warn("services.utils.formatDateTime called with null/undefined.");
        return "";
    }
};

export const user_roles = {
    ROLE_RESIDENT : "resident",
    ROLE_CLINICIAN : "clinician"
};

export function stringToBoolean(string){
    switch(_.toLower(string).trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
    }
}

export const isDemo = () => {
    return stringToBoolean(process.env.REACT_APP_DEMO_WARNING);
};

export const downloadCSV = (data, filename, type = 'text/csv;charset=utf-8;') => {
    const blob = new Blob([data], { type: type })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}

