import React, {useState} from "react";
import {Button, Divider, Header, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const Support = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Button className={"teal"}>{t("footer_menu_contact")}</Button>}
        >
            <Header>
                 {t("footer_menu_contact")}
            </Header>
            <Modal.Content className={"c-center"}>
                <h1>Infohotline</h1>
                <p>
                    <h3>Mo-Fr 08:00-19:00 Uhr</h3>
                    <h3>Telefon: 0251 83-40500</h3>
                </p>
                <a href={"mailto:salus-glaukom@ukmuenster.de"}>
                salus-glaukom@­ukmuenster.de
                </a>

                <h1>Technischer Support</h1>
                <a href={"mailto:salus-support@fit.fraunhofer.de"}>
                    salus-support@fit.fraunhofer.de
                </a>
                <Divider/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default Support;
