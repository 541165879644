import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/react-hooks";
import {toastError, toastSuccess, verifyGraphQlResponse} from "../services";
import {UPSERT_INTERVENTION} from "../data/mutations/interventions";

export const useMutateIntervention = () => {
    const {t} = useTranslation();
    const [mutate, {loading}] = useMutation(UPSERT_INTERVENTION);

    const mutateIntervention = (variables) => {
        if (loading) {
            return Promise.reject("Loading in progress...");
        }
        return mutate({variables})
            .then((response) => {
                verifyGraphQlResponse(response);
                toastSuccess(t("save_success"));
                return response;
            })
            .catch((e) => {
                console.error(e);
                toastError(t("error_gql"));
            });
    };

    return {mutateIntervention, loading};

};
