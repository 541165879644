import {TAB_QUESTIONNAIRE} from "../patientEdit/Tabs";
import CorrelationSamplesBlock from "./RenderCorrelationSamplesBlock";
import React, {forwardRef, useImperativeHandle, useRef} from "react";
import {Button, Divider, Form, TextArea} from "semantic-ui-react";
import {toastError} from "../../services";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

const QuestionnaireTab = ({dataModel, patientId, isReadOnly, afterSave, readerCComment}, ref) => {
    const correlationSamplesRef = useRef();
    const {t} = useTranslation();
    const form = useForm({
        mode: "onChange"
    });

    const checkRef = (ref) => {
        return ref && ref.current;
    };

    useImperativeHandle(ref, () => ({
        hasChanged () {
            return correlationSamplesRef.current.hasChanged();
        },
        hasError () {
            return correlationSamplesRef.current.hasError();
        },
        save() {
            return handleSave();
        }
    }));

    const handleSave = (data) => {
        if (checkRef(correlationSamplesRef) && correlationSamplesRef.current.hasChanged()) {
            if (!correlationSamplesRef.current.hasError()) {
                correlationSamplesRef.current.save(data).then(() => {
                    form.reset();
                    afterSave();
                });
            }else {
                toastError("Form has errors")
            }
        } else {
           //do nothing
        }
    };

    const onError = (errors, e) => {
        toastError(t("error_fields_incorrect"));
    }

    const renderReaderCComment = (label, value, key) => {
        return (
            <div key={`${key}_${label}`}>
                <Divider/>
                <label className={"pr-1"}><b>{label}</b></label>
                <TextArea
                    key={key}
                    value={value}
                    rows={5}
                    disabled={true}
                />
                <Divider/>
            </div>
        );
    };

    return (
        <Form id="questionnaire-form" onSubmit={form.handleSubmit(handleSave, onError)}>
            {readerCComment && renderReaderCComment(t("reader_c_comment"), readerCComment, "reader_c_comment")}

            <CorrelationSamplesBlock data={dataModel} patientId={patientId} tab={TAB_QUESTIONNAIRE}
                                     ref={correlationSamplesRef} isReadOnly={isReadOnly} form={form}/>
            <Divider/>
            <br/>

            <div className={"Grid -right"}>
                <Button disabled={isReadOnly} basic type="submit" size="tiny" color="teal"
                        content={t("save")} form="questionnaire-form"/>
            </div>
        </Form>
    )
}

export default forwardRef(QuestionnaireTab);
