import gql from "graphql-tag";

export const PAT_STATUS_DATA_MISSING = "DATA_MISSING";
export const PAT_STATUS_DATA_ACTIVE = "ACTIVE";
export const PAT_STATUS_DATA_EXCLUDED = "EXCLUDED";


export const CREATE_PATIENT = gql`
    mutation CreatePatient($input: PatientMasterDataInput!, $doctorInCharge: ID!) {
        createPatient(input: $input, doctorInCharge: $doctorInCharge) {
            id
        }
    }
`;

export const UPDATE_PATIENT = gql`
    mutation UpdatePatient($masterData: PatientMasterDataInput!, $patientId: ID!) {
        updateMasterData(masterData: $masterData, patientId: $patientId) {
            id
        }
    }
`;
