import gql from "graphql-tag";
import {addressFragment} from "./addressFragment";

export const profileFragment = gql`
    fragment profileFragment on Profile {
        title
        forename
        surname
        lanr
        address {
            ...addressFragment
        }
    }
    ${addressFragment}
`;
