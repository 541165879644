import React from "react";
import {Form} from "semantic-ui-react";
import RenderReaderZoneTensio from "./RenderReaderZoneTensio";
import RenderReaderZone from "./RenderReaderZone";

const DataEntryTab = ({dataModel, patientId, allAppointmentsCorrelationSamples, refresh, patient, isControlCohort, readerCComment}) => {

    function getCurrentTab() {
        return window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
    }

    return (
        <Form>
            <div className={"pb-2"}>

                {(getCurrentTab() === "3") ? // || getCurrentTab() === "4") ?
                    <RenderReaderZoneTensio
                        patient={patient}
                        refresh={refresh}
                        dataModel={dataModel}
                        patientId={patientId}
                        readerCComment={readerCComment}
                        allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                        isControlCohort={isControlCohort}/>
                    :
                    <RenderReaderZone
                        patient={patient}
                        refresh={refresh}
                        dataModel={dataModel}
                        patientId={patientId}
                        readerCComment={readerCComment}
                        allAppointmentsCorrelationSamples={allAppointmentsCorrelationSamples}
                        isControlCohort={isControlCohort}/>
                }
            </div>
        </Form>
    );
};

export default DataEntryTab;