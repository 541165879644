import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getQuantities, printPageNo} from "./util";
import {formatDateTime} from "../../services";
import {printChart} from "./util";

export async function clinicalTTPTensioProfileControl(masterData, correlationSamples, appointmentDate, domNode) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Clinical_TTP_Tensio_Profile_Control_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Stationäres TTP - Tensioprofil &\n24h Blutdruckmessung", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const helveticaBoldFont = await pdfDocWithHeading.embedFont(StandardFonts.HelveticaBold);
    const page = pdfDocWithHeading.getPages()[0];

    // addding heading to first page
    page.drawLine({start: { x: 40, y: 270 }, end: { x: 555, y: 270 }, thickness: 0.1});
    page.drawText("Messzeitpunkt", { x: 55, y: 255, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 180, y: 270 }, end: { x: 180, y: 245 }, thickness: 0.1});
    page.drawText("IOP (OD)", { x: 185, y: 255, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 235, y: 270 }, end: { x: 235, y: 245 }, thickness: 0.1});
    page.drawText("IOP (OS)", { x: 240, y: 255, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 290, y: 270 }, end: { x: 290, y: 245 }, thickness: 0.1});
    page.drawText("Gerät", { x: 405, y: 255, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 40, y: 245 }, end: { x: 555, y: 245 }, thickness: 0.1});

    const tableData = convertToTable(correlationSamples);

    await printChart(pdfDocWithHeading, domNode);

    printTable(tableData, pdfDocWithHeading, courierFont, helveticaBoldFont);

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Stationäres_TTP_Tensio_Profile_Summary", "application/pdf");
}

function convertToTable(correlationSamples) {
    return correlationSamples?.map(sample => {
       return [
           sample?.startDate,
           getQuantities(sample, "intraocularPressureRight")?.quantity?.toString() || "",
           getQuantities(sample, "intraocularPressureLeft")?.quantity?.toString() || "",
           sample?.metadata?.deviceName || "",
       ]
    });
}

function printTable(tableData, pdfDoc, courierFont, helveticaBoldFont) {
    let yValue = 230;
    const interval = 25;
    const maxYValue = 100;
    let page = pdfDoc.getPages()[0];

    for (let i = 0; i < tableData.length; ++i) {

        page.drawText(formatDateTime(tableData[i][0]).toString(), { x: 55, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 180, y: yValue+15 }, end: { x: 180, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][1].toString(), { x: 200, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 235, y: yValue+15 }, end: { x: 235, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][2].toString(), { x: 255, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 290, y: yValue+15 }, end: { x: 290, y: yValue-10 }, thickness: 0.1});
        page.drawText(tableData[i][3].toString(), { x: 305, y: yValue, size: 10, font: courierFont });
        page.drawLine({start: { x: 40, y: yValue-10 }, end: { x: 555, y: yValue-10 }, thickness: 0.1});

        yValue -= 25;

        if (yValue - interval < maxYValue) {
            page = pdfDoc.addPage();
            printTableHeading(page, helveticaBoldFont);
            yValue = 760;
        }
    }

    printPageNo(pdfDoc, helveticaBoldFont);
}

function printTableHeading(page, helveticaBoldFont) {
    page.drawLine({start: { x: 40, y: 800 }, end: { x: 555, y: 800 }, thickness: 0.1});
    page.drawText("Messzeitpunkt", { x: 55, y: 785, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 180, y: 800 }, end: { x: 180, y: 775 }, thickness: 0.1});
    page.drawText("IOP (OD)", { x: 185, y: 785, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 235, y: 800 }, end: { x: 235, y: 775 }, thickness: 0.1});
    page.drawText("IOP (OS)", { x: 240, y: 785, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 290, y: 800 }, end: { x: 290, y: 775 }, thickness: 0.1});
    page.drawText("Gerät", { x: 405, y: 785, size: 10, font: helveticaBoldFont })
    page.drawLine({start: { x: 40, y: 775 }, end: { x: 555, y: 775 }, thickness: 0.1});
}

