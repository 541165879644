import React, {useContext, useEffect, useState} from "react";
import {Button, Dropdown, Header, Icon, Loader, Modal, Select} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {insurance_companies} from "../../data/models/utils";
import axios from "axios";
import {AuthContext, downloadCSV, toastError, toastSuccess} from "../../services";

const ExportData = ({ afterSave, closeDropDown }) => {
    const auth = useContext(AuthContext);
    const {t} = useTranslation()
    const [selectedHI, setSelectedHI] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    /* eslint-disable */
    useEffect(() => {
        if(modalOpen === true){
            closeDropDown(true);
        } else {
            closeDropDown(false);
        }
    }, [modalOpen]);
    /* eslint-enable */

    const handleSave = () => {
        setIsLoading(true)
        axios.get(
            `${process.env.REACT_APP_SERVER_URI}/api/insurance-export/${encodeURI(selectedHI)}`,
            {headers: {Authorization: "Bearer " + auth.keycloak.token}})
            .then(function(response) {
                if (response.status === 200) {
                    downloadCSV(response?.data, `${selectedHI}.csv`)
                    setModalOpen(false)
                    afterSave()
                    const lines = response?.data?.split('\n') ?? null
                    toastSuccess(t("exported_X_patients", { X_patients: lines ? (lines?.length - 2) ?? "X" : "X" }))
                }
                else {
                    toastError("Export fehlgeschlagen: " + response.statusText);
                }
            })
            .catch(function(error) {
                toastError("Exportfehler: ", error);
            })
            .finally(() => {
                setIsLoading(false)
                setSelectedHI("")
            })
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setSelectedHI("")
        setModalOpen(false)
    }

    const insuranceCompanyOptions = [
        {key: "ikk_ins", text: insurance_companies.IKK, value: insurance_companies.IKK},
        {key: "dak_ins", text: insurance_companies.DAK, value: insurance_companies.DAK},
        {key: "barmer_ins", text: insurance_companies.BARMER, value: insurance_companies.BARMER},
        {key: "aok_ins", text: insurance_companies.AOK, value: insurance_companies.AOK}
    ]

    return (
            <Modal open={modalOpen}
                   onClose={handleClose}
                   size={"tiny"}
                   onOpen={handleOpen}
                   closeOnDimmerClick={false}
                   closeOnEscape={false}
                   trigger={<Dropdown.Item icon="download" text={t("data_export_kv")}/>}>

                <Header>
                    <Icon name={"download"} inverted /> {t("data_export_kv")}
                </Header>

                <Modal.Content>
                    {/*t("insurance_company")*/}
                    <Modal.Description>
                        <label>{t("insurance_company")}</label>
                        <Select
                            fluid
                            placeholder={t("select_an_kv")}
                            onChange={(event, { value }) => setSelectedHI(value)}
                            options={insuranceCompanyOptions} />
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button basic onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button color={"teal"} onClick={handleSave} disabled={!selectedHI}>
                        {isLoading ?
                            <>
                                <Loader size="tiny" active inline style={{ marginRight: "5px" }} />{`${t("exporting_txt")}...`}
                            </> : t("export_txt")}
                    </Button>
                </Modal.Actions>
            </Modal>
    );
};

export default ExportData;