import gql from "graphql-tag";

export const medicalHistoryFragment = gql`
    fragment medicalHistoryFragment on MedicalHistory {
        diagnosis
        eye
        initialDiagnoseYear
        maxMeasuredIOPLeft
        maxMeasuredIOPRight
        familyHistoryGlaucomaPerson
        antihypertensiveTherapy
        comment
    }
`;
