import {CAT_FOLLOWUP_T4} from "../../../data/mutations/appointments";
import {copyDataToModel} from "./utils";
import * as _ from "./allSections";


export const createReaderFollowup3Appointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples) => {
    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createDezimalvisusSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createTensiomessungSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createPerimetrieSection(allAppointmentsCorrelationSamples, CAT_FOLLOWUP_T4, userId, role, patientId)
    ];

    return copyDataToModel(model, categorySamples);
}