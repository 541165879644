import gql from "graphql-tag";
import {addressFragment} from "./addressFragment";

export const contactInfoFragment = gql`
    fragment contactInfoFragment on ContactInfo {
        email
        telephoneno
        address {
            ...addressFragment
        }
    }
    ${addressFragment}
`;