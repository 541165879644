import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getValue, getCategoryValue, getDeviceName} from "./util" ;


export async function finalExaminationSummary(masterData, correlationSamples, appointmentDate) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Abschlussuntersuchung_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Abschlussuntersuchung", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const pages = pdfDocWithHeading.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];
    const thirdPage = pages[2];
    const fourthPage = pages[3];

    // Visus
    firstPage.drawText(getValue(correlationSamples, "decimalvisusRight"), { x: 420, y: 573, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "decimalvisusLeft"), { x: 420, y: 557, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusRight"), { x: 420, y: 541, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusLeft"), { x: 420, y: 525, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionRight"), { x: 350, y: 509, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionLeft"), { x: 350, y: 493, size: 10, font: courierFont });

    // Tensiomessung
    firstPage.drawText(getDeviceName(correlationSamples, "tensioMeasurementManual"), {x: 127, y: 439, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurementManualRight"), { x: 420, y: 407, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurementManualLeft"), { x: 420, y: 391, size: 10, font: courierFont });

    // 30 degree perimetrie
    firstPage.drawText(getDeviceName(correlationSamples, "30perimetry"), {x: 127, y: 337, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "visualFieldIndexRight"), { x: 420, y: 305, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "visualFieldIndexLeft"), { x: 420, y: 289, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "averageDefectDepthRight"), { x: 420, y: 273, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "averageDefectDepthLeft"), { x: 420, y: 257, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "psdRight"), { x: 420, y: 236, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "psdLeft"), { x: 420, y: 205, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "sLVRight"), { x: 420, y: 179, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "sLVLeft"), { x: 420, y: 163, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "CsLVRight"), { x: 420, y: 147, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "CsLVLeft"), { x: 420, y: 132, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "meanSensitivityRight"), { x: 420, y: 115, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "meanSensitivityLeft"), { x: 420, y: 804, size: 10, font: courierFont });

    // Pachymetrie
    secondPage.drawText(getDeviceName(correlationSamples, "pachymtery"), {x: 127, y: 750, size: 10, font: courierFont});
    secondPage.drawText(getValue(correlationSamples, "pachymetryRight"), { x: 420, y: 718, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "pachymetryLeft"), { x: 420, y: 702, size: 10, font: courierFont });

    // BMO-MRW
    secondPage.drawText(getDeviceName(correlationSamples, "sdOct"), {x: 127, y: 648, size: 10, font: courierFont});
    secondPage.drawText(getValue(correlationSamples, "bmoAreaRight"), { x: 420, y: 616, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoAreaLeft"), { x: 420, y: 600, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwGlobalRight"), { x: 420, y: 584, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwGlobalLeft"), { x: 420, y: 568, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalSuperiorRight"), { x: 420, y: 552, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalSuperiorLeft"), { x: 420, y: 536, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalRight"), { x: 420, y: 520, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalLeft"), { x: 420, y: 504, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalInferiorRight"), { x: 420, y: 488, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwNasalInferiorLeft"), { x: 420, y: 472, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalInferiorRight"), { x: 420, y: 456, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalInferiorLeft"), { x: 420, y: 440, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalRight"), { x: 420, y: 424, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalLeft"), { x: 420, y: 408, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalSuperiorRight"), { x: 420, y: 392, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "bmoMrwTemporalSuperiorLeft"), { x: 420, y: 376, size: 10, font: courierFont });

    // RFNL-OCT 3.5mm
    secondPage.drawText(getDeviceName(correlationSamples, "sdOct"), {x: 127, y: 315, size: 10, font: courierFont});
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35GlobalRight"), { x: 420, y: 283, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35GlobalLeft"), { x: 420, y: 267, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35SuperiorRight"), { x: 420, y: 251, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35SuperiorLeft"), { x: 420, y: 235, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35InferiorRight"), { x: 420, y: 219, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35InferiorLeft"), { x: 420, y: 203, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalSuperiorRight"), { x: 420, y: 187, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalSuperiorLeft"), { x: 420, y: 171, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalRight"), { x: 420, y: 155, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalLeft"), { x: 420, y: 139, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalInferiorRight"), { x: 420, y: 123, size: 10, font: courierFont });
    secondPage.drawText(getValue(correlationSamples, "rfnlOct35NasalInferiorLeft"), { x: 420, y: 107, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalInferiorRight"), { x: 420, y: 804, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalInferiorLeft"), { x: 420, y: 788, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalRight"), { x: 420, y: 772, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalLeft"), { x: 420, y: 756, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalSuperiorRight"), { x: 420, y: 740, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct35TemporalSuperiorRight"), { x: 420, y: 724, size: 10, font: courierFont });

    // RFNL-OCT 4.1mm
    thirdPage.drawText(getDeviceName(correlationSamples, "sdOct"), {x: 127, y: 670, size: 10, font: courierFont});
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41GlobalRight"), { x: 420, y: 638, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41GlobalLeft"), { x: 420, y: 622, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41SuperiorRight"), { x: 420, y: 606, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41SuperiorLeft"), { x: 420, y: 590, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41InferiorRight"), { x: 420, y: 574, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41InferiorLeft"), { x: 420, y: 558, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalSuperiorRight"), { x: 420, y: 542, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalSuperiorLeft"), { x: 420, y: 526, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalRight"), { x: 420, y: 510, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalLeft"), { x: 420, y: 494, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalInferiorRight"), { x: 420, y: 478, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41NasalInferiorLeft"), { x: 420, y: 462, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalInferiorRight"), { x: 420, y: 446, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalInferiorLeft"), { x: 420, y: 430, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalRight"), { x: 420, y: 414, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalLeft"), { x: 420, y: 398, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalSuperiorRight"), { x: 420, y: 382, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct41TemporalSuperiorRight"), { x: 420, y: 366, size: 10, font: courierFont });

    // RFNL-OCT 4.7mm
    thirdPage.drawText(getDeviceName(correlationSamples, "sdOct"), {x: 127, y: 310, size: 10, font: courierFont});
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47GlobalRight"), { x: 420, y: 278, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47GlobalLeft"), { x: 420, y: 262, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47SuperiorRight"), { x: 420, y: 246, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47SuperiorLeft"), { x: 420, y: 230, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47InferiorRight"), { x: 420, y: 214, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47InferiorLeft"), { x: 420, y: 198, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47NasalSuperiorRight"), { x: 420, y: 182, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47NasalSuperiorLeft"), { x: 420, y: 166, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47NasalRight"), { x: 420, y: 150, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47NasalLeft"), { x: 420, y: 134, size: 10, font: courierFont });
    thirdPage.drawText(getValue(correlationSamples, "rfnlOct47NasalInferiorRight"), { x: 420, y: 118, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47NasalInferiorLeft"), { x: 420, y: 804, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalInferiorRight"), { x: 420, y: 788, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalInferiorLeft"), { x: 420, y: 772, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalRight"), { x: 420, y: 756, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalLeft"), { x: 420, y: 740, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalSuperiorRight"), { x: 420, y: 724, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rfnlOct47TemporalSuperiorRight"), { x: 420, y: 708, size: 10, font: courierFont });

    // HRT
    fourthPage.drawText(getValue(correlationSamples, "discAreaRight"), { x: 420, y: 622, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "discAreaLeft"), { x: 420, y: 606, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "RimAreaRight"), { x: 420, y: 590, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rimAreaLeft"), { x: 420, y: 574, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rimVolumeRight"), { x: 420, y: 558, size: 10, font: courierFont });
    fourthPage.drawText(getValue(correlationSamples, "rimVolumeLeft"), { x: 420, y: 542, size: 10, font: courierFont });

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Abschlussuntersuchung_Summary", "application/pdf");
}