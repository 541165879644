import {useQuery} from "@apollo/react-hooks";
import {HOSPITALS} from "../data/queries/queries";
import {toastError} from "../services";
import {useTranslation} from "react-i18next";

export const useHospitals = () => {
    const {t} = useTranslation();
    const {loading, error, data, refetch} = useQuery(HOSPITALS);

    if (error) {
        toastError(t("error_gql"));
    }

    const reload = (id) => {
        refetch({id});
    };
    return {loading, data, reload};
};
