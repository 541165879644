import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Select, Table} from "semantic-ui-react";
import {
    CAT_FIRST,
    CAT_INCLUSION,
    CAT_CONTROL,
    CAT_TENSIO,
    CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4,
    CAT_FINAL
} from "../../data/mutations/appointments";
import * as _ from "lodash";
import {AuthContext} from "../../services";
import {isFieldDisabled} from "./models/utils";


const RenderReaderZoneTable = ({tableData, handleTableStatusChange, values, readingStatus = null}) => {
    const {t} = useTranslation();

    const [tableDataState, setTableDataState] = useState(tableData);

    const {user} = useContext(AuthContext)
    const role = user.roles[0]
    const isReader = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c");
    const isDataEntryManager = user.roles.includes("data_entry_manager")

    const findIndexTableStatus = (categoryType, eye) => {
        for (let i = 0; i < tableDataState.tableStatusField.length; ++i) {
            if (tableDataState.tableStatusField[i].dataStorageField.categoryType.startsWith(categoryType + "Auswertung" + eye)) {
                return i;
            }
        }
        return -1;
    }

    const findValues = (appointmentName, categoryType, eye) => {
        if (getCurrentTab() === appointmentName && isDataEntryManager) {
            return tableDataState.tableStatusField[findIndexTableStatus(categoryType, eye)].dataStorageField.value;
        }

        return values?.find(x =>  _.get(x, "appointmentName") === appointmentName)?.categorySamples?.find(categorySample => _.get(categorySample, "categoryType")?.startsWith(categoryType + "Auswertung" + eye)
            && _.get(categorySample, "categoryType")?.endsWith("data_entry_manager"))?.value ?? null;
    }

    // const commonOptions = [
    //     { key: "empty", value: "empty", text:"" },
    //     { key: "normal", value: "normal", text:"Normal" },
    //     { key: "borderline", value: "borderline", text: "Borderline" },
    //     { key: "reduced", value: "reduced", text: "Reduziert" },
    //     { key: "notPossibleToEvaluate", value: "notPossibleToEvaluate", text: "Nicht auswertbar" },
    // ];
    //
    // const hrtOptions = [
    //     { key: "empty", value: "empty", text:"" },
    //     { key: "normal", value: "normal", text:"Normal" },
    //     { key: "macroPupil", value: "macroPupil", text: "Makropupille" },
    //     { key: "microPupil", value: "microPupil", text: "Mikropupille" },
    //     { key: "notPossibleToEvaluate", value: "notPossibleToEvaluate", text: "Nicht auswertbar" },
    // ];
    //
    // const selectField = (options) => {
    //     return (
    //         <Select placeholder="" options={options} fluid={true}/>
    //     );
    // };

    function getCurrentTab () {
        const tabsSorted = [CAT_FIRST, CAT_INCLUSION, CAT_CONTROL, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];
        const currentTabIndex = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
        return tabsSorted[currentTabIndex - 1];
    }

    function getAppointmentName (rawName) {
        let appointmentName;

        switch (rawName) {
            case CAT_FIRST:
                appointmentName = t("tabs_apponit_FIRST");
                break;
            case CAT_INCLUSION:
                appointmentName = t("tabs_apponit_INCLUSION");
                break;
            case CAT_FOLLOWUP_T2:
                appointmentName = t("tabs_apponit_FOLLOWUP1");
                break;
            case CAT_FOLLOWUP_T3:
                appointmentName = t("tabs_apponit_FOLLOWUP2");
                break;
            case CAT_FOLLOWUP_T4:
                appointmentName = t("tabs_apponit_FOLLOWUP3");
                break;
            case CAT_FINAL:
                appointmentName = t("tabs_apponit_FINAL");
                break;
            case CAT_CONTROL:
                appointmentName = t("tabs_apponit_CONTROL");
                break;
            case CAT_TENSIO:
                appointmentName = t("tabs_apponit_TENSIO");
                break;
            default:
                appointmentName = "";
        }
        return appointmentName;
    }

    const renderSelectOptions = (fieldIndex, value, disabled) => {
        return (
            <Select
                value={value}
                options={tableDataState.tableStatusField[fieldIndex].displayField.__options}
                disabled={disabled || isReader || isFieldDisabled(role, readingStatus)}
                onChange={(e, d) => {
                    handleTableStatusChange(tableDataState.tableStatusField[fieldIndex].dataStorageField.id, d.value)
                    tableDataState.tableStatusField[fieldIndex].dataStorageField.value = d.value
                    setTableDataState({...tableDataState})
                }}
            />
        );
    };

    return (
        <>
            {tableDataState && tableDataState.values && tableDataState.appointmentNames.includes(getCurrentTab()) &&
                <div>
                    <Table celled striped>
                        <Table.Header>
                            <Table.Row key={`${tableDataState?.section}_1`}>
                                <Table.HeaderCell rowSpan={["BMO-MRW", "HRT", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm"].includes(tableDataState.section) ? "2" : "1"}>{"Measurement"}</Table.HeaderCell>
                                    {tableDataState.appointmentNames.map( it => {
                                        return (
                                            <Table.HeaderCell colSpan={["BMO-MRW", "HRT", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm"].includes(tableDataState.section) ? "2" : "1"} textAlign={"center"} className={ getCurrentTab() === it ? "active-table-column compact" : ""}>
                                                {getAppointmentName(it)}
                                            </Table.HeaderCell>
                                        )
                                    })}
                            </Table.Row>

                            <Table.Row key={`${tableDataState?.section}_2`}>
                                {tableDataState.appointmentNames.map(it => {
                                    return (
                                        <>
                                            {["BMO-MRW", "HRT", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm"].includes(tableDataState.section) &&
                                                <Table.HeaderCell textAlign={"center"} className={ getCurrentTab() === it ? "active-table-column" : ""}>{}</Table.HeaderCell>
                                            }

                                            {["BMO-MRW", "HRT", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm"].includes(tableDataState.section) &&
                                                <Table.HeaderCell textAlign={"center"} className={ getCurrentTab() === it ? "active-table-column" : ""}>{"Auswertung"}</Table.HeaderCell>
                                            }
                                        </>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {tableDataState.values.map((it, index) => {
                                const unit = (it.quantity[0] && it.quantity[0].unit) ? "[" + it.quantity[0].unit + "]" : '';
                                return (
                                   <>
                                       <Table.Row key={`${it.fieldName}_${index}`}>
                                           <Table.Cell>{it.text + " rechts " + unit}</Table.Cell>
                                           {tableDataState?.appointmentNames.map(appName => {
                                               const q = it?.quantity?.find(_x => _x.appointmentName === appName)
                                               return (
                                                   <>
                                                       <Table.Cell textAlign={"center"} className={ getCurrentTab() === q?.appointmentName ? "active-table-column" : ""}>
                                                           {Math.round(q?.rightValue) === -50000 ? "" : q?.rightValue}
                                                       </Table.Cell>
                                                       {(["BMO-MRW", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm", "HRT"].includes(tableDataState.section)) &&
                                                       <Table.Cell textAlign={"center"} className={ getCurrentTab() === q?.appointmentName ? "active-table-column" : ""}>
                                                           {/*{selectField(commonOptions)}*/}
                                                           {(renderSelectOptions(findIndexTableStatus(it.fieldName, "Right"), findValues(q?.appointmentName, it.fieldName, "Right"), (!(getCurrentTab() === q?.appointmentName)) || (Math.round(q?.rightValue) === -50000)))}
                                                       </Table.Cell>}
                                                   </>
                                               )
                                           })}
                                       </Table.Row>
                                       <Table.Row>
                                           <Table.Cell>{it.text + " links " + unit}</Table.Cell>
                                           {tableDataState?.appointmentNames.map(appName => {
                                               const q = it?.quantity?.find(_x => _x.appointmentName === appName)
                                               return (
                                                   <>
                                                       <Table.Cell textAlign={"center"} className={ getCurrentTab() === q?.appointmentName ? "active-table-column" : ""}>
                                                           {Math.round(q?.leftValue) === -50000 ? "" : q?.leftValue}
                                                       </Table.Cell>
                                                       {(["BMO-MRW", "RFNL-OCT 3,5 mm", "RFNL-OCT 4,1 mm", "RFNL-OCT 4,7 mm", "HRT"].includes(tableDataState.section)) &&
                                                       <Table.Cell textAlign={"center"} className={ getCurrentTab() === q?.appointmentName ? "active-table-column" : ""}>
                                                           {/*{selectField(commonOptions)}*/}
                                                           {(renderSelectOptions(findIndexTableStatus(it.fieldName, "Left"), findValues(q?.appointmentName, it.fieldName, "Left"), (!(getCurrentTab() === q?.appointmentName)) || (Math.round(q?.leftValue) === -50000)))}
                                                       </Table.Cell>
                                                       }
                                                       {/*{(tableData.section === "HRT" && it.text === "Disc Area") &&*/}
                                                       {/*<Table.Cell textAlign={"center"} className={ getCurrentTab() === q.appointmentName ? "active-table-column" : ""}>*/}
                                                       {/*    {selectField(hrtOptions)}*/}
                                                       {/*</Table.Cell>*/}
                                                       {/*}*/}
                                                   </>
                                               )
                                           })}
                                       </Table.Row>
                                   </>
                               )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            }
        </>
    );
};

export default RenderReaderZoneTable;