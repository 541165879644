import {PATIENTS} from "data/queries/queries";
import {useQuery} from "@apollo/react-hooks";

export const usePatients = () => {

    const {loading, error, data, refetch} = useQuery(PATIENTS, {
        variables: {
            filter: "",
            offset: 0,
            limit: 100,
        },
    });

    const reload = () => {
        refetch();
    };

    return {loading, error, data,reload};
};
