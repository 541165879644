import gql from "graphql-tag";
import {appointmentFragment} from "../fragments/appointmentFragment";

export const CAT_FIRST = "FIRST";
export const CAT_INCLUSION = "INCLUSION";
export const CAT_INTERVENTION = "INTERVENTION";
export const CAT_CONTROL = "CONTROL";
export const CAT_FOLLOWUP_T2 = "FOLLOWUP_T2";
export const CAT_FOLLOWUP_T3 = "FOLLOWUP_T3";
export const CAT_FOLLOWUP_T4 = "FOLLOWUP_T4";
export const CAT_FINAL = "FINAL";
export const CAT_TENSIO = "TENSIO";

export const APPT_STATUS_CANCELED = "CANCELED";
export const APPT_STATUS_SCHEDULED = "SCHEDULED";
export const APPT_STATUS_NOT_SCHEDULED_YET = "NOT_SCHEDULED_YET";
export const APPT_STATUS_TOOK_PLACE = "TOOK_PLACE";
export const APPT_READING = "READING";
export const APPT_DISCREPANCY = "DISCREPANCY";
export const APPT_INQUIRY = "INQUIRY";
export const APPT_READING_FINISHED = "READING_FINISHED";

export const UPSERT_APPOINTMENT = gql`
    mutation UpsertAppointment($appointment: AppointmentInput!, $patientId: ID!) {
        upsertAppointment(appointment: $appointment, patientId: $patientId) {
            ...appointmentFragment
        }
    }
    ${appointmentFragment}
`;

export const REMOVE_ATTACHMENT = gql`
    mutation RemoveAttachment($appointmentId: ID!, $sampleId: ID!, $comment: String) {
        removeAttachment(appointmentId: $appointmentId, comment: $comment, sampleId: $sampleId) {
            ...appointmentFragment
        }
    }
    ${appointmentFragment}
`;
