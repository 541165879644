import gql from "graphql-tag";

export const medicationFragment = gql`
    fragment medicationFragment on Medication {
        id
        comment
        startDate
        endDate
        name
        eyeLocation
        dosageAtMorning
        dosageAtNoon
        dosageAtEvening
        unit
    }
`;