import React, {forwardRef, useContext, useEffect, useState} from "react";
import {cloneDeep, compact, find, get, set} from "lodash";
import {Button, Divider, Icon, Input, Table} from "semantic-ui-react";
import DateInput from "../inputs/DateInput";
import InputSelect from "../inputs/InputSelect";
import {createOptions, regExs} from "../../data/models/utils";
import {useTranslation} from "react-i18next";
import {AuthContext, toastError} from "../../services";
import {useMutateAppointment} from "../../hooks";

const MIN_FLOAT = -50000;
const MIN_DATE_TIME = "1900-01-01T00:00:00.000Z";


const RenderTensioTableControl = ({appointmentModel, leftTime, rightTime, isReadOnly, patientId, refresh}) => {

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    const [mutateData] = useMutateAppointment();
    const [samples, setSamples] = useState(appointmentModel && cloneDeep(appointmentModel.correlationSamples.filter(value => {
        return value.correlationType === "tensioMeasurement" || value.correlationType === "tensioControlImport";
    })));
    const [newSampleLocalId, setNewSampleLocalId] = useState(0);
    const [sortSampleStatus, setSortSampleStatus] = useState(true);
    const [dateChangeStatus, setDateChangeStatus] = useState(false); // only becomes true if changed date is less than leftTime or larger than right time

    useEffect(()=> {
        setSamples(cloneDeep(appointmentModel.correlationSamples));
        setSortSampleStatus(true);
        setDateChangeStatus(false);
    }, [appointmentModel.correlationSamples])

    const __deviceModel = createOptions([
        "",
        "Goldmann-Applanationstonometrie",
        "iCareHome",
        "iCarePro1",
        "iCarePro2",
        "NCT"
    ], {
        __placeholder: t("no_selection"),
        __optional: false,
    });

    const createNewSample = () => {
        return {
            categorySamples: [],
            correlationType: "tensioControlImport",
            endDate: new Date().toISOString(),
            id: "local_" + newSampleLocalId,
            metadata: {
                changedBy: null,
                changedDate: null,
                comment: null,
                createdBy: null,
                creationDate: null,
                deviceName: null,
                deviceVendorName: null,
                examinationType: null,
                eyeLocation: null,
                __typename: "MetaData"
            },
            quantitySamples: [
                {
                    endDate: new Date().toISOString(),
                    id: "local_" + newSampleLocalId + "_ipr",
                    metadata: {
                        changedBy: null,
                        changedDate: null,
                        comment: null,
                        createdBy: null,
                        creationDate: null,
                        deviceName: null,
                        deviceVendorName: null,
                        examinationType: null,
                        eyeLocation: null,
                        __typename: "MetaData"
                    },
                    quantity: MIN_FLOAT,
                    quantityType: "intraocularPressureRight",
                    sampleType: "quantity",
                    startDate: new Date().toISOString(),
                    subject: patientId,
                    unit: "mmHg",
                    __typename: "QuantitySample"
                },
                {
                    endDate: new Date().toISOString(),
                    id: "local_" + newSampleLocalId + "_ipl",
                    metadata: {
                        changedBy: null,
                        changedDate: null,
                        comment: null,
                        createdBy: null,
                        creationDate: null,
                        deviceName: null,
                        deviceVendorName: null,
                        examinationType: null,
                        eyeLocation: null,
                        __typename: "MetaData"
                    },
                    quantity: MIN_FLOAT,
                    quantityType: "intraocularPressureLeft",
                    sampleType: "quantity",
                    startDate: new Date().toISOString(),
                    subject: patientId,
                    unit: "mmHg",
                    __typename: "QuantitySample"
                }
            ],
            sampleType: "correlation",
            startDate: new Date().toISOString(),
            subject: patientId,
            __typename: "SalusCorrelationSample"
        }
    }

    const sortSamples = (samples) => {
        samples.sort((a,b) => (a.startDate > b.startDate) ? 1 : -1);
        return samples;
    };

    const getQuantities = (salusCorrelationSample, type) => {
        return find(salusCorrelationSample.quantitySamples,
            (it) => it.quantityType === type
        );
    };

    const getSortedAndFilteredSample = () => {
        return sortSamples(samples)?.filter(value => {
            return ((leftTime ? (new Date(value.startDate)).getTime() >= leftTime : true) &&
                (rightTime ? (new Date(value.startDate)).getTime() <= rightTime : true)
                && ((value.correlationType === "tensioMeasurement") || (value.correlationType === "tensioControlImport"))
                && (new Date(value.startDate).toISOString() !== new Date(MIN_DATE_TIME).toISOString()))
                || (value.id && value.id.startsWith("local"));
        }).sort((a,b) => a.startDate > b.startDate? 1 : -1)
    }

    const getFilteredSample = () => {
        return samples.filter(value => {
            return ((leftTime ? (new Date(value.startDate)).getTime() >= leftTime : true) &&
                (rightTime ? (new Date(value.startDate)).getTime() <= rightTime : true)
                && ((value.correlationType === "tensioMeasurement") || (value.correlationType === "tensioControlImport"))
                && (new Date(value.startDate).toISOString() !== new Date(MIN_DATE_TIME).toISOString()))
                || (value.id && value.id.startsWith("local"));
        })
    }

    const getSampleWithoutSortingAndDateFiltering = () => {
        return samples.filter(value => {
            return (((value.correlationType === "tensioMeasurement") || (value.correlationType === "tensioControlImport"))
                    && (new Date(value.startDate).toISOString() !== new Date(MIN_DATE_TIME).toISOString()))
                || (value.id && value.id.startsWith("local"));
        })
    };

    const hasError = () => {
        const tensioSamples = samples.filter(value => ((value.correlationType === "tensioMeasurement") || (value.correlationType === "tensioControlImport")));
        for(let i = 0; i < tensioSamples.length; ++i){
            const leftValue = tensioSamples[i].quantitySamples[tensioSamples[i].quantitySamples.findIndex(quantitySample => quantitySample.quantityType === "intraocularPressureLeft")].quantity;
            const rightValue = tensioSamples[i].quantitySamples[tensioSamples[i].quantitySamples.findIndex(quantitySample => quantitySample.quantityType === "intraocularPressureRight")].quantity;
            if (Object.is(leftValue, NaN) || Object.is(rightValue, NaN) || hasErrorInDevice(tensioSamples[i])){
                return true;
            }
        }
        return false;
    };

    const handleSave = () => {
        if (hasError()){
            toastError(t("error_fields_incorrect"));
            return;
        }

        const toSave = {
            id: appointmentModel.id,
            startDate: appointmentModel.startDate,
            status: appointmentModel.status,
            category: appointmentModel.category,
            recommendedTherapy: get(appointmentModel, "recommendedTherapy"),
            correlationSamples: compact(cloneDeep(samples.filter(value => {
                return value.correlationType === "tensioMeasurement" || value.correlationType === "tensioControlImport";
            })))
        };

        toSave.correlationSamples.forEach(function(sample) {
            if (sample.id && sample.id.startsWith("local")){
                sample.id = null;
                sample.quantitySamples[0].id = null;
                sample.quantitySamples[1].id = null;
            }
        })

        console.log(toSave.correlationSamples);

        return mutateData({
            appointment: toSave,
            patientId
        }).then(() => {
            refresh();
        }, () => {
            toastError(t("error_gql"));
        });
    };

    const handleRemove= (sampleId) => {
        // local row removal
        if (sampleId.startsWith("local")){
            setSamples(samples.filter(x => x.id !== sampleId));
            return;
        }

        // stored row removal
        const allSamples = cloneDeep(samples);
        const indexOfSample = allSamples.findIndex(sample => sample.id === sampleId);
        const sampleToBeChanged = allSamples[indexOfSample];

        set(sampleToBeChanged, "startDate", MIN_DATE_TIME);
        set(sampleToBeChanged, "endDate", MIN_DATE_TIME);
        set(sampleToBeChanged.quantitySamples[0], "startDate", MIN_DATE_TIME);
        set(sampleToBeChanged.quantitySamples[0], "endDate", MIN_DATE_TIME);
        set(sampleToBeChanged.quantitySamples[0], "quantity", MIN_FLOAT);
        set(sampleToBeChanged.quantitySamples[1], "startDate", MIN_DATE_TIME);
        set(sampleToBeChanged.quantitySamples[1], "endDate", MIN_DATE_TIME);
        set(sampleToBeChanged.quantitySamples[1], "quantity", MIN_FLOAT);
        setSamples(allSamples);
    };

    const handleAdd = () => {
        const newSample = createNewSample();
        setSamples(prevSamples => [...prevSamples, newSample]);
        setNewSampleLocalId(prevId => prevId + 1);
    };

    const handleChangeDate = (sampleId, value) => {
        if (new Date(value) < new Date(leftTime) || new Date(value) > new Date(rightTime)){
            setDateChangeStatus(prevStatus => prevStatus || true);
        }

        const allSamples = cloneDeep(samples);
        const indexOfSample = allSamples.findIndex(sample => sample.id === sampleId);
        const sampleToBeChanged = allSamples[indexOfSample];
        sampleToBeChanged.startDate = value.toISOString();
        set(sampleToBeChanged.quantitySamples[0], "startDate", value.toISOString());
        set(sampleToBeChanged.quantitySamples[1], "startDate", value.toISOString());
        setSamples(allSamples);
    };

    const handleChangeQuantity = (event, sampleId, type) => {
        const changedValue = event.target.value.match(regExs.REGEX_INTEGER) ? parseInt(event.target.value) : NaN;

        const allSamples = cloneDeep(samples);
        const indexOfSample = allSamples.findIndex(sample => sample.id === sampleId);
        const sampleToBeChanged = allSamples[indexOfSample];
        sampleToBeChanged.quantitySamples[sampleToBeChanged.quantitySamples.findIndex(quantitySample => quantitySample.quantityType === type)].quantity = changedValue;
        setSamples(allSamples);
    };

    const hasErrorInQuantity = (sampleId, type) => {
        const indexOfSample = samples.findIndex(sample => sample.id === sampleId);
        const requiredSample = samples[indexOfSample];
        const value = requiredSample.quantitySamples[requiredSample.quantitySamples.findIndex(quantitySample => quantitySample.quantityType === type)].quantity.toString();
        return !(value.match(regExs.REGEX_INTEGER)) || (value.trim().length === 0);
    }

    const hasErrorInDevice = (salusCorrelationSample) => {
        return !salusCorrelationSample.metadata.deviceName || (salusCorrelationSample.metadata.deviceName && salusCorrelationSample.metadata.deviceName.trim().length === 0);
    }

    const handleChangeDevice = (sampleId, value) => {
        const allSamples = cloneDeep(samples);
        const indexOfSample = allSamples.findIndex(sample => sample.id === sampleId);
        const sampleToBeChanged = allSamples[indexOfSample];
        sampleToBeChanged.metadata.deviceName = value;
        setSamples(allSamples);
    };

    const renderTable = (sampleData) => {
        return (
            <div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Messzeitpunkt</Table.HeaderCell>
                            <Table.HeaderCell>IOP (OD)</Table.HeaderCell>
                            <Table.HeaderCell>IOP (OS)</Table.HeaderCell>
                            <Table.HeaderCell>Gerät</Table.HeaderCell>
                            {!isPatient && <Table.HeaderCell> </Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>

                    {sampleData.map(salusCorrelationSample => {
                        return(
                            <Table.Body key={salusCorrelationSample.id +"_table"}>
                                <Table.Row key={salusCorrelationSample.id} warning={salusCorrelationSample.id && salusCorrelationSample.id.startsWith("local")}>

                                    <Table.Cell key={salusCorrelationSample.id +"_date"}>
                                        <DateInput name={"sampleStartDate"} value={salusCorrelationSample.startDate} type={"datetime"} onChange={(value) => handleChangeDate(salusCorrelationSample.id, value)}
                                        additionalProps={{disabled: isReadOnly && !isPatient, className: isPatient ? "patient-view-font" : ""}}
                                        />
                                    </Table.Cell>

                                    <Table.Cell key={salusCorrelationSample.id +"_iopRight"}>
                                        <Input defaultValue={getQuantities(salusCorrelationSample, "intraocularPressureRight")?.quantity === MIN_FLOAT ? "" : getQuantities(salusCorrelationSample, "intraocularPressureRight")?.quantity}
                                               onChange={(event) => handleChangeQuantity(event, salusCorrelationSample.id, "intraocularPressureRight")}
                                               error={hasErrorInQuantity(salusCorrelationSample.id, "intraocularPressureRight")}
                                               disabled={isReadOnly && !isPatient}
                                               className={isPatient ? "patient-view-font" : ""}
                                        />
                                    </Table.Cell>

                                    <Table.Cell key={salusCorrelationSample.id +"_iopLeft"}>
                                        <Input defaultValue={getQuantities(salusCorrelationSample, "intraocularPressureLeft")?.quantity === MIN_FLOAT ? "" : getQuantities(salusCorrelationSample, "intraocularPressureLeft")?.quantity}
                                               onChange={(event) => handleChangeQuantity(event, salusCorrelationSample.id, "intraocularPressureLeft")}
                                               error={hasErrorInQuantity(salusCorrelationSample.id, "intraocularPressureLeft")}
                                               disabled={isReadOnly && !isPatient}
                                               className={isPatient ? "patient-view-font" : ""}
                                        />
                                    </Table.Cell>

                                    <Table.Cell key={salusCorrelationSample.id +"_device"}>
                                        <InputSelect options={__deviceModel.__options} placeholder={__deviceModel.__placeholder} defaultValue={salusCorrelationSample.metadata.deviceName} onChange={(value) => handleChangeDevice(salusCorrelationSample.id, value)}
                                                     error={hasErrorInDevice(salusCorrelationSample)} disabled={isReadOnly && !isPatient}
                                                     className={isPatient ? "patient-view-font" : ""}
                                        />
                                    </Table.Cell>

                                    {!isPatient &&
                                        <Table.Cell key={salusCorrelationSample.id + "_remove"}>
                                            <Button icon type="button" basic size="medium" color="teal"
                                                    disabled={isReadOnly}
                                                    onClick={() => handleRemove(salusCorrelationSample.id)}>
                                                <Icon name='trash alternate outline'/>
                                            </Button>
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            </Table.Body>
                        );
                    })}
                </Table>
                {!isPatient &&
                    <>
                        <br/>
                        <Button type="button" basic size="tiny" color="teal" content={t("add_measurement")}  onClick={() => handleAdd()} disabled={isReadOnly}/>
                        <br/>
                    </>
                }
                <Divider/>
                {!isPatient &&
                    <div className={"Grid -right"}>
                        <Button disabled={isReadOnly} basic type="submit" size="tiny" color="teal" content={t("save")}
                                onClick={() => handleSave()}/>
                    </div>
                }
            </div>
        );
    }


    if (appointmentModel && appointmentModel.correlationSamples) {
        if (dateChangeStatus) {
            return renderTable(getSampleWithoutSortingAndDateFiltering());
        } else if (sortSampleStatus) {
            setSortSampleStatus(prevStatus => !prevStatus);
            return renderTable(getSortedAndFilteredSample());
        } else {
            return renderTable(getFilteredSample());
        }
    }
};
export default forwardRef(RenderTensioTableControl);
