import React, {useContext, useEffect, useState} from "react";
import {Dimmer, Dropdown, Header, Icon, Loader, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next"
import axios from "axios";
import {AuthContext, downloadCSV, toastError, toastSuccess} from "../../services";

const ExportEvaluationData = ({ afterSave }) => {
    const auth = useContext(AuthContext);
    const {t} = useTranslation()
    const [modalOpen, setModalOpen] = useState(false)

    /* eslint-disable */
    useEffect(() => {
        if (modalOpen) {
            handleSave()
        }
    }, [modalOpen]);
    /* eslint-enable */

    const handleSave = () => {
        axios.get(
            `${process.env.REACT_APP_SERVER_URI}/api/insurance-export-patients`,
            {headers: {Authorization: "Bearer " + auth.keycloak.token}})
            .then(function(response) {
                if (response.status === 200) {
                    downloadCSV(JSON.stringify(response?.data, null, '\t'), `Data.json`, "application/json")
                    setModalOpen(false)
                    afterSave()
                    toastSuccess(t("exported_X_patients", { X_patients: response?.data?.length ?? "X" }))
                }
                else {
                    toastError("Export fehlgeschlagen: " + response.statusText);
                }
            })
            .catch(function(error) {
                toastError("Exportfehler: ", error);
            })
            .finally(() => {
                setModalOpen(false)
            })
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    return (
        <Modal open={modalOpen}
               onClose={handleClose}
               size={"tiny"}
               onOpen={handleOpen}
               closeOnDimmerClick={false}
               closeOnEscape={false}
               trigger={<Dropdown.Item icon="cloud download" text={t("data_export_eval")}/>}>

            <Header>
                <Icon name={"cloud download"} inverted /> {t("data_export_eval")}
            </Header>
            <Dimmer active={true}>
                <Loader size="large" active inverted style={{ marginRight: "5px" }} />{`${t("exporting_txt")}...`}
            </Dimmer>
        </Modal>
    );
};

export default ExportEvaluationData