import React, {useContext} from "react";
import Patient from "./Patient";
import NoResultsFound from "../NoResultsFound";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../services";
import StudyLeadPatientTable from "./StudyLeadPatientTable";
import _ from "lodash";
import AccountingPatientTable from "./AccountingPatientTable";

const PatientList = ({patients, loading, searchString, stats}) => {
    const { user } = useContext(AuthContext);
    const isStudyLead = user.roles.includes("study_lead");
    const isAccounting = user.roles.includes("accounting");

    const history = useHistory();
    const noData = !patients.length && !loading;
    const hasResults = !!(patients && patients.length);

    const handleClick = (id) => history.push(`/patient/${id}`);

    const patientsStats = _.get(stats, ['stats', 'study', 'patients'], null);

    const recruitedPatients = _.get(patientsStats, ['active', 'total'], 0) +
        _.get(patientsStats, ['dataMissing', 'total'], 0) +
        _.get(patientsStats, ['excluded', 'total'], 0);

    const maxPatients = recruitedPatients + _.get(patientsStats, ['recruitable', 'total'], 0)

    const filterPatients = () => {
        if (isStudyLead || isAccounting) {
            return patients.filter((patient) => patient.masterData.surname.toUpperCase().includes(searchString.toUpperCase())
                || patient.masterData.forename.toUpperCase().includes(searchString.toUpperCase())
                || patient.patientId.toUpperCase().includes(searchString.toUpperCase())
                || patient.masterData.contact.address.city.toUpperCase().includes(searchString.toUpperCase())
                || patient.masterData.insurance.company.toUpperCase().includes(searchString.toUpperCase())
                || patient.masterData.hospital.name.toUpperCase().includes(searchString.toUpperCase())
                || patient.studyData.doctorInCharge.forename.toUpperCase().includes(searchString.toUpperCase())
                || patient.studyData.doctorInCharge.surname.toUpperCase().includes(searchString.toUpperCase())
            );
        }

        return patients.filter((patient) => patient.masterData.surname.toUpperCase().includes(searchString.toUpperCase())
            || patient.masterData.forename.toUpperCase().includes(searchString.toUpperCase())
            || patient.patientId.toUpperCase().includes(searchString.toUpperCase()));
    };

    return (
        <>
            {(isStudyLead || isAccounting)
            ? (isAccounting ? <AccountingPatientTable hasResults={hasResults} patientsData={filterPatients().sort((a, b) => a.masterData.surname > b.masterData.surname? 1 : -1)}
                                                       noData={noData} maxPatients={maxPatients}/>
                :<StudyLeadPatientTable hasResults={hasResults} patientsData={filterPatients().sort((a,b) => a.masterData.surname > b.masterData.surname? 1 : -1)}
                                        noData={noData} maxPatients={maxPatients}/>)
                : <div className={"c-container scroll-y"}>
                    {hasResults && filterPatients().sort((a,b) => a.masterData.surname > b.masterData.surname? 1 : -1).map((it) => (
                        <div className="col col-md-4" key={it.id}>
                            <Patient id={it.id} patient={it} onClick={() => handleClick(it.id)}/>
                        </div>
                    ))
                    }
                    {noData && (
                        <div className={"center-all"}>
                            <NoResultsFound />
                        </div>
                    )}
                </div>
            }
        </>
    );
};

export default PatientList;
