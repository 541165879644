import React from "react";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";
import {FORMAT_DATE, FORMAT_DATE_TIME} from "../../services";

const DateInput = ({label, value, type, onChange, className, additionalProps}) => {
    const {t} = useTranslation();

    const getProps = (type) => {
        const withTime = type === "datetime";

        const props = {
            ...additionalProps,
            selected: value ? new Date(value) : value,
            dateFormat: `${withTime ? FORMAT_DATE_TIME : FORMAT_DATE}`
        };

        return withTime
            ? {
                ...props,
                ...{
                    showTimeSelect: true,
                    timeFormat: "HH:mm",
                    timeIntervals: 15,
                    timeCaption: t("time")
                }
            }
            : props;
    };

    return (
        <div className={className}>
            <div className={`note ${className}`}>{label}</div>
            <DatePicker className={`note ${className}`} onChange={onChange} {...getProps(type)}/>
        </div>
    );
};

export default DateInput;
