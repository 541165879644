import React, {useState} from "react";
import {Button, Header, List, Modal} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const DownloadsPatient = () => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Modal onClose={() => setModalOpen(false)}
               onOpen={() => setModalOpen(true)}
               open={modalOpen}
               trigger={<Button className={"teal"}>{t("footer_menu_downloads")}</Button>}
        >
            <Header>
                {t("footer_menu_downloads")}
            </Header>
            <Modal.Content>
                <div className={"c-header c-header--big"}>
                    Dokumente
                </div>

                <List divided relaxed>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsPatient/Studieninformation_SALUS_22112021_V3.pdf"} download={"SALUS_Studieninformation.pdf"}>Studieninformation für Patienten</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsPatient/Datenschutzmerkblatt_SALUS_22112021_V3.pdf"} download={"SALUS_Datenschutzmerkblatt.pdf"}>Datenschutzmerkblatt für Patienten</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsPatient/Anleitung_PHYSIO-PORT.pdf"} download={"Anleitung_PHYSIO-PORT.pdf"}>Anleitung 24h-RR</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsPatient/Icare_HOME_patient_guide_TA022-035_DE-3-1_LO.pdf"} download={"Icare_HOME_patient_guide_TA022-035_DE-3-1_LO.pdf"}>iCare Flyer</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='file pdf' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>
                                <a href={"/downloadsPatient/Einleger_Hinweise_Nutzung_Geräte.pdf"} download={"Einleger_Hinweise_Nutzung_Geräte.pdf"}>Einleger Hinweis Nutzung Geräte</a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </List>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleClose()}>{t("close")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DownloadsPatient;
