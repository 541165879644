import gql from "graphql-tag";
import {contactInfoFragment} from './contactInfoFragment';
import {organisationFragment} from './organisationFragment';

export const doctorFragment = gql`
    fragment doctorFragment on Doctor {
        contact{
            ...contactInfoFragment
        }
        forename
        id
        lanr
        organisation{
            ...organisationFragment
        }
        surname
        title
    }
    ${contactInfoFragment}
    ${organisationFragment}
`;
