import {copyOverModified} from "services";
import {createCorrelation, createOptions, createQuantity, createSample, createZieldruck, regExs} from "./utils";
import {find, get} from "lodash";
import {APPT_STATUS_NOT_SCHEDULED_YET, CAT_CONTROL, CAT_INCLUSION} from "../mutations/appointments";

const createModel = (patient, t, category) => {
    const appointments = patient.appointments;
    const payload = find(appointments, (it) => it.category === category) || {};

    const __deviceModel = createOptions([
        "",
        "Goldmann-Applanationstonometrie",
        "iCareHome",
        "iCarePro1",
        "iCarePro2",
        "NCT"
    ], {
        __placeholder: t("no_selection"),
        __optional: false,
    });

    const __deviceModelSDOCT = createOptions([
        "",
        "Heidelberg Spectralis",
        "Optopol Copernicus",
        "Zeiss Cirrus"
    ], {
        __placeholder: t("no_selection"),
        __optional: false,
    });

    const __deviceModelPerimetrie = createOptions([
        "",
        "DiconPerimeter",
        "Humphrey Field Analyzer bis 1994",
        "Humphrey Field Analyzer ab 1995",
        "Kowa AP-5000C",
        "Octopus 1-2-3",
        "Octopus 101",
        "Octopus 301",
        "Octopus 500 EZ, 2000R",
        "Octopus 900",
        "Perimat 206",
        "Peristat 433",
        "TAP cc",
        "TAP 2000",
        "TAP 2000ct",
        "TEC cc",
        "Twinfield"
    ], {
        __placeholder: t("no_selection"),
        __optional: false,
    });

    const __deviceModelPachymetrie = createOptions([
        "",
        "OCT",
        "Scheimpflug (Pentacam)",
        "Ultraschall"
    ], {
        __placeholder: t("device_name"),
        __optional: true
    });

    const __shafferOptions = {
        __type: "select",
        __options: [
            {key: "none", value: "", text: ""},
            {key: "0", value: 0, text: "0"},
            {key: "1", value: 1, text: "I"},
            {key: "2", value: 2, text: "II"},
            {key: "3", value: 3, text: "III"},
            {key: "4", value: 4, text: "IV"},
            ],
        __placeholder: t("no_selection"),
    };

    const __eq5mobility = createOptions(
        [
            "Keine Angabe",
            "Ich habe keine Probleme herumzugehen",
            "Ich habe leichte Probleme herumzugehen",
            "Ich habe mäßige Probleme herumzugehen",
            "Ich habe große Probleme herumzugehen",
            "Ich bin nicht in der Lage herumzugehen"
        ],
        {__label: "Beweglichkeit / Mobilität"});

    const __decimalVisusOptions = createOptions(
        [
            "",
            "HBW (Handbewegungen)",
            "FZ (Fingerzählen)",
            "LSW (Lichtscheinwahrnehmungen)",
            "NL (Nulla Lux)"
        ],
        {__placeholder: t("no_selection"),
            __label: "Unter Metervisus "});

    const __eq5selfcare = createOptions(
        [
            "Keine Angabe",
            "Ich habe keine Probleme, mich selbst zu waschen oder anzuziehen",
            "Ich habe leichte Probleme, mich selbst zu waschen oder anzuziehen",
            "Ich habe mäßige Probleme, mich selbst zu waschen oder anzuziehen",
            "Ich habe große Probleme, micht selbst zu waschen oder anzuziehen",
            "Ich bin nicht in der Lage mich selbst zu waschen oder anzuziehen"
        ],
        {__label: "Für sich selbst sorgen"});

    const __eq5dailyActivities = createOptions(
        [
            "Keine Angabe",
            "Ich habe keine Probleme, meinen alltäglichen Tätigkeiten nachzugehen",
            "Ich habe leichte Probleme, meinen alltäglichen Tätigkeiten nachzugehen",
            "Ich habe mäßige Probleme, meinen alltäglichen Tätigkeiten nachzugehen",
            "Ich habe große Probleme, meinen alltäglichen Tätigkeiten nachzugehen",
            "Ich bin nicht in der Lage, meinen alltäglichen Tätigkeiten nachzugehen"
        ],
        {__label: "Alltägliche Tätigkeiten (z.B. Arbeit, Studium, Hausarbeit, Familien oder Freizeitaktivitäten)"}
    );
    const __eq5pain = createOptions(
        [
            "Keine Angabe",
            "Ich habe keine Schmerzen oder Beschwerden",
            "Ich habe leichte Schmerzen oder Beschwerden",
            "Ich habe mäßige Schmerzen oder Beschwerden",
            "Ich habe starke Schmerzen oder Beschwerden",
            "Ich habe extreme Schmerzen oder Beschwerden"
        ],
        {__label: "Schmerzen / Körperliche Beschwerden"}
    );

    const __eq5fear = createOptions(
        [
            "Keine Angabe",
            "Ich bin nicht ängstlich oder deprimiert",
            "Ich bin ein wenig ängstlich oder deprimiert",
            "Ich bin mäßig ängstlich oder deprimiert",
            "Ich bin sehr ängstlich oder deprimiert",
            "Ich bin extrem ängstlich oder deprimiert"
        ],
        {__label: "Angst / Niedergeschlagenheit"}
    );

    const __eq5interviewSituation = createOptions(
        [
            "Der Fragebogen wurde vom Patienten selbst ausgefüllt.",
            "Der Fragebogen wurde als Interview-Situation von einer Begleitperson ausgefüllt.",
            "Der Fragebogen wurde als Interview-Situation von einer Study Nurse ausgefüllt."
        ],
        {__label: "Interviewsituation"}
    );

    const zielDruck = get(patient, "studyData.cohort", "") === CAT_CONTROL && category === CAT_INCLUSION ?
        createZieldruck(patient.id, t) : [];

    const model = {
        id: null,
        startDate: null,
        endDate: null,
        category: category,
        status: APPT_STATUS_NOT_SCHEDULED_YET,
        doctors: [],
        documentSamples : [],
        __roles:["clinician"],
        interventions: patient.interventions,
        correlationSamples: [
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "Visus",
                    correlationType: "dezimalvisus",
                    __hint: "Falls Dezimalvisus unter 0.05, bitte Metervisus ODER Unter Metervisus ausfüllen!",
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "decimalvisusRight",
                        __label: "Dezimalvisus rechts (bestkorrigierter Wert)",
                        __css: "c-column-left",
                        __placeholder: "Z.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional:true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "decimalvisusLeft",
                        __label: "Dezimalvisus links (bestkorrigierter Wert)",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional:true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    })
                ],
                categorySamples: [
                    createSample({
                        subject: patient.id,
                        categoryType: "meterVisusRight",
                        __label: "Metervisus rechts",
                        __css: "c-column-left",
                        __placeholder: "x/y",
                        __optional: true,
                        __description: "Bitte geben Sie einen Bruch ein. Beispiel: 1/45",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_FRACTION)|| t("E_INVALID")
                        }
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "meterVisusLeft",
                        __label: "Metervisus links",
                        __css: "c-column-right",
                        __placeholder: "x/y",
                        __optional: true,
                        __description: "Bitte geben Sie einen Bruch ein. Beispiel: 1/45",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_FRACTION)|| t("E_INVALID")
                        }
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "decimalVisusOptionRight",
                        __type: "select",
                        __label: __decimalVisusOptions.value + " rechts",
                        __css: "c-column-left",
                        __placeholder: "",
                        __optional: true,
                        __description: "Bitte wählen Sie eine Option aus",
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__decimalVisusOptions
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "decimalVisusOptionLeft",
                        __type: "select",
                        __label: __decimalVisusOptions.value + " links",
                        __css: "c-column-right",
                        __placeholder: "",
                        __optional: true,
                        __description: "Bitte wählen Sie eine Option aus",
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__decimalVisusOptions
                    })
                ]
            },
            {
                ...createCorrelation(
                    {
                        subject: patient.id,
                        __label: "Tensiomessung",
                        __deviceModel,
                        correlationType: "tensioMeasurementManual"
                    }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "tensioMeasurementManualRight",
                        unit: "mmHg",
                        __label: "Tensiomessung rechts",
                        __css: "c-column-left",
                        __placeholder: "ZZ",
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: false
                    }),
                    createQuantity({
                        quantityType: "tensioMeasurementManualLeft",
                        unit: "mmHg",
                        __label: "Tensiomessung links",
                        __css: "c-column-right",
                        __placeholder: "ZZ",
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                        },
                        __optional: false
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "30° Perimetrie",
                    __deviceModel: __deviceModelPerimetrie,
                    correlationType: "30perimetry",
                    __hint: t("oct_group_hint")
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "visualFieldIndexRight",
                        unit: "%",
                        __label: "Visual Field Index (VFI) rechts",
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        __label: "Visual Field Index (VFI) links",
                        quantityType: "visualFieldIndexLeft",
                        unit: "%",
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __placeholder: "ZZ",
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "averageDefectDepthRight",
                        __label: "Mittlere Defekttiefe (MD) rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_decimal_with_sign"),
                        __placeholder: "ZZ.ZZ",
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "averageDefectDepthLeft",
                        __label: "Mittlere Defekttiefe (MD) links",
                        unit: "dB",
                        __css: "c-column-right",
                        __optional: true,
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal_with_sign"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL_WITH_SIGN) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "psdRight",
                        __label: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD) rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "psdLeft",
                        __label: "Musterbezogene Standardabweichung des individuellen Gesichtsfeldberges (PSD) links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.ZZ",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "sLVRight",
                        __label: "sLV rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "sLVLeft",
                        __label: "sLV links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "CsLVRight",
                        __label: "CsLV rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "CsLVLeft",
                        __label: "CsLV links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "meanSensitivityRight",
                        __label: "Mean Sensitivity rechts",
                        unit: "dB",
                        __css: "c-column-left",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "meanSensitivityLeft",
                        __label: "Mean Sensitivity links",
                        __css: "c-column-right",
                        __optional: true,
                        unit: "dB",
                        __placeholder: "ZZ.Z",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    correlationType: "pachymtery",
                    __label: "Pachymetrie",
                    __deviceModel: __deviceModelPachymetrie
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "pachymetryRight",
                        __label: "Pachymetrie rechts",
                        unit: "µm",
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "pachymetryLeft",
                        __label: "Pachymetrie links",
                        unit: "µm",
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    correlationType: "sdOct",
                    __label: "SD-OCT",
                    __deviceModel: __deviceModelSDOCT
                }),
                quantitySamples: [
                    {__separator: {title: "BMO-MRW", hint:t("oct_group_hint")}},
                    createQuantity({
                        quantityType: "bmoAreaRight",
                        __label: "BMO Area Rechts",
                        unit: "mm^2",
                        __css: "c-column-left",
                        __placeholder: "Z.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoAreaLeft",
                        __label: "BMO Area Links",
                        unit: "mm^2",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        __description: t("tooltip_decimal"),
                        __optional: true,
                        __v: {
                            validate: (value) => value==="" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwGlobalRight",
                        __label: "Global rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwGlobalLeft",
                        __label: "Global links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalSuperiorRight",
                        __label: "Nasal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalSuperiorLeft",
                        __label: "Nasal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalRight",
                        __label: "Nasal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalLeft",
                        __label: "Nasal links",
                        unit: null,
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalInferiorRight",
                        __label: "Nasal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwNasalInferiorLeft",
                        __label: "Nasal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalInferiorRight",
                        __label: "Temporal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalInferiorLeft",
                        __label: "Temporal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalRight",
                        __label: "Temporal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalLeft",
                        __label: "Temporal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalSuperiorRight",
                        __label: "Temporal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "bmoMrwTemporalSuperiorLeft",
                        __label: "Temporal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),

                    /*************************3,5mm*******************/
                    {__separator: {title: "RFNL-OCT 3,5 mm" , hint:t("oct_group_hint")}},
                    createQuantity({
                        quantityType: "rfnlOct35GlobalRight",
                        __label: "Global rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35GlobalLeft",
                        __label: "Global links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35SuperiorRight",
                        __label: "Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35SuperiorLeft",
                        __label: "Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35InferiorRight",
                        __label: "Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35InferiorLeft",
                        __label: "Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalSuperiorRight",
                        __label: "Nasal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalSuperiorLeft",
                        __label: "Nasal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalRight",
                        __label: "Nasal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalLeft",
                        __label: "Nasal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalInferiorRight",
                        __label: "Nasal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35NasalInferiorLeft",
                        __label: "Nasal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalInferiorRight",
                        __label: "Temporal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalInferiorLeft",
                        __label: "Temporal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalRight",
                        __label: "Temporal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalLeft",
                        __label: "Temporal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalSuperiorRight",
                        __label: "Temporal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct35TemporalSuperiorLeft",
                        __label: "Temporal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),

                    /**************************************4,1mm****************************/
                    {__separator: {title: "RFNL-OCT 4,1 mm", hint:t("oct_group_hint")}},
                    createQuantity({
                        quantityType: "rfnlOct41GlobalRight",
                        __label: "Global rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41GlobalLeft",
                        __label: "Global links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41SuperiorRight",
                        __label: "Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41SuperiorLeft",
                        __label: "Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41InferiorRight",
                        __label: "Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41InferiorLeft",
                        __label: "Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalSuperiorRight",
                        __label: "Nasal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalSuperiorLeft",
                        __label: "Nasal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalRight",
                        __label: "Nasal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalLeft",
                        __label: "Nasal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalInferiorRight",
                        __label: "Nasal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41NasalInferiorLeft",
                        __label: "Nasal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalInferiorRight",
                        __label: "Temporal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalInferiorLeft",
                        __label: "Temporal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalRight",
                        __label: "Temporal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalLeft",
                        __label: "Temporal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalSuperiorRight",
                        __label: "Temporal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct41TemporalSuperiorLeft",
                        __label: "Temporal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),

                    /**************************************4,7mm****************************/
                    {__separator: {title: "RFNL-OCT 4,7 mm", hint:t("oct_group_hint")}},
                    createQuantity({
                        quantityType: "rfnlOct47GlobalRight",
                        __label: "Global rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47GlobalLeft",
                        __label: "Global links",
                        unit: null,
                        __css: "c-column-right",
                        __description: t("tooltip_integer"),
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47SuperiorRight",
                        __label: "Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47SuperiorLeft",
                        __label: "Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47InferiorRight",
                        __label: "Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47InferiorLeft",
                        __label: "Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalSuperiorRight",
                        __label: "Nasal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalSuperiorLeft",
                        __label: "Nasal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalRight",
                        __label: "Nasal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalLeft",
                        __label: "Nasal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalInferiorRight",
                        __label: "Nasal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47NasalInferiorLeft",
                        __label: "Nasal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalInferiorRight",
                        __label: "Temporal Inferior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalInferiorLeft",
                        __label: "Temporal Inferior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalRight",
                        __label: "Temporal rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalLeft",
                        __label: "Temporal links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalSuperiorRight",
                        __label: "Temporal Superior rechts",
                        unit: null,
                        __css: "c-column-left",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rfnlOct47TemporalSuperiorLeft",
                        __label: "Temporal Superior links",
                        unit: null,
                        __css: "c-column-right",
                        __optional: true,
                        __description: t("tooltip_integer"),
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_OR_EMPTY) || t("E_INVALID")
                        }
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id, __label: "HRT", correlationType: "hrt"
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "discAreaRight",
                        __label: "Disc Area rechts",
                        __css: "c-column-left",
                        unit: "mm^2",
                        __optional: true,
                        __placeholder: "Z.ZZ",
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "discAreaLeft",
                        __label: "Disc Area links",
                        unit: "mm^2",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "RimAreaRight",
                        __label: "Rim Area rechts",
                        unit: "mm^2",
                        __css: "c-column-left",
                        __placeholder: "Z.ZZ",
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rimAreaLeft",
                        __label: "Rim Area links",
                        unit: "mm^2",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rimVolumeRight",
                        __label: "Rim Volume rechts",
                        __css: "c-column-left",
                        __placeholder: "Z.ZZ",
                        unit: "mm^3",
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    }),
                    createQuantity({
                        quantityType: "rimVolumeLeft",
                        __label: "Rim Volume links",
                        __css: "c-column-right",
                        __placeholder: "Z.ZZ",
                        unit: "mm^3",
                        __optional: true,
                        __description: t("tooltip_decimal"),
                        __v: {
                            validate: (value) => value === "" || value.match(regExs.REGEX_DECIMAL) || t("E_INVALID")
                        }
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "Gonioskopie",
                    correlationType: "gonioscopy"
                }),
                quantitySamples: [
                    createQuantity({
                        quantityType: "shafferRight",
                        __type: "select",
                        __label: "Shaffer 0,I-IV rechts",
                        __css: "c-column-left",
                        __placeholder: "Z",
                        __description: t("tooltip_shaffer"),
                        __optional: true,
                        ...__shafferOptions
                    }),
                    createQuantity({
                        quantityType: "shafferLeft",
                        __type: "select",
                        __label: "Shaffer 0,I-IV links",
                        __css: "c-column-right",
                        __description: t("tooltip_shaffer"),
                        __placeholder: "Z",
                        __optional: true,
                        ...__shafferOptions
                    })
                ]
            },
            {
                ...createCorrelation({
                    subject: patient.id,
                    __label: "EQ-5D Fragebogen",
                    correlationType: "eq5Questionnaire"
                }),
                categorySamples: [
                    createSample({
                        subject: patient.id,
                        categoryType: "interviewSituation",
                        __type: "select",
                        __label: __eq5interviewSituation.value,
                        __css: "c-column-left",
                        __placeholder: "",
                        __optional: true,
                        __description: "Bitte wählen Sie eine Option aus",
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5interviewSituation
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "mobility",
                        __type: "select",
                        __label: __eq5mobility.value,
                        __css: "c-column-left",
                        __placeholder: "",
                        __description: "Bitte wählen Sie eine Option aus",
                        __optional: true,
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5mobility
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "pain",
                        __type: "select",
                        __label: __eq5pain.value,
                        __css: "c-column-right",
                        __description: "Bitte wählen Sie eine Option aus",
                        __placeholder: "",
                        __optional: true,
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5pain
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "selfcare",
                        __type: "select",
                        __label: __eq5selfcare.value,
                        __css: "c-column-left",
                        __description: "Bitte wählen Sie eine Option aus",
                        __placeholder: "",
                        __optional: true,
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5selfcare
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "fear",
                        __type: "select",
                        __label: __eq5fear.value,
                        __css: "c-column-right",
                        __description: "Bitte wählen Sie eine Option aus",
                        __placeholder: "",
                        __optional: true,
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5fear
                    }),
                    createSample({
                        subject: patient.id,
                        categoryType: "dailyActivities",
                        __type: "select",
                        __label: __eq5dailyActivities.value,
                        __description: "Bitte wählen Sie eine Option aus",
                        __css: "c-column-left",
                        __placeholder: "",
                        __optional: true,
                        __v: {
                            validate: (value) => !!value || t("E_INVALID")
                        },
                        ...__eq5dailyActivities
                    })
                ],
                quantitySamples: [
                    createQuantity({
                        quantityType: "eq5rateHealth",
                        __css: "c-column-left",
                        __label: "Wir wollen herausfinden, wie gut oder schlecht Ihre Gesundheit HEUTE ist. ",
                        __placeholder: "0 ... 100",
                        __description: "Bitte geben Sie eine Zahl zwischen 0 (schlecht) und 100 (gut) ein.",
                        __optional: true,
                        __v: {
                            validate: (value) => value.match(regExs.REGEX_INTEGER_0_100) || t("E_INVALID")
                        }
                    })
                ]
            },
            ...zielDruck
        ],
        recommendedTherapy: {
            therapy: "",
        }
    };

    return copyOverModified(model, payload);
};

export default createModel;
