import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Header, Icon} from "semantic-ui-react";
import {get} from "lodash";
import {PAT_STATUS_DATA_ACTIVE, PAT_STATUS_DATA_EXCLUDED, PAT_STATUS_DATA_MISSING} from "../data/mutations/mutations";
import {AuthContext, formatDate} from "../services";

const PatientMasterData = ({patient, onEdit, stats}) => {
    const { user } = useContext(AuthContext);
    const isAccountingUser = user.roles.includes("accounting");
    const isQualityControlUser = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c") || user.roles.includes("data_entry_manager");
    const isPatient = user.roles.includes("patient");
    const {t} = useTranslation();
    const {masterData = {}} = patient;
    const {contact = {}} = masterData;

    const databaseUnlocked = get(stats, ['stats', 'security', 'databaseUnlocked'], false);

    const setPatientStatusColor = (status) =>{
        let cssclass;
        switch (status){
            case PAT_STATUS_DATA_MISSING:
                cssclass = "patientStatusLabel--data-missing";
                break;
            case PAT_STATUS_DATA_ACTIVE:
                cssclass = "patientStatusLabel--active";
                break;
            case PAT_STATUS_DATA_EXCLUDED:
                cssclass = "patientStatusLabel--excluded";
                break;
            default:
                cssclass = "";
        }
        return(
            <div className={cssclass}>{t("PAT_STATUS_"+ status)}</div>
        );

    };

    const isDisabled = () => {
        return !databaseUnlocked || patient.status === PAT_STATUS_DATA_EXCLUDED || isPatient;
    }

    const editPatient = () => {
        if(!isDisabled())
            onEdit();
    }

    const isVisible = () => {
        return !isQualityControlUser;
    }

    const isNotAccUser = () => {
        return !isAccountingUser;
    }

    return (
        <div className={"Grid padding-normal"}>
            {(isNotAccUser() && isVisible()) &&
            <div>
                <Header as={"h3"} onClick={editPatient}>
                    <Icon name={"pencil square"}
                          disabled={isDisabled()}
                          color={"teal"}
                          className={"cursor-pointer"}/>
                </Header>
            </div>
            }

            <div>
                {isVisible() &&
                <Header as={"h3"}>
                    {masterData.forename + " " + masterData.surname}
                    <br />
                </Header>
                }

                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("cohort")}</div>
                    <div>{t("COHORT_" + patient.studyData.cohort)}</div>
                    <br/>
                </div>
                }

                {(isAccountingUser || isPatient) ? null :
                <div>
                    <div className={"c-header"}>{t("study_id")}</div>
                    <div>{patient.patientId}</div>
                    <br/>
                </div>}

                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("doctor")}</div>
                    <div>
                        {
                            get(patient, "studyData.doctorInCharge.forename") + " " +
                            get(patient, "studyData.doctorInCharge.surname")
                        }
                    </div>
                    <br/>
                </div>
                }
                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("study_inclusion_date")}</div>
                    <div>{formatDate(patient.studyData.inclusionDate)}</div>
                    <br/>
                </div>
                }
                <div>
                    <div className={"c-header"}>{t("patient_status")}</div>
                    {setPatientStatusColor(patient.status)}
                    <br />
                </div>
                {/*<div>
                    <div className={"c-header"}>{t("doctor")}</div>
                    <div>{_.get(patient, "studyData.doctorInCharge")}</div>
                    <br />
                </div>*/}
                {(isNotAccUser() && isVisible()) &&
                <div>
                    <div className={"c-header"}>{t("date_of_birth")}</div>
                    <div>{formatDate(masterData.birthdate)}</div>
                    <br/>
                </div>
                }

                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("insurance_company")}</div>
                    <div>{masterData.insurance.company}</div>
                    <br/>
                </div>
                }

                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("insurance_number")}</div>
                    <div>{masterData.insurance.number}</div>
                    <br />
                </div>
                }

                {isVisible() &&
                <div>
                    <div className={"c-header"}>{t("hospital_in_charge")}</div>
                    <div>{masterData.hospital && masterData.hospital.name}</div>
                    <br/>
                </div>
                }

                {(isNotAccUser() && isVisible()) &&
                <>
                    <div>
                        <div className={"c-header"}>{t("address")}</div>
                        <div>{contact.address.street + " " + contact.address.streetno}</div>
                        <div>{contact.address.postalcode + " " + contact.address.city}</div>
                        <br/>
                    </div>

                    <Contact contact={contact} t={t}/>
                </>
                }
            </div>
        </div>
    );
};

const ContactRow = ({icon, data}) => {
    return (
        <div className={"Grid"}>
            <Icon name={icon} color={"teal"} />
            <div className={"color-teal"}>
                {data.map((it, index) => (
                    <div key={index}> {it} </div>
                ))}
            </div>
        </div>
    );
};
const Contact = ({contact, t}) => {
    return (
        <div style={{fontSize: "1.1rem"}}>
            <div className={"c-header"}>{t("contact_data")}</div>
            <ContactRow icon={"phone"} data={contact.telephoneno} />
            <ContactRow icon={"mail outline"} data={contact.email} />
        </div>
    );
};

export default PatientMasterData;
