import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Form, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {AuthContext, toastError} from "../../services";
import {useMutateAppointment} from "../../hooks";
import * as _ from "lodash";
import {
    CAT_FINAL,
    CAT_FIRST,
    CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4,
    CAT_INCLUSION,
    CAT_TENSIO
} from "../../data/mutations/appointments";


const KonsilKlinikTab = ({patientId, patient, dataModel, refresh}) => {
    const {user} = useContext(AuthContext);
    const isClinician = user.roles.includes("clinician");
    const { t } = useTranslation();
    const [mutateData] = useMutateAppointment();

    const getCurrentTab = () => {
        const tabs = ["MEDICAL_HISTORY", CAT_FIRST, CAT_INCLUSION, CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];
        const currentTabIndex = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
        return tabs[currentTabIndex];
    };

    const categorySamples = patient?.appointments.find(x => _.get(x, "category") === getCurrentTab())?.categorySamples ?? null;

    const getTemplate = (categoryType, value) => {
        return {
            categoryType: categoryType,
            endDate: new Date().toISOString(),
            id: null,
            metadata: {
                changedBy: null,
                changedDate: null,
                comment: null,
                createdBy: null,
                creationDate: new Date().toISOString(),
                deviceName: null,
                deviceVendorName: null,
                examinationType: null,
                eyeLocation: null,
                __typename: "MetaData",
            },
            sampleType: "category",
            startDate: new Date().toISOString(),
            subject: patientId,
            value: value,
            __typename: "CategorySample",
        }
    };

    const [samples, setSamples] = useState(categorySamples ? [...categorySamples] : []);

    /* eslint-disable */
    useEffect(() => {
        setSamples(categorySamples ? [...categorySamples] : [])
    }, [patient])

    // eslint-enable

    const findIndex = (categoryType) => {
        for (let i = 0; i < samples.length; ++i ){
            if (samples[i].categoryType === categoryType) {
                return i;
            }
        }
        return -1;
    }

    const onChangeTherapyRecommendation = (value) => {
        const index = findIndex("KonsilKlinikTherapyRecommendation");
        if (index === -1) {
            const template = getTemplate("KonsilKlinikTherapyRecommendation", value);
            samples.push(template);
        } else {
            samples[index].value = value;
        }
        setSamples([...samples]);
    }

    const onChangeComment = (value) => {
        const index = findIndex("KonsilKlinikComment");
        if (index === -1) {
            const template = getTemplate("KonsilKlinikComment", value);
            samples.push(template);
        } else {
            samples[index].value = value;
        }
        setSamples([...samples]);
    }

    const handleSave = () => {
        const toSave = {
            id: dataModel.id,
            startDate: dataModel.startDate,
            status: dataModel.status,
            category: dataModel.category,
            recommendedTherapy: _.get(dataModel, "recommendedTherapy"),
            categorySamples: samples,
        };

        return mutateData({
            appointment: toSave,
            patientId
        }).then(() => {
            refresh();
        }, () => {
            toastError(t("error_gql"));
        });
    }

    return (
      <div>
          <div className={{width: "100%"}}>
              {t("therapy_recommendation")}
              <br/>
              <Form>
                  <TextArea
                      rows={5}
                      disabled={!isClinician}
                      value={samples[findIndex("KonsilKlinikTherapyRecommendation")]?.value}
                      onChange={(event, data) => onChangeTherapyRecommendation(data.value)}
                  />
              </Form>
          </div>

          <br />

          <div className={{width: "100%"}}>
              {t("comment")}
              <br/>
              <Form>
                  <TextArea
                      rows={5}
                      disabled={!isClinician}
                      value={samples[findIndex("KonsilKlinikComment")]?.value}
                      onChange={(event, data) => onChangeComment(data.value)}
                  />
              </Form>
          </div>

          <Divider/>
          <br/>

          <div className={"Grid -right"}>
              <Button disabled={!isClinician} basic type="submit" size="tiny" color="teal" onClick={handleSave}
                      content={t("save")}/>
          </div>
      </div>
  );
};

export default KonsilKlinikTab;
