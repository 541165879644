import React, {useState, forwardRef} from "react";
import {getChartDataFromSamples} from "../../data/models/utils";

import {
    DiscreteColorLegend,
    HorizontalGridLines,
    LineMarkSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";
import {formatDateTime} from "../../services";

const ChartTensio = ({data, leftTime, rightTime}, ref) => {
    const [iopODEnable, setIopODEnable] = useState(true);
    const [iopOSEnable, setIopOSEnable] = useState(true);
    const correlationSamples = (data && data.correlationSamples) || [];

    const sortSamples = (samples) => {
        samples.sort((a,b) => (a.x > b.x) ? 1 : -1);
        return samples;
    };

    // 1st Chart
    const s1 = sortSamples(getChartDataFromSamples(correlationSamples, "intraocularPressureRight", leftTime, rightTime));

    // 2nd Chart
    const s2 = sortSamples(getChartDataFromSamples(correlationSamples, "intraocularPressureLeft", leftTime, rightTime));

    const tickFormatOne = (v) => {
        return formatDateTime(v);
    };

    return (
        <div ref={ref}>
            <XYPlot className="clustered-stacked-bar-chart-example" width={1000} height={400}  margin={{bottom: 80, left: 80}}>
                <VerticalGridLines tickTotal={data.length / 2}/>
                <HorizontalGridLines/>
                <XAxis xType={"time"} tickFormat={(v) => tickFormatOne(v)} tickLabelAngle={-45} />
                <YAxis/>
                <LineMarkSeries size={3} data={s1} opacity={iopODEnable ? 1 : 0}/>
                <LineMarkSeries size={3} data={s2} opacity={iopOSEnable ? 1 : 0}/>
                <DiscreteColorLegend
                    orientation="horizontal"
                    style={{position: 'absolute', border: '2px solid #c3c3c3', marginTop: '10px'}}
                    items={[
                        {
                            title: "IOP (OD)",
                            disabled: !iopODEnable
                        },
                        {
                            title: "IOP (OS)",
                            disabled: !iopOSEnable
                        }
                    ]}
                    onItemClick={(item, index) => {
                        switch (index){
                            case 0:
                                setIopODEnable(!iopODEnable);
                                break;
                            case 1:
                                setIopOSEnable(!iopOSEnable);
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </XYPlot>
        </div>
    );
};

export default forwardRef(ChartTensio);
