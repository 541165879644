import {CAT_FINAL} from "../../../data/mutations/appointments";
import * as _ from "./allSections";
import {copyDataToModel} from "./utils";


export const createReaderFinalAppointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples) => {
    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createDezimalvisusSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createTensiomessungSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createBMOMRWSection(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createPerimetrieSection(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createHRTSection(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createRFNLOCT35Section(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createRFNLOCT41Section(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createRFNLOCT47Section(allAppointmentsCorrelationSamples, CAT_FINAL, userId, role, patientId),
        _.createPapillenphotoSection(CAT_FINAL, userId, role, patientId),
        _.createPapillenphotoProgressionSection(userId, role, patientId)
    ]

    return copyDataToModel(model, categorySamples);
}