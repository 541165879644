import { CAT_INCLUSION, CAT_CONTROL} from "../../../data/mutations/appointments";
import * as _ from "./allSections";
import {copyDataToModel} from "./utils";


export const createReaderInclusionAppointment = (allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort) => {
    const model = [
        _.createReaderCSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createDezimalvisusSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createTensiomessungSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        ...cohort === CAT_CONTROL ? [_.createIndividuellerZieldruckSection(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId)] : [],
        _.createBMOMRWSection(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createPerimetrieSection(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createPachymetrieSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createHRTSection(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createGonioskopieSection(allAppointmentsCorrelationSamples, userId, role, patientId),
        _.createRFNLOCT35Section(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createRFNLOCT41Section(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createRFNLOCT47Section(allAppointmentsCorrelationSamples, CAT_INCLUSION, userId, role, patientId),
        _.createPapillenphotoSection(CAT_INCLUSION, userId, role, patientId)
    ]

    return copyDataToModel(model, categorySamples);
}