import RenderMedicationBlock from "./RenderMedicationBlock";
import InterventionBlock from "./RenderInterventionTable";
import CorrelationSamplesBlock from "./RenderCorrelationSamplesBlock";
import {TAB_EXAMINATION} from "../patientEdit/Tabs";
import React, {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import {AuthContext, toastError} from "../../services";
import {Button, Divider, Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {initialAppointmentSummary} from "../patientSummary/ErstuntersuchungSummary";
import {inclusionSummary} from "../patientSummary/EinschlussuntersuchungSummary";
import {followup1Summary} from "../patientSummary/Kontrolluntersuchung1Summary";
import {followup2Summary} from "../patientSummary/Kontrolluntersuchung2Summary";
import {followup3Summary} from "../patientSummary/Kontrolluntersuchung3Summary";
import {finalExaminationSummary} from "../patientSummary/AbschlussuntersuchungSummary";
import {clinicalTTPExaminationControlSummary} from "../patientSummary/ClinicalTTPExaminationControlSummary";
import {CAT_CONTROL} from "../../data/mutations/appointments";
import {renderReaderCComment} from "../reader/models/utils";

const ExaminationTab = ({dataModel, patientId, isReadOnly, afterSave, readerCComment, patient}, ref) => {
    const {t} = useTranslation();
    const { user } = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");
    const medBlockRef = useRef();
    const interventionBlockRef = useRef();
    const correlationBlockRef = useRef();
    const form = useForm({
        mode: "onChange"
    });
    const isDataEntryManager = user.roles.includes("data_entry_manager");

    const checkRef = (ref) => {
        return ref && ref.current;
    };

    useImperativeHandle(ref, () => ({
        hasChanged () {
            return medBlockRef.current.hasChanged() ||
                interventionBlockRef.current.hasChanged() ||
                correlationBlockRef.current.hasChanged();
        },
        async hasError () {
            const result = await form.trigger();
            if (result) {
                return !(checkForErrors(medBlockRef) && checkForErrors(interventionBlockRef));
            } else {
                return true;
            }
        },
        save () {
            return handleSave();
        }
    }));

    // check if can save the form
    const checkForErrors = (ref) => {
        let canSave = true;
        if (checkRef(ref) && ref.current.hasChanged()) {
            if (ref.current.hasError()) {
                canSave = false;
            }
        }
        return canSave;
    };

    const handleSave = (data) => {
        if (form.formState.isValid) {
            if (checkForErrors(medBlockRef) && checkForErrors(interventionBlockRef)) {
                const f1 = medBlockRef.current.save(data);
                const f2 = interventionBlockRef.current.save(data);
                const f3 = correlationBlockRef.current.save(data);

                form.reset(null, {isDirty: false});

                return Promise.all([f1, f2, f3]).then(() => {
                    afterSave();
                });
            } else {
                toastError(t("error_fields_incorrect"));
            }
        }
    };

    const onError = (errors, e) => {
        toastError(t("error_fields_incorrect"));
    }

    const showPrintButton = () => {
        const activeTabIndex = window.sessionStorage.getItem("appointmentTabActiveIndexForPatient");
        if (isPatient) {
            if (activeTabIndex === "3") {
                return patient.studyData.cohort === CAT_CONTROL;
            }
            return ["1", "2", "4", "5", "6", "7"].includes(activeTabIndex)
        }
        return false;
    }

    async function printSummary () {
        const { masterData } = patient;

        switch(window.sessionStorage.getItem("appointmentTabActiveIndexForPatient")){
            case "1":
                await initialAppointmentSummary(masterData, patient.studyData.cohort, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "2":
                await inclusionSummary(masterData, patient.studyData.cohort, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "3":
                await clinicalTTPExaminationControlSummary(masterData, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "4":
                await followup1Summary(masterData, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "5":
                await followup2Summary(masterData, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "6":
                await followup3Summary(masterData, dataModel.correlationSamples, dataModel.startDate);
                break;
            case "7":
                await finalExaminationSummary(masterData, dataModel.correlationSamples, dataModel.startDate);
                break;
            default:
        }

    }

    return (
        <>
            {showPrintButton() &&
            <div className={"Grid -right"}>
                <Button disabled={false} basic size="tiny" color="teal" onClick={() => printSummary(window.sessionStorage.getItem("appointmentTabActiveIndexForPatient"))} content={t("print")} />
            </div>
            }

            <Form id="examination-form" onSubmit={form.handleSubmit(handleSave, onError)}>
                {(readerCComment && !isDataEntryManager) ? renderReaderCComment(t("reader_c_comment"), readerCComment, "reader_c_comment") : null}

                <RenderMedicationBlock category={dataModel.category} patientId={patientId}
                                       startDate={dataModel.startDate} ref={medBlockRef} isReadOnly={isReadOnly}/>
                <InterventionBlock category={dataModel.category} patientId={patientId}
                                   interventions={dataModel.interventions}
                                   startDate={dataModel.startDate} ref={interventionBlockRef} isReadOnly={isReadOnly}/>
                <CorrelationSamplesBlock data={dataModel} patientId={patientId} tab={TAB_EXAMINATION} form={form}
                                         ref={correlationBlockRef} isReadOnly={isReadOnly}/>

                {!isPatient &&
                <>
                    <Divider/>
                    <br/>
                    <div className={"Grid -right"}>
                        <Button disabled={isReadOnly} form="examination-form" basic type="submit" size="tiny" color="teal"
                                content={t("save")}/>
                    </div>
                </>
                }
            </Form>
        </>
    );
};

export default forwardRef(ExaminationTab);
