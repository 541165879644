import gql from "graphql-tag";
import {metaDataFragment} from './metaDataFragment';

export const documentSampleFragment = gql`
    fragment documentSampleFragment on DocumentSample {
        id
        endDate
        sampleType
        startDate
        subject
        documentType
        documentName
        dataSize
        dataType
        dataURL
        metadata {
            ...metaDataFragment
        }
    }
    ${metaDataFragment}
`;
