import React, {useContext, useEffect, useReducer, useState} from "react";
import {Table} from "semantic-ui-react";
import _, {get} from "lodash";
import {useTranslation} from "react-i18next";
import {
    CAT_FIRST,
    CAT_INCLUSION,
    CAT_CONTROL,
    CAT_TENSIO,
    CAT_FOLLOWUP_T2,
    CAT_FOLLOWUP_T3,
    CAT_FOLLOWUP_T4,
    CAT_FINAL,
    CAT_INTERVENTION,
    APPT_READING_FINISHED,
    APPT_STATUS_TOOK_PLACE,
    APPT_READING,
    APPT_DISCREPANCY,
    APPT_INQUIRY,
    APPT_STATUS_CANCELED
} from "../../data/mutations/appointments";
import {AuthContext, formatDateTime} from "../../services";
import {Link} from "react-router-dom";
import {
    checkDocLength, checkRequiredFields, getReaderCategorySamples, getStatusFieldSection
} from "./models/utils";
import createModelFollowUpFirst from "../../data/models/appointmentSharedFollowUpFirst";
import createModelFollowUp from "../../data/models/appointmentFollowUp";
import createModelFollowUpT4 from "../../data/models/appointmentFollowupT4";
import createModelControl from "../../data/models/appointmentControl";
import createModelInclusionFinal from "../../data/models/appointmentSharedInclusionFinal";
import createModelFinal from "../../data/models/appointmentFinal";
import createModelTensio from "../../data/models/appointmentTensio";
import {PAT_STATUS_DATA_EXCLUDED} from "../../data/mutations/mutations";

function reducer(state, action) {
    switch (action.type) {
        case "CHANGE_SORT":
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === "ascending" ? "descending" : "ascending",
                }
            }
            if (action.column === "studyId") {
                return {
                    ...state,
                    column: action.column,
                    data: state.data.sort((a,b) => a.patientId > b.patientId ? 1 : -1),
                    direction: "ascending",
                }
            }
            if (action.column === "todo") {
                return {
                    ...state,
                    column: action.column,
                    data: state.data.sort((a,b) => state.toDoPatient.includes(a) && !state.toDoPatient.includes(b) ? -1 : 1),
                    direction: "ascending",
                }
            }
            break;
        default:
            throw new Error();
    }
}

function getAppointment(appointments, appointmentName) {
    return _.find(appointments, function(obj){
        return obj.category === appointmentName;
    }) || [];
}

function setSessionStorageIndex(index) {
    window.sessionStorage.setItem("appointmentTabActiveIndexForPatient", index);
    window.sessionStorage.setItem("appointmentActiveIndexInTab", "0");
}

const appointmentNames = [CAT_FIRST, CAT_INCLUSION, CAT_CONTROL, CAT_TENSIO, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4, CAT_FINAL];


const getPreviousAppointmentDate = (category, modelInclusion, modelFollowUp1, modelFollowUp2) => {
    switch (category) {
        case CAT_FOLLOWUP_T2:
            return new Date(modelInclusion?.startDate);
        case CAT_FOLLOWUP_T3:
            return new Date(modelFollowUp1?.startDate);
        case CAT_FOLLOWUP_T4:
            return new Date(modelFollowUp2?.startDate);
        default:
            return null;
    }
}

const didPreviousAppointmentTookPlace = (category, modelInclusion, modelFollowUp1, modelFollowUp2) => {
    switch (category) {
        case CAT_FOLLOWUP_T2:
            return modelInclusion?.status === APPT_STATUS_TOOK_PLACE
        case CAT_FOLLOWUP_T3:
            return modelFollowUp1?.status === APPT_STATUS_TOOK_PLACE
        case CAT_FOLLOWUP_T4:
            return modelFollowUp2?.status === APPT_STATUS_TOOK_PLACE
        default:
            return null;
    }
}

const checkIfNextAppointmentsTookPlace = (currentAppointment, allModels) => {
    allModels = allModels.filter((item) => item?.category !== currentAppointment?.category)
    return allModels.some((item) => item.status === APPT_STATUS_TOOK_PLACE);
}

const isAppointmentInComplete = (appointment, allModels, modelInclusion, modelFollowUp1, modelFollowUp2) => {
    const sample = get(appointment, "correlationSamples", []);
    const didAppointmentTakePlace = appointment?.status === APPT_STATUS_TOOK_PLACE;
    const didNextAppointmentsTookPlace = checkIfNextAppointmentsTookPlace(appointment, allModels);
    const followUp = [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4]

    if (didAppointmentTakePlace && ((sample && !checkRequiredFields(sample)) || checkDocLength(appointment))) {
        return true
    }
    if (!didAppointmentTakePlace && appointment?.startDate && new Date(appointment?.startDate).getTime() <= new Date().getTime()) {
        return true
    }
    if (!didAppointmentTakePlace && didNextAppointmentsTookPlace) {
        return true
    }

    if (followUp.includes(appointment?.category)) {
        let previousAppointmentDate = getPreviousAppointmentDate(appointment?.category, modelInclusion, modelFollowUp1, modelFollowUp2);
        previousAppointmentDate.setMonth(previousAppointmentDate.getMonth() + 3);
        if (!didAppointmentTakePlace && didPreviousAppointmentTookPlace(appointment?.category, modelInclusion, modelFollowUp1, modelFollowUp2) &&
            new Date().getTime() >= previousAppointmentDate.getTime()) {

            return true
        }
    }
    return false
}

function getColor (color, readingStatus, role, status, readerAOrBStatuses, dataEntryManager = null,
                   isInclusion = false, appointment = null) {
    if (readingStatus) {
        if (readingStatus.value === APPT_READING_FINISHED) {
            if (status === APPT_STATUS_CANCELED) {
                color = "green"
            } else if (checkDocLength(appointment)) {
                color = ""
            } else {
                color = "green"
            }
        } else if (appointment && (role === "reader_a" || role === "reader_b" || role === "data_entry_manager") && checkDocLength(appointment)) {
            color = ""
        } else if (role === "data_entry_manager" && (readingStatus.value === "" || readingStatus.value === APPT_INQUIRY)) {
            color = "red"
        } else if ((role === "reader_a" || role === "reader_b") && (readingStatus.value === APPT_READING)) {
            if (readerAOrBStatuses && readerAOrBStatuses.length) {
                for(let _x of readerAOrBStatuses) {
                    let fieldNotAvailable = false
                    if (isInclusion && (_x?.categoryType.includes("bmoMrwHinweisAufProgression") ||
                        _x?.categoryType?.includes("papillenphotoHinweisAufProgression"))) {

                        continue
                    }
                    if (dataEntryManager) {
                        const fieldVal = getStatusFieldSection(dataEntryManager, _x)
                        fieldNotAvailable = fieldVal === "nichtVorhanden"
                    }
                    if (_x.value || fieldNotAvailable) {
                        continue
                    } else {
                        color = "red"
                        break
                    }
                }
            } else {
                color = "red"
            }
        } else if (role === "reader_c" && readingStatus.value === APPT_DISCREPANCY) {
            color = "red"
        } else {
            color = ""
        }
    } else if (status && status === APPT_STATUS_TOOK_PLACE) {
        if (role === "data_entry_manager") {
            color = "red"
        } else {
            color = ""
        }
    }
    return color
}

function whichModel(modelName, modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelControl, modelInclusion,
                    modelFinal, modelTensio) {
    switch (modelName) {
        case CAT_FIRST:
            return modelFirst
        case CAT_FOLLOWUP_T2:
            return modelFollowUp1
        case CAT_FOLLOWUP_T3:
            return modelFollowUp2
        case CAT_FOLLOWUP_T4:
            return modelFollowUp3
        case CAT_INCLUSION:
            return modelInclusion
        case CAT_FINAL:
            return modelFinal
        case CAT_CONTROL:
            return modelControl
        case CAT_TENSIO:
            return modelTensio
        default:
            return null
    }

}

function getTableCells(t, patient, role,  modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelControl,
                       modelInclusion, modelFinal, modelTensio, isControlCohort, isPatientExcluded) {

    const appointmentNamesIndex = [1, 2, 3, 3, 4, 5, 6, 7];
    let items = [];

    let allModels = [modelInclusion, isControlCohort ? modelControl : modelTensio, modelFollowUp1, modelFollowUp2,
        modelFollowUp3, modelFinal]

    for(let i = 0; i < appointmentNames.length; i++) {
        if ((appointmentNames[i] === CAT_CONTROL && patient.studyData.cohort === CAT_INTERVENTION) || (appointmentNames[i] === CAT_TENSIO && patient.studyData.cohort === CAT_CONTROL)) {
            continue;
        }
        const categorySamples = patient?.appointments.find(x => _.get(x, "category") === appointmentNames[i])?.categorySamples ?? null;

        const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null
        let status = getAppointment(patient.appointments, appointmentNames[i]).status
        let color = ""

        if (!readingStatus && role === "data_entry_manager" && status === APPT_STATUS_TOOK_PLACE) {
            if(isAppointmentInComplete(whichModel(appointmentNames[i], modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3,
                modelControl, modelInclusion, modelFinal, modelTensio), allModels, modelInclusion, modelFollowUp1, modelFollowUp2)) {

                color = ""
            } else color = "red"
        } else {
            const readersAOrB = ((role === "reader_a" || role === "reader_b") && categorySamples && readingStatus && readingStatus.value === APPT_READING) ?
                getReaderCategorySamples(categorySamples, role) : []
            const dataEntryManager = categorySamples?.filter(x => {
                const value = _.get(x, "categoryType")
                return value.includes("data_entry_manager")
            })
            color = getColor("", readingStatus, role, status, readersAOrB, dataEntryManager, appointmentNames?.[i] === CAT_INCLUSION,
                whichModel(appointmentNames[i], modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelControl, modelInclusion, modelFinal, modelTensio))
        }

        if (readingStatus) {
            if (status === APPT_STATUS_CANCELED && readingStatus.value === APPT_READING_FINISHED) {
                status = APPT_READING_FINISHED
            }
            else if (checkDocLength(whichModel(appointmentNames[i], modelFirst, modelFollowUp1, modelFollowUp2,
                modelFollowUp3, modelControl, modelInclusion, modelFinal, modelTensio))) {
                status = APPT_STATUS_TOOK_PLACE
            } else if (readingStatus.value === APPT_READING_FINISHED) {
                status = APPT_READING_FINISHED
            } else if (readingStatus !== "") {
                status = APPT_READING
            }
        }

        items.push(
            <Table.Cell key={patient.patientId + "_" + appointmentNames[i]}>
                <Link to={`/patient/${patient.id}`}
                      onClick={() => setSessionStorageIndex(appointmentNamesIndex[i])}>

                    {getAppointment(patient.appointments, appointmentNames[i]).length === 0 &&
                        <div style={{color: color}}>
                            {t("APPT_STATUS_NOT_SCHEDULED_YET")}
                        </div>
                    }
                    {getAppointment(patient.appointments, appointmentNames[i]).length !== 0 &&
                        <div style={{color: color}}>
                            {color === "red" ? t("APPT_STATUS_READING") : t("APPT_STATUS_" + status)}
                            <br />
                            {formatDateTime(getAppointment(patient.appointments, appointmentNames[i]).startDate)}
                        </div>
                    }
                </Link>
            </Table.Cell>
        );
    }
    return items;
}

function getTableCellToDo(t, it, toDoPatient) {
    if (toDoPatient.includes(it?.patientId)) {
        return <i className={`red times circle icon`} />
    }
    return null
}

function getLastExamDate(appointments) {
    if (appointments.sort((a,b) => a.startDate > b.startDate ? -1 : 1).length > 0) {
        return formatDateTime(appointments.sort((a,b) => a.startDate > b.startDate ? -1 : 1)[0].startDate);
    }
    return "";
}

function getToDoPatient(t, data, role) {
    let toDoPatient = []
    // eslint-disable-next-line array-callback-return
    data.map(it => {
        const isControlCohort = it && it.studyData.cohort === "CONTROL"
        const modelFirst = createModelFollowUpFirst(it, t, CAT_FIRST);
        const modelFollowUp1 = createModelFollowUp(it, t, CAT_FOLLOWUP_T2);
        const modelFollowUp2 = createModelFollowUp(it, t, CAT_FOLLOWUP_T3);
        const modelFollowUp3 = createModelFollowUpT4(it, t, CAT_FOLLOWUP_T4);
        const modelControl = createModelControl(it, t);
        const modelInclusion = createModelInclusionFinal(it, t, CAT_INCLUSION);
        const modelFinal = createModelFinal(it, t, CAT_FINAL);
        const modelTensio = createModelTensio(it);
        let allModels = [modelInclusion, isControlCohort ? modelControl : modelTensio, modelFollowUp1, modelFollowUp2,
            modelFollowUp3, modelFinal]

        it.isControlCohort = isControlCohort
        it.modelFirst = modelFirst
        it.modelFollowUp1 = modelFollowUp1
        it.modelFollowUp2 = modelFollowUp2
        it.modelFollowUp3 = modelFollowUp3
        it.modelControl = modelControl
        it.modelInclusion = modelInclusion
        it.modelFinal = modelFinal
        it.modelTensio = modelTensio

        for(let i = 0; i < appointmentNames.length; i++) {
            if ((appointmentNames[i] === CAT_CONTROL && it.studyData.cohort === CAT_INTERVENTION) ||
                (appointmentNames[i] === CAT_TENSIO && it.studyData.cohort === CAT_CONTROL)) {
                continue;
            }
            let status = getAppointment(it.appointments, appointmentNames[i]).status

            const categorySamples = it?.appointments.find(x => _.get(x, "category") === appointmentNames[i])?.categorySamples ?? null;
            const readingStatus = categorySamples ? categorySamples.find(x => _.get(x, "categoryType") === "readingStatus") : null
            let color = ""

            if (!readingStatus && role === "data_entry_manager" && status === APPT_STATUS_TOOK_PLACE) {
                if(isAppointmentInComplete(whichModel(appointmentNames[i], modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3,
                    modelControl, modelInclusion, modelFinal, modelTensio), allModels, modelInclusion, modelFollowUp1, modelFollowUp2)) {

                    color = ""
                } else color = "red"
            } else {
                const readersAOrB = ((role === "reader_a" || role === "reader_b") && categorySamples && readingStatus && readingStatus.value === APPT_READING) ?
                    getReaderCategorySamples(categorySamples, role) : []
                const dataEntryManager = categorySamples?.filter(x => {
                    const value = _.get(x, "categoryType")
                    return value.includes("data_entry_manager")
                })
                color = getColor("", readingStatus, role, status, readersAOrB, dataEntryManager, appointmentNames?.[i] === CAT_INCLUSION,
                    whichModel(appointmentNames[i], modelFirst, modelFollowUp1, modelFollowUp2, modelFollowUp3, modelControl, modelInclusion, modelFinal, modelTensio))
            }

            if (color === "red") {
                toDoPatient.push(it)
                break
            }
        }
    })
    return toDoPatient
}

const RenderReaderTable = ({tableData}) => {
    const {user} = useContext(AuthContext);
    const {t} = useTranslation();
    const role = user.roles[0]
    const [readerTableData, setReaderTableData] = useState(tableData || []);
    const toDoPatient = getToDoPatient(t, readerTableData, role)

    const [state, dispatch] = useReducer(reducer, {
        column: toDoPatient.length ? "todo" : "studyId",
        data: toDoPatient.length ?
            readerTableData.sort((a, b) => toDoPatient.includes(a) && !toDoPatient.includes(b) ? -1 : 1) :
            readerTableData.sort((a, b) => a.patientId > b.patientId ? 1 : -1),//a.patientId > b.patientId ? 1 : -1),
        direction: toDoPatient.length ? "descending" : "ascending",
        toDoPatient
    });

    const {column, data, direction} = state;

    /* eslint-disable */
    useEffect(() => {
        state.data = tableData;
        setReaderTableData(tableData);
    }, [tableData])

    return (
        <div>
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={column === "studyId" ? direction : null}
                            onClick={() => dispatch({type: "CHANGE_SORT", column: "studyId"})}
                        >
                            {"StudienID"}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "todo" ? direction : null}
                            onClick={() => dispatch({type: "CHANGE_SORT", column: "todo"})}
                        >{"ToDo"}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_FIRST")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_INCLUSION")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("reader_view_ttp_tensio_profile")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_FOLLOWUP1")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_FOLLOWUP2")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_FOLLOWUP3")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("tabs_apponit_FINAL")}</Table.HeaderCell>
                        <Table.HeaderCell>{"Datum letzte Untersuchung"}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {data.map((it, index) => {
                    const isPatientExcluded = it?.status === PAT_STATUS_DATA_EXCLUDED || false

                    return (
                        <Table.Body style={{color: isPatientExcluded ? "grey" : ""}} key={`${it.patientId}_${index}`}>
                            <Table.Row key={`${it.patientId}_${index}`}>
                                <Table.Cell key={it.patientId + "_studyId"}>{it.patientId + " " + it?.studyData?.cohort}</Table.Cell>
                                <Table.Cell textAlign={"center"} key={it.patientId + "_toDo"}>
                                    {getTableCellToDo(t, it, toDoPatient.map((elem) => elem?.patientId))}
                                </Table.Cell>
                                {getTableCells(t, it, role,  it?.modelFirst, it?.modelFollowUp1, it?.modelFollowUp2,
                                    it?.modelFollowUp3, it?.modelControl, it?.modelInclusion, it?.modelFinal,
                                    it?.modelTensio, it?.isControlCohort, isPatientExcluded)}
                                <Table.Cell key={it.patientId + "_last_exam_date"}>{getLastExamDate(it.appointments)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    );
                })}
            </Table>
        </div>
    );
};

export default RenderReaderTable;