import gql from "graphql-tag";
import {masterDataFragment} from "./masterDataFragment";
import {medicalHistoryFragment} from "./medicalHistoryFragment";
import {appointmentFragment} from "./appointmentFragment";
import {medicationFragment} from "./medicationFragment";
import {interventionFragment} from "./interventionFragment";
import {studyDataFragment} from "./studyDataFragment";

export const patientFragment = gql`
    fragment patientFragment on Patient {
        id
        patientId
        status
        masterData {
            ...masterDataFragment
        }
        medicalHistory {
            ...medicalHistoryFragment
        }
        appointments {
            ...appointmentFragment
        }
        medications {
            ...medicationFragment
        }
        interventions {
            ...interventionFragment
        }
        studyData {
            ...studyDataFragment
        }
    }
    ${masterDataFragment}
    ${medicalHistoryFragment}
    ${appointmentFragment}
    ${medicationFragment}
    ${interventionFragment}
    ${studyDataFragment}
`;
