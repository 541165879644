import React, {useContext, useState} from "react";
import {Route, useHistory, useParams} from "react-router-dom";
import {usePatient} from "hooks";
import PatientMasterData from "./PatientMasterData";
import PageHeaderSimple from "./PageHeaderSimple";
import PatientMasterDataEdit from "./PatientMasterDataEdit";
import Tabs from "./patientEdit/Tabs";
import {Header} from "semantic-ui-react";
import {isDemo} from "../services";
import {AuthContext} from "../services";

const EditPatientContent = ({stats, handleRefresh, userRoles}) => {
    const history = useHistory();
    let {id} = useParams();

    const {data, reload} = usePatient(id);
    const patient = data && data.patientByID;
    const isAccountingUser = userRoles.includes("accounting");
    const [activeTab, setActiveTab] = useState(window.sessionStorage.getItem("appointmentTabActiveIndexForPatient") || 0)

    const {user} = useContext(AuthContext);
    const isQualityControlUser = user.roles.includes("reader_a") || user.roles.includes("reader_b") || user.roles.includes("reader_c") || user.roles.includes("data_entry_manager");

    const handleOnMasterDataEdit = () => {
        history.push(`/patient/${id}/edit`);
    };

    const goBack = () => {
        window.sessionStorage.setItem("appointmentTabActiveIndexForPatient", "0");
        window.sessionStorage.setItem("appointmentActiveIndexInTab", "0");
        history.replace("/");
    };

    const closeMasterDataEdit = (refreshData) => {
        if (refreshData) {
            reload(id);
            handleRefresh();
        }
        history.replace(`/patient/${id}`);
    };

    const changedTab = (tab) => {
        setActiveTab(tab)
    }

    return (
        <>
            <div className={"flex-column-full-height"}>
                <PageHeaderSimple onClick={goBack} />
                {patient && (
                    <div className={`Grid  c-container scroll-y ${isAccountingUser && "-center"}`}>
                        <div>
                            <PatientMasterData patient={patient} stats={stats} onEdit={handleOnMasterDataEdit} />
                            {/*{(isQualityControlUser && !isAccountingUser) && <>*/}
                            {/*    <br /><br /><br />*/}
                            {/*    <div className={"reader-log"}>*/}
                            {/*        <ReaderLog/>*/}
                            {/*    </div>*/}
                            {/*</>}*/}
                        </div>
                        <div className={`Cell ${!isAccountingUser && "-fill"}`}>
                            <Tabs
                                patient={patient}
                                changedTab={(tab) => changedTab(tab)}
                                refresh={() => {
                                    reload(id);
                                    handleRefresh();
                                    changedTab(activeTab - 1)
                                }}
                            />
                        </div>
                    </div>
                )}
                {isDemo() && <Header as={"h3"}>DEMO VERSION</Header>}
            </div>
            {(!isQualityControlUser && !isAccountingUser) &&
                <Route path="/patient/:id/edit">
                    {patient && (<PatientMasterDataEdit
                        patient={patient}
                        onClose={(refresh) => {
                            closeMasterDataEdit(refresh);
                        }}
                    />)}
                </Route>
            }
        </>
    );
};

const EditPatientContentForPatientView = ({data, handleRefresh}) => {
    const history = useHistory();
    const patient = data && data.patientSelf;

    const handleOnMasterDataEdit = () => {
        history.push(`/edit`);
    };

    const closeMasterDataEdit = (refreshData) => {
        if (refreshData) {
            handleRefresh();
        }
        history.replace(`/`);
    };

    return (
        <>
            <div className={"flex-column-full-height scroll-y"}>
                {patient && (
                    <div className={`Grid  c-container scroll-y`}>
                        <PatientMasterData patient={patient} onEdit={handleOnMasterDataEdit}/>
                        <div className={`Cell -fill`}>
                            <Tabs patient={patient} refresh={() => {handleRefresh();}}/>
                        </div>
                    </div>
                )}
                {isDemo() && <Header as={"h3"}>DEMO VERSION</Header>}
            </div>
            <Route path="/edit">
                {patient && (<PatientMasterDataEdit
                    patient={patient}
                    onClose={(refresh) => {
                        closeMasterDataEdit(refresh);
                    }}
                />)}
            </Route>
        </>
    );
};

const EditPatient = ({stats, handleRefresh, userRoles, data}) => {
    const { user } = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    return (
        <>
            {isPatient
             ?
                <EditPatientContentForPatientView
                    data={data}
                    handleRefresh={handleRefresh}
                />
             :
                <EditPatientContent
                    stats={stats}
                    handleRefresh={handleRefresh}
                    userRoles={userRoles}
                />
            }
        </>
    );
};

export default EditPatient;
