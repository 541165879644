import React, {useContext, useState} from "react";
import {Form, Label, Radio, Select} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import * as _ from "lodash";
import {cloneDeep, get, isArray, isEmpty, keys, set} from "lodash";
import {format, isExists, isPast, isValid, parseISO} from "date-fns";
import {useHospitals, useMutateMasterData} from "../../hooks";
import {insurance_companies, regExs} from "../../data/models/utils";
import {AuthContext, removePrivateFields, toastError} from "../../services";
import {testModeEnabled} from "../../services/TestMode";
import SearchInput from "../inputs/SearchInput";
import {DOCTOR_SEARCH} from "../../data/queries/queries";
import InsuranceCompanyInput from "../inputs/InsuranceCompanyInput";


const NewPatientFormContent = ({patientData, registerSaveCallback, onSaveFinished, isEditMode}) => {
    const {mutateMasterData, loading} = useMutateMasterData({isEditMode});
    const hospitals = useHospitals();
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const isStudyLead = user.roles.includes("study_lead");
    const isClinician = user.roles.includes("clinician");

    const getTemplate = () => ({
        status: "ACTIVE",
        masterData: {
            surname: "",
            forename: "",
            gender: "",
            birthdate: "",
            contact: {
                telephoneno: [],
                email: [],
                address: {
                    street: "",
                    streetno: "",
                    postalcode: "",
                    city: "",
                    country: "Deutschland"
                }
            },
            insurance: {
                number: "",
                company: ""
            },
            hospital: ""
        },
        studyData: {
            doctorInCharge: {
                id: "",
                lanr: user.lanr.endsWith("-mfa") ? " " : user.lanr
            }
        }
    });
    const getTemplateWithDemoData = () => ({
        status: "ACTIVE",
        masterData: {
            surname: "Mustermann",
            forename: "Max",
            gender: "MALE",
            birthdate: "1973-07-07T23:00:00.000Z",
            contact: {
                telephoneno: ["089 420 012 18"],
                email: ["max.mustermann@gmail.com"],
                address: {
                    street: "Schloss Birlinghoven",
                    streetno: "1",
                    postalcode: "53757",
                    city: "Sankt Augustin",
                    country: "Deutschland"
                }
            },
            insurance: {
                number: "103411401",
                company: "AOK NordWest (103 411 401)"
            },
            hospital: "17592186045465"
        },
        studyData: {
            doctorInCharge: {
                id:"",
                lanr: "123"
            }
        }
    });
    const getDateModel = (dateIsoString) => {
        if (!dateIsoString) {
            return {day: "", month: "", year: ""};
        }

        const formatted = format(parseISO(dateIsoString), "yyyy-MM-dd");
        const dateModel = formatted.split("-");
        return {
            day: dateModel[2],
            month: dateModel[1],
            year: dateModel[0]
        };
    };

    const getIsUserDefinedInsurance = (patient) => {
        for (const key in insurance_companies) {
            if (insurance_companies[key] === patient.masterData.insurance.company) {
                return false;
            }
        }
        return !(patient.masterData.insurance.company === "");
    };

    const [patient, setPatient] = useState(cloneDeep(removePrivateFields(patientData)) || getTemplate());
    const [birthDay, setBirthDay] = useState(getDateModel(patient.masterData.birthdate));
    const isUserDefinedInsurance = getIsUserDefinedInsurance(patient);
    const [isCustomizedInsurance, setIsCustomizedInsurance] = useState(isUserDefinedInsurance);
    const {masterData} = patient;

    const objectToIsoDate = (obj) => {
        try {
            const regEx = /^\d{4}-\d{2}-\d{2}$/;
            const match = `${obj.year}-${obj.month}-${obj.day}`.match(regEx);
            return match ? new Date(obj.year, obj.month - 1, obj.day).toISOString() : "";
        } catch (e) {
            console.error(e);
            return "";
        }
    };

    const isDateValid = (date) => {
        const d = parseISO(objectToIsoDate(date));
        return isValid(d) && isExists(parseInt(date.year), parseInt(date.month) - 1, parseInt(date.day)) && isPast(d);
    };

    const [errors, setErrors] = useState({
        forename: !patient.masterData.forename,
        surname: !patient.masterData.surname,
        gender: !patient.masterData.gender,
        "contact.address.street": !patient.masterData.contact.address.street,
        "contact.address.streetno": !patient.masterData.contact.address.streetno,
        "contact.address.postalcode": !patient.masterData.contact.address.postalcode,
        "contact.address.city": !patient.masterData.contact.address.city,
        "contact.telephoneno": isEmpty(patient.masterData.contact.telephoneno),
        "insurance.number": !patient.masterData.insurance.number,
        "insurance.company": !patient.masterData.insurance.company,
        day: !isDateValid(birthDay),
        month: !isDateValid(birthDay),
        year: !isDateValid(birthDay),
        lanr: !patient.studyData.doctorInCharge.lanr,
        "hospital.id": !patient.masterData.hospital
    });

    const options = [
        {key: "gender_male", text: t("gender_male"), value: "MALE"},
        {key: "gender_female", text: t("gender_female"), value: "FEMALE"},
        {key: "gender_divers", text: t("gender_divers"), value: "OTHER"}
    ];

    const insuranceCompanyOptions = [
        {key: "aok_ins", text: insurance_companies.AOK, value: insurance_companies.AOK},
        {key: "barmer_ins", text: insurance_companies.BARMER, value: insurance_companies.BARMER},
        {key: "dak_ins", text: insurance_companies.DAK, value: insurance_companies.DAK},
        {key: "ikk_ins", text: insurance_companies.IKK, value: insurance_companies.IKK},
        {key: "kkh_ins", text: insurance_companies.KKH, value: insurance_companies.KKH},
        {key: "knappschaft_ins", text: insurance_companies.KNAPPSCHAFT, value: insurance_companies.KNAPPSCHAFT},
        {key: "tk_ins", text: insurance_companies.TK, value: insurance_companies.TK},
        {key: "viactiv_ins", text: insurance_companies.VIACTIV, value: insurance_companies.VIACTIV}
    ];

    const hospitalsData = (hospitals.data && hospitals.data.hospitals) || [];
    const hospitalInChargeOptions = isClinician ?
        hospitalsData.filter(hospital => hospital.name !== "Klinikum Bielefeld" && hospital.hospitalId === user.organisation.id).map(
            hospital => { return {key: hospital.id, text: hospital.name, value: hospital.id};}
        )
        :
        hospitalsData.filter(hospital => hospital.name !== "Klinikum Bielefeld").map(
            hospital => { return {key: hospital.id, text: hospital.name, value: hospital.id};}
        );

    const validateModel = () => {
        const hasErrors = keys(errors).find((it) => errors[it]);
        if (hasErrors) {
            toastError(t("error_mandatory_fields"));
            setErrors({...errors});
        }
        return !hasErrors;
    };

    const setPhoneAndEmailValue = (value) =>{
        return value.join(",").split(",").map(x => x.trim()).filter(x => x !== "");
    }

    const saveData = () => {
        if (!loading && validateModel()) {
            patient.masterData.birthdate = objectToIsoDate(birthDay);
            patient.masterData.contact.telephoneno = setPhoneAndEmailValue(patient.masterData.contact.telephoneno);
            patient.masterData.contact.email = setPhoneAndEmailValue(patient.masterData.contact.email);

            const variables = isEditMode ? {
                masterData: patient.masterData,
                patientId: patient.id
            } : {input: patient.masterData};

            if (!isEditMode) {
                window.localStorage.setItem("new_patient", JSON.stringify(patient));
                onSaveFinished(null);
            } else if (isStudyLead) {
                window.localStorage.setItem("patient_to_be_edited", JSON.stringify(patient));
            } else {
                mutateMasterData(variables).then((patientId) => {
                    onSaveFinished(patientId);
                });
            }
            return true;
        } else {
            return false;
        }
    };

    const onListChangeWithValidation = (e, {name, value}, validate) => {
        onDataChange(e, {name, value: value.split(", ")}, validate);
    };

    const onDataChange = (e, {name, value, skipValidation}, validate) => {
        if (!skipValidation) {
            const isValid = validate ? !!validate(value) : true;
            errors[name] = name === "contact.email" ? !isValid : (isEmpty(isArray(value) ? value.join("") : value) || !isValid);
            setErrors({...errors});
        }
        set(masterData, name, value);
        setPatient({...patient});
    };

    const onDateChange = (e, {name, value}) => {
        set(birthDay, name, value);
        setBirthDay({...birthDay});
        const isValid = isDateValid(birthDay);
        errors["day"] = !isValid;
        errors["month"] = !isValid;
        errors["year"] = !isValid;
        setErrors({...errors});
    };

    const getPropsFrom = (object, name, label, onChange, skipValue) => {
        const error = errors[name];
        if (skipValue)
            return {name, label: t(label), onChange, error};
        const value = get(object, name);
        return {name, label: t(label), value, onChange, error};
    };

    const getProps = (name, label, validation) => {
        return getPropsFrom(masterData, name, label, (e, {name, value, skipValidation}) => {
            onDataChange(e, {name, value, skipValidation}, validation);
        });
    };

    const getTelProps = (name, label, skipValue) => {
        return getPropsFrom(masterData, name, label, onListChangeWithValidation, skipValue);
    };

    const getDateProps = (name, label) => {
        return getPropsFrom(birthDay, name, label, onDateChange);
    };

    const handleHospitalChange = (e, data) => {
        set(masterData, "hospital", data.value);
        setPatient({...patient});
        errors["hospital.id"] = false;
        set(errors, {...errors});
    };

    const handleFillDemoData = () => {
        patient.masterData = getTemplateWithDemoData().masterData;
        setPatient({...patient});
        setBirthDay(getDateModel(patient.masterData.birthdate));
        setErrors({
            forename: false,
            surname: false,
            gender: false,
            "contact.address.street": false,
            "contact.address.streetno": false,
            "contact.address.postalcode": false,
            "contact.address.city": false,
            "contact.telephoneno": false,
            "insurance.number": false,
            "insurance.company": false,
            day: false,
            month: false,
            year: false
        });
    };

    const handleLanrSelection = (v) => {
        const lanrValue = _.get(v, "lanr", "");
        patient.studyData.doctorInCharge.lanr = lanrValue;
        patient.studyData.doctorInCharge.id = _.get(v, "id", "");
        setPatient({...patient});
        errors["lanr"] = (lanrValue === "");
    };

    const customizedInsuranceHandler = (v) => {
        patient.masterData.insurance.company = v;
        setPatient({...patient});
        errors["insurance.company"] = (v === "");
    };

    registerSaveCallback(saveData);

    return (
        <Form size={"big"} as={"div"}>
            <Form.Group>
                <Form.Input disabled={isStudyLead} {...getProps("forename", "first_name", (v) => v.match(new RegExp("^(?!\\s*$).+")))}/>
                <Form.Input disabled={isStudyLead} {...getProps("surname", "last_name", (v) => v.match(new RegExp("^(?!\\s*$).+")))}/>
                <Form.Select disabled={isStudyLead} {...getProps("gender", "gender")} options={options}/>
                {!isEditMode && testModeEnabled && (
                    <Form.Button onClick={handleFillDemoData} width={3}>
                        {t("fillDemoPatientData")}
                    </Form.Button>
                )}
            </Form.Group>

            <Form.Group>
                <Form.Input disabled={isStudyLead} {...getDateProps("day", "date_of_birth")} width={2} placeholder={t("date_dd")}/>
                <Form.Input disabled={isStudyLead} {...getDateProps("month", "-")} width={2} placeholder={t("date_mm")}/>
                <Form.Input disabled={isStudyLead} {...getDateProps("year", "-")} width={4} placeholder={t("date_yy")}/>
            </Form.Group>

            <br />

            <Form.Group>
                <div className={"insurance-class-1"}>
                    <Form.Input {...getProps("insurance.number", "insurance_number", (v) => v.match(regExs.REGEX_INSURANCENO))}
                                disabled={isStudyLead ? false : isEditMode}/>
                </div>
                <div className={"insurance-class"}>
                    <div className={"pb-1of2"}>
                        <Radio
                            toggle
                            defaultChecked={isCustomizedInsurance}
                            label={t("user_defined")}
                            disabled={isStudyLead ? false : isEditMode}
                            onChange={() => {
                                setIsCustomizedInsurance(prevState => !prevState );
                                customizedInsuranceHandler("");
                            }}
                        />
                    </div>

                    <Form.Input
                        control={Select}
                        options={insuranceCompanyOptions}
                        search
                        disabled={isStudyLead ? isCustomizedInsurance : (isEditMode || isCustomizedInsurance)}
                        {...getProps("insurance.company", "insurance_company")} />
                </div>

            </Form.Group>

            {isCustomizedInsurance &&
            <Form.Group>
                <InsuranceCompanyInput
                    handleCustomizedInsuranceInput={customizedInsuranceHandler}
                    value={isUserDefinedInsurance ? patient.masterData.insurance.company : ""}
                    disabled={isStudyLead ? false : isEditMode}
                />
            </Form.Group>
            }

            <Form.Group>
                <Form.Input disabled={false} {...getProps("contact.address.street", "street", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={4}/>
                <Form.Input disabled={false} {...getProps("contact.address.streetno", "number", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={2}/>
                <Form.Input disabled={false} {...getProps("contact.address.postalcode", "zip", (v) => v.match(regExs.REGEX_ZIP))}
                            width={2}/>
                <Form.Input disabled={false} {...getProps("contact.address.city", "city", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={4}/>
            </Form.Group>

            <Form.Field>
                <Label>{t("more_numbers_and_emails")}</Label>
            </Form.Field>
            <Form.Group>
                <Form.Input
                    value={masterData.contact.telephoneno.join(", ")}
                    disabled={false}
                    {...getTelProps("contact.telephoneno", "telephone", true)}
                    width={6}/>
                <Form.Input
                    width={6}
                    label={t("email")}
                    placeholder={t("optional")}
                    name={"contact.email"}
                    value={masterData.contact.email.join(", ")}
                    disabled={false}
                    onChange={
                        (e, payload) => onListChangeWithValidation(
                            e, {...payload, skipValidation: true},
                            (values) => {
                                const filteredValues = values.filter(v => v.trim().length);
                                return filteredValues.length === 0 ? true : filteredValues.map(v => v.match(regExs.REGEX_EMAIL)).reduce((a, c) => a && c);
                            }
                        )
                    }
                />
            </Form.Group>

            {!isEditMode && <Form.Group>
                <Form.Input
                    control={Select}
                    options={hospitalInChargeOptions}
                    defaultValue={masterData.hospital}
                    value={masterData.hospital}
                    search
                    {...getProps("hospital.id", "hospital_in_charge")}
                    onChange={handleHospitalChange}
                    disabled={false}
                    width={6}/>


                <Form.Field>
                    <label>&nbsp;</label>
                    <Label pointing={"left"}>Auswahl bindend!</Label>
                </Form.Field>

            </Form.Group>}

            {!isEditMode && <Form.Group>
                <div>
                    <SearchInput
                        queryString={DOCTOR_SEARCH}
                        label={"LANR (7-stellig)"}
                        initialValue={patient.studyData.doctorInCharge.lanr}
                        fieldName={"doctorSearch"}
                        title={["forename", "surname"]}
                        description={"lanr"}
                        handleSelection={handleLanrSelection}
                        disabled={false}
                    />
                </div>
            </Form.Group>}
        </Form>
    );
};


const NewPatientFormContentForPatientView = ({patientData, registerSaveCallback, onSaveFinished, isEditMode}) => {
    const {mutateMasterData, loading} = useMutateMasterData({isEditMode});
    const {t} = useTranslation();

    const [patient, setPatient] = useState(cloneDeep(removePrivateFields(patientData)));
    const {masterData} = patient;

    const [errors, setErrors] = useState({
        "contact.address.street": !patient.masterData.contact.address.street,
        "contact.address.streetno": !patient.masterData.contact.address.streetno,
        "contact.address.postalcode": !patient.masterData.contact.address.postalcode,
        "contact.address.city": !patient.masterData.contact.address.city,
        "contact.telephoneno": isEmpty(patient.masterData.contact.telephoneno),
    });

    const validateModel = () => {
        const hasErrors = keys(errors).find((it) => errors[it]);
        if (hasErrors) {
            toastError(t("error_mandatory_fields"));
            setErrors({...errors});
        }
        return !hasErrors;
    };

    const setPhoneAndEmailValue = (value) =>{
        return value.join(",").split(",").map(x => x.trim()).filter(x => x !== "");
    }

    const saveData = () => {
        if (!loading && validateModel()) {
            patient.masterData.contact.telephoneno = setPhoneAndEmailValue(patient.masterData.contact.telephoneno);
            patient.masterData.contact.email = setPhoneAndEmailValue(patient.masterData.contact.email);

            const variables = {
                masterData: patient.masterData,
                patientId: patient.id
            };

            mutateMasterData(variables).then((patientId) => {
                onSaveFinished(patientId);
            });

            return true;
        } else {
            return false;
        }
    };

    const onListChangeWithValidation = (e, {name, value}, validate) => {
        onDataChange(e, {name, value: value.split(", ")}, validate);
    };

    const onDataChange = (e, {name, value, skipValidation}, validate) => {
        if (!skipValidation) {
            const isValid = validate ? !!validate(value) : true;
            errors[name] = name === "contact.email" ? !isValid : (isEmpty(isArray(value) ? value.join("") : value) || !isValid);
            setErrors({...errors});
        }
        set(masterData, name, value);
        setPatient({...patient});
    };

    const getPropsFrom = (object, name, label, onChange, skipValue) => {
        const error = errors[name];
        if (skipValue)
            return {name, label: t(label), onChange, error};
        const value = get(object, name);
        return {name, label: t(label), value, onChange, error};
    };

    const getProps = (name, label, validation) => {
        return getPropsFrom(masterData, name, label, (e, {name, value, skipValidation}) => {
            onDataChange(e, {name, value, skipValidation}, validation);
        });
    };

    const getTelProps = (name, label, skipValue) => {
        return getPropsFrom(masterData, name, label, onListChangeWithValidation, skipValue);
    };

    registerSaveCallback(saveData);

    return (
        <Form size={"big"} as={"div"}>
            <Form.Group>
                <Form.Input disabled={false} {...getProps("contact.address.street", "street", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={4}/>
                <Form.Input disabled={false} {...getProps("contact.address.streetno", "number", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={2}/>
                <Form.Input disabled={false} {...getProps("contact.address.postalcode", "zip", (v) => v.match(regExs.REGEX_ZIP))}
                            width={2}/>
                <Form.Input disabled={false} {...getProps("contact.address.city", "city", (v) => v.match(new RegExp("^(?!\\s*$).+")))}
                            width={4}/>
            </Form.Group>

            <Form.Field>
                <Label>{t("more_numbers_and_emails")}</Label>
            </Form.Field>
            <Form.Group>
                <Form.Input
                    value={masterData.contact.telephoneno.join(", ")}
                    disabled={false}
                    {...getTelProps("contact.telephoneno", "telephone", true)}
                    width={6}/>
                <Form.Input
                    width={6}
                    label={t("email")}
                    placeholder={t("optional")}
                    name={"contact.email"}
                    value={masterData.contact.email.join(", ")}
                    disabled={false}
                    onChange={
                        (e, payload) => onListChangeWithValidation(
                            e, {...payload, skipValidation: true},
                            (values) => {
                                const filteredValues = values.filter(v => v.trim().length);
                                return filteredValues.length === 0 ? true : filteredValues.map(v => v.match(regExs.REGEX_EMAIL)).reduce((a, c) => a && c);
                            }
                        )
                    }
                />
            </Form.Group>
        </Form>
    );
};

const NewPatientForm = ({patientData, registerSaveCallback, onSaveFinished, isEditMode}) => {
    const { user } = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    return (
        <>
            {isPatient
            ?
                <NewPatientFormContentForPatientView
                    patientData={patientData}
                    registerSaveCallback={registerSaveCallback}
                    onSaveFinished={onSaveFinished}
                    isEditMode={isEditMode}
                />
            :
                <NewPatientFormContent
                    patientData={patientData}
                    registerSaveCallback={registerSaveCallback}
                    onSaveFinished={onSaveFinished}
                    isEditMode={isEditMode}
                />
            }
        </>
    );
};

export default NewPatientForm;
