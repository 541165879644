import React, {forwardRef, useContext, useEffect, useImperativeHandle} from "react";
import {Checkbox, Divider, Select} from "semantic-ui-react";
import {TAB_EXAMINATION, TAB_QUESTIONNAIRE} from "../patientEdit/Tabs";
import SelectInputForm from "../inputs/SelectInputForm";
import {cloneDeep, compact, get, remove, set} from "lodash";
import DateInput from "../inputs/DateInput";
import FormInput from "../inputs/FormInput";
import {useTranslation} from "react-i18next";
import {useMutateAppointment} from "../../hooks";
import {AuthContext, copyOverModified, removePrivateFields, toastError} from "../../services";
import {Controller} from "react-hook-form";

// We need this number to save to the DB instead of empty string
const MIN_FLOAT = -50000;

const CorrelationSamplesBlock = ({data, tab, patientId, isReadOnly, form}, ref) => {
    const [mutateData, , model, setModel, upsertLoading] = useMutateAppointment(() => {
        return {...cloneDeep(data)};
    });
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        form.trigger();
    }, [data]);
    /* eslint-disable */


    useImperativeHandle(ref, () => ({
        hasChanged () {
            return form.formState.isDirty || get(model, "recommendedTherapy.therapy", "")
                .localeCompare(get(data, "recommendedTherapy.therapy", "")) !== 0;
        },
        save (data) {
            return save(data);
        },
        hasError () {
            return hasError();
        }
    }));

    const hasError = () => {
        return !form.formState.isValid;
    };

    const showField = (it) => {
        if (isPatient) {
            if (it?.quantity === -50000 || it?.value === "") {
                return false;
            }
        }
        return true;
    }

    const save = (d) => {
        if (hasError()) {
            toastError(t("error_fields_incorrect"));
            return;
        }

        if (upsertLoading) {
            // Form is been saving
        }

        if (!form.formState.isDirty &&
            get(model, "recommendedTherapy.therapy", "")
                .localeCompare(get(data, "recommendedTherapy.therapy", "")) === 0
        ) {
            // The form was not changed
            return;
        }

        // need ids
        const temp = removePrivateFields(copyOverModified(model, d));

        if (get(model, "correlationSamples", []).length >
            get(d, "correlationSamples", []).length) {
            temp.correlationSamples.splice(d.correlationSamples.length, model.correlationSamples.length - d.correlationSamples.length);
        }

        const toSave = {
            id: model.id,
            startDate: model.startDate,
            status: model.status,
            category: model.category,
            recommendedTherapy: get(model, "recommendedTherapy"),
            correlationSamples: compact(get(temp, "correlationSamples"))
        };

        //remove empty objects (can not save to DB)
        //TODO removing is cosing shifting in copyOver function
        toSave.correlationSamples.forEach(correlation => {
            remove(correlation.quantitySamples, (qs) => {
                return Object.keys(qs).length === 0;
            });
            correlation.quantitySamples.forEach((qs) => {
                if(qs.quantity === null || qs.quantity === "") {
                    qs.quantity = MIN_FLOAT;
                } else {
                    qs.quantity = parseFloat(qs.quantity);
                }
            });
            remove(correlation.categorySamples, (cs) => {
                return cs.value === null;
            });
        });

        return mutateData({
            appointment: toSave,
            patientId
        }).then(() => {
            console.log("sent");
            //const c = copyOver(model, value.data.upsertAppointment);
            //setModel({...c});
        }, () => {
            toastError(t("error_gql"));
        });
    };

    const getDevicePath = (row) => `correlationSamples[${row}].metadata.deviceName`;

    const getDeviceValue = (row) => get(model, `${getDevicePath(row)}`);

    const getPath = (row, column, it) => `correlationSamples[${row}].${it.__field}[${column}]`;

    const getValue = (row, column, it) => {
        return get(model, `${getPath(row, column, it)}.${it.__valueField}`, "");
    };

    const getProps = (path, el, model) => {
        const formPath = `${path}.${el.__valueField}`;

        if (!form.formState.isSubmitting) {
            const defvalue = get(data, path + `.${el.__valueField}`, "");
            if (typeof (form.getValues(formPath)) === "undefined" && form.getValues(formPath) === null) {
                form.setValue(formPath, defvalue, {
                    shouldDirty: false,
                    shouldValidate: false
                });
            } else if (form.getValues(formPath) !== String(defvalue) && !form.formState.isDirty) {
                form.setValue("patientId", patientId, {
                    shouldDirty: true,
                    shouldValidate: false
                });
            }
        }

        const defvalue = get(model, path + `.${el.__valueField}`, "");

        return {
            name: formPath,
            defaultValue: parseFloat(defvalue) === MIN_FLOAT ? "" : defvalue,
            placeholder: el.__placeholder,
            ref: form.register({
                ...el.__v,
                maxLength: 6,
                minLength: 1,
                required: {
                    value: !el.__optional,
                    message: t("E_INVALID")
                }
            }),
            onChange: (e) => {
                let value = e.target.value;
                set(model, formPath, String(value));
                setModel({...model});
            }
        };
    };

    const getError = (path) => {
        const test = get(form.errors, path);
        if (test) {
            return get(test, "message", t("E_INVALID"));
        } else {
            return null;
        }
    };

    const needObligatoryMark = (row, column, it) => {
        const v = getValue(row, column, it) || "";
        return v === "" && !it.__optional;
    };

    const renderOptions = ({row, column, className, it}) => {
        return (
            <div key={column} className={className}>
                <div className={"Grid"}>
                    <div className={"note form-input-label" + (isPatient ? " patient-view-font" : "")}>
                        {it.__label}
                        {needObligatoryMark(row, column, it) &&
                        <label className={"label-error"}>{t("E_INVALID")}</label>
                        }
                    </div>
                </div>
                <Controller
                    control={form.control}
                    name={`${getPath(row, column, it)}.${it.__valueField}`}
                    rules={{
                        required: {
                            value: !it.__optional,
                            message: t("E_INVALID")
                        }
                    }}
                    defaultValue={getValue(row, column, it)}
                    render={({onChange}) => (
                        <Select
                            options={it.__options}
                            value={getValue(row, column, it)}
                            onChange={(_, {value}) => {
                                handleChange(row, column, value, it);
                                onChange(value);
                            }}
                            disabled={isReadOnly && !isPatient}
                            error={getError(getPath(row, column, it))}
                            placeholder={it.__placeholder}
                        />
                    )}
                />
            </div>
        );
    };

    const handleChange = (row, column, value, it) => {
        set(model, `${getPath(row, column, it)}.${it.__valueField}`, value);
        if (!form.formState.isSubmitting) {
            form.setValue(`${getPath(row, column, it)}.${it.__valueField}`, value, {
                shouldDirty: true,
                shouldValidate: false
            });
        }
        setModel({...model});
    };

    const renderCheckbox = ({row, column, it}) => {
        return <Checkbox
            label={it.__label}
            onChange={(e, {checked}) => {
                const value = checked ? 1 : 0;
                handleChange(row, column, value, it);
            }}
            checked={getValue(row, column, it) === 1}
            disabled={isReadOnly && !isPatient}
        />;
    };

    const renderItem = (it, row, column) => {
        const className = "pb-1" + (isPatient ? " patient-view-font" : "");

        if (it.__separator) {
            if(it.__separator.subgroup != null) {
                return (
                    <div>
                        <div><br/><b>{it.__separator.subgroup}</b></div>
                    </div>
                )
            }
            else{
                return (<div>
                        <div className="groupSeparator">{it.__separator.title}</div>
                        {!isPatient && <div><br/><b>{it.__separator.hint}</b></div>}
                    </div>
                );
            }
        }

        if (it.__type?.indexOf("date") > -1) {
            return (
                <DateInput
                    className={className}
                    key={column}
                    label={it.__label}
                    value={getValue(row, column, it)}
                    type={it.__type}
                    onChange={(value) => {
                        handleChange(row, column, value, it);
                    }}
                    additionalProps={{disabled: isReadOnly && !isPatient}}
                />
            );
        }

        if (it.__type === "select") {
            return renderOptions({row, column, className: className, it});
        }
        if (it.__type === "checkbox") {
            return renderCheckbox({row, column, className: className, it});
        }

        const path = getPath(row, column, it);
        const obligatory = get(form.errors, `${path}.quantity.type`, "") === "required" ? t("E_INVALID") : null;

        return (
            <FormInput
                className={className}
                label={it.__label}
                obligatory={obligatory}
                error={getError(path)}
                unit={it.unit}
                popupText={it.__description}
                properties={getProps(path, it, model)}
                disabled={isReadOnly && !isPatient}
                patientView={isPatient}
            />
        );
    };

    const handleDeviceChange = ({it, value}) => {
        it.metadata = {
            deviceName: value
        };
        setModel({...model});
        /*const path = getDevicePath(row);
        if(!form.formState.isSubmitting) {
            form.setValue(path, value, {
                shouldDirty: true,
                shouldValidate: true,
            });
        }*/
    };

    //Todo: rename? This is not only code for the questionnaire but for examinations
    const renderQuestionnaire = (it, row) => {
        var tensioCorrelationTypes = ["tensioMeasurement","targetPressure","24hBloodPressureImport", "tensioControlImport", "24hBloodPressureDipping"];
        let isTensio = tensioCorrelationTypes.includes(it.correlationType);
        return (
            <div key={`questionnaire[${row}]`}>
                {!isTensio && renderNotTensioBlock(it, row)}
                {it.correlationType === "targetPressure" && renderZielDruck(it, row)}
            </div>
        );
    };

    const renderZielDruck = (it, row) => {
        return (
            <div className={"pb-2"} key={row}>
                <div className={"c-header c-header--big"}>
                    {it.__label}
                    {it.__description && <div className={"note"}>{it.__description}</div>}
                </div>
                {(!isPatient && it.__hint) && <div><b>{it.__hint}</b><br/><br/></div>}
                <div className="c-grid">
                    {it.categorySamples.map((it, column) => (
                        <div key={`${row},${column}.categorySamples`}
                             className={"pb-1 c-column-full " + (it.__css || "")}>
                            {renderItem(it, row, column)}
                        </div>
                    ))}
                </div>
                <div className="c-grid">
                    {it.quantitySamples?.map((it, column) => (
                        <div key={`${row},${column}.quantitySamples`}
                             className={"pb-1 c-column-full " + (it.__css || "")}>
                            {renderItem(it, row, column)}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderNotTensioBlock = (it, row) => {
        return (
            <div className={"pb-2"} key={row}>
                <div className={"c-header c-header--big"}>
                    {it.__label}
                    {it.__description && <div className={"note"}>{it.__description}</div>}
                </div>
                {(!isPatient && it.__hint) &&
                <div><b>{it.__hint}</b><br/><br/></div>
                }
                {it.__deviceModel && (
                    <div className={"pb-1 width-1-2"}>
                        <div className={"Grid"}>
                            <div className={"note" + isPatient ? (" patient-view-font") : ""}>{t("device")}</div>
                            {
                                getDeviceValue(row) === "" && !it.__deviceModel.__optional &&
                                <label className={"label-error"}>{getError(getDevicePath(row))}</label>
                            }
                        </div>
                        <SelectInputForm
                            register={form.register}
                            unregister={form.unregister}
                            name={getDevicePath(row)}
                            model={it.__deviceModel}
                            defaultValue={getDeviceValue(row)}
                            onChange={(value) => handleDeviceChange({row, it, value})}
                            disabled={isReadOnly && !isPatient}
                            className={isPatient ? "patient-view-font" : ""}
                            error={getDeviceValue(row) === "" && !it.__deviceModel.__optional}
                            control={form.control}
                        />
                    </div>
                )}
                {it.correlationType !== "dezimalvisus" &&
                <div className="c-grid">
                    {it.categorySamples &&
                    it.categorySamples.map((it, column) => (
                        <>
                            {showField(it) &&
                                <div key={`${row},${column}.categorySamples`}
                                     className={"pb-1 c-column-full " + (it.__css || "")}>
                                    {renderItem(it, row, column)}
                                </div>
                            }
                        </>
                    ))}

                    {it.quantitySamples &&
                    it.quantitySamples.map((it, column) => (
                        <>
                            {showField(it) &&
                                <div key={`${row},${column}.quantitySamples`}
                                     className={"pb-1 c-column-full " + (it.__css || "")}>
                                    {renderItem(it, row, column)}
                                </div>
                            }
                        </>
                    ))}
                </div>
                }
                {it.correlationType === "dezimalvisus" && renderDecimalvisus(it, row)}
            </div>
        );
    };

    const renderDecimalvisus = (it, row) => {
        return (
            <div className="c-grid">
                {it.quantitySamples &&
                it.quantitySamples.map((it, column) => (
                    <>
                        {showField(it) &&
                            <div key={`${row},${column}.quantitySamples`}
                                 className={"pb-1 c-column-full " + (it.__css || "")}>
                                {renderItem(it, row, column)}
                            </div>
                        }
                    </>
                ))}
                {it.categorySamples &&
                it.categorySamples.map((el, column) => (
                    <>
                        {showField(el) &&
                            <div key={`${row},${column}.categorySamples`}
                                 className={"pb-1 c-column-full " + (el.__css || "")}>
                                {renderItem(el, row, column)}
                            </div>
                        }
                    </>
                ))}
            </div>
        );

    };

    const renderTherapyRecommendation = () => {
      return (
          <div>
              <div align="center">
                  <div className={"c-header c-header--big"}>{t("appointment_recommendation")}</div>
              </div>
              <div>
                  <label className={"pr-1"}>{t("appointment_recommendation")}</label><br/>
                  <label className={"label-small"}>{t("hint_nopatientrelateddata")}</label>
                  <textarea value={model.recommendedTherapy.therapy}
                            onChange={(e) => {
                                set(model, "recommendedTherapy.therapy", e.target.value);
                                setModel({...model});
                            }}
                            disabled={isReadOnly && !isPatient}/>
              </div>
          </div>
      ) ;
    };

    return (
        <div>
            <Divider/>
            {model.correlationSamples?.map((it, row) => {
                if (it.correlationType === "eq5Questionnaire" && tab === TAB_QUESTIONNAIRE) {
                    return renderQuestionnaire(it, row);
                } else if (!(it.correlationType === "eq5Questionnaire") && tab === TAB_EXAMINATION) {
                    return renderQuestionnaire(it, row);
                } else {
                    return null;
                }
            })}
            {tab === TAB_EXAMINATION && !isPatient && renderTherapyRecommendation()}
        </div>
    );
};

export default forwardRef(CorrelationSamplesBlock);
