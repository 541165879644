import gql from 'graphql-tag';
import {medicalHistoryFragment} from '../fragments/medicalHistoryFragment';

export const UPSERT_MED_HISTORY = gql`
    mutation UpsertMedicalHistory($medicalHistory: MedicalHistoryInput!, $patientId: ID!) {
        upsertMedicalHistory(medicalHistory: $medicalHistory, patientId: $patientId) {
            id
            medicalHistory {
                ...medicalHistoryFragment
            }
        }
    }
    ${medicalHistoryFragment}
`;
