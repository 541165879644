import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, Form, Select, Table, TextArea} from 'semantic-ui-react'
import {AuthContext, formatDateTime, toastError} from "../../services";
import ChartTensio from "../appointment/RenderTensioChart";
import {useTranslation} from "react-i18next";
import {useMutateAppointment} from "../../hooks";
import * as _ from "lodash";
import {
    getCurrentTab, getReaderCategorySamples, getReaderStatusStates,
    getStatusStatesRequiredFields, isFieldDisabled, renderReaderCComment
} from "./models/utils";
import {
    APPT_DISCREPANCY, APPT_INQUIRY, APPT_READING, APPT_READING_FINISHED, APPT_STATUS_TOOK_PLACE,
    CAT_CONTROL
} from "../../data/mutations/appointments";
import {createReaderControlTensioAppointment} from "./models/readerControlTensioAppointment"
import {get} from "lodash";

const MIN_DATE_TIME = "1900-01-01T00:00:00.00Z";

const RenderReaderZoneTensioControl = ({readerCorrelationSample, dataModel, patientId, refresh, patient, allAppointmentsCorrelationSamples, readerCComment}) => {
    const { t } = useTranslation();
    const [mutateData] = useMutateAppointment();
    const { user } = useContext(AuthContext)
    const userId = user.id
    const cohort = patient.studyData.cohort;
    const role = user.roles[0]
    const isReaderC = user.roles.includes("reader_c")
    const isDataEntryManager = user.roles.includes("data_entry_manager")

    const categorySamples = patient?.appointments.find(x => _.get(x, "category") === getCurrentTab(true))?.categorySamples ?? null
    const readingStatus = categorySamples?.find(x => _.get(x, "categoryType") === "readingStatus")

    const bloodPressureSample = readerCorrelationSample.correlationSamples.filter(x => x.correlationType === "24hrrControlGroup")[0] || [];
    const tensioImportSample = readerCorrelationSample.correlationSamples.filter(x => x.correlationType === "tensioControlImport") || []
    const [ readerAOrBReqField, setReaderAOrBReqField ] = useState(false)

    const overallSuffix = "Overall";
    const awakeSuffix = "Awake";
    const asleepSuffix = "Asleep";

    function getLocalId (model)  {
        let idCounter = 1
        for (let i = 0; i < model.length; ++i) {
            for (let j = 0; j < model[i]?.statusField?.length; ++j) {
                if(model[i]?.statusField[j]?.dataStorageField?.id === null) {
                    model[i].statusField[j].dataStorageField.id = `local_${idCounter++}`
                }

                if (model[i].sectionField.tableStatusField) {
                    for (let j = 0; j < model[i].sectionField.tableStatusField.length; ++j) {
                        if (model[i].sectionField.tableStatusField[j].dataStorageField.id === null) {
                            model[i].sectionField.tableStatusField[j].dataStorageField.id = `local_${idCounter++}`
                        }
                    }
                }
            }
        }

        return model
    }

    const model = () => {
        if (getCurrentTab(true) === CAT_CONTROL)
            return getLocalId(createReaderControlTensioAppointment(allAppointmentsCorrelationSamples, userId, role, patientId, categorySamples, cohort))
    }

    const getReaderCCategorySamples = (catSamples, reader) => {
        return catSamples.filter(x => {
            const value = _.get(x, "categoryType")
            return value.includes(reader)
        })
    }

    const getReaderValue = (readerX, label) => {
        const _x = readerX.filter((x) => x?.categoryType.split("_")[0] === label)
        if (_x && _x.length) {
            return readerX.filter((x) => x?.categoryType.split("_")[0] === label)[0].value
        }
        return ""
    }

    const [statusStates, setStatusStates] = useState(getReaderStatusStates(model(), isReaderC))
    const readerA = getReaderCCategorySamples(categorySamples, "reader_a")
    const readerB = getReaderCCategorySamples(categorySamples, "reader_b")

    /* eslint-disable */
    useEffect(() => {
        setStatusStates(getReaderStatusStates(model(), isReaderC))
    }, [patient])
    useEffect(() => {
        if (user.roles.includes("reader_a") || user.roles.includes("reader_b")) {
            setReaderAOrBReqField(checkAllRequiredFields())
        }
    }, [])
    // eslint-enable

    const getValue = (id) => {
        return statusStates.find(x => _.get(x, "id") === id)?.value
    }

    const setValue = (id, value) => {
        const sample = statusStates.find(x => _.get(x, "id") === id)
        if (sample) {
            sample.value = value
        }
    }

    const handleChange = (id, value) => {
        setValue(id, value)
        setStatusStates([...statusStates])
    }

    const handleSave = () => {
        if ((isDataEntryManager && checkReaderStatusCommentFields()) ||
            ((user.roles.includes("reader_a") || user.roles.includes("reader_b")) && checkReaderStatusCommentFields()) ||
            (isReaderC && checkReaderCStatusCommentFields())) {

            toastError(t("error_comment_missing"))
            return
        }

        const categorySamples = [...statusStates, ...getReadingStatus()]

        for (let i = 0; i < categorySamples.length; i++) {
            if (categorySamples[i].id && categorySamples[i].id.startsWith('local')) {
                categorySamples[i].id = null
            }
        }

        const toSave = {
            id: dataModel.id,
            startDate: dataModel.startDate,
            status: dataModel.status,
            category: dataModel.category,
            recommendedTherapy: get(dataModel, "recommendedTherapy"),
            categorySamples: categorySamples
        };

        return mutateData({
            appointment: toSave,
            patientId
        }).then(() => {
            refresh();
            if (user.roles.includes("reader_a") || user.roles.includes("reader_b")) {
                setReaderAOrBReqField(checkAllRequiredFields())
            }
        }, () => {
            toastError(t("error_gql"));
        });
    }

    const getReadingStatus = () => {
        if (readingStatus && readingStatus.value === APPT_READING_FINISHED) {
            return [readingStatus]
        }
        switch (role) {
            case "data_entry_manager":
                return getDEMReadingStatus()
            case "reader_a":
            case "reader_b":
                return getReaderReadingStatus()
            case "reader_c":
                return getReaderCReadingStatus()
            default:
                return getRStatus("")
        }
    }

    const checkAllTableStatusStatesRequiredFields = () => {
        const completeStatus = ["complete", "korrekt"]
        const statusStatesRequiredFields = getStatusStatesRequiredFields(model(), isReaderC)

        for (let i = 0; i < statusStates.length; ++i) {
            if (statusStates[i].categoryType === statusStatesRequiredFields[i].categoryType) {
                if (!statusStatesRequiredFields[i].optional && statusStates[i].categoryType.includes("Status") &&
                    statusStates[i].categoryType.includes("data_entry_manager") && !completeStatus.includes(statusStates[i].value.trim())) {

                    return false
                }
            }
        }
        return true
    }

    const getDEMStatus = () => {
        if (checkAllTableStatusStatesRequiredFields()) {
            return getRStatus(APPT_READING)
        }
        return getRStatus(APPT_INQUIRY)
    }

    const didAppointmentTookPlace = () => {
        return dataModel?.status === APPT_STATUS_TOOK_PLACE
    }

    const getDEMReadingStatus = () => {
        if (didAppointmentTookPlace()) {
            if (readingStatus) {
                if (readingStatus.value === "" || readingStatus.value === APPT_INQUIRY) {
                    return getDEMStatus()
                }
                return [readingStatus]
            }
            return getDEMStatus()
        }
        return getRStatus("")
    }

    const checkAllRequiredFields = () => {
        const completeStatus = ["complete", "korrekt"]
        const requiredFields = getStatusStatesRequiredFields(model(), isReaderC)

        for (let i = 0; i < statusStates.length; ++i) {
            if (statusStates[i].categoryType === requiredFields[i].categoryType) {
                if (!requiredFields[i].optional && (!statusStates[i].value || (statusStates[i].categoryType.includes("Status")
                    && statusStates[i].categoryType.includes("data_entry_manager") && !completeStatus.includes(statusStates[i].value.trim())))) {

                    return false
                }
            }
        }
        return true
    }

    const getReaderReadingStatus = () => {
        if (readingStatus) {
            const reader = getReaderCategorySamples(categorySamples, role === "reader_a" ? "reader_b" : "reader_a")
            let isPlausible = "finished"
            const completeStatus = ["complete", "korrekt"]

            if (reader.length > 0 && readingStatus.value === APPT_READING && checkAllRequiredFields()) {
                reader.forEach((item, index, array) => {
                    const otherItem = statusStates.find((elem) => elem.categoryType === `${item.categoryType.split("_")[0]}_${userId}_${role}`)

                    if (item && item.value && otherItem && otherItem.value && item?.categoryType.includes("Status")) {
                        if (!item.categoryType.includes("abschlissendeBewertung") &&
                            (!completeStatus.includes(item.value.trim()) || !completeStatus.includes(otherItem?.value.trim()))) {

                            isPlausible = "discrepancy"
                            array.length = index + 1
                        }
                    } else if (item && otherItem && !item?.categoryType.includes("Status")) {
                        if (item?.value !== otherItem?.value) {
                            isPlausible = "discrepancy"
                            array.length = index + 1
                        }
                    } else {
                        isPlausible = "old"
                        array.length = index + 1
                    }
                })
                switch (isPlausible) {
                    case "finished":
                        return getRStatus(APPT_READING_FINISHED)
                    case "discrepancy":
                        return getRStatus(APPT_DISCREPANCY)
                    default:
                        return [readingStatus]
                }
            }
            return [readingStatus]
        }
        return getRStatus("")
    }

    const getReaderCReadingStatus = () => {
        if (readingStatus) {
            if (readingStatus.value === APPT_DISCREPANCY) {
                if (statusStates.find((item) => item.categoryType.startsWith("readerCStatus")).value === "korrektur") {
                    return [...getRStatus(APPT_INQUIRY), ...restoreValues(readerA), ...restoreValues(readerB)]
                } else {
                    return getRStatus(APPT_READING_FINISHED)
                }
            }
            return [readingStatus]
        }
        return getRStatus("")
    }

    const restoreValues = (readerX) => {
        readerX.forEach((item) => {
            item.value = ""
        })
        return readerX
    }

    const getRStatus = (value) => {
        return [{
            "id": readingStatus ? readingStatus?.id : null,
            "categoryType": "readingStatus",
            "startDate": readingStatus ? readingStatus.startDate : (new Date()).toISOString(),
            "endDate": (new Date()).toISOString(),
            "sampleType": "category",
            "value": value
        }]
    }

    const checkReaderStatusCommentFields = () => {
        const inCompleteStatus = ["incorrect", "nichtKorrekt"]
        for (let i = 0; i < statusStates.length; ++i) {
            if(statusStates[i].categoryType.includes("Status") && inCompleteStatus.includes(statusStates[i].value)) {
                const fieldName = statusStates[i].categoryType.split("Status")[0] + "Comment"
                const commentField = statusStates.find((item) => item.categoryType.includes(fieldName))

                if (!commentField.value.trim()) {
                    return true
                }
            }
        }
        return false
    }

    const checkReaderCStatusCommentFields = () => {
        for (let i = 0; i < statusStates.length; ++i) {
            if(statusStates[i].categoryType.includes("readerCStatus") && statusStates[i].value === "korrektur") {
                const fieldName = statusStates[i].categoryType.split("Status")[0] + "Comment"
                const commentField = statusStates.find((item) => item.categoryType.includes(fieldName))
                if (!commentField.value.trim()) {
                    return true
                }
            }
        }
        return false
    }

    function get24hBloodPressureTableData () {
        let tableData = [];
        const fields = [
            {fieldName: "systoleAverage", text: "Systole Mittel"},
            {fieldName: "systoleStdDeviation", text: "Systole Standardabweichung"},
            {fieldName: "systoleMinimum", text: "Systole Minimum"},
            {fieldName: "systoleMaximum", text: "Systole Maximum"},
            {fieldName: "systoleDipping", text: "Dipping systolisch"},
            {fieldName: "diastoleAverage", text: "Diastole Mittel"},
            {fieldName: "diastoleStdDeviation", text: "Diastole Standardabweichung"},
            {fieldName: "diastoleMinimum", text: "Diastole Minimum"},
            {fieldName: "diastoleMaximum", text: "Diastole Maximum"},
            {fieldName: "diastoleDipping", text: "Dipping diastolisch"},
            {fieldName: "madAverage", text: "MAD Mittel"},
            {fieldName: "madStdDeviation", text: "MAD Standardabweichung"},
            {fieldName: "madMinimum", text: "MAD Minimum"},
            {fieldName: "madMaximum", text: "MAD Maximum"},
            {fieldName: "madDipping", text: "Dipping MAD"},
            {fieldName: "pulsePressureAverage", text: "Pulsdruck Mittel"},
            {fieldName: "pulsePressureStdDeviation", text: "Pulsdruck Standardabweichung"},
            {fieldName: "pulsePressureMinimum", text: "Pulsdruck Minimum"},
            {fieldName: "pulsePressureMaximum", text: "Pulsdruck Maximum"},
            {fieldName: "hfAverage", text: "Herzfrequenz Mittel"},
            {fieldName: "hfStdDeviation", text: "Herzfrequenz Standardabweichung"},
            {fieldName: "hfMinimum", text: "Herzfrequenz Minimum"},
            {fieldName: "hfMaximum", text: "Herzfrequenz Maximum"},
        ];

        for (let field of fields) {
            let quantity = [];

            const overallSample = bloodPressureSample.quantitySamples.find(x => x.quantityType === field.fieldName + overallSuffix)
            quantity.push((overallSample && overallSample.quantity) || '');

            const awakeSample = bloodPressureSample.quantitySamples.find(x => x.quantityType === field.fieldName + awakeSuffix)
            quantity.push((awakeSample && awakeSample.quantity) || '');

            const asleepSample = bloodPressureSample.quantitySamples.find(x => x.quantityType === field.fieldName + asleepSuffix)
            quantity.push((asleepSample && asleepSample.quantity) || '');

            tableData.push({
               text: field.text,
               quantity: quantity
            });
        }
        return tableData;
    }

    function getTensioMeasurementTableData() {
        if (tensioImportSample.length === 0) return [];

        let tableData = [];
        for(let sample of tensioImportSample) {
            if ((new Date(sample.startDate)).toISOString() !== (new Date(MIN_DATE_TIME)).toISOString()) {
                tableData.push({
                    date: sample.startDate,
                    intraocularPressureRight: sample.quantitySamples[0].quantity,
                    intraocularPressureLeft: sample.quantitySamples[1].quantity
                });
            }
        }
        return tableData;
    }

    function getLeftTime() {
        const tensioTableData = getTensioMeasurementTableData();
        if (tensioTableData.length === 0) return '';

        return (new Date(tensioTableData.sort((a,b) => a.date > b.date? 1 : -1)[0].date)).getTime();
    }

    function getRightTime() {
        const tensioTableData = getTensioMeasurementTableData();
        if (tensioTableData.length === 0) return '';

        return (new Date(tensioTableData.sort((a,b) => a.date < b.date? 1 : -1)[0].date)).getTime();
    }

    const renderSelectOptions = (field, key, isDisabled) => {
        return (
            <div className={"width-1-2"} key={`${key}_${field.__label}`}>
                {/*<label className={"pr-1"}>{field.__label}</label>*/}
                {field.__label}{!field.__optional && <span style={{color: "red"}}>{" *"}</span>}
                <br />
                <Select
                    value={getValue(key)}
                    key={key}
                    disabled={isDisabled}
                    error={!field.__optional && getValue(key) === ""}
                    options={field.__options}
                    onChange={(e, d) => {
                        handleChange(key, d.value)
                    }}
                />
            </div>
        );
    };

    const renderTextArea = (field, key, isDisabled=false) => {
        return (
            <div key={`${key}_${field.__label}`}>
                <br/>
                <label className={"pr-1"}>{field.__label}</label>
                <Form>
                    <TextArea
                        key={key}
                        value={getValue(key)}
                        rows={5}
                        disabled={isDisabled}
                        onChange={(e, d) => {
                            handleChange(key, d.value)
                        }}
                    />
                </Form>
            </div>
        );
    };

    const renderField = (field, key, isDisabled=false) => {
        if (field.__type === "select") {
            return renderSelectOptions(field, key, isDisabled);
        } else if (field.__type === "textarea") {
            return renderTextArea(field, key, isDisabled);
        }
    }

    const renderReaderCFields = (field, key, label) => {
        return (
            <div className={"half-grid"}>
                <div className={"pb-1 r-column-full r-column-left"}>
                    {field?.__type === "select" &&
                    <div align="center">
                        <div className={"readerGroupSeparator"}>{"Reader A"}</div>
                    </div>}
                    <div>
                        <label className={"pr-1"}>{field.__label}</label>
                        <br/>
                        {field?.__type === "select" ?
                            <Select
                                value={getReaderValue(readerA, label)}
                                key={key}
                                disabled={true}
                                options={field.__options}
                            /> :
                            <TextArea
                                key={key}
                                value={getReaderValue(readerA, label)}
                                rows={5}
                                className={"center-all textareaResizeNone"}
                                disabled={true}
                            />
                        }
                    </div>
                </div>
                <div className={"pb-1 r-column-full r-column-middle"}>
                    {field?.__type === "select" &&
                    <div align="center">
                        <div className={"readerGroupSeparator"}>{"Reader B"}</div>
                    </div>}
                    <div>
                        <label className={"pr-1"}>{field.__label}</label>
                        <br/>
                        {field?.__type === "select" ?
                            <Select
                                value={getReaderValue(readerB, label)}
                                key={key}
                                disabled={true}
                                options={field.__options}
                            /> :
                            <TextArea
                                key={key}
                                value={getReaderValue(readerB, label)}
                                rows={5}
                                className={"center-all textareaResizeNone"}
                                disabled={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderStatusField = (statusField) => {
        const displayItems = []
        for (let i = 0; i < statusField.length; ++i) {
            if (role === "reader_c") {
                displayItems.push(renderReaderCFields(statusField[i]?.displayField, statusField[i]?.dataStorageField?.id,
                    statusField[i]?.dataStorageField?.categoryType.split("_")[0]))
            } else {
                displayItems.push(renderField(statusField[i]?.displayField, statusField[i]?.dataStorageField?.id,
                    isFieldDisabled(role, readingStatus, readerAOrBReqField)))
            }
        }
        return displayItems
    }

    const renderReaderCReadingStatus = (statusField) => {
        const fieldStatus = statusField[0]?.displayField
        const keyStatus = statusField[0]?.dataStorageField?.id
        const fieldComment = statusField[1]?.displayField
        const keyComment = statusField[1]?.dataStorageField?.id

        return <>
            <div className={"width-1-2"} key={`${keyStatus}_${fieldStatus.__label}`}>
                {t("correction_or_not")}<span style={{color: "red"}}>{" *"}</span>
                <br />
                <Select
                    value={getValue(keyStatus)}
                    key={keyStatus}
                    error={!fieldStatus.__optional && getValue(keyStatus) === ""}
                    options={fieldStatus.__options}
                    onChange={(e, d) => {
                        handleChange(keyStatus, d.value)
                    }}
                />
            </div>
            <div key={`${keyComment}_${fieldComment.__label}`}>
                <br/>
                <label className={"pr-1"}>{fieldComment.__label}</label>
                <Form>
                    <TextArea
                        key={keyComment}
                        value={getValue(keyComment)}
                        rows={5}
                        disabled={getValue(keyStatus) !== "korrektur"}
                        onChange={(e, d) => {
                            handleChange(keyComment, d.value)
                        }}
                    />
                </Form>
            </div>
            <Divider /><br/>
        </>
    }

    return (
        <div>
            {(readerCComment && isDataEntryManager && readingStatus && [APPT_DISCREPANCY, APPT_INQUIRY]?.includes(readingStatus?.value)) ?
                renderReaderCComment(t("dem_contact_study_nurse"), readerCComment, "dem_contact_study_nurse") : null}
            <>
                {
                    model().map((x) => {
                        return (
                            x.sectionField.section === "ReaderC" ?
                                role && role === "reader_c" && readingStatus && readingStatus.value === APPT_DISCREPANCY && renderReaderCReadingStatus(x.statusField) :
                                null
                        );
                    })
                }
            </>
            <div align="center">
                <div className={"groupSeparator"}>{"24h Blutdruckmessungen"}</div>
                <br />
            </div>
            <div>
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{"Measurement"}</Table.HeaderCell>
                            <Table.HeaderCell textAlign={"center"}>{"Gesamtübersicht"}</Table.HeaderCell>
                            <Table.HeaderCell textAlign={"center"}>{"Wachphase"}</Table.HeaderCell>
                            <Table.HeaderCell textAlign={"center"}>{"Schlafphase"}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {get24hBloodPressureTableData().map((it, index) => {
                            return (
                                <Table.Row key={index + "_row"}>
                                    <Table.Cell>{it.text}</Table.Cell>
                                    <Table.Cell textAlign={"center"}>{it.quantity[0]}</Table.Cell>
                                    <Table.Cell textAlign={"center"}>{it.quantity[1]}</Table.Cell>
                                    <Table.Cell textAlign={"center"}>{it.quantity[2]}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

                <>
                    <br /><br />
                    {/*<RenderReaderZoneTableStatus section={"TensioMeasurement"} />*/}
                    {/*<br />*/}
                </>
            </div>

            <div>
                <div align="center">
                    <div className={"groupSeparator"}>{"Tensiomessung"}</div>
                    <br />
                </div>

                {getTensioMeasurementTableData().length &&
                    <>
                        <ChartTensio data={readerCorrelationSample} leftTime={getLeftTime()} rightTime={getRightTime()}/>
                        <br /> <br /> <br />
                    </>
                }

                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{"Messzeitpunkt"}</Table.HeaderCell>
                            <Table.HeaderCell>{"IOP (OD)"}</Table.HeaderCell>
                            <Table.HeaderCell>{"IOP (OS)"}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {getTensioMeasurementTableData().length && getTensioMeasurementTableData().map((it, index) => {
                            return(
                                <Table.Row key={index + "_row"}>
                                    <Table.Cell>{formatDateTime(it.date)}</Table.Cell>
                                    <Table.Cell>{it.intraocularPressureRight}</Table.Cell>
                                    <Table.Cell>{it.intraocularPressureLeft}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
            <>
                <br /><br />
                <Divider />
                {
                    model().map((x, index) => {
                        return (
                            x.sectionField.section === "ReaderC" ? null :
                                <div key={index}>
                                    {renderStatusField(x.statusField)}
                                    <br />
                                </div>
                        );
                    })
                }
                <Divider />

                <div className={"Grid -right"}>
                    <Button basic type="submit" size="tiny" color="teal" content={t("save")} onClick={handleSave} />
                </div>
            </>
        </div>
    );
};

export default RenderReaderZoneTensioControl