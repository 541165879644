import React, {forwardRef, useImperativeHandle, useState} from "react";
import DateInput from "../inputs/DateInput";
import {Button, Divider, Form, Select} from "semantic-ui-react";
import {
    APPT_READING_FINISHED,
    APPT_STATUS_CANCELED,
    APPT_STATUS_NOT_SCHEDULED_YET,
    APPT_STATUS_SCHEDULED,
    APPT_STATUS_TOOK_PLACE, CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4
} from "../../data/mutations/appointments";
import {toastError} from "../../services";
import {useTranslation} from "react-i18next";
import {useSaveAppointment} from "../../hooks";
import {isEqual, keys, set} from "lodash";
import {useForm} from "react-hook-form";
import * as _ from "lodash";
import {getCurrentTab} from "../reader/models/utils";

const InitiateAppointment = ({model, patientId, callback, isReadOnly, afterSave, inclusionDate, patient, isStudyLead = false}, ref) => {
    const {t} = useTranslation();
    const [startDate, setStartDate] = useState(model.startDate || new Date());
    const {update} = useSaveAppointment();
    const [status, setStatus] = useState(model.status);
    const [errors, setErrors] = useState({
        startDate: false,
        status: false
    });
    const form = useForm();

    const categorySamples = patient?.appointments.find(x => _.get(x, "category") === getCurrentTab())?.categorySamples ?? null;
    const readingStatus = categorySamples?.find(x => _.get(x, "categoryType") === "readingStatus")

    useImperativeHandle(ref, () => ({
        hasChanged() {
            if (model["startDate"]) {
                const isNotSameDate = (new Date(model["startDate"])).getTime() !== (new Date(startDate)).getTime();
                return isNotSameDate || !isEqual(model["status"], status);
            } else {
                return !isEqual(model["status"], status);
            }
        },
        isDateSet() {
            return startDate !== null;
        },
        hasError() {
            return hasError();
        },
        save() {
            return save();
        }
    }));

    const hasError = () => {
        if (startDate === null) {
            set(errors, "startDate", true);
            setErrors({...errors});
            return true;
        } else if (status === null) {
            set(errors, "status", true);
            setErrors({...errors});
            return true;
        } else if (new Date(startDate) > new Date() && status === APPT_STATUS_TOOK_PLACE) {
            set(errors, "status", true);
            setErrors({...errors});
            return true;
        } else if (new Date(startDate) < new Date(inclusionDate)) {
            set(errors, "startDate", true);
            setErrors({...errors});
            return true;
        } else {
            set(errors, "startDate", false);
            set(errors, "status", false);
            setErrors({...errors});
            return false;
        }
    };

    const handleStatusChange = (event, data) => {
        setStatus(data.value);
        if (data.value === APPT_STATUS_TOOK_PLACE && model.status === APPT_STATUS_TOOK_PLACE) {
            callback(false);
        } else {
            callback(true);
        }
    };
    const handleDateChange = (value) => {
        setStartDate(value);
    };

    const getReadingStatus = () => {
        if (status === APPT_STATUS_CANCELED) {
            return [{
                "id": readingStatus ? readingStatus?.id : null,
                "categoryType": "readingStatus",
                "startDate": readingStatus ? readingStatus.startDate : (new Date()).toISOString(),
                "endDate": (new Date()).toISOString(),
                "sampleType": "category",
                "value": APPT_READING_FINISHED
            }]
        } else {
            return readingStatus ? [readingStatus] : []
        }
    }

    const statusOptions = [
        {value: APPT_STATUS_NOT_SCHEDULED_YET, text: t("APPT_STATUS_NOT_SCHEDULED_YET")},
        {value: APPT_STATUS_SCHEDULED, text: t("APPT_STATUS_SCHEDULED")},
        {value: APPT_STATUS_TOOK_PLACE, text: t("APPT_STATUS_TOOK_PLACE")},
        {value: APPT_STATUS_CANCELED, text: t("APPT_STATUS_CANCELED")}
    ];

    const save = (data) => {
        if (!hasError()){
            let appointmentTemplate = {
                id: model.id,
                startDate: startDate,
                status: status,
                category: model.category
            }

            if (isStudyLead && [CAT_FOLLOWUP_T2, CAT_FOLLOWUP_T3, CAT_FOLLOWUP_T4].includes(model?.category)) {
                let _categorySamples = []
                if(categorySamples) {
                    _categorySamples = [...categorySamples?.filter(x => _.get(x, "categoryType") !== "readingStatus"), ...getReadingStatus()]
                } else {
                    _categorySamples = [...getReadingStatus()]
                }
                appointmentTemplate = {
                    ...appointmentTemplate,
                    categorySamples: _categorySamples
                }
            }

            return update({
                appointment: appointmentTemplate,
                patientId
            }).then(value => {
                keys(errors).forEach(key => {
                    errors[key] = false;
                });
                setErrors({...errors});
                afterSave();
                if(value.data.upsertAppointment.status === APPT_STATUS_TOOK_PLACE){
                    callback(false);
                }else {
                    callback(true);
                }
            }, reason => {
                toastError(t("error_gql"));
            });
        } else {
            toastError(t("error_fields_incorrect"));
        }
    };

    const onError = (errors, e) => {
        toastError(t("error_fields_incorrect"));
    }

    const isDatePickerDisabled = () => {
        return (isReadOnly || status === APPT_STATUS_NOT_SCHEDULED_YET);
    }

    return (
        <Form id="initial-appointment-form" onSubmit={form.handleSubmit(save, onError)}>
            <div className={"pb-2"}>
                <div className={"pb-2 width-1-2"}>
                    <label className={"pr-1"}>{t("appointment_status")}</label>
                    <Form.Input control={Select} value={status} options={statusOptions}
                                onChange={handleStatusChange} disabled={isReadOnly}/>
                </div>
                <div className={"pb-1 width-1-2"}>
                    <label className={"pr-1"}>{t("appointment_start_date")}</label>
                    <br/>
                    <label className={"label-small"}>{t("hint_appointmentdateafterinclusion")}</label>
                    <DateInput value={startDate} type={"datetime"}
                               onChange={(v) => handleDateChange(v)}
                               className={errors["startDate"] ? "patientStatus--data-missing" : "" }
                               additionalProps={{disabled : isDatePickerDisabled()}}/>
                </div>

                <Divider/>
                <br/>

                <div className={"Grid -right"}>
                    <Button disabled={isReadOnly} basic type="submit" size="tiny" color="teal"
                            form="initial-appointment-form"
                            content={t("save")}/>
                </div>
            </div>
        </Form>
    );
};

export default forwardRef(InitiateAppointment);

