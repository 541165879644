import React, {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Divider, Form, Modal, Radio} from "semantic-ui-react";
import {useMutateMedicalHistory} from "hooks";
import {AuthContext, hasPermission, removePrivateFields} from "services";
import {useTranslation} from "react-i18next";
import {PAT_STATUS_DATA_EXCLUDED} from "../data/mutations/mutations";
import {get} from "lodash";
import {Controller, useForm} from "react-hook-form";
import {toastError} from "../services";
import {regExs} from "../data/models/utils";
import {Link} from "react-router-dom";


const radioModel = [
    {
        title: "Diagnose",
        field: "diagnosis",
        fieldtype: "text",
        values: [
            {label: "H40.0 Glaukomverdacht", value: "H40.0 Glaukomverdacht"},
            {label: "H40.1 Primäres Weitwinkelglaukom",value: "H40.1 Primäres Weitwinkelglaukom",},
            {label: "H40.2 Primäres Engwinkelglaukom", value: "H40.2 Primäres Engwinkelglaukom"},
            {label: "H42.- Glaukom bei anderorts klassifizierten Krankheiten",value: "H42.- Glaukom bei anderorts klassifizierten Krankheiten",},
        ],
    },
    {
        title: "Auge",
        field: "eye",
        fieldtype: "text",
        values: [
            {label: "Rechtes Auge", value: "Rechtes Auge"},
            {label: "Linkes Auge", value: "Linkes Auge"},
            {label: "Beide Augen", value: "Beide Augen"},
        ],
    },
];

const checkBoxModel = {
    title: "Familienanamnese für Glaukom (blutsverwandt)",
    field: "family",
    values: ["Bruder", "Schwester", "Vater", "Mutter", "Großvater", "Großmutter", "Onkel", "Tante"],
};

const createModel = (patient = {}) => {
    const template = {
        diagnosis: undefined,
        eye: undefined,
        initialDiagnoseYear: undefined,
        maxMeasuredIOPLeft: undefined,
        maxMeasuredIOPRight: undefined,
        familyHistoryGlaucomaPerson: [],
        comment: undefined,
    };

    const model = removePrivateFields(patient);

    return model.medicalHistory ? {...template, ...model.medicalHistory} : template;
};

const MedicalHistory = ({patient, roles}) => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");
    const [model, setModel] = useState(createModel(patient));
    const {mutate, loading} = useMutateMedicalHistory();
    const form = useForm({mode: "all"});
    const isStudyLead = hasPermission(roles,["study_lead"]);
    const isQualityControlUser = hasPermission(roles, ["reader_a"]) || hasPermission(roles, ["reader_b"]) ||
        hasPermission(roles, ["reader_c"]) || hasPermission(roles, ["data_entry_manager"]);
    const isReadOnly = patient.status === PAT_STATUS_DATA_EXCLUDED || isStudyLead || isQualityControlUser || isPatient;

    const [modalOpen, setModalOpen] = useState(false);

    /* @todo Please check/fix React Hook useEffect has a missing dependency: ...  react-hooks/exhaustive-deps */
    /* eslint-disable */
    useEffect(() => {
        form.trigger();
    }, [patient])
    /* eslint-enable */

    const handleChange = (e, data) => {
        setModel((prev) => {
            const {checked, name, value, type} = data;
            let result;
            if (type === "checkbox") {
                let cbModel = prev[name];
                if (checked) {
                    cbModel.push(data.label);
                } else {
                    cbModel = cbModel.filter((it) => it !== data.label);
                }
                result = {...model, [name]: cbModel};
            } else {
                result = {...model, [name]: value};
            }
            form.setValue(name, get(result, name),  { shouldDirty: true, shouldValidate: true});
            return result;
        });
    };

    const handleAntihypertensiveTherapyChange = (e,data) => {
        setModel({...model, antihypertensiveTherapy: data.checked});
        form.setValue("antihypertensiveTherapy", data.checked,  { shouldDirty: true, shouldValidate: true});
    };

    const goToInitialExam = () => {
        window.sessionStorage.setItem("appointmentTabActiveIndexForPatient", 1);
        setModalOpen(false);
    };

    const renderModalOnSavingMedicalHistory = () => {
        return (
            <Modal onClose={() => setModalOpen(false)} onOpen={() => setModalOpen(true)} open={modalOpen}>
                <Modal.Header>{t("medical_history_modal_header")}</Modal.Header>
                <Modal.Content>
                    <p>{t("medical_history_modal_content")}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setModalOpen(false)}>{t("cancel")}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to={`/patient/${patient.id}`} onClick={() => goToInitialExam()}>
                        <Button positive>{t("medical_history_modal_redirect_initial_exam")}</Button>
                    </Link>
                </Modal.Actions>
            </Modal>
        );
    };

    const handleSave = (data) => {
        if (loading) {
            return;
        }

        if(data && !isStudyLead)
        {
            mutate({
                medicalHistory: {
                    ...data,
                    familyHistoryGlaucomaPerson: get(model, "familyHistoryGlaucomaPerson", []),
                    maxMeasuredIOPLeft: Number.parseFloat(data.maxMeasuredIOPLeft),
                    maxMeasuredIOPRight: Number.parseFloat(data.maxMeasuredIOPRight),
                    initialDiagnoseYear: Number.parseFloat(data.initialDiagnoseYear),
                },
                patientId: patient.id,
            }).then((v)=>{
                patient.medicalHistory = v.data.upsertMedicalHistory.medicalHistory;
            });
            setModalOpen(true);
        }
    };

    const onSubmitError = () => {
        toastError(t("error_fields_incorrect"));
    }

    return (
        <div>
            {!isPatient &&
                <>
                    <div className={"Grid -right"}>
                        <Button basic type={"submit"} size="tiny" color="teal" content={t("save")} form={"medical-history-form"}
                                disabled={isReadOnly}/>
                    </div>
                    <Divider />
                </>
            }
            <Form autoComplete="off" id={"medical-history-form"} onSubmit={form.handleSubmit(handleSave, onSubmitError)}>
                {radioModel.map((it) => (
                    <div key={it.title} className={"pb-2"}>
                        <div className={"c-header"}>{it.title}:
                            {!!get(form, `errors.${it.field}`) &&
                                <label className={"label-error"}>{t("E_REQUIRED")}</label>
                            }
                        </div>
                        {it.values.map((val, index) => {
                            return (
                                <Form.Field key={index} error={!!get(form, `errors.${it.field}`)}>
                                    <Controller
                                        control={form.control}
                                        name={it.field}
                                        rules={{ required: true }}
                                        defaultValue={model[it.field]}
                                        render={() => (
                                            <Radio
                                                label={val.label}
                                                name={it.field}
                                                value={val.value}
                                                fieldtype={it.fieldtype}
                                                checked={model[it.field] === val.value}
                                                onChange={handleChange}
                                                disabled = {isReadOnly && !isPatient}
                                                className = {isPatient ? "patient-view-font" : ""}
                                            />
                                        )}
                                    />
                                </Form.Field>
                            );
                        })}
                    </div>
                ))}

                <div className={"pb-2"}>
                    <div className={"c-header"}>{checkBoxModel.title}:</div>
                    {checkBoxModel.values.map((it, index) => (
                        <Form.Field key={index}>
                            <Controller
                                control={form.control}
                                name="familyHistoryGlaucomaPerson"
                                render={() => (
                                    <Checkbox
                                        name={"familyHistoryGlaucomaPerson"}
                                        label={it}
                                        defaultChecked={model.familyHistoryGlaucomaPerson.indexOf(it) > -1}
                                        onChange={handleChange}
                                        disabled = {isReadOnly && !isPatient}
                                        className = {isPatient ? "patient-view-font" : ""}
                                    />
                                )}
                            />
                        </Form.Field>
                    ))}
                </div>

                <div className={"pb-2"}>
                    <div className={"c-header"}>Höchster bekannter Augeninnendruck rechts (mmHg)
                        {!!get(form, "errors.maxMeasuredIOPRight") &&
                            <label className={"label-error"}>{t("E_REQUIRED")}</label>
                        }
                    </div>
                    <Form.Field error={!!get(form, "errors.maxMeasuredIOPRight")}>
                        <input
                            className={"width-1-2" + (isPatient ? " patient-view-font" : "")}
                            name={"maxMeasuredIOPRight"}
                            type="number"
                            defaultValue={get(model, "maxMeasuredIOPRight", "")}
                            data-fieldtype={"number"}
                            placeholder={"ZZ"}
                            autoComplete="off"
                            ref={form.register({
                                required: true,
                                validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                            })}
                            disabled = {isReadOnly && !isPatient}
                        />
                    </Form.Field>

                    <div className={"c-header"}>Höchster bekannter Augeninnendruck links (mmHg)
                        {!!get(form, "errors.maxMeasuredIOPLeft") &&
                            <label className={"label-error"}>{t("E_REQUIRED")}</label>
                        }
                    </div>
                    <Form.Field error={!!get(form, "errors.maxMeasuredIOPLeft")}>
                        <input
                            className={"width-1-2" + (isPatient ? " patient-view-font" : "")}
                            name={"maxMeasuredIOPLeft"}
                            type="number"
                            defaultValue={get(model, "maxMeasuredIOPLeft", "")}
                            data-fieldtype={"number"}
                            placeholder={"ZZ"}
                            autoComplete="off"
                            ref={form.register({
                                required: true,
                                validate: (value) => value.match(regExs.REGEX_INTEGER) || t("E_INVALID")
                            })}
                            disabled = {isReadOnly && !isPatient}
                        />
                    </Form.Field>

                    <div className={"c-header"}>Erstdiagnose der Glaukomerkrankung
                        {!!get(form, "errors.initialDiagnoseYear") &&
                            <label className={"label-error"}>{t("E_REQUIRED")}</label>
                        }
                    </div>
                    <Form.Field error={!!get(form, "errors.initialDiagnoseYear")}>
                        <input
                            className={"width-1-2" + (isPatient ? " patient-view-font" : "")}
                            name={"initialDiagnoseYear"}
                            defaultValue={get(model, "initialDiagnoseYear", "")}
                            data-fieldtype={"number"}
                            autoComplete="off"
                            placeholder={"JJJJ, e.g. 1985"}
                            ref={form.register({
                                required: true,
                                validate: (value) => value.match(/^\d{4}$/) || t("E_INVALID")
                            })}
                            disabled = {isReadOnly && !isPatient}
                        />
                    </Form.Field>
                    <div className={"c-header"}>Blutdrucksenkende Therapie
                        {!isPatient &&<div className={"label-small"}> Wenn nicht bekannt, bitte im Kommentar vermerken!</div>}
                    </div>
                    <Form.Field error={!!get(form, "errors.antihypertensiveTherapy")}>
                        <Controller
                            control={form.control}
                            name={"antihypertensiveTherapy"}
                            defaultValue={model.antihypertensiveTherapy}
                            render={() => (
                                <Checkbox
                                    className={"width-1-2" + (isPatient ? " patient-view-font" : "")}
                                    name={"antihypertensiveTherapy"}
                                    autoComplete="off"
                                    defaultChecked = {model.antihypertensiveTherapy}
                                    onChange={handleAntihypertensiveTherapyChange}
                                    disabled = {isReadOnly && !isPatient}
                                />
                            )}
                        />
                    </Form.Field>
                    <div className={"c-header"}>Kommentar</div>
                    {!isPatient && <div className={"label-small"}>{t("hint_nopatientrelateddata")}</div>}
                    <Form.Field error={!!get(form, "errors.comment")}>
                        <textarea
                            className={"width-1-2" + (isPatient ? " patient-view-font" : "")}
                            defaultValue={get(model, "comment", "")}
                            name={"comment"}
                            autoComplete="off"
                            ref={form.register({ required: false })}
                            disabled = {isReadOnly && !isPatient}
                        />
                    </Form.Field>
                </div>
            </Form>
            {!isPatient &&
                <>
                    <Divider />
                    <div className={"Grid -right"}>
                        <Button basic type={"submit"} size="tiny" color="teal" content={t("save")} form={"medical-history-form"}
                                disabled={isReadOnly}/>
                    </div>
                </>
            }
            {renderModalOnSavingMedicalHistory()}
        </div>
    );
};

export default MedicalHistory;
