import gql from "graphql-tag";

import {categorySampleFragment} from "./categorySampleFragment";
import {metaDataFragment} from "./metaDataFragment";
import {quantitySampleFragment} from "./quantitySampleFragment";

export const salusCorrelationSampleFragment = gql`
    fragment salusCorrelationSampleFragment on SalusCorrelationSample {
        categorySamples {
            ...categorySampleFragment
        }
        correlationType
        endDate
        id
        metadata {
            ...metaDataFragment
        }
        quantitySamples {
            ...quantitySampleFragment
        }
        sampleType
        startDate
        subject
    }
    ${categorySampleFragment}
    ${metaDataFragment}
    ${quantitySampleFragment}
`;
