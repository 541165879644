import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {Button, Checkbox, Divider, Select} from "semantic-ui-react";
import ShowMedicationHistory from "./ShowMedicationHistory";
import {useMedications, useMedicationsPatientSelf, useMutateMedication} from "../../hooks";
import {useTranslation} from "react-i18next";
import {AuthContext, formatDate, toastError} from "../../services";
import StopMedication from "./StopMedication";
import {get, isEqual} from "lodash";
import InputSelect from "../inputs/InputSelect";

const MedicationBlockContent = forwardRef(({patientId, startDate, category, isReadOnly}, ref) => {
    const {t} = useTranslation();
    const [medicationsTable, setMedicationsTable] = useState([]);
    const [newMedications, setNewMedications] = useState([]);
    const [errors] = useState({
        newMedications: false
    });
    const mutateMed = useMutateMedication();
    const medicationData = useMedications(patientId);

    const medicationHistory = medicationData.data?.patientByID?.medications;

    //reload table after data was changed
    if (!medicationData.loading && !mutateMed.loading && medicationHistory) {
        const medications = medicationHistory.filter(it => it.endDate == null);
        if (!isEqual(medications, medicationsTable)) {
            setMedicationsTable(medications);
        }
    }

    useImperativeHandle(ref, () => ({
        hasChanged () {
            return newMedications && newMedications.length > 0;
        },
        save () {
            return saveMedications();
        },
        hasError () {
            return hasError() || get(errors, "newMedications");
        }
    }));

    const hasError = () => {
        for (let i = 0; i < newMedications.length; i++) {
            if (newMedications[i].name === null) {
                toastError("error_name");
                return true;
            }

            if (newMedications[i].dosageAtMorning === 0 && newMedications[i].dosageAtNoon === 0 && newMedications[i].dosageAtEvening === 0) {
                toastError(t("error_fields_incorrect"));
                return true;
            }
        }

        return false;
    };

    const saveMedications = () => {
        if (!(newMedications && newMedications.length > 0)) {
            return Promise.resolve("medication list is empty");
        }

        if (hasError()) return;

        const temp = newMedications?.map(value => {
            return {
                id: null,
                endDate: null,
                startDate: startDate || new Date(),
                name: value.name,
                eyeLocation: value.eyeLocation,
                dosageAtMorning: value.dosageAtMorning,
                dosageAtNoon: value.dosageAtNoon,
                dosageAtEvening: value.dosageAtEvening,
                unit: null
            };
        });
        return Promise.all(temp.map(value => {
            return mutateMed.mutateMedication({
                medication: {...value},
                patientId: patientId
            });
        })).then(() => {
            setNewMedications([]);
            medicationData.reload(patientId);
        }, () => {
            toastError(t("error_gql"));
        });
    };

    const afterUpdated = () => {
        medicationData.reload(patientId);
    };

    const renderMedicationTable = () => {
        const renderMedicationTableData = () => {
            return medicationsTable?.map((medication) => {
                const {id, startDate, name, eyeLocation, dosageAtMorning, dosageAtNoon, dosageAtEvening} = medication;
                return (
                    <tr key={id}>
                        <td>{formatDate(startDate)}</td>
                        <td>{name}</td>
                        <td>{t(eyeLocation)}</td>
                        <td>{dosageAtMorning}</td>
                        <td>{dosageAtNoon}</td>
                        <td>{dosageAtEvening}</td>
                        <td><StopMedication medication={medication} mutation={mutateMed} patientId={patientId}
                                            after={afterUpdated} disabled={isReadOnly}/></td>
                    </tr>
                );
            });
        };

        if (medicationsTable && medicationsTable.length > 0) {
            return (
                <table>
                    <thead>
                    <tr>
                        <td><label className={"c-header c-header--small"}>Datum</label></td>
                        <td><label className={"c-header c-header--small"}>Medikation</label></td>
                        <td><label className={"c-header c-header--small"}>Auge</label></td>
                        <td><label className={"c-header c-header--small"}>Morgens</label></td>
                        <td><label className={"c-header c-header--small"}>Mittags</label></td>
                        <td><label className={"c-header c-header--small"}>Abends</label></td>
                        <td><label className={"c-header c-header--small"}/></td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderMedicationTableData()}
                    </tbody>
                </table>
            );
        }
    };

    const renderMedicationFields = () => {
        const medicationOptions = [
            {
                value: "Betablocker (Wirkstoff: Carteolol, Levobunolol, Timolol, Handelsnamen: Tim-Ophtal, Timo-COMOD, Timo-Stulln, Arteoptic, Betoptima, Vistagan)",
                text: "Betablocker (Wirkstoff: Carteolol, Levobunolol, Timolol, Handelsnamen: Tim-Ophtal, Timo-COMOD, Timo-Stulln, Arteoptic, Betoptima, Vistagan)"
            },
            {
                value: "Carboanhydrasehemmer (Wirkstoff: Dorzolamid, Brinzolamid, Azetazolamid, Handelsnamen: Dorzo-Vision, Trusopt, Azopt)",
                text: "Carboanhydrasehemmer (Wirkstoff: Dorzolamid, Brinzolamid, Azetazolamid, Handelsnamen: Dorzo-Vision, Trusopt, Azopt)"
            },
            {
                value: "Prostaglandin Analoga (Wirkstoff: Bimatoprost, Latanoprost, Travoprost, Tafluprost; Handelsnamen: Lumigan, Travatan, Xalatan, Latano-Vision, Taflotan)",
                text: "Prostaglandin Analoga (Wirkstoff: Bimatoprost, Latanoprost, Travoprost, Tafluprost; Handelsnamen: Lumigan, Travatan, Xalatan, Latano-Vision, Taflotan)"
            },
            {
                value: "Parasympathomimetika (Wirkstoff: Pilocarpin; Handelsnamen: Piloman, Spersacarpin)",
                text: "Parasympathomimetika (Wirkstoff: Pilocarpin; Handelsnamen: Piloman, Spersacarpin)"
            },
            {
                value: "Alpha-2-Rezeptoragonisten (Wirkstoff: Brimonidin, Clonidin, Apraclonidin; Handelsnamen: Alphagan, Brimo Ophthal, Clonid Ophtal, Iopedine)",
                text: "Alpha-2-Rezeptoragonisten (Wirkstoff: Brimonidin, Clonidin, Apraclonidin; Handelsnamen: Alphagan, Brimo Ophthal, Clonid Ophtal, Iopedine)"
            },
            {
                value: "Betablocker + Carboanhydrasehemmer (Timolol + Brinzolamid, Timolol + Dorzolamid; Handelsnamen: Azarga, Cosopt, Duokopt, Dorzo comp. Vision)",
                text: "Betablocker + Carboanhydrasehemmer (Timolol + Brinzolamid, Timolol + Dorzolamid; Handelsnamen: Azarga, Cosopt, Duokopt, Dorzo comp. Vision)"
            },
            {
                value: "Betablocker + Prostaglandin Analoga (Timolol + Travoprost, Timolol + Bimatoprost, Timolol + Latanoprost; Handelsnamen: DuoTrav, Ganfort, Xalacom, LatanoTim vision, Latano plus T, Latanomed comp.)",
                text: "Betablocker + Prostaglandin Analoga (Timolol + Travoprost, Timolol + Bimatoprost, Timolol + Latanoprost; Handelsnamen: DuoTrav, Ganfort, Xalacom, LatanoTim vision, Latano plus T, Latanomed comp.)"
            },
            {
                value: "Betablocker + Parasympathomimetika (Timolol + Pilocarpin; Handelsnamen: Fotil, TP-Ophtal)",
                text: "Betablocker + Parasympathomimetika (Timolol + Pilocarpin; Handelsnamen: Fotil, TP-Ophtal)"
            },
            {
                value: "Betablocker + Alpha-2-Rezeptoragonisten (Timolol + Brimonidin; Handelsnamen: Combigan)",
                text: "Betablocker + Alpha-2-Rezeptoragonisten (Timolol + Brimonidin; Handelsnamen: Combigan)"
            },
            {
                value: "Alpha-2-Rezeptoragonisten + Carboanhydrasehemmer (Brimonidin + Brinzolamid; Handelsnamen: Simbrinza)",
                text: "Alpha-2-Rezeptoragonisten + Carboanhydrasehemmer (Brimonidin + Brinzolamid; Handelsnamen: Simbrinza)"
            },
            {
                value: "Acetazolamid p.o. (z.B. Glaupax Tabletten) 250 mg",
                text: "Acetazolamid p.o. (z.B. Glaupax Tabletten) 250 mg"
            },
            {
                value: "Acetazolamid p.o. (z.B. Glaupax Tabletten) 125 mg",
                text: "Acetazolamid p.o. (z.B. Glaupax Tabletten) 125 mg"
            }
        ];

        const getNewMedTemplate = (key) => {
            return {
                key: key,
                name: medicationOptions[0].text,
                eyeLocation: "NOT_APPLICABLE",
                dosageAtMorning: 0,
                dosageAtNoon: 0,
                dosageAtEvening: 0
            };
        };

        const handleDelete = (key) => {
            if (newMedications.length === 0) {
                return;
            }
            const updatedMed = newMedications.filter(value => value.key !== key);
            setNewMedications([
                ...updatedMed
            ]);
        };

        const selectChanged = (key, value) => {
            newMedications.filter(med => {
                return med.key === key;
            }).forEach(med => {
                return med.name = value;
            });
            setNewMedications([...newMedications]);
        };

        const radioChanged = (key, type, value) => {
            newMedications.filter(med => {
                return med.key === key;
            }).forEach(med => {
                return med[type] = value;
            });
            setNewMedications([...newMedications]);
        };

        const handleAdd = () => {
            const lastMedKey = newMedications.length === 0 ? -1 : newMedications[newMedications.length - 1].key;
            setNewMedications([
                ...newMedications,
                getNewMedTemplate(lastMedKey + 1)
            ]);
        };

        return (
            <div>
                <Button type="button" basic size="tiny" color="teal" content={t("add_medication")}
                        onClick={() => handleAdd()} disabled={isReadOnly}/>
                <br/>
                <table className={"table-without-border"}>
                    <tbody>
                    {newMedications.length > 0 &&
                    <tr>
                        <td className={"width-1-2 td-without-border"}/>
                        <td className={"td-without-border"}> Morgens</td>
                        <td className={"td-without-border"}>Mittags</td>
                        <td className={"td-without-border"}>Abends</td>
                        <td className={"td-without-border"}/>
                        <td className={"td-without-border"}/>
                    </tr>
                    }

                    {newMedications.map(newMed => {
                        return (
                            <tr key={newMed.key}
                                className={get(errors, "newMedications") ? "patientStatus--data-missing" : ""}>
                                <td className={"td-without-border"}>
                                    <InputSelect
                                        options={medicationOptions}
                                        onChange={(value) => {
                                            selectChanged(newMed.key, value);
                                        }}
                                    />
                                </td>
                                <td align="center" className={"td-without-border"}>
                                    <Checkbox
                                        defaultChecked={newMed.dosageAtMorning === 1}
                                        onChange={((e, {checked}) => radioChanged(newMed.key, "dosageAtMorning", +checked))}
                                    />
                                </td>
                                <td className={"td-without-border"} align="center">
                                    <Checkbox
                                        defaultChecked={newMed.dosageAtNoon === 1}
                                        onChange={(e, {checked}) => radioChanged(newMed.key, "dosageAtNoon", +checked)}
                                    />
                                </td>
                                <td className={"td-without-border"} align="center">
                                    <Checkbox
                                        defaultChecked={newMed.dosageAtEvening === 1}
                                        onChange={(e, {checked}) => radioChanged(newMed.key, "dosageAtEvening", +checked)}
                                    />
                                </td>
                                <td align="center" className={"td-without-border"}>
                                    <Select
                                        options={[
                                            {key: "right", value: "RIGHT_EYE", text: "rechtes Auge"},
                                            {key: "left", value: "LEFT_EYE", text: "linkes Auge"},
                                            {key: "not", value: "NOT_APPLICABLE", text: "Nicht Anwendbar"}
                                        ]}
                                        placeholder={"Nicht Anwendbar"}
                                        onChange={(e, {value}) => radioChanged(newMed.key, "eyeLocation", value)}
                                    />
                                </td>
                                <td className={"td-without-border"}>
                                    <Button type="button" basic size="tiny" color="teal" content={t("remove")}
                                            onClick={() => handleDelete(newMed.key)}/>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <Divider/>
            <ShowMedicationHistory medications={medicationHistory}/>
            <Divider/>
            <div align="center">
                <div className={"c-header c-header--big"}>{t("conservative_therapy")}</div>
            </div>
            <br/>
            {renderMedicationTable(medicationHistory, mutateMed, patientId, afterUpdated)}
            <br/>
            {renderMedicationFields()}
        </div>
    );
});


const MedicationBlockContentForPatientView = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [medicationsTable, setMedicationsTable] = useState([]);

    const medicationData = useMedicationsPatientSelf();

    const medicationHistory = medicationData.data?.patientSelf?.medications;

    //reload table after data was changed
    if (!medicationData.loading && medicationHistory) {
        const medications = medicationHistory.filter(it => it.endDate == null);
        if (!isEqual(medications, medicationsTable)) {
            setMedicationsTable(medications);
        }
    }

    useImperativeHandle(ref, () => ({
        hasChanged () {
            return false;
        },
        save () {
            return false;
        },
        hasError () {
            return false;
        }
    }));

    const renderMedicationTable = () => {
        const renderMedicationTableData = () => {
            return medicationsTable?.map((medication) => {
                const {id, startDate, name, eyeLocation, dosageAtMorning, dosageAtNoon, dosageAtEvening} = medication;
                return (
                    <tr key={id}>
                        <td>{formatDate(startDate)}</td>
                        <td>{name}</td>
                        <td>{t(eyeLocation)}</td>
                        <td>{dosageAtMorning}</td>
                        <td>{dosageAtNoon}</td>
                        <td>{dosageAtEvening}</td>
                    </tr>
                );
            });
        };

        if (medicationsTable && medicationsTable.length > 0) {
            return (
                <table>
                    <thead>
                    <tr>
                        <td><label className={"c-header c-header--small"}>Datum</label></td>
                        <td><label className={"c-header c-header--small"}>Medikation</label></td>
                        <td><label className={"c-header c-header--small"}>Auge</label></td>
                        <td><label className={"c-header c-header--small"}>Morgens</label></td>
                        <td><label className={"c-header c-header--small"}>Mittags</label></td>
                        <td><label className={"c-header c-header--small"}>Abends</label></td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderMedicationTableData()}
                    </tbody>
                </table>
            );
        }
    };

    return (
        <div>
            <Divider/>
            <ShowMedicationHistory medications={medicationHistory}/>
            <Divider/>
            <div align="center">
                <div className={"c-header c-header--big"}>{t("conservative_therapy")}</div>
            </div>
            <br/>
            {renderMedicationTable(medicationHistory)}
        </div>
    );
});

const MedicationBlock = ({patientId, startDate, category, isReadOnly}, ref) => {
    const { user } = useContext(AuthContext);
    const isPatient = user.roles.includes("patient");

    return (
      <>
          {isPatient
           ?
              <MedicationBlockContentForPatientView ref={ref}/>
           :
              <MedicationBlockContent
                  patientId={patientId}
                  startDate={startDate}
                  category={category}
                  isReadOnly={isReadOnly}
                  ref={ref}
              />
          }
      </>
    );
}

export default forwardRef(MedicationBlock);
