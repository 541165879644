import {ApolloClient, InMemoryCache} from "apollo-boost";
import {createHttpLink} from "apollo-link-http";
import {setContext} from "@apollo/link-context";
import {ApolloProvider} from "@apollo/react-hooks";
import React, {useContext} from "react";
import {AuthContext} from "./AuthContext";

import {onError} from "apollo-link-error";
import {ApolloLink, Observable} from "apollo-link";

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
    },
    mutate: {
        errorPolicy: "all"
    }
};

const cache = new InMemoryCache({
    addTypename: true
});

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVER_URI}/graphql`
});

export const GraphQlProvider = ({children}) => {

    const authContext = useContext(AuthContext);

    const authLink = setContext((_, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${authContext.keycloak.token}`
            }
        };
    });

    const link401Handler = ApolloLink.from([
        onError(({graphQLErrors, networkError, operation, forward}) => {
            if (networkError && networkError.statusCode === 401) {
                return new Observable(observer => {
                    authContext.keycloak.updateToken(5)
                        .then(refreshResponse => {
                            if(refreshResponse) {
                                operation.setContext(({headers = {}}) => ({
                                    headers: {
                                        ...headers,
                                        authorization: `Bearer ${authContext.keycloak.token}`
                                    }
                                }));
                            } else {
                               throw Error('Could not refresh token.');
                            }
                        })
                        .then(() => {
                            const subscriber = {
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer)
                            };
                            forward(operation).subscribe(subscriber);
                        })
                        .catch(error => {
                            observer.error(error);
                        });
                });
            }
        })
    ]);

    const apolloClient = new ApolloClient({
        cache,
        link: link401Handler.concat(authLink).concat(httpLink),
        defaultOptions
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
