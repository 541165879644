import gql from 'graphql-tag';
import {organisationFragment} from "../fragments/organisationFragment";

export const UPDATE_ORGANISATION_PROFILE = gql`
    mutation UpdateOrganisation($organisation: OrganisationProfileInput!) {
        updateOrganisation(organisation: $organisation) {
            ...organisationFragment
        }
    }
    ${organisationFragment}
`;
