import gql from "graphql-tag";
import {contactInfoFragment} from "./contactInfoFragment";
import {hospitalFragment} from './hospitalFragment';
import {organisationFragment} from './organisationFragment';

export const masterDataFragment = gql`
    fragment masterDataFragment on MasterData {
        gender
        surname
        forename
        birthdate
        contact {
            ...contactInfoFragment
        }
        insurance {
            number
            company
        }
        hospital {
            ...hospitalFragment
        }
        practice {
            ...organisationFragment
        }
    }
    ${contactInfoFragment}
    ${hospitalFragment}
    ${organisationFragment}
`;
