import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import {addHeading, getValue, getCategoryValue, getDeviceName} from "./util" ;


export async function followup2Summary(masterData, correlationSamples, appointmentDate) {
    const initialSummaryBase64String = await fetch("pdftemplates/patientSummary/Kontrolluntersuchung_1_2_Summary.pdf").then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(initialSummaryBase64String);

    const pdfDocWithHeading = await addHeading(pdfDoc, "Kontrolluntersuchung 2", masterData, appointmentDate);
    const courierFont = await pdfDocWithHeading.embedFont(StandardFonts.Courier);
    const pages = pdfDocWithHeading.getPages();
    const firstPage = pages[0];

    // Visus
    firstPage.drawText(getValue(correlationSamples, "decimalvisusRight"), { x: 420, y: 573, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "decimalvisusLeft"), { x: 420, y: 557, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusRight"), { x: 420, y: 541, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "meterVisusLeft"), { x: 420, y: 525, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionRight"), { x: 350, y: 509, size: 10, font: courierFont });
    firstPage.drawText(getCategoryValue(correlationSamples, "decimalVisusOptionLeft"), { x: 350, y: 493, size: 10, font: courierFont });

    // Tensiomessung
    firstPage.drawText(getDeviceName(correlationSamples, "tensioMeasurementManual"), {x: 120, y: 439, size: 10, font: courierFont});
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurmentManualRight"), { x: 420, y: 407, size: 10, font: courierFont });
    firstPage.drawText(getValue(correlationSamples, "tensioMeasurementManualLeft"), { x: 420, y: 391, size: 10, font: courierFont });

    const pdfBytes = await pdfDocWithHeading.save();

    download(pdfBytes, "Kontrolluntersuchung_2_Summary", "application/pdf");
}