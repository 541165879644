import React from "react";
import {useTranslation} from "react-i18next";

const TermsAndConditions = () => {
    const {t} = useTranslation();

    const renderContent = name => {
        const content = t(name).split("\n").filter(it => it.trim());
        return content.map((it, index) => <li key={index}>{it}</li>)
    };


    return (
        <div className={"font-medium"}>
            <br/>
            <div>
                <b> {t("tc_content_section_1_title")}</b>
            </div>

            <div>
                <ul>
                    {renderContent("tc_content_section_1_content")}
                </ul>
            </div>

            <br/>

            <div>
                <b> {t("tc_content_section_2_title")}</b>
            </div>

            <div>
                <ul>
                    {renderContent("tc_content_section_2_content")}
                </ul>
            </div>
        </div>
    )
};

export default TermsAndConditions
